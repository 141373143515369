import { createApiClient } from "./utils";
import { queryBuilder } from "../shared/query-builder";

export default class ApiService {
  globalFilters = {};
  collection = "";
  defaultLimit = 15;
  client;
  constructor() {
    this.client = createApiClient();
  }
  async all(config = null) {
    if (this.collection.trim().length < 1) {
      throw "Should specify collection name!";
    }

    let url = `/api/${this.collection}`;
    if (config) {
      config.filters = { ...config.filters, ...this.globalFilters };
      url += `?${queryBuilder(config)}`;
    }

    const { data } = await this.client.get(url);
    return data.data;
  }
  async first(value, key = "id", extraConfig = null) {
    let config;

    if (extraConfig) {
      config = extraConfig;
      config.filters = {
        [key]: [value],
        ...config.filters,
      };
    } else {
      config = {
        filters: {
          [key]: [value],
        },
      };
    }
    const responseData = await this.all(config);
    if (!Array.isArray(responseData) || responseData.length < 1) {
      throw "Resource not found!";
    }
    return responseData[0];
  }
  async create(data) {
    return this.client.post(`/api/${this.collection}`, data);
  }
  async update(resource, data) {
    return this.client.put(`/api/${this.collection}/${resource}`, data);
  }
  async delete(resource) {
    return this.client.delete(`/api/${this.collection}/${resource}`);
  }
}

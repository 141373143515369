<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="ESS : Epworth Uykululuk Ölçeği Hesaplama Aracı"
        nav="ESS : Epworth Uykululuk Ölçeği Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  >
                    {{ number }}
                  </h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                    >{{ text }}</span
                  >
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div>
              <p class="font-s-m-12px font-s-16px text-secondary">
                Son zamanlarda, günlük yaşantınız içinde, aşağıda belirtilen
                durumlarda hangi sıklıkla uyuklarsınız? (buradan yorgun
                hissetmek değil, uyuklamak veya uyuya kalmak anlaşılmalıdır)
                Aşağıdaki aktiviteleri yapmıyorsanız bu durumların sizi nasıl
                etkileyeceğini düşünmeye çalışarak cevap verebilirsiniz.
              </p>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div class="w-m-100 w-50">
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Oturup Okurken
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-1-1"
                        v-model="q1"
                        type="radio"
                        class="mr-1"
                        name="ess-1"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-1-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-1-2"
                        v-model="q1"
                        type="radio"
                        class="mr-1"
                        name="ess-1"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-1-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-1-3"
                        v-model="q1"
                        type="radio"
                        class="mr-1"
                        name="ess-1"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-1-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-1-4"
                        v-model="q1"
                        type="radio"
                        class="mr-1"
                        name="ess-1"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-1-4">Yüksek</label>
                    </div>
                  </form>
                </div>
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Kamuya Açık Bir Yerde (örn. sinema veya toplantıda) Etkin
                      Olmayacak Şekilde Otururken
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-2-1"
                        v-model="q2"
                        type="radio"
                        class="mr-1"
                        name="ess-2"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-2-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-2-2"
                        v-model="q2"
                        type="radio"
                        class="mr-1"
                        name="ess-2"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-2-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-2-3"
                        v-model="q2"
                        type="radio"
                        class="mr-1"
                        name="ess-2"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-2-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-2-4"
                        v-model="q2"
                        type="radio"
                        class="mr-1"
                        name="ess-2"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-3-4">Yüksek</label>
                    </div>
                  </form>
                </div>
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Öğleden Sonraları Dinlenmek İçin Uzanırken (mümkünse)
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-3-1"
                        v-model="q3"
                        type="radio"
                        class="mr-1"
                        name="ess-3"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-3-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-3-2"
                        v-model="q3"
                        type="radio"
                        class="mr-1"
                        name="ess-3"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-3-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-3-3"
                        v-model="q3"
                        type="radio"
                        class="mr-1"
                        name="ess-3"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-3-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-3-4"
                        v-model="q3"
                        type="radio"
                        class="mr-1"
                        name="ess-3"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-3-4">Yüksek</label>
                    </div>
                  </form>
                </div>
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Öğle Yemeğinden Sonra Sessizce Otururken (alkol almamış
                      halde)
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-4-1"
                        v-model="q4"
                        type="radio"
                        class="mr-1"
                        name="ess-4"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-4-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-4-2"
                        v-model="q4"
                        type="radio"
                        class="mr-1"
                        name="ess-4"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-4-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-4-3"
                        v-model="q4"
                        type="radio"
                        class="mr-1"
                        name="ess-4"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-4-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-4-4"
                        v-model="q4"
                        type="radio"
                        class="mr-1"
                        name="ess-4"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-4-4">Yüksek</label>
                    </div>
                  </form>
                </div>
              </div>

              <div class="w-m-100 w-50">
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Televizyon İzlerken
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-5-1"
                        v-model="q5"
                        type="radio"
                        class="mr-1"
                        name="ess-5"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-5-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-5-2"
                        v-model="q5"
                        type="radio"
                        class="mr-1"
                        name="ess-5"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-5-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-5-3"
                        v-model="q5"
                        type="radio"
                        class="mr-1"
                        name="ess-5"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-5-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-5-4"
                        v-model="q5"
                        type="radio"
                        class="mr-1"
                        name="ess-5"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-5-4">Yüksek</label>
                    </div>
                  </form>
                </div>
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Ara Vermeden En Az Bir Saatlik Araba Yolculuğunda
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-6-1"
                        v-model="q6"
                        type="radio"
                        class="mr-1"
                        name="ess-6"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-6-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-6-2"
                        v-model="q6"
                        type="radio"
                        class="mr-1"
                        name="ess-6"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-6-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-6-3"
                        v-model="q6"
                        type="radio"
                        class="mr-1"
                        name="ess-6"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-6-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-6-4"
                        v-model="q6"
                        type="radio"
                        class="mr-1"
                        name="ess-6"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-6-4">Yüksek</label>
                    </div>
                  </form>
                </div>
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Oturup Biriyle Sohbet Ederken
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-7-1"
                        v-model="q7"
                        type="radio"
                        class="mr-1"
                        name="ess-7"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-7-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-7-2"
                        v-model="q7"
                        type="radio"
                        class="mr-1"
                        name="ess-7"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-7-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-7-3"
                        v-model="q7"
                        type="radio"
                        class="mr-1"
                        name="ess-7"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-7-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-7-4"
                        v-model="q7"
                        type="radio"
                        class="mr-1"
                        name="ess-7"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-7-4">Yüksek</label>
                    </div>
                  </form>
                </div>
                <div class="mr-5 mb-4">
                  <form>
                    <h5 class="font-s-20px font-w-600 black-700">
                      Bir Arabada Trafikte Birkaç Dakika Dururken
                    </h5>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-8-1"
                        v-model="q8"
                        type="radio"
                        class="mr-1"
                        name="ess-8"
                        value="0"
                      />
                      <label class="text-secondary" for="ess-8-1">Yok</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-8-2"
                        v-model="q8"
                        type="radio"
                        class="mr-1"
                        name="ess-8"
                        value="1"
                      />
                      <label class="text-secondary" for="ess-8-2">Hafif</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-8-3"
                        v-model="q8"
                        type="radio"
                        class="mr-1"
                        name="ess-8"
                        value="2"
                      />
                      <label class="text-secondary" for="ess-8-3">Orta</label>
                    </div>
                    <div class="d-flex align-items-baseline">
                      <input
                        id="ess-8-4"
                        v-model="q8"
                        type="radio"
                        class="mr-1"
                        name="ess-8"
                        value="3"
                      />
                      <label class="text-secondary" for="ess-8-4">Yüksek</label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import { ref } from "@vue/reactivity";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "EssScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const result = ref();
    const text = ref();
    const number = ref();
    const q1 = ref(0);
    const q2 = ref(0);
    const q3 = ref(0);
    const q4 = ref(0);
    const q5 = ref(0);
    const q6 = ref(0);
    const q7 = ref(0);
    const q8 = ref(0);

    const calculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      let returnText;
      let total =
        parseInt(q1.value) +
        parseInt(q2.value) +
        parseInt(q3.value) +
        parseInt(q4.value) +
        parseInt(q5.value) +
        parseInt(q6.value) +
        parseInt(q7.value) +
        parseInt(q8.value);
      if (total < 9) {
        returnText = "Normal";
      } else if ((total > 8) & (total < 12)) {
        returnText = "Hafif uykululuk";
      } else if ((total > 11) & (total < 16)) {
        returnText = "Orta düzey uykululuk";
      } else if ((total > 15) & (total < 21)) {
        returnText = "Ciddi uykululuk";
      } else {
        returnText = "Çok ciddi uykululuk";
      }
      result.value = total + " - " + returnText;
      text.value = returnText;
      number.value = total;
    };

    const clearAll = () => {
      q1.value = 0;
      q2.value = 0;
      q3.value = 0;
      q4.value = 0;
      q5.value = 0;
      q6.value = 0;
      q7.value = 0;
      q8.value = 0;
      result.value = null;
    };

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      text,
      number,
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      result,
      calculate,
      clearAll,
      apgar: [
        {
          title: "Epworth Uykululuk Ölçeği (ESS) Nedir?",
          content: `<p>Epworth Uykululuk Ölçeği gün içerisindeki uykululuk hali ve uyku durumunu ölçmek için 1990 yılında Dr. Murray Johns tarafından kısa bir anket olarak tasarlanmıştır. Ankette insanlara her gün olmasa da genel olarak gün içerisinde yaptıkları aktiviteler sırasında uyuya kalma ihtimalleri sorulup, 0 ila 3 arasında değerlendirmeleri istenir. Anket toplam 8 durumdan oluşur. Anket sonucunda alınan 0-9 arası puan normal olarak değerlendirilirken 10-24 arası puan uzmana danışılması gerektiğini göstermektedir. 11-15 arası skorlar hafif-orta seviyede uyku apnesine işaret ederken, 16 puan ve üstü ağır seviyede uyku apnesi ya da narkolepsiye işaret etmektedir.</p>
            <p>Test genel olarak yetişkinler için tasarlanmıştır. ESS-CHAD isimli versiyonu ise çocuk ve ergenlerde kullanılmak üzere ufak değişiklikler yapılarak yenilenmiştir.</small></p>
            <p>Gündüz aşırı uykululuk hali bir uyku bozukluğunun ya da altta yatan bir sağlık durumunun belirtisi olabilir. Bu anketin sonucu, doktorunuza herhangi bir uyku bozukluğunu teşhis etme ya da bozukluğun gidişatını takip etme olanağı sağlayacaktır.</p>

            <hr>

            <p>Anket sırasında, oturarak bir şeyler okurken, televizyon izlerken, toplantı veya sinema gibi kamuya açık bir yerde etkin olmayan bir şekilde otururken, ara vermeden yapılan 1 saatlik araba yolculuğunda, öğlen dinlenmek için uzanırken, oturarak biriyle sohbet ederken, alkolsüz öğle yemeğinden sonra sessizce otururken, trafikte birkaç dakikalığına durmuş arabada otururken uyuya kalma olasılığı 0 ila 3 arasında değerlendirilir. Ankette 0 asla uyuklamam, 1 düşük ihtimalle uyuklayabilirim, 2 orta ihtimalle uyuklayabilirim ve 3 yüksek ihtimalle uyuklayabilirim anlamlarına gelmektedir.</p>

            <hr>


            <p>Eğer yukarıda geçen durumlardan herhangi birinden ya da birden fazla durumdan şikayetçiyseniz ve altında yatan bir sorun olabileceğinden şüpheleniyorsanız, Medikaynak’ta yer alan ESS Epworth Uykululuk Ölçeği testini uygulayabilirsiniz.</p>
            <p>Ayrıca, doktorlar da Medikaynak’ta yer alan ESS Epworth Uykululuk Ölçeği testini hastalarına rahatlıkla uygulayabilir ve çıkan sonuca göre durum değerlendirmesi yapabilirler.</p>`,
        },
      ],
      references: [
        "https://www.healthline.com/health/epworth-sleepiness-scale#scoring",
        "https://en.wikipedia.org/wiki/Epworth_Sleepiness_Scale",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <div
    class="
      podcast-card
      mb-3
      box-shadow-4-20-2
      d-flex
      flex-column flex-md-row
      justify-content-between
      mr-0 mr-md-4
      p-4
    "
  >
    <div class="d-flex flex-column align-items-center flex-md-row">
      <div class="mb-3 mb-md-0 mr-md-4">
        <div
          class="border-radius px-4 py-3"
          :class="activePodcast.id == podcast.id ? 'bg-primary' : 'bg-blue-300'"
        >
          <voice-icon :size="50" color="white" />
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
          <clock-icon :size="14" color="#7684AA" class="mr-1" />
          <span class="font-s-10px gray-500">{{
            dayjs(podcast.publish_on ?? podcast.created_at).format("DD.MM.YYYY")
          }}</span>
        </div>
        <div v-if="podcast.channel">
          <router-link
            :to="{
              name: 'podcastchanneldetail',
              params: { id: podcast.channel.id },
            }"
            class="font-s-12px blue-300"
            >Konuk: {{ podcast.channel.title }}
          </router-link>
        </div>
        <div>
          <h5 class="font-s-16px font-w-600 black-700">
            {{ podcast.title }}
          </h5>
        </div>
      </div>
    </div>
    <div
      class="
        d-flex
        align-items-center
        justify-content-center
        position-relative
        btn-podcast
      "
    >
      <div class="d-flex flex-column mr-4">
        <div class="position-relative">
          <podcast-play-icon
            :size="39"
            color="#AFBBDB"
            class="cursor-pointer"
            @click="playPodcast(podcast)"
          />
          <transition name="slide-fade">
            <div
              v-if="playingText"
              class="position-absolute bg-white border-radius p-2"
            >
              <span class="font-s-12px">Oynatılıyor</span>
            </div>
          </transition>
        </div>
        <span v-if="podcast.duration" class="gray-300 text-center">
          {{ calcDuration(podcast.duration) }}</span
        >
      </div>
      <div class="bg-blue-300 border-radius-50 p-1 position-relative">
        <plus-icon
          :size="39"
          color="white"
          class="cursor-pointer"
          @click="addPodcastList(podcast)"
        />
        <transition name="slide-fade">
          <span
            v-if="warningText"
            class="warning-text font-s-12px position-absolute"
            >Playliste eklendi.</span
          >
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useStore } from "vuex";
import { ref, computed } from "vue";

dayjs.extend(duration);

export default {
  name: "PodcastCard",
  components: {},
  props: {
    podcast: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const warningText = ref(false);
    const playingText = ref(false);

    const activePodcast = computed(() => {
      return store.getters.getActivePodcast;
    });

    const addPodcastList = (podcast) => {
      warningText.value = true;
      store.commit("setPodcastList", podcast);
      store.dispatch("setStoragePodcastList");
      if (!activePodcast.value.id) {
        store.commit("setActivePodcast", podcast);
      }
      setTimeout(() => {
        warningText.value = false;
      }, 2000);
    };

    const playPodcast = (podcast) => {
      playingText.value = true;
      if (activePodcast.value.id != podcast.id) {
        store.commit("setPodcastList", podcast);
        store.dispatch("setStoragePodcastList");
        store.commit("setActivePodcast", podcast);
      }
      if (!activePodcast.value.id) {
        store.commit("setActivePodcast", podcast);
      }
      setTimeout(() => {
        playingText.value = false;
      }, 2000);
    };

    const calcDuration = (duration) => {
      if (parseInt(duration) % 60 < 10) {
        return (
          parseInt(parseInt(duration) / 60) +
          ":" +
          "0" +
          (parseInt(duration) % 60)
        );
      } else {
        return (
          parseInt(parseInt(duration) / 60) + ":" + (parseInt(duration) % 60)
        );
      }
    };

    return {
      dayjs,
      addPodcastList,
      warningText,
      playPodcast,
      playingText,
      activePodcast,
      calcDuration,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-podcast {
  right: -45px;
}

.warning-text {
  left: 45px;
  top: 0;
  line-height: 13px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media (max-width: 768px) {
  .btn-podcast {
    right: 0;
  }
}
</style>

import { createApiClient } from "./utils";

const apiClient = createApiClient();

export default {
  getAuthUserInformation() {
    return apiClient.get("/api/me", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("api_token"),
      },
    });
  },
  refreshToken() {
    return apiClient.post("/oauth/token", {
      client_id: process.env.MIX_OAUTH_CLIENT_ID,
      client_secret: process.env.MIX_OAUTH_CLIENT_SECRET,
      grant_type: "refresh_token",
      refresh_token: localStorage.getItem("api_refresh_token"),
    });
  },
  login(username, password) {
    return apiClient.post("/oauth/token", {
      username,
      password,
      client_id: process.env.MIX_OAUTH_CLIENT_ID,
      client_secret: process.env.MIX_OAUTH_CLIENT_SECRET,
      grant_type: "password",
    });
  },
  updatePassword(requestBody) {
    return apiClient.put("/api/me/update-password", requestBody);
  },
  update(requestBody) {
    return apiClient.put("/api/me/update-general", requestBody);
  },
  notifications(id) {
    return apiClient.get(`api/users/${id}/notifications`);
  },
};

<template>
  <svg
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.949247 21.1119L0.890341 21.0557C0.659639 20.8122 0.523438 20.4341 0.523438 19.9445V20.0597V2.57029C0.523438 2.56871 0.523438 2.56753 0.523438 2.56626C0.523438 2.56776 0.523438 2.56903 0.523438 2.57061V2.68615C0.523438 2.15599 0.682233 1.75664 0.948295 1.51718L10.7708 11.3149L0.949247 21.1119ZM0.523438 2.56531C0.523438 2.52174 0.524627 2.47943 0.526846 2.43776C0.524627 2.47911 0.523438 2.52174 0.523438 2.56531ZM0.526846 2.43586C0.526846 2.4353 0.526846 2.43467 0.527084 2.43404C0.526846 2.43467 0.526846 2.4353 0.526846 2.43586ZM0.527084 2.43032C0.527084 2.43001 0.527084 2.42969 0.527084 2.42937C0.527084 2.42969 0.527084 2.43001 0.527084 2.43032Z"
      :fill="color"
    />
    <path
      d="M16.0862 14.7872L16.1609 14.7449L20.0399 12.5464C20.4098 12.3366 20.6562 12.0824 20.7792 11.8134C20.6565 12.0824 20.41 12.337 20.0399 12.5467L16.1609 14.7452L16.0862 14.7872ZM16.0871 14.6713L12.8125 11.4044L16.0868 8.13795L20.0399 10.3781C20.5419 10.6627 20.8237 11.0294 20.8708 11.404C20.8708 11.4044 20.8708 11.405 20.8708 11.4055C20.8237 11.7794 20.5419 12.1464 20.0399 12.4308L16.0871 14.6713Z"
      :fill="color"
    />
    <path
      d="M2.39473 22.7218C2.12034 22.7218 1.88092 22.6329 1.69422 22.4648L1.69454 22.4645C1.88124 22.6326 2.12098 22.7218 2.39536 22.7218C2.41915 22.7218 2.44333 22.7212 2.46783 22.72C2.44301 22.7212 2.41851 22.7218 2.39473 22.7218ZM2.39505 22.606C2.12066 22.6063 1.88124 22.517 1.69454 22.349V22.3487L11.5161 12.5517L14.7908 15.8187L3.28321 22.3407C2.96483 22.5207 2.66317 22.606 2.39505 22.606ZM1.69176 22.4627C1.6744 22.447 1.65735 22.4303 1.64062 22.413L1.69176 22.4627Z"
      :fill="color"
    />
    <path
      d="M11.4632 10.5837L1.64062 0.786021C1.82733 0.618292 2.06643 0.529326 2.3405 0.529326C2.60934 0.529326 2.91123 0.614891 3.23025 0.795273L14.7375 7.31728L11.4632 10.5837ZM14.8115 7.24349L3.23025 0.679814C2.91123 0.499353 2.60934 0.413789 2.3405 0.413789C2.33923 0.413789 2.33836 0.413789 2.33709 0.413789C2.33868 0.413789 2.33987 0.413789 2.34177 0.413789C2.60997 0.413789 2.91155 0.499037 3.23025 0.679499L14.8115 7.24349Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "AndroidIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  svg {
    width: 14px;
    height: 16px;
  }
}
</style>

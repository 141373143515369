<template>
  <div class="upcoming-events bg-image pt-30px">
    <div v-if="upcomingEvents.total" class="container-xl events">
      <section-title
        v-if="mappedEvents.length"
        title-dark="Yaklaşan"
        title-blue="Etkinlikler"
      />

      <div>
        <event-card
          v-for="(event, index) in mappedEvents"
          :key="index"
          :event="event"
        />
      </div>
    </div>
    <div v-else class="container-xl events pb-5">Etkinlik Bulunamadı.</div>
  </div>
</template>

<script>
import SectionTitle from "../ui/SectionTitle.vue";
import EventCard from "../ui/EventCard.vue";
import dayjs from "dayjs";

export default {
  name: "UpcomingEvents",
  components: {
    SectionTitle,
    EventCard,
  },
  props: {
    upcomingEvents: { type: Object, required: true },
  },
  setup(props) {
    const mappedEvents =
      props.upcomingEvents.items?.map((event) => {
        return {
          ...event,
          day: dayjs(event.starts_at).locale("tr").format("D"),
          week_day: dayjs(event.starts_at).locale("tr").format("dddd"),
          month: dayjs(event.starts_at).locale("tr").format("MMMM"),
        };
      }) || [];

    return {
      mappedEvents,
    };
  },
};
</script>

<style lang="scss" scoped>
.upcoming-events {
  background: url("/new-img/upcoming-events-bg.png");
}
//   .events{
//     position: relative;
//     top: -465px;
//   }
</style>

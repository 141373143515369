import { createApiClient } from "../utils";

const apiClient = createApiClient();
apiClient.defaults.baseURL = process.env.MIX_SANTAFARMA_API_URL;

export default {
  getCategories() {
    return apiClient.get(
      "/collections/product_categories/entries?filter[site]=tr&filter[parent]"
    );
  },
  getCategoryById(id) {
    return apiClient.get(
      `/collections/product_categories/entries?filter[site]=tr&filter[id]=${id}`
    );
  },
  getSubCategoriesById(id) {
    return apiClient.get(
      `/collections/product_categories/entries?filter[site]=tr&filter[parent]=${id}`
    );
  },
  getProductsById(id) {
    return apiClient.get(
      `/collections/products/entries?filter[product_categories:contains]=${id}`
    );
  },
};

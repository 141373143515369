<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="FLIPI (Follicular Lymphoma International Prognostic Index)"
        nav="FLIPI (Follicular Lymphoma International Prognostic Index)"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4 class="red-200 font-s-12px font-w-500 return-number"></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                    >{{ result }}</span
                  >
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-100">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Yaş</h5>
                <select
                  v-model="age"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  :class="{ 'is-invalid': isAgeInvalid }"
                >
                  <option value="-1" selected>Lütfen Seçiniz</option>
                  <option value="0">60 yaş ve altı</option>
                  <option value="1">60 yaş ve üzeri</option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div class="w-m-100 w-50">
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Hastalık Evresi
                  </h5>
                  <select
                    v-model="evre"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    :class="{ 'is-invalid': isEvreInvalid }"
                  >
                    <option value="-1" selected>Lütfen Seçiniz</option>
                    <option value="0">Evre I-II</option>
                    <option value="1">Evre III-IV</option>
                  </select>
                </div>
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    LDH Seviyesi
                  </h5>
                  <select
                    v-model="ldh"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    :class="{ 'is-invalid': isLdhInvalid }"
                  >
                    <option value="-1" selected>Lütfen Seçiniz</option>
                    <option value="0">Normal</option>
                    <option value="1">Yükselmiş</option>
                  </select>
                </div>
              </div>

              <div class="w-m-100 w-50">
                <div class="mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Serum Hb Düzeyi
                  </h5>
                  <select
                    v-model="shb"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    :class="{ 'is-invalid': isShbInvalid }"
                  >
                    <option value="-1" selected>Lütfen Seçiniz</option>
                    <option value="0">12 g/dL veya daha az</option>
                    <option value="1">12 g/dL'dan fazla</option>
                  </select>
                </div>
                <div class="mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Tutulan Lenf Nodu Sayısı
                  </h5>
                  <select
                    v-model="lns"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    :class="{ 'is-invalid': isLnsInvalid }"
                  >
                    <option value="-1" selected>Lütfen Seçiniz</option>
                    <option value="0">0-3</option>
                    <option value="1">4 ve daha fazlası</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import { computed, ref } from "@vue/reactivity";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "FilipiScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const result = ref();
    const age = ref(-1);
    const evre = ref(-1);
    const ldh = ref(-1);
    const lns = ref(-1);
    const shb = ref(-1);
    const isFormSent = ref(false);
    const isFormInvalid = computed(() => {
      return (
        isAgeInvalid.value &&
        isEvreInvalid.value &&
        isLdhInvalid.value &&
        isLnsInvalid.value &&
        isShbInvalid.value
      );
    });
    const isAgeInvalid = computed(() => {
      if (isFormSent.value) {
        return age.value < 0;
      }
      return false;
    });
    const isEvreInvalid = computed(() => {
      if (isFormSent.value) {
        return evre.value < 0;
      }
      return false;
    });
    const isLdhInvalid = computed(() => {
      if (isFormSent.value) {
        return ldh.value < 0;
      }
      return false;
    });
    const isLnsInvalid = computed(() => {
      if (isFormSent.value) {
        return lns.value < 0;
      }
      return false;
    });
    const isShbInvalid = computed(() => {
      if (isFormSent.value) {
        return shb.value < 0;
      }
      return false;
    });

    const calculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      isFormSent.value = true;
      if (isFormInvalid.value) {
        return false;
      }

      let bStatus = age.value + evre.value + ldh.value + lns.value + shb.value;
      if (bStatus < 2) {
        result.value =
          "Düşük risk (0-1 puan) 5 yıllık sağkalım 91% , 10 yıllık sağkalım 71%";
      } else if (bStatus == 2) {
        result.value =
          "Orta risk (2 puan) &ndash; 5 yıllık sağkalım 78% , 10 yıllık sağkalım 51%";
      } else if (bStatus >= 3) {
        result.value =
          "Yüksek risk (3-5 puan) - 5 yıllık sağkalım 53% , 10 yıllık sağkalım 36%";
      }
    };

    const clearAll = () => {
      age.value = null;
      evre.value = null;
      ldh.value = null;
      lns.value = null;
      shb.value = null;
      result.value = null;
    };

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      result,
      age,
      evre,
      ldh,
      lns,
      shb,
      isAgeInvalid,
      isEvreInvalid,
      isLdhInvalid,
      isLnsInvalid,
      isShbInvalid,
      calculate,
      clearAll,
      apgar: [
        {
          title: "Foliküler Lenfoma Nedir?",
          content: `<p>Foliküler lenfoma vücuttaki beyaz kan hücrelerinde başlayan bir kanser türü olmakla birlikte non-Hodgkin lenfomanın en sık görülen alt türüdür. Genel olarak yavaş büyüyen bir lenfoma tipidir, bu yüzden indolent yani yavaş seyirli lenfoma olarak da adlandırılır. Foliküler lenfoma genellikle tedavi edilemeyen bir kanser türü olsa da, yavaş ilerlediği için uzun ve sağlıklı bir yaşam söz konusu olabilir.</p>
            <p>Foliküler lenfomada, hasar görmüş beyaz kan hücreleri, organlar, kemik iliği, lenf bezi gibi vücudun farklı bölgelerine giderek buralarda tümör oluşturabilir.</p>
            <p>Foliküler lenfomaya neyin sebep olduğu tam olarak bilinmemektedir. Diğer bazı kanser türleri gibi kalıtsal da değildir. Radyasyon ve kansere sebep olan kimyasallara maruz kalmak bu kanser türüne sebep olabilir.</p>
            <p>Genellikle 60 yaş ve üstü olmak üzere, yaşlı insanların foliküler lenfoma olma riski daha yüksektir. Aynı zamanda HIV, romatoid artrit, lupus ve çölyak hastalığı gibi bağışıklık sistemi hastalıklarına sahip olan insanların da foliküler kanser olma riski daha fazladır.</p>
            <p>Boyun, kasık, karın ve koltuk altı gibi bölgelerdeki lenf bezlerine acısız şişlikler, nefes darlığı, yorgunluk hissi, gece terlemesi ve kilo kaybı bu kanserin belirtilerinden olabilir.</p>`,
        },
        {
          title:
            "Foliküler Lenfoma Uluslararası Prognostik Endeksi (FLIPI) Nedir?",
          content: `<p>Foliküler lenfomada birkaç farklı tedavi seçeneğinden bahsedilebilir. International Prognostic Index (IPI), agresif non-Hodgkin lenfoma hastaları için tasarlanmıştır. Foliküler lenfoma hastalarının %10-15 kadarının tedavi edilebilme şansı düşüktür. Bu gibi yoğun tedavinin gerektiği hastalarda IPI uygulamak uygun değildir. Bu yüzden ortaya Follicular Lymphoma International Prognostic Index (FLIPI) çıkmıştır. Bu yöntem, foliküler lenfoma hastalarının prognostik değerlendirmeleri için kullanılan yararlı ve kolay bir yöntemdir.</p>
            <p>0 ila 1 arasında yer alan FLIPI skoru “düşük riskli” olarak görülür ve %70 oranında 10 yıllık yaşama ihtimali vardır. 2 olan FLIPI skoru “orta risk” anlamına gelir ve %50 oranında 10 yıllık yaşama ihtimali vardır. 3 ve 3’ten büyük skorlar ise “yüksek risk” anlamına gelmektedir ve %35 oranında 10 yıllık yaşama ihtimali vardır.</p>
            <p>FLIPI (Follicular Lymphoma International Prognostic Index) hesaplama aracına Medikaynak’tan ulaşabilirsiniz.</p>`,
        },
      ],
      references: [
        "https://oncologypro.esmo.org/oncology-in-practice/practice-tools/international-prognostic-index-tools-for-lymphoma/international-prognostic-index-for-follicular-lymphoma",
        "https://www.mdcalc.com/follicular-lymphoma-international-prognostic-index-flipi",
        "https://www.webmd.com/cancer/lymphoma/follicular-lymphoma#1",
        "https://getappvice.com/tr/folikuler-lenfoma-nedir-tedavi-secenekleri/",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

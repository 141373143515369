<template>
  <div class="message mb-md-5">
    <section class="message-main bg-image d-flex flex-column mb-3 mb-md-5">
      <content-main
        title="Mesaj Kutusu"
        nav="Mesaj Kutusu"
        class="mt-m-100px mt-160px"
      />
    </section>

    <section>
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-md-5 col-lg-4">
            <div
              :class="showMessage == true ? 'd-none d-md-block' : 'd-block'"
              class="bg-white box-shadow-4-20-2 border-radius pb-3"
            >
              <a
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  px-4
                  border-radius
                  comment-top-bar
                  cursor-pointer
                "
                @click="showMessageForm = true"
              >
                <span class="text-white font-s-12px">Yeni Mesaj Oluştur</span>
                <comment-icon color="#fff" :size="16" />
              </a>
              <div>
                <messages-thread
                  v-for="threadId in userThreads"
                  :key="threadId"
                  :thread-id="threadId"
                  @click="threadClick"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-md-7 col-lg-8">
            <div class="message-content-box position-relative d-block">
              <messages-form v-if="showMessageForm" @created="messageCreated" />
              <delete-conversation
                v-if="activeThread && askingDeleting"
                :thread="activeThread"
                @accept="threadDeleted($event)"
                @deny="askingDeleting = false"
              />
              <div
                v-if="false"
                class="
                  position-absolute
                  w-100
                  h-100
                  report
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <report-img-icon :size="90" :color="reportColor" class="mb-3" />
                <p class="text-primary font-s-18px font-w-500">
                  Sorunu anlamamıza yardım et.
                  <b class="font-weight-bold blue-300"
                    >⁦Prof. Dr. Sibel Çakır⁩</b
                  >
                  adlı kullanıcıyı hangi sorundan dolayı bildiriyorsun?
                </p>
                <p
                  class="text-secondary font-s-12px font-w-600 w-100 text-left"
                >
                  Sohbeti bildirme sebebin
                </p>
                <select
                  id=""
                  name=""
                  class="select-report text-secondary px-3 mb-4 bg-transparent"
                >
                  <option value="0">
                    Tehdit ve hakaret içerikli mesajlar gönderiyor
                  </option>
                  <option value="1">
                    Tehdit ve hakaret içerikli mesajlar gönderiyor
                  </option>
                  <option value="2">
                    Tehdit ve hakaret içerikli mesajlar gönderiyor
                  </option>
                </select>
                <div
                  class="
                    d-flex
                    flex-column flex-md-row
                    justify-content-md-center
                    w-100
                  "
                >
                  <button
                    class="
                      w-m-100
                      mb-3 mb-md-0
                      btn
                      bg-gray-500
                      width-84px
                      height-50px
                      font-s-12px font-w-500
                      text-white
                      border-radius
                      mr-4
                    "
                    @click="showReport = false"
                  >
                    Vazgeç
                  </button>
                  <button
                    class="
                      w-m-100
                      btn
                      bg-blue-300
                      width-120px
                      height-50px
                      font-s-12px font-w-500
                      text-white
                      border-radius
                    "
                    @click="showReport = false"
                  >
                    Sohbeti Bildir
                  </button>
                </div>
              </div>
              <div
                v-if="false"
                class="
                  px-5 px-md-0
                  border-radius
                  position-absolute
                  w-100
                  h-100
                  message-request
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <p class="font-s-18px black-700 max-w-555px text-center mb-5">
                  Merhaba Nazmiye, <br /><b
                    class="text-info message-request-person"
                    >Prof. Dr. Sibel Çakır</b
                  >
                  sana bir mesaj göndermek istiyor.
                </p>
                <div
                  class="
                    d-flex
                    flex-column flex-md-row
                    justify-content-md-center
                    w-100
                  "
                >
                  <button
                    class="
                      w-m-100
                      mb-3 mb-md-0
                      btn
                      bg-gray-500
                      width-84px
                      height-50px
                      text-white
                      font-s-12px font-w-500
                      border-radius
                      mr-4
                    "
                    @click="showMessageRequest = false"
                  >
                    Reddet
                  </button>
                  <button
                    class="
                      w-m-100
                      btn btn-info
                      width-84px
                      height-50px
                      font-s-12px font-w-500
                      border-radius
                    "
                    @click="showMessageRequest = false"
                  >
                    Kabul Et
                  </button>
                </div>
              </div>
              <messages-conversation
                v-if="!showMessageForm && activeThread"
                :thread="activeThread"
                @ask-deleting="askingDeleting = true"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import { message } from "../helpers/ExampleMessage.js";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ColorHelper from "../helpers/ColorHelper";
import MessagesThread from "../components/Messages/MessagesThread";
import MessagesConversation from "../components/Messages/MessagesConversation";
import MessagesForm from "../components/Messages/MessagesForm";
import DeleteConversation from "../components/Messages/Conversation/DeleteConversation";
import { useRoute, useRouter } from "vue-router";
import { child, onValue } from "firebase/database";
import { userThreadsDocRef } from "../services/FirebaseChatService";
import { threadsDocRef } from "../services/FirebaseChatService";

export default {
  name: "Message",
  components: {
    MessagesForm,
    MessagesConversation,
    MessagesThread,
    ContentMain,
    DeleteConversation,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const user = computed(() => store.state.user.user);
    const userThreads = ref([]);
    const activeThread = ref(null);
    const askingDeleting = ref(false);
    const showMessageForm = ref(false);

    onMounted(() => {
      if (route.params.thread_id) {
        const threadsDocRefChild = child(
          threadsDocRef,
          route.params.thread_id.toString()
        );
        onValue(threadsDocRefChild, (snapshot) => {
          activeThread.value = snapshot.val();
        });
      }
    });

    const userThreadsRef = child(userThreadsDocRef, user.value.id.toString());
    onValue(userThreadsRef, (snapshot) => {
      const value = snapshot.val();
      if (!value) {
        userThreads.value = [];
      } else {
        userThreads.value = Object.values(snapshot.val()).filter(
          (id) => id !== undefined
        );
      }
    });

    const threadClick = (thread) => {
      askingDeleting.value = false;
      showMessageForm.value = false;
      router.push({
        name: "message",
        params: {
          thread_id: thread.id,
        },
      });
    };

    const messageCreated = (threadId) => {
      threadClick({ id: threadId });
      showMessageForm.value = false;
    };

    const threadDeleted = (thread) => {
      askingDeleting.value = false;
      const index = userThreads.value.indexOf(thread);
      if (index > -1) {
        userThreads.value.splice(index, 1);
      }
      router.push({
        name: "message",
        params: {
          thread_id: null,
        },
      });
    };

    return {
      email: computed(() => store.state.user.user.email),
      color: ColorHelper[store.state.theme].blueText,
      colorDark: ColorHelper[store.state.theme].blueDark,
      reportColor: ColorHelper[store.state.theme].blue300,
      main_img: "url('/new-img/" + store.state.theme + "-content-main-bg.png')",
      showMessageRequest: false,
      showReport: false,
      message: message,
      lastMessage: [],
      showMessage: false,
      askingDeleting,
      threadDeleted,
      userThreads,
      activeThread,
      threadClick,
      messageCreated,
      showMessageForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}

.input-open-box {
  max-width: 360px;
  width: 100%;
  top: 40px;
  padding: 23px 0;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10%;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  span {
    padding: 10px 50px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: v-bind("colorSearch");
      background: #daedf733;
    }
  }
}

.textarea-message {
  width: 100%;
  border: 1px solid #374a59;
  border-radius: 10px;
  padding: 13px 10px;

  &::placeholder {
    font-size: 12px;
    color: #7684aa;
  }
}

.person-box {
  margin: 6px 7px;
}

.textarea-message {
  height: 314px;
}

.comment-top-bar {
  height: 62px;
  background: linear-gradient(319.36deg, #ff6188 2.25%, #ffb199 95.74%);
}

.mobile-btn {
  width: 48px;
  height: 40px;
}

.message-box-person {
  padding-left: 59px;
}

.message-box {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 90px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 90px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: v-bind("color");
  }
}

.message-request {
  background: rgba(251, 252, 255, 0.8);
  backdrop-filter: blur(5px);
  padding-bottom: 200px;
}

.report {
  background: rgba(251, 252, 255, 0.8);
  backdrop-filter: blur(5px);
  padding: 100px 100px 300px;
}

.message-request-person {
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.select-report {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #374a59;
  font-size: 12px;
}

@media (max-width: 768px) {
  .message-content-box {
    // top: -50px;
  }

  .report {
    padding: 0 !important;
  }

  .message-request-person {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.person-img {
  width: 28px;
  height: 28px;
}

.person-img-large {
  width: 50px;
  height: 50px;
}

@media (max-width: 576px) {
  .message-main {
    height: 200px;
  }
}
</style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33301 1.33334H5.99967C2.66634 1.33334 1.33301 2.66668 1.33301 6.00001V10C1.33301 13.3333 2.66634 14.6667 5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3333 14.6663 10V8.66668"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6933 2.01332L5.43992 7.26665C5.23992 7.46665 5.03992 7.85999 4.99992 8.14665L4.71325 10.1533C4.60659 10.88 5.11992 11.3867 5.84659 11.2867L7.85325 11C8.13325 10.96 8.52659 10.76 8.73325 10.56L13.9866 5.30665C14.8933 4.39999 15.3199 3.34665 13.9866 2.01332C12.6533 0.679985 11.5999 1.10665 10.6933 2.01332Z"
      :stroke="color"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.94043 2.76666C10.3871 4.36 11.6338 5.60666 13.2338 6.06"
      :stroke="color"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EditIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },
  },
};
</script>

<style></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.71933 2.30151H13.2635C14.9793 2.30151 16.4585 3.69235 16.4585 5.40901V14.3365C16.4585 16.1498 15.076 17.5957 13.2635 17.5957H6.606C4.88933 17.5957 3.4985 16.054 3.4985 14.3365V6.69818L7.71933 2.30151Z"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.93848 2.29175V4.71591C7.93848 5.89925 6.98098 6.85925 5.79848 6.86175C4.70098 6.86425 3.57848 6.86508 3.50265 6.86008"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.09674 12.9648H12.5942"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.79806 8.83781H12.5947"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArticleIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "#7684AA",
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },
  },
};
</script>

<style></style>

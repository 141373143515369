<template>
  <div
    class="
      mb-5
      bg-white
      height-530px
      upload-article-box
      border-radius
      d-flex
      flex-column
      align-items-center
      justify-content-center
    "
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="dropFile($event)"
  >
    <img src="/new-img/upload-article.png" alt="" class="mb-4 upload-img" />
    <template v-if="!currentFile">
      <p class="font-s-12px gray-500 mb-4">
        {{ isType == "makale" ? "Makalenizi" : "Videonuzu" }} bu alana
        sürükleyip bırakın ya da yükleyin
      </p>
      <button
        class="
          btn
          bg-blue-light
          d-flex
          upload-btn
          mb-5
          d-flex
          align-items-center
          justify-content-center
          border-radius
        "
        @click="$refs.file.click()"
      >
        <upload-icon color="#114DAE" class="mr-1" />
        <span class="font-s-12px text-primary"
          >{{ isType == "makale" ? "Makale" : "Video" }} Yükle</span
        >
      </button>
      <p class="font-s-10px red-200 px-3">
        Dosyanızın
        {{ acceptables }}
        uzantılı olmasına özen gösterin. Maksimum 10MB yüklenebilir.
      </p>
    </template>
    <template v-else>
      <span class="font-s-12px gray-500 mb-4">
        {{ isType == "makale" ? "Makale" : "Video" }} Başarıyla Eklendi
      </span>
      <button
        class="
          btn
          d-flex
          upload-btn
          mb-5
          d-flex
          align-items-center
          justify-content-center
          border-radius
        "
        @click="removeFile"
      >
        <delete-img-icon />
        <span class="font-s-12px text-primary">
          {{ isType == "makale" ? "Makaleyi" : "Videoyu" }} Sil
        </span>
      </button>
    </template>
    <input
      ref="file"
      type="file"
      :accept="acceptables"
      style="display: none"
      @change="selectFile"
    />
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
export default {
  props: {
    isType: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:files"],
  setup(props, ctx) {
    const file = ref();
    const currentFile = ref(null);

    const acceptables = computed(() => {
      if (props.isType === "makale") {
        return ".pdf, .doc, .docx, .rft, .txt";
      } else {
        return ".mp4, .mpeg, .avi";
      }
    });

    const selectFile = () => {
      currentFile.value = file.value.files[0];
    };

    const dropFile = (e) => {
      const files = e.dataTransfer.files;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      currentFile.value = file;
    };

    const removeFile = () => {
      currentFile.value = null;
    };

    watch(currentFile, (newVal, oldVal) => {
      if (newVal) {
        const clearedFiles = props.files.filter((x) => x !== oldVal);
        ctx.emit("update:files", [...clearedFiles, newVal]);
      } else {
        const clearedFiles = props.files.filter((x) => x !== oldVal);
        ctx.emit("update:files", [...clearedFiles]);
      }
    });

    return {
      file,
      currentFile,
      selectFile,
      dropFile,
      removeFile,
      acceptables,
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-article-box {
  border: 1px dashed #7684aa;
}

.upload-img {
  max-height: 316px;
  max-width: 316px;
}

.upload-btn {
  width: 208px;
  height: 36px;
}
</style>

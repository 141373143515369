<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="eventDetail.title" class="event-detail">
    <section
      class="event-detail-main bg-image d-flex justify-content-center mb-5"
    >
      <content-main
        :title="eventDetail.title"
        :nav="eventDetail.title"
        class="mt-m-100px mt-160px max-w-640px"
      />
    </section>

    <section class="mb-5">
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3 mb-3 mb-sm-0">
            <div class="bg-primary border-radius h-100 px-4">
              <div class="d-flex align-items-start py-4 event-detail-box">
                <div class="mr-2">
                  <event-date-icon :size="24" :color="iconColor" />
                </div>
                <div class="d-flex flex-column">
                  <span class="blue-300 font-s-12px font-w-500"
                    >Etkinlik Tarihi</span
                  >
                  <span class="text-white font-w-500">{{
                    dayjs(eventDetail.starts_at).format("DD MMMM dddd")
                  }}</span>
                  <span
                    v-if="
                      dayjs(eventDetail.starts_at).format('DD MMMM dddd') !==
                      dayjs(eventDetail.ends_at).format('DD MMMM dddd')
                    "
                    class="text-white font-w-500"
                    >{{
                      dayjs(eventDetail.ends_at).format("DD MMMM dddd")
                    }}</span
                  >
                </div>
              </div>
              <div class="d-flex align-items-start py-4 event-detail-box">
                <div class="mr-2">
                  <event-clock-icon :size="24" :color="iconColor" />
                </div>
                <div class="d-flex flex-column">
                  <span class="blue-300 font-s-12px font-w-500"
                    >Etkinlik Saati</span
                  >
                  <span class="text-white font-w-500">{{
                    dayjs(eventDetail.starts_at).format("HH:mm") +
                    " - " +
                    dayjs(eventDetail.ends_at).format("HH:mm")
                  }}</span>
                </div>
              </div>
              <div
                v-if="eventDetail.place"
                class="d-flex align-items-start py-4 event-detail-box"
              >
                <div class="mr-2">
                  <event-location-icon :size="24" :color="iconColor" />
                </div>
                <div class="d-flex flex-column">
                  <span class="blue-300 font-s-12px font-w-500"
                    >Canlı Yayın Adresi</span
                  >
                  <span class="text-white font-w-500">{{
                    eventDetail.place
                  }}</span>
                </div>
              </div>
              <template v-if="eventDetail.requires_ministry_report">
                <div
                  v-if="dayjs(eventDetail.starts_at).isAfter(dayjs())"
                  class="alert alert-primary mt-3"
                >
                  <span>
                    Etkinlik başladıktan sonra etkinliğe katılabilirsiniz.
                  </span>
                </div>
                <router-link
                  v-else-if="
                    dayjs(eventDetail.ends_at).isAfter(dayjs()) &&
                    eventDetail.auth_ministry_reports?.length === 0
                  "
                  :to="{
                    name: 'ministryreport',
                    params: { slug: eventDetail.slug },
                  }"
                  class="
                    mt-3
                    btn
                    bg-blue-300
                    text-white
                    border-radius-90px
                    mb-3
                    px-3
                    py-2
                    text-center
                    font-weight-bold
                    w-100
                  "
                >
                  Etkinliğe Katıl
                </router-link>
                <div
                  v-else-if="dayjs(eventDetail.ends_at).isAfter(dayjs())"
                  class="alert alert-success mt-3"
                >
                  <span>
                    Etkinliğe katıldınız. Lütfen e-posta adresinizi kontrol
                    ediniz.
                  </span>
                </div>
                <div v-else class="alert alert-danger mt-3" role="alert">
                  Etkinlik sona erdi.
                </div>
              </template>
              <template v-else-if="eventDetail.redirect_url">
                <a
                  v-if="dayjs(eventDetail.ends_at).isAfter(dayjs())"
                  :href="eventDetail.redirect_url"
                  class="
                    mt-3
                    btn
                    bg-blue-300
                    text-white
                    border-radius-90px
                    mb-3
                    px-3
                    py-2
                    text-center
                    font-weight-bold
                    w-100
                  "
                >
                  Etkinliğe Katıl
                </a>
                <div v-else class="alert alert-danger mt-3" role="alert">
                  Etkinlik sona erdi.
                </div>
              </template>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-9">
            <div class="d-flex flex-column">
              <div v-if="eventDetail.detail_image_url" class="mb-3">
                <img
                  :src="eventDetail.detail_image_url"
                  class="img-fluid border-radius"
                  :alt="eventDetail.title"
                />
              </div>
              <div v-if="dayjs(eventDetail.ends_at).isAfter(dayjs())">
                <div v-if="eventDetail.embed_code">
                  <div
                    class="border-radius"
                    v-html="eventDetail.embed_code"
                  ></div>
                </div>
                <div v-html="eventDetail.content"></div>
              </div>
              <div v-else>
                <div
                  v-html="eventDetail.content_finished || eventDetail.content"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <upcoming-events
        v-if="!isUpcomingEventsLoading && upcomingEvents.items?.length > 0"
        :upcoming-events="upcomingEvents"
      />
    </section>

    <section>
      <social-media />
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import UpcomingEvents from "../components/ui/UpcomingEvents.vue";
import SocialMedia from "../components/ui/SocialMedia.vue";
import { computed, ref, watch } from "vue";
import EventService from "../services/event/EventService";
import EventDateIcon from "../components/icon/EventDateIcon.vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";

export default {
  name: "EventDetail",
  components: {
    ContentMain,
    UpcomingEvents,
    SocialMedia,
    EventDateIcon,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const mainColor = ColorHelper[store.state.theme].articleDetailMain;
    const iconColor = ColorHelper[store.state.theme].blue300;
    const img_url = "url(/new-img/event-detail-bg.png)";
    const isEventLoading = ref(false);
    const eventDetail = ref([]);
    const isUpcomingEventsLoading = ref(false);
    const upcomingEvents = ref([]);
    const loading = computed(
      () => isUpcomingEventsLoading.value || isEventLoading.value
    );

    const fetchUpcomingEvents = () => {
      isUpcomingEventsLoading.value = true;
      EventService.getUpcomingEvents(1, 4)
        .then((response) => {
          if (!response.data.data.length) {
            return;
          }

          upcomingEvents.value = response.data.data.filter(
            (event) => event.slug !== route.params.slug
          );
        })
        .finally(() => {
          isUpcomingEventsLoading.value = false;
        });
    };

    const fetchEvent = (slug) => {
      isEventLoading.value = true;
      EventService.getEventBySlug(slug)
        .then((response) => {
          eventDetail.value = response?.data?.data?.[0];
        })
        .catch(() => {
          router.push({ name: "404" });
        })
        .finally(() => {
          isEventLoading.value = false;
        });
    };

    watch(loading, (newVal) => {
      store.commit("setLoading", newVal);
    });

    if (route.params.slug) {
      fetchEvent(route.params.slug);
    }

    onBeforeRouteUpdate((to) => {
      fetchEvent(to.params.slug);
    });

    fetchUpcomingEvents();

    return {
      mainColor,
      img_url,
      isUpcomingEventsLoading,
      upcomingEvents,
      iconColor,
      eventDetail,
      dayjs,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-detail-main {
  width: 100vw;
  height: 400px;
  background: linear-gradient(0deg, v-bind("mainColor"), v-bind("mainColor")),
    linear-gradient(
      180deg,
      rgba(7, 38, 66, 0.2) 0%,
      rgba(11, 22, 32, 0.2) 48.44%
    ),
    v-bind("img_url"), #081f32;
}
.event-detail-box {
  border-bottom: 1px solid #afbbdb7a;
}
</style>

<template>
  <div>
    <p class="font-s-12px font-w-600 gray-500">Kapak Görsel Seçimi</p>
    <div
      class="bg-white p-3 mb-4"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="dropImage($event)"
    >
      <a v-if="!previewImage" class="d-flex flex-column align-items-center">
        <p class="font-s-12px gray-500 text-center">
          Yüklemek için dosyaları sürükleyip bırakın ya da yükleyin
        </p>
        <image-icon class="mb-3" :size="48" color="#7684AA" />
        <button
          class="
            btn
            bg-blue-light
            border-radius
            d-flex
            align-items-center
            justify-content-center
            w-100
            mb-3
          "
          @click="$refs.file.click()"
        >
          <upload-icon :color="color" class="mr-1" />
          <span class="font-s-12px font-w-500 text-primary">Görsel Yükle</span>
          <input
            ref="file"
            type="file"
            accept=".jpg, .jpeg, .png"
            style="display: none"
            @change="selectImage"
          />
        </button>
        <p class="font-s-10px red-200">Maksimum 20MB yüklenebilir.</p>
      </a>
      <div v-else class="cursor-pointer" @click="removeImage">
        <img :src="previewImage" alt="" class="img-fluid upload-img-active" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      required: true,
    },
  },
  emits: ["update:files"],
  setup(props, ctx) {
    const file = ref();
    const currentImage = ref(null);
    const previewImage = ref(null);

    const selectImage = () => {
      currentImage.value = file.value.files[0];
      previewImage.value = URL.createObjectURL(currentImage.value);
    };

    const dropImage = (e) => {
      const files = e.dataTransfer.files;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      currentImage.value = file;
      previewImage.value = URL.createObjectURL(currentImage.value);
    };

    const removeImage = () => {
      currentImage.value = null;
      previewImage.value = null;
    };

    watch(currentImage, (newVal, oldVal) => {
      if (newVal) {
        const clearedFiles = props.files.filter((x) => x !== oldVal);
        ctx.emit("update:files", [...clearedFiles, newVal]);
      } else {
        const clearedFiles = props.files.filter((x) => x !== oldVal);
        ctx.emit("update:files", [...clearedFiles]);
      }
    });

    return {
      file,
      currentImage,
      previewImage,
      selectImage,
      dropImage,
      removeImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-img-active {
  &:hover {
    border: 2px dashed #fe4550;
  }
}
</style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getNonSpecialVideoCategories(page = 1, per_page = 15) {
    return apiClient.get("/api/videocategory/non-special", {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  getSpecialVideoCategories(page = 1, per_page = 15) {
    return apiClient.get("/api/videocategory/special", {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  getAllVideoCategories(page = 1, per_page = 15) {
    return apiClient.get("/api/videocategory", {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  getVideoCategory(id) {
    return apiClient.get(`/api/videocategory/${id}`);
  },
  getCategoriesVideos(id, page = 1, per_page = 15) {
    return apiClient.get(`/api/videocategory/${id}/video`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  getCategoriesPopularVideos(id, page = 1, per_page = 15) {
    return apiClient.get(`/api/videocategory/${id}/popular`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  getCategoriesRecentVideos(id, page = 1, per_page = 15) {
    return apiClient.get(`/api/videocategory/${id}/recent`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  getEpiLibraryCategories() {
    return apiClient.get("/api/epilibrary/categories");
  },
};

<template>
  <div class="products mb-100px">
    <section class="products-main bg-image d-flex flex-column mb-75px">
      <content-main
        title="Arama Sonuçları"
        nav="Arama Sonuçları"
        class="mt-160px"
      />
      <product-search-input
        :search-with-name="type === 'active-ingredient' ? true : false"
      />
    </section>

    <section class="mb-5">
      <div class="container-xl">
        <div v-if="!productPromise.loading.value" class="row">
          <template v-if="productPromise.results.value">
            <div
              v-for="(product, index) in productPromise.results.value"
              :key="index"
              class="col-6 col-sm-4 col-md-3 mb-4 height-296px"
            >
              <product-card :product="product" />
            </div>
          </template>
          <div v-else class="col-6 col-sm-4 col-md-3 mb-4 height-296px">
            Ürün bulunamadı!
          </div>
        </div>
        <loading-anim-icon v-else />
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ProductCard from "../components/ui/ProductCard.vue";
import { useStore } from "vuex";
import usePromise from "../shared/composables/use-promise";
import ProductService from "../services/product/ProductService";
import ProductSearchInput from "../components/ui/products/ProductSearchInput.vue";
import { useRoute } from "vue-router";
import { computed } from "@vue/reactivity";

export default {
  name: "Products",
  components: {
    ContentMain,
    ProductCard,
    ProductSearchInput,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    const type = computed(() => {
      return route.query.type;
    });

    const productPromise = usePromise(() => {
      if (type.value === "active-ingredient") {
        return ProductService.searchByActiveIngredient(route.query.keyword);
      } else {
        return ProductService.searchByName(route.query.keyword);
      }
    });

    productPromise.createPromise();

    return {
      route,
      type,
      productPromise,
      main_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.products-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 354px;
}
</style>

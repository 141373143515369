<template>
  <div class="search">
    <section class="search-main bg-image d-flex flex-column mb-5">
      <content-main
        title="Arama Sonuçları"
        nav="Arama"
        class="mt-m-100px mt-160px"
      />
      <div
        class="
          total-result
          d-flex
          justify-content-center
          align-items-center
          mx-auto
          position-relative
          bg-white
          border-radius-90px
          box-shadow-4-20
        "
      >
        <h4 class="font-s-18px font-w-400 text-secondary m-0">
          Bulunan Toplam
          <span class="text-info font-weight-bold">({{ total }}) Sonuç</span>
        </h4>
      </div>
    </section>

    <template v-if="theme !== 'hemaworld'">
      <section
        v-if="route.query.in === 'posts' || !route.query.in"
        class="mb-4 mb-md-0"
      >
        <div class="container-xl">
          <h4 class="font-s-24px font-weight-bold text-info mb-4 section-title">
            {{ theme == "eczazone" ? "Haberler" : "Makaleler" }}
          </h4>
          <template v-if="!isLoading">
            <div v-if="posts && posts.length" class="row">
              <div
                v-for="(post, i) in posts"
                :key="i"
                class="col-12 col-sm-6 col-md-4"
              >
                <article-sm :post="post" :color="color" class="mb-3 mb-md-5" />
              </div>
            </div>
            <p v-else>Sonuç bulunamadı</p>
          </template>
          <loading-anim-icon v-else />
        </div>
      </section>

      <section
        v-if="route.query.in === 'videos' || !route.query.in"
        class="mb-4 mb-md-0"
      >
        <div class="container-xl">
          <h4 class="font-s-24px font-weight-bold text-info mb-4 section-title">
            Videolar
          </h4>
          <template v-if="!isLoading">
            <div v-if="videos && videos.length" class="row">
              <div
                v-for="(video, i) in videos"
                :key="i"
                class="col-12 col-sm-6 col-md-4"
              >
                <videos-card :video="video" class="mb-3 mb-md-5" />
              </div>
            </div>
            <p v-else>Sonuç bulunamadı</p>
          </template>
          <loading-anim-icon v-else />
        </div>
      </section>

      <section
        v-if="route.query.in === 'podcasts' || !route.query.in"
        class="mb-4 mb-md-0"
      >
        <div class="container-xl">
          <h4 class="font-s-24px font-weight-bold text-info mb-4 section-title">
            Podcastler
          </h4>
          <template v-if="!isLoading">
            <div v-if="podcasts && podcasts.length" class="row">
              <podcast-card
                v-for="(podcast, index) in podcasts"
                :key="index"
                :podcast="podcast"
                :index="index"
              />
            </div>
            <p v-else>Sonuç bulunamadı</p>
          </template>
          <loading-anim-icon v-else />
        </div>
      </section>
    </template>

    <section
      v-if="route.query.in === 'calculations' || !route.query.in"
      class="mb-4 mb-md-0"
    >
      <div class="container-xl">
        <h4 class="font-s-24px font-weight-bold text-info mb-4 section-title">
          Hesaplama Cetvelleri
        </h4>
        <template v-if="!isLoading">
          <div v-if="calculations && calculations.length" class="row">
            <div
              v-for="(calcualtion, i) in calculations"
              :key="i"
              class="col-6 col-sm-4 col-md-4 mb-4"
            >
              <calculate-card :calculation="calcualtion" :color="color" />
            </div>
          </div>
          <p v-else>Sonuç bulunamadı</p>
        </template>
        <loading-anim-icon v-else />
      </div>
    </section>

    <pagination
      :total-pages="lastPage"
      :current-page="currentPage"
      @go-page="fetchSearchData($event)"
    />
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ArticleSm from "../components/ui/ArticleSm.vue";
import VideosCard from "../components/ui/VideosCard.vue";
import CalculateCard from "../components/ui/CalculateCard.vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import ColorHelper from "../helpers/ColorHelper";
import { useRoute } from "vue-router";
import SearchService from "../services/search/SearchService";
import PodcastCard from "../components/ui/PodcastCard.vue";
import Pagination from "../components/ui/Pagination";

export default {
  name: "Search",
  components: {
    ContentMain,
    ArticleSm,
    VideosCard,
    CalculateCard,
    Pagination,
    PodcastCard,
  },
  setup() {
    const store = useStore();
    const theme = ref(store.state.theme);
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const color = ColorHelper[store.state.theme].blueText;
    const route = useRoute();
    const posts = ref([]);
    const videos = ref([]);
    const calculations = ref([]);
    const podcasts = ref([]);
    const total = ref("...");
    const currentPage = ref(0);
    const lastPage = ref(0);
    const isLoading = ref(true);

    const fetchSearchData = (page = 1) => {
      isLoading.value = true;
      SearchService.search(route.params.keyword, route.query.in, page, 6)
        .then((response) => {
          const response_data = response.data.data;
          total.value = 0;
          lastPage.value = 0;
          // eslint-disable-next-line no-prototype-builtins
          if (response_data.hasOwnProperty("posts")) {
            posts.value = response_data.posts.items;
            total.value += response_data.posts.total;
            currentPage.value = response_data.posts.current_page;
            lastPage.value =
              response_data.posts.last_page > lastPage.value
                ? response_data.posts.last_page
                : lastPage.value;
          }

          // eslint-disable-next-line no-prototype-builtins
          if (response_data.hasOwnProperty("videos")) {
            videos.value = response_data.videos.items;
            total.value += response_data.videos.total;
            currentPage.value = response_data.videos.current_page;
            lastPage.value =
              response_data.videos.last_page > lastPage.value
                ? response_data.videos.last_page
                : lastPage.value;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (response_data.hasOwnProperty("calculations")) {
            calculations.value = response_data.calculations.items;
            total.value += response_data.calculations.total;
            currentPage.value = response_data.calculations.current_page;
            lastPage.value =
              response_data.calculations.last_page > lastPage.value
                ? response_data.calculations.last_page
                : lastPage.value;
          }

          // eslint-disable-next-line no-prototype-builtins
          if (response_data.hasOwnProperty("podcasts")) {
            podcasts.value = response_data.podcasts.items;
            total.value += response_data.podcasts.total;
            currentPage.value = response_data.podcasts.current_page;
            lastPage.value =
              response_data.podcasts.last_page > lastPage.value
                ? response_data.podcasts.last_page
                : lastPage.value;
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    fetchSearchData();

    return {
      isLoading,
      fetchSearchData,
      currentPage,
      lastPage,
      total,
      main_img,
      color,
      posts,
      videos,
      calculations,
      route,
      podcasts,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 354px;
}

.total-result {
  width: 327px;
  height: 59px;
  top: 80px;
}

@media (max-width: 567px) {
  .search-main {
    height: 200px;
  }
  .total-result {
    width: 255px;
    height: 45px;
    top: 40px;

    h4 {
      font-size: 14px !important;
    }
  }
  .section-title {
    font-size: 18px;
  }
}
</style>

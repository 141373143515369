<template>
  <div>
    <svg
      v-if="show == 1"
      width="94"
      height="94"
      viewBox="0 0 273 273"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M153.562 146.351C145.979 141.973 145.979 131.027 153.563 126.649L221.812 87.245C229.396 82.8668 238.875 88.3396 238.875 97.0961L238.875 175.904C238.875 184.661 229.396 190.134 221.812 185.755L153.562 146.351Z"
        fill="url(#paint0_linear_914:1412)"
      />
      <g filter="url(#filter0_bi_914:1412)">
        <path
          d="M34.125 113.75C34.125 92.3011 34.125 81.5766 40.7883 74.9133C47.4516 68.25 58.1761 68.25 79.625 68.25H147.875C169.324 68.25 180.048 68.25 186.712 74.9133C193.375 81.5766 193.375 92.3011 193.375 113.75V159.25C193.375 180.699 193.375 191.423 186.712 198.087C180.048 204.75 169.324 204.75 147.875 204.75H79.625C58.1761 204.75 47.4516 204.75 40.7883 198.087C34.125 191.423 34.125 180.699 34.125 159.25V113.75Z"
          fill="#F1F1F1"
          fill-opacity="0.1"
        />
        <path
          d="M34.125 113.75C34.125 92.3011 34.125 81.5766 40.7883 74.9133C47.4516 68.25 58.1761 68.25 79.625 68.25H147.875C169.324 68.25 180.048 68.25 186.712 74.9133C193.375 81.5766 193.375 92.3011 193.375 113.75V159.25C193.375 180.699 193.375 191.423 186.712 198.087C180.048 204.75 169.324 204.75 147.875 204.75H79.625C58.1761 204.75 47.4516 204.75 40.7883 198.087C34.125 191.423 34.125 180.699 34.125 159.25V113.75Z"
          fill="url(#paint1_linear_914:1412)"
          fill-opacity="0.2"
        />
        <path
          d="M34.225 113.75C34.225 103.023 34.2252 94.9873 35.057 88.8004C35.8884 82.6163 37.549 78.294 40.859 74.984C44.169 71.674 48.4913 70.0134 54.6754 69.182C60.8623 68.3502 68.8977 68.35 79.625 68.35H147.875C158.602 68.35 166.638 68.3502 172.825 69.182C179.009 70.0134 183.331 71.674 186.641 74.984C189.951 78.294 191.612 82.6163 192.443 88.8004C193.275 94.9873 193.275 103.023 193.275 113.75V159.25C193.275 169.977 193.275 178.013 192.443 184.2C191.612 190.384 189.951 194.706 186.641 198.016C183.331 201.326 179.009 202.987 172.825 203.818C166.638 204.65 158.602 204.65 147.875 204.65H79.625C68.8977 204.65 60.8623 204.65 54.6754 203.818C48.4913 202.987 44.169 201.326 40.859 198.016C37.549 194.706 35.8884 190.384 35.057 184.2C34.2252 178.013 34.225 169.977 34.225 159.25V113.75Z"
          stroke="#F1F1F1"
          stroke-opacity="0.1"
          stroke-width="0.2"
        />
        <path
          d="M34.225 113.75C34.225 103.023 34.2252 94.9873 35.057 88.8004C35.8884 82.6163 37.549 78.294 40.859 74.984C44.169 71.674 48.4913 70.0134 54.6754 69.182C60.8623 68.3502 68.8977 68.35 79.625 68.35H147.875C158.602 68.35 166.638 68.3502 172.825 69.182C179.009 70.0134 183.331 71.674 186.641 74.984C189.951 78.294 191.612 82.6163 192.443 88.8004C193.275 94.9873 193.275 103.023 193.275 113.75V159.25C193.275 169.977 193.275 178.013 192.443 184.2C191.612 190.384 189.951 194.706 186.641 198.016C183.331 201.326 179.009 202.987 172.825 203.818C166.638 204.65 158.602 204.65 147.875 204.65H79.625C68.8977 204.65 60.8623 204.65 54.6754 203.818C48.4913 202.987 44.169 201.326 40.859 198.016C37.549 194.706 35.8884 190.384 35.057 184.2C34.2252 178.013 34.225 169.977 34.225 159.25V113.75Z"
          stroke="url(#paint2_linear_914:1412)"
          stroke-opacity="0.2"
          stroke-width="0.2"
        />
      </g>
      <g filter="url(#filter1_i_914:1412)">
        <path
          d="M56.875 102.375C56.875 96.0928 61.9678 91 68.25 91H159.25C165.532 91 170.625 96.0928 170.625 102.375C170.625 108.657 165.532 113.75 159.25 113.75H68.25C61.9678 113.75 56.875 108.657 56.875 102.375Z"
          fill="white"
          fill-opacity="0.16"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_914:1412"
          x="31.125"
          y="65.25"
          width="165.25"
          height="142.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="1.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_914:1412"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_914:1412"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_914:1412"
          />
        </filter>
        <filter
          id="filter1_i_914:1412"
          x="56.875"
          y="91"
          width="113.75"
          height="23.25"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_914:1412"
          />
        </filter>
        <linearGradient
          id="paint0_linear_914:1412"
          x1="147.875"
          y1="85.7041"
          x2="256.369"
          y2="110.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_914:1412"
          x1="34.125"
          y1="68.25"
          x2="217.701"
          y2="122.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_914:1412"
          x1="34.125"
          y1="68.25"
          x2="217.701"
          y2="122.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
      </defs>
    </svg>

    <svg
      v-if="show == 2"
      width="94"
      height="94"
      viewBox="0 0 273 273"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M238.875 91C238.875 116.129 218.504 136.5 193.375 136.5C168.246 136.5 147.875 116.129 147.875 91C147.875 65.871 168.246 45.5 193.375 45.5C218.504 45.5 238.875 65.871 238.875 91Z"
        fill="url(#paint0_linear_914:1435)"
      />
      <circle
        cx="91"
        cy="125.125"
        r="11.375"
        fill="url(#paint1_linear_914:1435)"
      />
      <circle
        cx="136.5"
        cy="125.125"
        r="11.375"
        fill="url(#paint2_linear_914:1435)"
      />
      <circle
        cx="182"
        cy="125.125"
        r="11.375"
        fill="url(#paint3_linear_914:1435)"
      />
      <g filter="url(#filter0_bi_914:1435)">
        <path
          d="M91 56.875C65.871 56.875 45.5 77.246 45.5 102.375V147.875C45.5 173.004 65.871 193.375 91 193.375H133.349C135.411 193.375 137.434 193.935 139.202 194.996L184.761 222.332C188.552 224.606 193.375 221.876 193.375 217.455V196.351C193.375 193.755 195.147 191.524 197.586 190.636C215.038 184.273 227.5 167.529 227.5 147.875V102.375C227.5 77.246 207.129 56.875 182 56.875H91Z"
          fill="#F1F1F1"
          fill-opacity="0.1"
        />
        <path
          d="M91 56.875C65.871 56.875 45.5 77.246 45.5 102.375V147.875C45.5 173.004 65.871 193.375 91 193.375H133.349C135.411 193.375 137.434 193.935 139.202 194.996L184.761 222.332C188.552 224.606 193.375 221.876 193.375 217.455V196.351C193.375 193.755 195.147 191.524 197.586 190.636C215.038 184.273 227.5 167.529 227.5 147.875V102.375C227.5 77.246 207.129 56.875 182 56.875H91Z"
          fill="url(#paint4_linear_914:1435)"
          fill-opacity="0.2"
        />
        <path
          d="M45.6 102.375C45.6 77.3013 65.9263 56.975 91 56.975H182C207.074 56.975 227.4 77.3013 227.4 102.375V147.875C227.4 167.486 214.966 184.193 197.551 190.542C195.079 191.443 193.275 193.708 193.275 196.351V217.455C193.275 221.798 188.537 224.481 184.813 222.246L139.253 194.91C137.47 193.84 135.429 193.275 133.349 193.275H91C65.9263 193.275 45.6 172.949 45.6 147.875V102.375Z"
          stroke="#F1F1F1"
          stroke-opacity="0.1"
          stroke-width="0.2"
        />
        <path
          d="M45.6 102.375C45.6 77.3013 65.9263 56.975 91 56.975H182C207.074 56.975 227.4 77.3013 227.4 102.375V147.875C227.4 167.486 214.966 184.193 197.551 190.542C195.079 191.443 193.275 193.708 193.275 196.351V217.455C193.275 221.798 188.537 224.481 184.813 222.246L139.253 194.91C137.47 193.84 135.429 193.275 133.349 193.275H91C65.9263 193.275 45.6 172.949 45.6 147.875V102.375Z"
          stroke="url(#paint5_linear_914:1435)"
          stroke-opacity="0.2"
          stroke-width="0.2"
        />
      </g>
      <g filter="url(#filter1_i_914:1435)">
        <path
          d="M102.375 125.125C102.375 131.407 97.2822 136.5 91 136.5C84.7178 136.5 79.625 131.407 79.625 125.125C79.625 118.843 84.7178 113.75 91 113.75C97.2822 113.75 102.375 118.843 102.375 125.125Z"
          fill="white"
          fill-opacity="0.16"
        />
        <path
          d="M147.875 125.125C147.875 131.407 142.782 136.5 136.5 136.5C130.218 136.5 125.125 131.407 125.125 125.125C125.125 118.843 130.218 113.75 136.5 113.75C142.782 113.75 147.875 118.843 147.875 125.125Z"
          fill="white"
          fill-opacity="0.16"
        />
        <path
          d="M193.375 125.125C193.375 131.407 188.282 136.5 182 136.5C175.718 136.5 170.625 131.407 170.625 125.125C170.625 118.843 175.718 113.75 182 113.75C188.282 113.75 193.375 118.843 193.375 125.125Z"
          fill="white"
          fill-opacity="0.16"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_914:1435"
          x="42.5"
          y="53.875"
          width="188"
          height="172.276"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="1.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_914:1435"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_914:1435"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_914:1435"
          />
        </filter>
        <filter
          id="filter1_i_914:1435"
          x="79.625"
          y="113.75"
          width="113.75"
          height="23.25"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_914:1435"
          />
        </filter>
        <linearGradient
          id="paint0_linear_914:1435"
          x1="147.875"
          y1="45.5"
          x2="255.07"
          y2="72.7188"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_914:1435"
          x1="79.625"
          y1="113.75"
          x2="106.424"
          y2="120.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_914:1435"
          x1="125.125"
          y1="113.75"
          x2="151.924"
          y2="120.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_914:1435"
          x1="170.625"
          y1="113.75"
          x2="197.424"
          y2="120.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_914:1435"
          x1="45.5"
          y1="56.875"
          x2="257.349"
          y2="115.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_914:1435"
          x1="45.5"
          y1="56.875"
          x2="257.349"
          y2="115.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
      </defs>
    </svg>

    <svg
      v-if="show == 3"
      width="94"
      height="94"
      viewBox="0 0 273 273"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M238.875 91C238.875 116.129 218.504 136.5 193.375 136.5C168.246 136.5 147.875 116.129 147.875 91C147.875 65.871 168.246 45.5 193.375 45.5C218.504 45.5 238.875 65.871 238.875 91Z"
        fill="url(#paint0_linear_914:1435)"
      />
      <circle
        cx="91"
        cy="125.125"
        r="11.375"
        fill="url(#paint1_linear_914:1435)"
      />
      <circle
        cx="136.5"
        cy="125.125"
        r="11.375"
        fill="url(#paint2_linear_914:1435)"
      />
      <circle
        cx="182"
        cy="125.125"
        r="11.375"
        fill="url(#paint3_linear_914:1435)"
      />
      <g filter="url(#filter0_bi_914:1435)">
        <path
          d="M91 56.875C65.871 56.875 45.5 77.246 45.5 102.375V147.875C45.5 173.004 65.871 193.375 91 193.375H133.349C135.411 193.375 137.434 193.935 139.202 194.996L184.761 222.332C188.552 224.606 193.375 221.876 193.375 217.455V196.351C193.375 193.755 195.147 191.524 197.586 190.636C215.038 184.273 227.5 167.529 227.5 147.875V102.375C227.5 77.246 207.129 56.875 182 56.875H91Z"
          fill="#F1F1F1"
          fill-opacity="0.1"
        />
        <path
          d="M91 56.875C65.871 56.875 45.5 77.246 45.5 102.375V147.875C45.5 173.004 65.871 193.375 91 193.375H133.349C135.411 193.375 137.434 193.935 139.202 194.996L184.761 222.332C188.552 224.606 193.375 221.876 193.375 217.455V196.351C193.375 193.755 195.147 191.524 197.586 190.636C215.038 184.273 227.5 167.529 227.5 147.875V102.375C227.5 77.246 207.129 56.875 182 56.875H91Z"
          fill="url(#paint4_linear_914:1435)"
          fill-opacity="0.2"
        />
        <path
          d="M45.6 102.375C45.6 77.3013 65.9263 56.975 91 56.975H182C207.074 56.975 227.4 77.3013 227.4 102.375V147.875C227.4 167.486 214.966 184.193 197.551 190.542C195.079 191.443 193.275 193.708 193.275 196.351V217.455C193.275 221.798 188.537 224.481 184.813 222.246L139.253 194.91C137.47 193.84 135.429 193.275 133.349 193.275H91C65.9263 193.275 45.6 172.949 45.6 147.875V102.375Z"
          stroke="#F1F1F1"
          stroke-opacity="0.1"
          stroke-width="0.2"
        />
        <path
          d="M45.6 102.375C45.6 77.3013 65.9263 56.975 91 56.975H182C207.074 56.975 227.4 77.3013 227.4 102.375V147.875C227.4 167.486 214.966 184.193 197.551 190.542C195.079 191.443 193.275 193.708 193.275 196.351V217.455C193.275 221.798 188.537 224.481 184.813 222.246L139.253 194.91C137.47 193.84 135.429 193.275 133.349 193.275H91C65.9263 193.275 45.6 172.949 45.6 147.875V102.375Z"
          stroke="url(#paint5_linear_914:1435)"
          stroke-opacity="0.2"
          stroke-width="0.2"
        />
      </g>
      <g filter="url(#filter1_i_914:1435)">
        <path
          d="M102.375 125.125C102.375 131.407 97.2822 136.5 91 136.5C84.7178 136.5 79.625 131.407 79.625 125.125C79.625 118.843 84.7178 113.75 91 113.75C97.2822 113.75 102.375 118.843 102.375 125.125Z"
          fill="white"
          fill-opacity="0.16"
        />
        <path
          d="M147.875 125.125C147.875 131.407 142.782 136.5 136.5 136.5C130.218 136.5 125.125 131.407 125.125 125.125C125.125 118.843 130.218 113.75 136.5 113.75C142.782 113.75 147.875 118.843 147.875 125.125Z"
          fill="white"
          fill-opacity="0.16"
        />
        <path
          d="M193.375 125.125C193.375 131.407 188.282 136.5 182 136.5C175.718 136.5 170.625 131.407 170.625 125.125C170.625 118.843 175.718 113.75 182 113.75C188.282 113.75 193.375 118.843 193.375 125.125Z"
          fill="white"
          fill-opacity="0.16"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_914:1435"
          x="42.5"
          y="53.875"
          width="188"
          height="172.276"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="1.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_914:1435"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_914:1435"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_914:1435"
          />
        </filter>
        <filter
          id="filter1_i_914:1435"
          x="79.625"
          y="113.75"
          width="113.75"
          height="23.25"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_914:1435"
          />
        </filter>
        <linearGradient
          id="paint0_linear_914:1435"
          x1="147.875"
          y1="45.5"
          x2="255.07"
          y2="72.7188"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_914:1435"
          x1="79.625"
          y1="113.75"
          x2="106.424"
          y2="120.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_914:1435"
          x1="125.125"
          y1="113.75"
          x2="151.924"
          y2="120.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_914:1435"
          x1="170.625"
          y1="113.75"
          x2="197.424"
          y2="120.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_914:1435"
          x1="45.5"
          y1="56.875"
          x2="257.349"
          y2="115.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_914:1435"
          x1="45.5"
          y1="56.875"
          x2="257.349"
          y2="115.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="color1" />
          <stop offset="1" :stop-color="color2" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "LoginSliderImg",
  props: {
    show: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const color1 = ColorHelper[store.state.theme].loginImg1;
    const color2 = ColorHelper[store.state.theme].loginImg2;
    return {
      color1,
      color2,
    };
  },
};
</script>

<style></style>

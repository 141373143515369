import store from "../store/store.js";
import ZoneHelper from "../shared/zone-helper.js";

const zoneHelper = new ZoneHelper();
const zone = zoneHelper.getZoneInfo(store.getters.getTheme);

export const navText = [
  {
    nav: "Videolar",
    text: "Son Eklenen Videolar kısmında, güncel içeriklerden haberdar olun. Güncel Konulardaki en etkili içerikler sizi bekliyor.",
  },
  {
    nav: "Haberler",
    text: "Haberler sizlere bir tık kadar uzaklıkta.",
  },
  // {
  //   nav: "Olgular",
  //   text: "Sistemimizde yer alan olgulara buradan ulaşabilirsiniz.",
  // },
  {
    nav: "Podcast",
    text: "Uzmanlık alanınıza özel hazırlanmış, geniş içeriğe sahip Podcastleri dinleyebilirsiniz.",
  },
  {
    nav: "Makaleler",
    text: `${zone.deparment} alanında yazılmış onlarca makaleye tek tıkla ulaşabilirsiniz. Dilerseniz kendi makalenizi de bu alanda yayınlayabilirsiniz.`,
  },
  {
    nav: "Literatür Tarama",
    text: 'Pubmed üzerinde arama yapıp bulduğunuz makaleyi "Pubmed Tam Metin Talebi" kısmından talepte bulunabilirsiniz.',
  },
  {
    nav: "Kılavuzlar",
    text: "Sistemimizde bulunan kılavuzlara rahatlıkla ulaşabilir ve bu kılavuzları cihazlarınıza indirebilirsiniz.",
  },
  {
    nav: "EPI Library",
    text: "EPI Library içeriklerimiz sizlere tek tık uzaklıkta.",
  },
  {
    nav: "Fakülteden",
    text: "Sistemimizde bulunan 93 fakülte ve bu fakültelerden mezun 682 meslektaşlarınızla anında iletişim kurabilirsiniz.",
  },
  {
    nav: "Ürünler",
    text: "Ürünlerimizi terapötik kategoriye göre listeleyebildiğiniz gibi etken madde ya da isim araması yaparak da görüntüleyebilirsiniz.",
  },
  {
    nav: "Çeviri Talebi",
    text: "Çeviri taleplerinizi buradan iletebilirsiniz.",
  },
  {
    nav: "Redaksiyon Talebi",
    text: " Redaksiyon taleplerinizi buradan iletebilirsiniz.",
  },
  {
    nav: "İstatistik Talebi",
    text: "İstatistik taleplerinizi buradan iletebilirsiniz.",
  },
];

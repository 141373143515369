<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.2495 48.6571C42.5242 34.8748 41.4313 29.8992 41.3285 29.205H41.3156C41.2128 29.8864 40.1328 34.8748 28.3947 48.6571C14.6894 64.7537 22.4549 85.7615 41.2128 85.813H41.4185C60.0607 85.7615 67.9418 64.7409 54.2495 48.6571Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.2734 66.2709C30.2734 66.2709 30.3634 72.3135 35.7761 75.3734"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M72.0029 20.3339C66.0631 13.3527 65.5103 10.8199 65.4588 10.4728C65.4074 10.8199 64.8545 13.3398 58.9148 20.3339C51.9721 28.485 55.9063 39.1432 65.4074 39.1689H65.5102C74.9471 39.1432 78.9455 28.4979 72.0029 20.3339Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Hematoloji",
};
</script>

<style></style>

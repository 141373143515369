<template>
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9998 28.6456C22.8105 28.6456 28.3332 23.1242 28.3332 16.3122C28.3332 9.50157 22.8105 3.9789 15.9998 3.9789C9.18917 3.9789 3.6665 9.50157 3.6665 16.3122C3.6665 23.1242 9.18917 28.6456 15.9998 28.6456Z"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0767 20.9405L18.7247 16.3125L14.0767 11.6845"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "RightPaginationIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style></style>

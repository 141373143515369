<template>
  <div>
    <div class="d-flex flex-column">
      <span class="font-s-16px font-w-500 blue-300"
        >Soru: {{ questionNumber }}</span
      >
      <hr class="question-hr w-100 mt-1 mb-3" />
    </div>
    <div class="mb-4">
      <p class="font-s-18px font-w-500 black-700">
        {{ questionValue.title }}
      </p>
    </div>
    <div>
      <div
        v-for="(option, i) in questionValue.options"
        :key="i"
        class="d-flex justify-content-between align-items-baseline my-3 p-3"
        :class="
          questionValue.is_answered && option.is_correct
            ? 'answer-success'
            : option.is_answer && !option.is_corret
            ? 'answer-warning'
            : ''
        "
      >
        <div>
          <input
            :id="'question-' + index + '-' + i"
            :name="'q-' + index"
            type="radio"
            class="mr-1"
            value="0"
            :checked="option.is_answer"
            :disabled="questionValue.is_answered || isAnswerSending"
            @click="answer(option.id)"
          />
          <label class="text-secondary" :for="'question-' + index + '-' + i">
            {{ option.title }}
          </label>
        </div>
        <div
          v-if="questionValue.is_answered && option.is_correct"
          class="d-flex"
        >
          <span class="font-s-12px font-w-500 mr-1">Doğru Cevap</span>
          <div class="border-radius-50 bg-green bg-icon">
            <close-icon color="#00B3A6" :size="10" />
          </div>
        </div>
        <div v-else-if="option.is_answer && !option.is_corret" class="d-flex">
          <span class="font-s-12px font-w-500 mr-1">Yanlış Cevap</span>
          <div class="border-radius-50 bg-red-100 bg-icon">
            <close-icon color="#FE4550" :size="10" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import QuestionUserAnswerService from "../../services/question-user-answer/QuestionUserAnswerService";
import { useStore } from "vuex";
import SwalError from "../../helpers/SwalError";

export default {
  name: "Question",
  props: {
    color: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    questionBankId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const questionUserAnswerService = new QuestionUserAnswerService();
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const isAnswerSending = ref(false);
    const questionValue = ref(props.question);

    const questionNumber = computed(() => {
      return props.perPage * (props.currentPage - 1) + (props.index + 1);
    });

    const answer = (optionId) => {
      isAnswerSending.value = true;
      const option = questionValue.value.options.find((x) => x.id === optionId);
      questionUserAnswerService
        .create({
          question_id: questionValue.value.id,
          user_id: user.value.id,
          option_id: option.id,
          answer_body: option.title,
        })
        .then(() => {
          questionValue.value.is_answered = true;
          option.is_answer = true;
        })
        .catch((error) => {
          SwalError.fire(error);
        })
        .finally(() => {
          isAnswerSending.value = false;
        });
    };

    return {
      answer,
      questionValue,
      questionNumber,
      isAnswerSending,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-hr {
  border: 1px solid v-bind("color");
}
.answer-success {
  background-color: #f7fdfd;

  span {
    color: #00b3a6;
  }
}
.answer-warning {
  background-color: #ffeff4;

  span {
    color: #fe4550;
  }
}
.bg-green {
  background-color: #bfeeeb;
}
.bg-icon {
  width: 14px;
  height: 14px;
}
</style>

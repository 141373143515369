<template>
  <div
    v-if="$attrs.type === 'checkbox'"
    class="form-check form-check-inline d-flex align-items-start"
  >
    <input
      v-model="value"
      v-bind="{ ...inputBinds }"
      class="checkbox form-check-input"
      type="checkbox"
      :class="{ 'is-invalid': errorMessage }"
      :disabled="disabled"
    />
    <slot name="label">
      <label class="gray-500 font-s-12px m-0" :for="$attrs.id">{{
        label
      }}</label>
    </slot>
  </div>
  <div v-else-if="$attrs.type === 'radio'" class="d-flex align-items-baseline">
    <input
      v-model="value"
      v-bind="{ ...inputBinds }"
      class="mr-1"
      type="radio"
      :class="{ 'is-invalid': errorMessage }"
      :disabled="disabled"
    />
    <slot name="label">
      <label class="text-secondary font-s-12px gray-500" :for="$attrs.id">{{
        label
      }}</label>
    </slot>
  </div>
  <textarea
    v-else-if="$attrs.type === 'textarea'"
    v-model="value"
    v-bind="{ ...inputBinds }"
    class="border-radius"
    :class="{ 'is-invalid': errorMessage }"
    :disabled="disabled"
  />
  <div v-else class="input-with-icons">
    <component
      :is="icons.left.name"
      v-if="icons.left"
      v-bind="{ ...icons.left }"
    />
    <input
      v-model="value"
      :type="reactiveType"
      v-bind="{ ...inputBinds }"
      :class="{
        'is-invalid': errorMessage,
        'has-left-icon': icons.left,
        'has-right-icon': icons.right,
      }"
      :disabled="disabled"
    />
    <component
      :is="icons.right.name"
      v-if="icons.right"
      v-bind="{ ...icons.right }"
      @click="rightIconClicked"
    />
    <div v-if="showErrorMsg && errorMessage" class="input-error-msg px-2">
      {{ errorMessage }}
    </div>
    <p v-if="infoMessage" class="m-0 mt-2 px-2 font-s-12px gray-500">
      {{ infoMessage }}
    </p>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useField } from "vee-validate";
import { computed } from "vue";

export default {
  name: "BaseInput",
  inheritAttrs: false,
  props: {
    leftIcon: {
      type: Object,
      required: false,
      default: undefined,
    },
    rightIcon: {
      type: Object,
      required: false,
      default: undefined,
    },
    infoMessage: {
      type: String,
      required: false,
      default: undefined,
    },
    showErrorMsg: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: false,
      type: [String, Boolean, Object, Array],
      default: undefined,
    },
    validations: {
      type: Object,
      required: false,
      default: undefined,
    },
    label: {
      // for checkbox
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { attrs, emit }) {
    const { errorMessage, value, meta } = props.validations
      ? useField(attrs.name, props.validations)
      : useField(attrs.name);
    const reactiveType = ref(attrs.type);

    const inputBinds = {
      placeholder: "",
      class: "form-control",
      ...attrs,
    };
    if (attrs.type === "checkbox" || attrs.type === "radio") {
      delete inputBinds.class;
    }
    delete inputBinds.leftIcon;
    delete inputBinds.rightIcon;
    delete inputBinds.type;
    // <password-icon color="#7684AA" class="input-left-svg" />
    const icons = computed(() => ({
      left: props.leftIcon
        ? props.leftIcon
        : attrs.type === "password"
        ? {
            name: "password-icon",
            color: "#7684AA",
            class: "input-left-svg",
          }
        : undefined,
      right: props.rightIcon
        ? props.rightIcon
        : attrs.type === "password"
        ? {
            name:
              reactiveType.value === "password"
                ? "eye-icon"
                : "hide-password-icon",
            class: "input-right-svg cursor-pointer",
          }
        : undefined,
    }));

    watchEffect(() => {
      emit("update:modelValue", value.value);
    });

    return {
      inputBinds,
      errorMessage,
      value,
      icons,
      reactiveType,
      meta,
      rightIconClicked() {
        if (attrs.type === "password") {
          reactiveType.value =
            reactiveType.value === "password" ? "text" : "password";
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.input-error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}
input[type="checkbox"] {
  height: 14px !important;
  width: 14px !important;
  min-height: 14px !important;
  min-width: 14px !important;
  margin-top: 2px;
}
.input-with-icons {
  position: relative;

  .has-left-icon {
    padding-left: 40px;
  }

  .has-right-icon {
    padding-right: 40px;
  }

  svg:first-child {
    position: absolute;
    top: 13px;
    left: 15px;
  }

  svg:last-child {
    position: absolute;
    top: 13px;
    right: 30px;
  }
}
</style>

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="Toplam Lenfosit Sayısı Hesaplama Aracı"
        nav="Toplam Lenfosit Sayısı Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />
      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="form_vya"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Lymphocyte
                </h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="ezf"
                  maxlength="5"
                  max="5"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  White blood cells (WBC)
                </h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="wbc"
                  maxlength="5"
                  max="5"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "LenfositScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const calculate = () => {
      let form = $("#form_vya");
      window.scrollTo({ top: 0, behavior: "smooth" });
      $(".return-text").slideDown();
      let error = 0;
      form.find(".is-invalid").removeClass("is-invalid");
      let wbc = form.find('input[name="wbc"]').val();
      let ezf = form.find('input[name="ezf"]').val();
      if (!wbc) {
        form.find('input[name="wbc"]').addClass("is-invalid");
        error++;
      }
      if (!ezf) {
        form.find('input[name="ezf"]').addClass("is-invalid");
        error++;
      }
      if (error) {
        $(".return-text").html("Eksik alanlar mevcut");
        return false;
      }
      let f = (wbc * ezf) / 100;
      $(".return-text").html("Absolute Lymphocyte Count");
      $(".return-number").html(f);
    };

    function clearAll() {
      $("form")[0].reset();
      $(".return-text").slideUp();
    }
    return {
      calculate,
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      clearAll,
      apgar: [
        {
          title: "Toplam Lenfosit Sayısı Hesaplama?",
          content: `<p>Lenfosit, kemik iliğinde üretilen beyaz kan hücrelerinden bir türdür. Vücudun hastalık direncini arttıran ve savunma mekanizmasını güçlendiren antikorlar üretirler. Tehlikelere karşı diğer hücreleri kimyasallar göndererek uyarırlar. Dolaşım sisteminde bulunan lym; bakteri, virüs ve antijenlere karşı vücudun korunmasına yardımcı olur. Kemik iliğinde lenfosit hale gelmesi için sürekli olarak hücre üretilir. Bu hücrelerin bazıları kan akışına karışırken, büyük bir kısmı organ ve dokulardan oluşan lenf sistemine geçer.</p>
        `,
        },
        {
          title: "Lenfosit (LYM) Hesaplama Nasıl Yapılır?",
          content: `   <p>Lenfosit değerleri kan ve idrar testi ile kolayca hesaplanır. Bu hesaplamada beyaz küre sayısı ve lenfosit değerleri kullanılır. Bireyler bu değerleri biliyorsa, internet üzerinden hesaplama yapılarak da lenfosit mutlak sayısı öğrenilir. Çıkan sonuçlara göre lenfosit düşüklüğü veya yüksekliği görüldüyse ilk olarak nedeni araştırılır. Nedeni bulunduktan sonra doktor tarafından uygun tedavi yöntemleri belirlenir.</p>

        `,
        },
        {
          title: "Lenfosit Düşüklüğü ve Yüksekliği Nedenleri",
          content: `
               <p>Lenfosit (LYM) düşüklüğü; vücutta yeterince beyaz kan hücresi üretilmediği anlamına gelir. Bu durumda vücut, kanser gibi hastalıklara karşı zayıf düşer. Ayrıca iç organların hasar görmesine de neden olur. Lenfosit değerini düşüren hastalıklar;</p>

            <ul>
                <li>Bulaşıcı hastalıklar</li>
                <li>Radyoterapi</li>
                <li>Steroid tedavisi</li>
                <li>Ataksi</li>
                <li>Kan hastalıkları</li>
                <li>Di George anomalisidir</li>
            </ul>

            <p>Lenfosit düşüklüğünde ateş, öksürük, eklem ağrıları ve solunum yolu enfeksiyonları gibi belirtiler görülür. Doğru tedavi uygulanmadığı takdirde bağışıklık sistemi zayıflayarak ciddi rahatsızlıklara yol açabilir</p>
            <p>Lenfosit (LYM) yüksekliği; kanda fazla miktarda lenfosit olması anlamına gelir. Kan testi yapıldığında 1 mikrolitre 3000'den fazla lenfosit bulunuyorsa, lym yüksekliği vardır. Çocuklarda bu değer 7000, bebeklerde ise 9000'den fazla olduğunda yüksek lenfositoz olarak isimlendirilir. Lenfosit değerinin yükselmesi, kişinin yakın zamanda enfeksiyonlu ya da bulaşıcı bir hastalık geçirmesinden kaynaklanır. Lenfosit değerini yükselten hastalıklar;</p>

            <ul>
                <li><span>Grip</span></li>
                <li><span>Öpücük hastalığı</span></li>
                <li><span>Su çiçeği</span></li>
                <li><span>Kan kanseri</span></li>
                <li><span>Tüberküloz</span></li>
                <li><span>Boğmaca</span></li>
                <li><span>Akut lenfositik lösemi</span></li>
                <li><span>Hepatit</span></li>
                <li><span>Kan nakli</span></li>
                <li><span>Otoimmün bozukluk</span></li>
                <li><span>Vaskülit yani kan hücrelerinin iltihaplanmasıdır.</span></li>
            </ul>

            <p>Lenfosit sayısı yüksekliğinde egzama, solunum yolunda oluşan enfeksiyonlar, deride anormallikler, bademciklerin şişmesi gibi belirtiler görülür. Lym yüksekliğinde öncelikle nedeni araştırılır ve buna bağlı olarak antibiyotik tedavisi sunulur. Eğer neden kanserden kaynaklanıyorsa, ışın tedavisi veya kemoterapiye başlanır. Bunun dışında yetişkin bireylerin lenfosit değerini düşürmesi için; omega 3, C ve D vitamini içeren besinler tüketmesi önerilir.</p>

`,
        },
        {
          title: "Kanda LYM Kaç Olmalı?",
          content: `<p>Kandaki lym değeri yaşa göre değişir. Lenfositlerin normal değerleri yetişkin bireyler için, 1 mikrolitre kanda 1000-4800 arasındadır. Çocuklarda ise bu değer 3000-9500 aralığında görülür. Lenfosit değerlerinin 1000'den az olması lym düşüklüğü, 3000'den fazla olması ise lym yüksekliği olarak adlandırılır. Bu değer, kan testi yaptırıldığında net olarak belirlenir.</p>
`,
        },
      ],
      references: [
        "https://www.sozcu.com.tr/2018/saglik/lym-lenfosit-nedir-lenfosit-dusuklugu-ve-yuksekligi-ne-anlama-gelir-oub2-2707047/",
        "http://gulhanemedj.org/uploads/pdf/pdf_GMJ_954.pdf",
        "https://www.mynet.com/lym-nedir-kac-olmali-lym-dusuklugu-veya-yuksekligi-ne-anlama-gelir-110104476846",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

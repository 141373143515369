<template>
  <div>
    <loading-anim-icon
      v-if="authLoading || !routerReady || !headerMenu || loading"
      :is-full-screen="true"
    />
    <div
      v-show="!loading && !authLoading && routerReady && headerMenu"
      name="fade"
      mode="out-in"
    >
      <component
        :is="layout"
        v-if="!authLoading && routerReady && headerMenu"
        :header-menu="headerMenu"
      >
        <router-view v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.fullPath" />
          </transition>
        </router-view>
      </component>
      <notification-box v-if="user && notificationPermission === 'default'" />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { saveMessagingDeviceToken } from "./shared/firebase-messaging";
import LoadingAnimIcon from "./components/icon/LoadingAnimIcon.vue";
import NotificationBox from "./components/ui/Notification.vue";
import SimplestLayout from "./layouts/SimplestLayout.vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import HeaderMenuService from "./services/header-menu/HeaderMenuService";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    SimplestLayout,
    DefaultLayout,
    LoadingAnimIcon,
    NotificationBox,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const token = computed(() => store.state.bearerToken);
    const user = computed(() => store.state.user.user);
    const routerReady = computed(() => store.state.routerReady);
    const authLoading = computed(() => store.state.authLoading);
    const loading = computed(() => store.state.loading);
    const headerMenu = ref(null);
    const notificationPermission = ref(null);
    const layout = computed(() => {
      if (route.query.is_mobile === "true") {
        // for zone app
        return "simplest-layout";
      }

      if (route.meta.layout) {
        return route.meta.layout;
      }

      return "default-layout";
    });

    const fetchHeaderMenu = () => {
      HeaderMenuService.getHeaderMenu().then((res) => {
        headerMenu.value = res.data.data;
      });
    };

    const logout = () =>
      store.dispatch("logout").then(() => router.replace({ name: "home" }));

    fetchHeaderMenu();

    store.dispatch("getStoragePodcastList");

    onBeforeMount(async () => {
      await store.dispatch("fetchSettings");
    });

    onMounted(async () => {
      notificationPermission.value = window.Notification.permission;
    });

    watch(user, (newUser) => {
      if (newUser && notificationPermission.value === "granted") {
        saveMessagingDeviceToken();
      }
    });

    return {
      user,
      token,
      logout,
      authLoading,
      loading,
      routerReady,
      layout,
      headerMenu,
      main_img,
      notificationPermission,
    };
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.6s, transform 0.6s;
}
.slide-fade-enter {
  opacity: 0;
  transform: translateY(-10%);
}
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.page-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 300px;
}

@media (max-width: 576px) {
  .page-main {
    height: 180px;
  }
}
</style>

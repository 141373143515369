<template>
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4076 11.6374C15.4186 10.8114 15.6442 10.0015 16.0634 9.28303C16.4826 8.56459 17.0819 7.96096 17.8054 7.52831C17.3458 6.88982 16.7394 6.36437 16.0345 5.99369C15.3295 5.62302 14.5454 5.41732 13.7445 5.39295C12.0359 5.21851 10.3794 6.38738 9.50876 6.38738C8.62124 6.38738 7.2807 5.41027 5.83716 5.43915C4.90344 5.46849 3.99345 5.73259 3.19586 6.2057C2.39828 6.67881 1.74029 7.34481 1.28602 8.1388C-0.681806 11.4526 0.786012 16.3227 2.67102 19.0013C3.61413 20.313 4.71635 21.7781 6.15859 21.7262C7.56991 21.6692 8.09701 20.8508 9.80059 20.8508C11.4884 20.8508 11.9829 21.7262 13.4544 21.6931C14.9688 21.6692 15.9229 20.3757 16.833 19.0516C17.5106 18.117 18.032 17.0841 18.3779 15.9911C17.4981 15.6291 16.7473 15.0233 16.2191 14.2491C15.6909 13.4749 15.4087 12.5666 15.4076 11.6374Z"
      :fill="color"
    />
    <path
      d="M12.6276 3.63136C13.4533 2.66723 13.8601 1.428 13.7616 0.176849C12.5 0.305723 11.3348 0.892152 10.4979 1.81929C10.0887 2.27222 9.77533 2.79915 9.57565 3.36995C9.37598 3.94076 9.29392 4.54424 9.33418 5.14591C9.96516 5.15223 10.5894 5.01921 11.1599 4.75686C11.7303 4.49452 12.2322 4.10969 12.6276 3.63136Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IosIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  svg {
    width: 14px;
    height: 16px;
  }
}
</style>

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="Toplam Nötrofil Sayısı Hesaplama Aracı"
        nav="Toplam Nötrofil Sayısı Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="form_vya"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Nötrofil</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="ntr"
                  maxlength="5"
                  max="5"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  White blood cells (WBC)
                </h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="wbc"
                  maxlength="5"
                  max="5"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Bands (%)</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="brands"
                  maxlength="5"
                  max="5"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "NotrofilScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const calculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      let form = $("#form_vya");
      $(".return-text").slideDown();
      let error = 0;
      form.find(".is-invalid").removeClass("is-invalid");
      let wbc = form.find('input[name="wbc"]').val();
      let ntr = form.find('input[name="ntr"]').val();
      let brands = form.find('input[name="brands"]').val();
      if (!wbc) {
        form.find('input[name="wbc"]').addClass("is-invalid");
        error++;
      }
      if (!ntr) {
        form.find('input[name="ntr"]').addClass("is-invalid");
        error++;
      }
      if (!brands) {
        form.find('input[name="brands"]').addClass("is-invalid");
        error++;
      }
      if (error) {
        $(".return-text").html("Eksik alanlar mevcut");
        return false;
      }
      let f = wbc * (ntr / 100 + brands / 100);
      $(".return-text").html("Toplam N&ouml;trofil Sayısı");
      $(".return-number").html(f);
    };

    const clearAll = () => {
      $("form")[0].reset();
      $(".return-text").slideUp();
    };

    return {
      clearAll,
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      calculate,
      apgar: [
        {
          title: "Nötrofil (NEU) Nedir?",
          content: `  </p>
            <p>Nötrofiller (NEU), beyaz kan hücrelerinin bir türüdür ve bağışıklık sisteminin önemli bir parçasıdır. Nötrofiller enfeksiyon bölgesine ilk giden hücre tiplerindendir ve içerdikleri enzimler sayesinde mantar ve bakterilerle savaşırlar.</p>
            <p>Bağışıklık sistemi doku, hücre ve organlardan oluşur. Beyaz kan hücreleri de kan dolaşımını ve lenf sistemini korurlar. Nötrofiller (NEU) beyaz kan hücrelerinin yüzde 55 ila 70’ini oluşturmaktadır. Beyaz kan hücreleri enfeksiyon ve iltihap bölgesinde, virüs, bakteri, mantar, zehir ve kanser hücreleri gibi antijenlerle savaşırlar. Nötrofiller beyaz kan hücreleri arasında oldukça önemlidir, çünkü diğer beyaz kan hücrelerinin aksine nötrofillerin dolaşım alanı sınırlı değildir. Yani vücudunuz herhangi bir noktasında yer alan antijenlerle savaşmak için hemen harekete geçerler. Mikrop ile karşılaşmayan nötrofiller ise programlanmış hücre ölümüne uğrarlar ve karaciğer ve dalak tarafından temizlenirler. Her nötrofilin ortalama ömrü birkaç saattir. Fakat kemik iliği devamlı olarak nötrofil üretmektedir.</p>
            <p>Vücutta bulunan nötrofil sayısı ise oldukça önemlidir. Sağlıklı bir yetişkinde bir mikrolitre kandaki nötrofil (NEU) değeri 1500-8000 civarında olmalıdır. Çocuklarda ise bu sayı 1000 ila 8000 arasındadır.</p>
            <p>Vücudunuzda olması gerekenden daha fazla ya da daha az sayıda nötrofil (NEU) olabilir. Kanda yüksek oranda nötrofil bulunmasına nötrofili, düşük oranda nötrofil bulunmasına ise nötropeni denmektedir. Genel olarak, nötrofil yüzdesi kan testlerinde %70’ten fazla olduğunda, nötrofiliden bahsedilebilir.</p>

        `,
        },
        {
          title: "Nötrofil Yüksekliği Nedir?",
          content: `    <p>Nötrofil yüksekliği ve nötrofil düşüklüğünün altında yatan farklı sebepler vardır. Nötrofil yüksekliği, vücudunuzda bulunan bir enfeksiyona işaret eder. Bakteriyel enfeksiyonlar, bulaşıcı olmayan iltihaplar, yaralanmalar, ameliyatlar, sigara içmek, yüksek stres, aşırı egzersiz yapmak, steroid kullanımı, kalp krizi ve kronik miyeloid lösemi gibi çeşitli etmenler nötrofil yüksekliğine sebep olabilir.</p>


        `,
        },
        {
          title: "Nötrofil Düşüklüğü Nedir?",
          content: `
                   <p>Nötrofil düşüklüğü anlamına gelen nötropeniyse genellikle ilaç kullanımıyla alakalı olsa da, farklı sebepleri de olabilir. Bu sebepler şunlar olabilir; kemoterapide kullanılan ilaçlar, bastırılmış bağışıklık sistemi, kemik iliği yetmezliği, aplastik anemi, febril nötropeni, Kostmann sendromu ve siklik nötropeni gibi konjenital hastalıklar, hepatit A, B ya da C, HIV/AIDS, sepsis, otoimmün hastalıklar, lösemi ve myelodisplastik sendromlar.</p>
            <p>Eğer hemogram testindeki nötrofil oranları anormalse, daha derinlemesine inceleme ve değerlendirme yapılmalıdır. Genellikle bir sonraki adım periferik yaymadır.</p>
            <p>Fakat, nötrofil sayısı, nötrofil yükseliği ya da nötrofil düşüklüğünün sebepleri arasında yaş, cinsiyet, kalıtım, deniz seviyesine göre yükseklik, ve test sırasında kullanılan araçlar da yer almaktadır. Bu değişkenlere bağlı olarak, doktorunuz nötrofil yüzdenizi size ve durumunuza uygun olarak yorumlamalıdır.</p>


`,
        },
        {
          title: "NEU Nasıl Hesaplanır?",
          content: `      <p>Nötrofil oranları, kişide nötrofil yüksekliği ya da nötrofil düşüklüğü olup olmadığı yapılan kan testi sonucu ortaya çıkabilir. Bu kan testinin sonucunda kişide normal değerlerden yüksek ya da düşük nötrofil mevcutsa, altında yatan sebepler araştırılabilir. Ayrıca nötrofil oranınızı Medikaynak’ta yer alan Toplam Nötrofil Sayısı Hesaplama Aracı ile de hesaplayabilirsiniz.</p>

`,
        },
      ],
      references: [
        "https://www.healthline.com/health/neutrophils#outlook",
        "https://www.verywellhealth.com/what-are-neutrophils-p2-2249134",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

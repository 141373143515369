<template>
  <div class="w-100 d-flex flex-column overflow-hidden">
    <podcast-player />
    <div class="w-100">
      <Header :header-menu="headerMenu" />
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import PodcastPlayer from "../components/ui/PodcastPlayer.vue";
import Footer from "./DefaultLayout/Footer";
import Header from "./DefaultLayout/Header";

export default {
  name: "DefaultLayout",
  components: { Header, Footer, PodcastPlayer },
  props: {
    headerMenu: { type: Object, required: true },
  },
  setup() {
    const store = useStore();

    const podcastList = computed(() => {
      return store.getters.getPodcastList;
    });

    const activePodcast = computed(() => {
      return store.getters.getActivePodcast;
    });

    return {
      podcastList,
      activePodcast,
    };
  },
};
</script>

<style lang="scss" scoped></style>

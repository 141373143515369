<template>
  <section class="page-intro bg-image d-flex flex-column mb-5">
    <content-main
      :title="pageTitle"
      :nav="pageNav"
      class="mt-m-100px mt-160px"
    />
  </section>
</template>

<script>
import ContentMain from "./ContentMain.vue";
import { useStore } from "vuex";
export default {
  components: {
    ContentMain,
  },
  props: {
    pageTitle: {
      type: String,
      required: "false",
      default: "",
    },
    pageNav: {
      type: String,
      required: "false",
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    return {
      main_img,
    };
  },
};
</script>

<style>
.page-intro {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}

@media (max-width: 576px) {
  .page-intro {
    height: 200px;
  }
}
</style>

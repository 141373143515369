import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  search(query, filter = null, page = 1, per_page = 15) {
    return apiClient.get("/api/search", {
      params: {
        query,
        page,
        per_page,
        ...(filter ? { in: [filter] } : {}),
      },
    });
  },
};

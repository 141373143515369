<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="Bazal Enerji Tüketimi Hesaplama Aracı"
        nav="Bazal Enerji Tüketimi Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-30px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <form
            id="bet"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Cinsiyet</h5>
                <select
                  id=""
                  name=""
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    gender
                  "
                >
                  <option selected disabled hidden>Lütfen Seçiniz</option>
                  <option value="2">Erkek</option>
                  <option value="1">Kadın</option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Boy(cm)</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="boy"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Yaş</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="age"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Kilo(kg)</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="kilo"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "BazalScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const calculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      $(".return-text").slideDown();
      var error = 0;
      var gender = $("#bet .gender").val();
      if (!gender) {
        $("#bet .gender").closest(".selectFs").addClass("errorCol");
      }
      var age = $('#bet input[name="age"]').val();
      if (!age) {
        $('#bet input[name="age"]').addClass("errorCol");
        error++;
      }
      var h = $('#bet input[name="boy"]').val();
      if (!h) {
        $('#bet input[name="boy"]').addClass("errorCol");
        error++;
      }
      var k = $('#bet input[name="kilo"]').val();
      if (!k) {
        $('#bet input[name="kilo"]').addClass("errorCol");
        error++;
      }
      if (error) {
        return;
      }
      var total = 0;
      if (gender == 1) {
        total = 66.5 + 13.75 * k + 5.003 * h - 6.775 * age;
      } else {
        total = 655.1 + 9.563 * k + 1.85 * h - 4.676 * age;
      }
      returnData(Math.round(total), null, "kcal/g&uuml;n");
    };

    const clearAll = () => {
      $("#bet")[0].reset();
      $(".return-text").slideUp();
    };

    $(document).ready(function () {
      $("form").submit(function (event) {
        event.preventDefault();
        calculate();
      });
      $(".calc_add").click(function () {
        var c = $(this).prev("input").val();
        $(this)
          .prev("input")
          .val(parseInt(c) + 1);
      });
      $(".calc_int").click(function () {
        var c = $(this).closest("div").find("input");
        if (c.val()) {
          c.val(parseInt(c.val()) - 1);
        }
      });
    });

    const returnData = (data = null, string = null, type = "") => {
      if (!data || data < 0) {
        data = -1;
      } else {
        data = parseFloat(data);
      }
      if (!string) {
        string = -1;
      }
      if (data == -1 && string == -1) {
        string = "-";
      }
      if (type) {
        if (data > 0) {
          $(".return-number").html(data);
          $(".return-text").html(type);
        } else {
          $(".return-text").html(type);
        }
      } else {
        if (data > 0) {
          $(".return-number").html(data);
        }
      }
    };

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      calculate,
      clearAll,
      apgar: [
        {
          title: "Bazal Metobolizma Hızı Hesaplama",
          content: `<p>Canlıların yaşamsal faaliyetlerini sürdürmek için vücutta gerçekleşen biyokimyasal işlemlerin toplamına metabolizma denir. Vücuda giren besinlerin yakılması, bunun sonucunda ortaya çıkan enerjinin yapılandırılması ve onarılması metabolizmanın süreçleridir.</p>`,
        },
        {
          title: "Bazal Metobolizma Hızı Nedir?",
          content: `<p>Vücudun hareketsiz haldeyken yaktığı enerji miktarına bazal metabolizma hızı denir. Bazal metabolizmayı oluşturan unsurlar;</p>`,
        },
        {
          title: "Bazal Metobolizma Hızını Etkileyen Unsurlar Nelerdir?",
          content: `
            <ul>
                <li>Erkeklerin metabolizma hızı kadınlara göre %5-%10 oranında daha fazladır. Bunun nedeni, erkeklerin vücudundaki kas yüzdesinin fazla olmasıdır.</li>
                <li>Zayıf ve uzun kişiler aldıkları kalorileri daha hızlı yakarken, kısa ve şişman kişilerin kalori yakma hızı daha düşüktür.</li>
                <li>Çocuk ve gençken hızlı çalışan bazal metabolizma, yaşlandıkça yavaşlamaya başlar. Bunun nedeni çocukların daha hareketli olması ve yaşlandıkça başka hastalıkların metabolizmayı etkilemesidir.</li>
                <li>40 yaşından itibaren bazal metabolizma hızında, %2 oranında yavaşlama olur. Bu da vücuttaki yağ oranının artmasına ve kilo alımına neden olur.</li>
                <li>Mevsim sıcaklıklarının da metabolizma hızına etkileri vardır. Kış aylarında yavaşlayan bazal metabolizma, yaz aylarında daha hızlı çalışır.</li>
                <li>Hormonlar da metabolizma hızını etkiler. Özellikle ergenlik ve hamilelik döneminde yükselen hormonlar, metabolizmanın hızlanmasını sağlar.</li>
                <li>Proteinli besinler, kafein ve yeşil çay gibi uyarıcılar da metabolizmanın hızlanmasında etkilidir. Bu yüzden diyet listelerinde sık sık bitkisel çaylara yer verilir.</li>
                <li>Bazı ilaçların bu hızı arttırıcı veya azaltıcı etkileri görülebilir.</li>
                <li>Vücudun kas oranı yüksek ise, metabolizma hızı da artar. Özellikle yağlı besinler daha kolay bir şekilde yakılır.</li>
                <li>Aç kalmak, vücuttaki enerjinin korunmasını sağlamak için metabolizma hızını yavaşlatır. Uyku esnasında bu hız, %10 oranında yavaşlama gösterir.</li>
            </ul>`,
        },
        {
          title: "Bazal Metobolizma Hızı(BMR)  Nasıl Hesaplanır?",
          content: ` <p>Bazal metabolizma hesaplama da kullanılan 2 farklı formül vardır. Kadınlar için; 65.5 + (9.6 x ağırlık (kilogram) ) + (1.8 x boy uzunluğu (cm) ) – (4.7 x yaş) formülü kullanılırken, erkekler için bu formül; 66.5 + (13.7 x ağırlık (kilogram) ) + (5 x boy uzunluğu (cm) ) – (6.7 x yaş) şeklindedir. BMR değerini hesaplamak için internetten de yararlanılabilir. Cinsiyet, boy, kilo ve yaş bilgileri girildiğinde bazal metabolizma hızını kolayca öğrenebilirsiniz.</p>
            <p>Bazal metabolizmayı hızlandırmak, bireylerin fazla kilolardan kurtulmasını sağlar. İdeal kiloya ulaşabilmek ve kiloyu sabit tutabilmek için, günde yaklaşık 8 bardak su içilmesi önerilir. Ana öğünler, ara öğünlerle desteklenmeli; az ve sık olacak şekilde beslenilmelidir. Gün içinde egzersiz yapılmalı, uyku düzeni ayarlanmalıdır. Ayrıca aşırı tuz ve yağ tüketiminden kaçınılması da bazal metabolizmayı hızlandırır.</p>
        `,
        },
        {
          title: "Bazal Metobolizma Hızı Kaç Olmalıdır?",
          content: `<p>Bazal metabolizma hızı kaç olmalı diye merak edenler için, cevap oldukça değişkendir. Metabolizma hızının normal değerleri cinsiyet ve yaşa göre değişir. 20-40 yaş aralığındaki kadınların ideal bazal metabolizma hızı 1000 ile 1400 arasındadır. Erkeklerde ise bu değerler 1200 - 1600 arasında olmalıdır. 40 yaş ve üzerindeki kişilerde metabolizma hızı yavaşlamaya başlar. Ayrıca hızlı kilo değişimleri de bazal metabolizmayı olumsuz etkileyerek yavaşlamasına neden olur.</p>`,
        },
      ],
      references: [
        "http://www.gastronomi-mutfaksanatlari.com/FileUpload/ks241201/File/enerji_metabolizmasi.pdf",
        "https://acikders.ankara.edu.tr/pluginfile.php/47655/mod_resource/content/0/PDF%202.pdf",
        "http://www.journalagent.com/medeniyet/pdfs/MEDJ_19_1_15_18.pdf",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

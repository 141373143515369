<template>
  <div class="mobile-nav position-fixed bg-blue-footer d-lg-none">
    <transition name="fade">
      <div
        v-if="showNav"
        class="d-flex flex-column align-items-center py-4 mb-4"
      >
        <router-link
          v-for="(item, index) in headerMenu"
          :key="index"
          :to="{ name: item.router_name }"
          class="nav-link text-white font-s-18px font-w-500 my-2"
          @click="$emit('showNav', false)"
        >
          {{ item.name }}
        </router-link>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showProfileNav"
        class="d-flex flex-column align-items-center py-4 mb-4"
        @click="$emit('showProfileNav', false)"
      >
        <button
          v-for="(mobileProfileItem, mobileProfileIndex) in mobileProfileNav"
          :key="mobileProfileIndex"
          class="nav-link btn text-white font-s-18px font-w-500 my-2"
          @click="mobileProfileItem.callback()"
        >
          {{ mobileProfileItem.name }}
        </button>
      </div>
    </transition>

    <social-media-mobile />

    <legal-contents-mobile @change-show="$emit('showNav', false)" />
  </div>
</template>

<script>
import SocialMediaMobile from "../../components/ui/SocialMediaMobile.vue";
import LegalContentsMobile from "../../components/ui/LegalContentsMobile.vue";
import { useRouter } from "vue-router";

export default {
  name: "MobileNav",
  components: {
    SocialMediaMobile,
    LegalContentsMobile,
  },
  props: {
    headerMenu: {
      type: Object,
      required: true,
    },
    showNav: {
      type: Boolean,
      required: true,
    },
    showProfileNav: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["showNav", "showProfileNav", "logout"],
  setup(props, { emit }) {
    const router = useRouter();
    const mobileProfileNav = [
      {
        name: "Profil Ayarları",
        callback: () => {
          router.push({ name: "settings" });
        },
      },
      {
        name: "Mesajlar",
        callback: () => {
          router.push({ name: "message" });
        },
      },
      {
        name: "Kaydettiklerim",
        callback: () => {
          router.push({ name: "favorites" });
        },
      },
      {
        name: "Çıkış",
        callback: () => {
          emit("logout");
        },
      },
    ];
    return {
      mobileProfileNav,
    };
  },
};
</script>

<style style="scss" scoped>
.mobile-nav {
  width: 100vw;
  height: 100vh;
  top: 60px;
  left: 0;
  z-index: 6;
  overflow-x: auto;
}
</style>

<template>
  <div>
    <h4 class="font-weight-normal font-s-24px mb-4 d-none d-md-block">
      Mesleki <b class="text-info">Bilgiler</b>
    </h4>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Şehir</span>
          <base-select
            name="city_id"
            :options="cities"
            option-text-key="name"
            option-value-key="id"
            placeholder="Şehir seçiniz"
            :loading="isOptionsLoading"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >Çalıştığınız Kurum</span
          >
          <base-select
            name="hospital_id"
            :options="hospitals"
            option-text-key="name"
            option-value-key="id"
            placeholder="Lütfen Kurumunuzu Seçiniz"
            :loading="isOptionsLoading"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Ünvanınız</span>
          <base-select
            name="title_id"
            :options="titles"
            option-text-key="name"
            option-value-key="id"
            placeholder="Ünvanınız"
            :loading="isOptionsLoading"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >Göreviniz (Biriminiz)</span
          >
          <base-input
            name="job_position"
            placeholder="Lütfen görev adını yazınız..."
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >Başlangıç Tarihi</span
          >
          <base-input type="date" name="job_start_date" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Bitiş Tarihi</span>
          <base-input type="date" name="job_end_date" />
        </div>
      </div>
    </div>

    <div class="row">
      <settings-action-buttons
        submit-button-text="Mesleki Bilgileri Güncelle"
        :is-submitting="isSubmitting"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import * as yup from "yup";
import BaseSelect from "../ui/RegisterForm/BaseSelect";
import BaseInput from "../ui/RegisterForm/BaseInput";
import { useForm } from "vee-validate";
import HospitalService from "../../services/hospital/HospitalService";
import { ref, watch } from "vue";
import RegisterService from "../../services/register/RegisterService";
import SettingsActionButtons from "./SettingsActionButtons";
import UserServiceNew from "../../services/UserServiceNew";
import SwalError from "../../helpers/SwalError";
import { showError } from "../../shared/utils";

const hospitalService = new HospitalService();
const userService = new UserServiceNew();

export default {
  name: "SettingsProfessionalInformation",
  components: { SettingsActionButtons, BaseInput, BaseSelect },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["update-success", "update-fail"],
  setup(props, { emit }) {
    const isOptionsLoading = ref(false);
    const hospitals = ref([]);
    const cities = ref([]);
    const titles = ref([]);
    const isSubmitting = ref(false);

    const form = useForm({
      validationSchema: {
        city_id: yup.number().required(),
        hospital_id: yup.number().required(),
        title_id: yup.number().required(),
        job_position: yup.string().notRequired().nullable(),
      },
      initialValues: {
        city_id: props.user.city_id ?? 1,
        hospital_id: props.user.hospital_id ?? 0,
        title_id: props.user.title_id ?? 0,
        job_position: props.user.job_position,
        job_start_date: props.user.job_start_end_year?.split("@")[0],
        job_end_date: props.user.job_start_end_year?.split("@")[1],
      },
    });

    function fetchOptions() {
      isOptionsLoading.value = true;
      Promise.all([
        hospitalService.getHospitalsByCity(props.user.city_id ?? 1),
        RegisterService.getCities(),
        RegisterService.getTitles(),
      ])
        .then((options) => {
          hospitals.value = options[0].data.data;
          cities.value = options[1].data.data;

          let titleValues = [];

          Object.keys(options[2].data.data).map((key) => {
            titles.value.push({ name: options[2].data.data[key], id: key });
          });

          titles.value = titleValues;
        })
        .catch((e) => {
          SwalError.fire(e);
        })
        .finally(() => {
          isOptionsLoading.value = false;
        });
    }
    const fieldLabels = {
      city_id: "Şehir",
      hospital_id: "Çalıştığınız Kurum",
      title_id: "Ünvan",
      job_position: "Görev",
    };

    async function handleSubmit() {
      isSubmitting.value = true;
      const { valid, errors } = await form.validate();
      if (!valid) {
        return showError(valid, errors, fieldLabels);
      }
      const values = {
        ...form.values,
        job_start_end_year:
          form.values.job_start_date + "@" + form.values.job_end_date,
      };
      try {
        await userService.updateProfessionalInformations(values);
        isSubmitting.value = false;
        emit("update-success");
      } catch (e) {
        isSubmitting.value = false;
        emit("update-fail");
      }
    }

    fetchOptions();

    watch(
      () => form.values.city_id,
      () => {
        form.values.hospital_id = undefined;
        isOptionsLoading.value = true;
        hospitalService
          .getHospitalsByCity(form.values.city_id)
          .then((response) => {
            hospitals.value = response.data.data;
          })
          .catch((e) => {
            SwalError.fire(e);
          })
          .finally(() => {
            isOptionsLoading.value = false;
          });
      }
    );

    return {
      handleSubmit,
      form,
      isOptionsLoading,
      hospitals,
      cities,
      titles,
      isSubmitting,
    };
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <h4 class="font-weight-normal font-s-24px mb-4 d-none d-md-block">
      Parola <b class="text-info">Değişikliği</b>
    </h4>
    <p class="text-secondary font-s-14px my-4">
      Şifreniz en az 8 karakterden oluşmalı ve en az bir küçük harf, bir büyük
      harf, bir rakam ve bir de özel karakter içermelidir.
    </p>

    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >Şu anki Şifreniz</span
          >
          <div class="position-relative w-100">
            <base-input name="old_password" type="password" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >Yeni Şifreniz</span
          >
          <base-input name="new_password" type="password" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >Yeni Şifreniz Tekrar</span
          >
          <base-input name="new_password_confirmation" type="password" />
        </div>
      </div>
    </div>
    <div class="row">
      <settings-action-buttons
        submit-button-text="Şifre Güncelle"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import { useForm } from "vee-validate";
import * as yup from "yup";
import UserServiceNew from "../../services/UserServiceNew";
import BaseInput from "../ui/RegisterForm/BaseInput";
import SettingsActionButtons from "./SettingsActionButtons";
import { passwordRegex } from "../../shared/utils";
import { showError } from "../../shared/utils";

const userService = new UserServiceNew();

export default {
  name: "SettingsPassword",
  components: { SettingsActionButtons, BaseInput },
  emits: ["update-success", "update-fail"],
  setup(props, { emit }) {
    const form = useForm({
      validationSchema: yup.object({
        old_password: yup.string().required(),
        new_password: yup.string().required().matches(passwordRegex),
        new_password_confirmation: yup
          .string()
          .required()
          .matches(passwordRegex)
          .oneOf([yup.ref("new_password")]),
      }),
    });

    const fieldLabels = {
      old_password: "Şu anki Şifreniz",
      new_password: "Yeni Şifreniz",
      new_password_confirmation: "Yeni Şifreniz Tekrar",
    };

    async function handleSubmit() {
      const { valid, errors } = await form.validate();
      if (!valid) {
        return showError(valid, errors, fieldLabels);
      }
      try {
        await userService.updatePassword(form.values);
        form.resetForm();
        emit("update-success");
      } catch (e) {
        if (e.response.status === 412) {
          const errors = {};
          Object.keys(e.response.data.data).forEach(
            (key) => (errors[key] = e.response.data.data[key][0])
          );
          form.setErrors(errors);
          return emit(
            "update-fail",
            `${Object.keys(e.response.data.data).join(", ")} alanları hatalı!`
          );
        }
        emit("update-fail");
      }
    }

    return {
      handleSubmit,
      form,
    };
  },
};
</script>

<style scoped></style>

<template>
  <div class="container-xl mb-5">
    <div class="swiper-tools position-relative">
      <div class="swiper-container mySwiperTools py-3 d-none d-md-block">
        <div class="swiper-wrapper home-tools-swiper-wrapper">
          <div
            v-for="(item, index) in calRules"
            :key="index"
            class="swiper-slide border-radius box-shadow-4-20 home-tools"
          >
            <div class="d-flex flex-column align-items-center p-4">
              <div class="mb-3">
                <calculate-img v-if="index === 0" class="icon-img" />
                <!-- <interaction-img v-else-if="index === 1" class="icon-img" /> -->
                <questinnaire-img v-else-if="index === 1" class="icon-img" />
                <pubmed-img v-else class="icon-img" />
              </div>
              <div>
                <h4 class="font-s-22px mb-2 gray-500 font-weight-bold">
                  {{
                    item.route_name == "questionnaire"
                      ? zoneName + item.title
                      : item.title
                  }}
                </h4>
              </div>
              <div>
                <p class="font-s-12px mb-3 gray-700">
                  {{
                    item.route_name == "questionnaire"
                      ? "Yenilenen " + zoneName + item.desc
                      : item.desc
                  }}
                </p>
              </div>
              <div>
                <router-link
                  :to="{ name: item.route_name }"
                  class="btn bg-gray-500 border-radius"
                >
                  <span class="font-s-12px white border-radius">{{
                    item.btn
                  }}</span>
                  <right-arrow-icon color="white" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination" />
        <div class="container-xl swiper-tools-pagination">
          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
      </div>
      <!-- <div class="height-350px"></div> -->

      <div
        class="
          d-md-none d-flex
          flex-column
          align-items-center
          position-relative
          home-tools-cards-mobile
        "
      >
        <div
          v-for="(item, index) in calRules"
          :key="index"
          class="
            home-tools-card-mobile
            d-flex
            flex-column
            bg-white
            p-3
            my-2
            border-radius
            box-shadow-4-20
          "
        >
          <div class="d-flex align-items-center justify-content-center mb-4">
            <div class="mr-2">
              <calculate-img v-if="index === 0" class="icon-img" />
              <!-- <interaction-img v-else-if="index === 1" class="icon-img" /> -->
              <questinnaire-img v-else-if="index === 1" class="icon-img" />
              <pubmed-img v-else class="icon-img" />
            </div>
            <div>
              <h4 class="font-s-18px font-weight-bold gray-500">
                {{
                  item.route_name == "questionnaire"
                    ? zoneName + item.title
                    : item.title
                }}
              </h4>
              <p class="font-s-12px">
                {{
                  item.route_name == "questionnaire"
                    ? "Yenilenen " + zoneName + item.desc
                    : item.desc
                }}
              </p>
            </div>
          </div>
          <div>
            <router-link
              :to="{ name: item.route_name }"
              class="
                btn
                bg-gray-500
                d-flex
                justify-content-center
                border-radius
              "
            >
              <span class="text-white font-s-10px">{{ item.btn }}</span>
              <right-arrow-icon color="white" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/bundle";
import CalculateImg from "../icon/img/CalculateImg.vue";
// import InteractionImg from "../icon/img/InteractionImg.vue";
import QuestinnaireImg from "../icon/img/QuestionnaireImg.vue";
import PubmedImg from "../icon/img/PubmedImg.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import ZoneHelper from "../../shared/zone-helper";

export default {
  name: "HomeTools",
  components: {
    CalculateImg,
    // InteractionImg,
    QuestinnaireImg,
    PubmedImg,
  },
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const zoneHelper = new ZoneHelper();
    const zoneName = computed(() => zoneHelper.getZoneName(theme));

    const calRules = [
      {
        title: "Hesaplama Cetvelleri",
        desc: "Hesaplama Cetvelleri ile birçok değeri hesaplayabilirsiniz. ",
        btn: "Tümünü Gör",
        route_name: "hesaplama-cetvelleri",
      },
      {
        title: " Anket",
        desc: "’u beğendiniz mi? Buraya tıklayarak anketi doldurabilirsiniz.",
        btn: "Ankete Katıl",
        route_name: "questionnaire",
      },
      {
        title: "Literatür Tarama",
        desc: "Pubmed üzerinde arama yapıp bulduğunuz makaleyi 'Pubmed Tam Metin Talebi' kısmından talepte bulunabilirsiniz.",
        btn: "Tümünü Gör",
        route_name: "pubmed",
      },
    ];

    if (theme === "hemaworld") {
      calRules.splice(1, 2);
    }

    return {
      zoneName,
      calRules,
    };
  },
  mounted() {
    new Swiper(".mySwiperTools", {
      observer: true,
      observeParents: true,
      slidesPerView: 3,
      spaceBetween: 30,
      // centeredSlides: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1400: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.home-tools-card-mobile {
  max-width: 374px;
  width: 100%;
}

.home-tools-swiper-wrapper {
  height: 355px;
}

.swiper-tools {
  .swiper-container {
    width: 100%;
    height: 100%;
    overflow: inherit;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-pagination {
    display: none;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: initial;

    &:after {
      content: none;
    }
  }
  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%0A%3Csvg width='27' height='22' viewBox='0 0 27 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.375 11.4115L24.875 11.4115' stroke='%23114DAE' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.4497 20.4481L2.37471 11.4121L11.4497 2.37457' stroke='%23114DAE' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
    background: no-repeat;
    margin-right: 30px;
  }
  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%0A%3Csvg width='27' height='22' viewBox='0 0 27 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.625 10.5885L2.125 10.5885' stroke='%23114DAE' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5503 1.55193L24.6253 10.5879L15.5503 19.6254' stroke='%23114DAE' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background: no-repeat;
  }
  .swiper-tools-pagination {
    display: flex;
    position: relative;
    top: 45px;
    padding: 32px 0;
  }
}
</style>

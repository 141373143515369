import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getEvents() {
    return apiClient.get("/api/events");
  },
  getUpcomingEvents(page = 1, perPage = 4) {
    return apiClient.get("/api/events", {
      params: {
        page: page,
        per_page: perPage,
        qb: true,
        sort: "starts_at",
        "filter[published]": true,
      },
    });
  },
  getActiveEvents(page = 1, perPage = 4) {
    return apiClient.get("/api/events", {
      params: {
        page: page,
        per_page: perPage,
        qb: true,
        sort: "starts_at",
        "filter[started]": true,
        "filter[not_finished]": true,
      },
    });
  },
  async getEventBySlug(slug) {
    const result = await apiClient.get("/api/events", {
      params: {
        qb: true,
        "filter[slug]": slug,
        "filter[published]": true,
        include: "auth_ministry_reports",
        append: "detail_image_url",
      },
    });

    if (result?.data?.data?.length === 0) {
      throw new Error("Event not found");
    }

    return result;
  },
  registerMinistryReport(eventId, data) {
    return apiClient.post("/api/ministry-report", {
      ...data,
      reportable_id: eventId,
      reportable_type: "Epigra\\Events\\Models\\Event",
    });
  },
};

<template>
  <div class="login d-flex align-items-center justify-content-center p-4">
    <div class="max-w-555px">
      <div class="d-none d-lg-block login-logo mb-4">
        <router-link :to="{ name: 'home' }">
          <img :src="logo_url" alt="" class="img-fluid" />
        </router-link>
      </div>

      <div class="mb-5">
        <p class="font-s-16px gray-500">
          Türkiye'nin {{ zone.deparment }}
          {{ zone.theme == "ahezone" ? "hekimlerine" : "uzmanlarına" }} özel
          geniş içeriğe sahip iletişim platformu.
        </p>
      </div>

      <div>
        <form>
          <h4 class="mb-3">Şifre Sıfırlama</h4>
          <div class="form-input">
            <div class="mb-4 position-relative">
              <base-input
                v-model:modelValue="password"
                name="password"
                type="password"
                placeholder="Parola"
                info-message="Parolanız en az 8 karakterden oluşmalı ve en az bir küçük harf,
                bir büyük harf, bir rakam ve bir de özel karakter içermelidir."
              />
            </div>
          </div>
          <div class="form-input">
            <div class="mb-4 position-relative">
              <base-input
                name="password_confirmation"
                type="password"
                placeholder="Parola Tekrar Giriniz"
              />
            </div>
          </div>
          <div class="mb-4">
            <button
              type="button"
              class="btn btn-primary w-100 border-radius py-3"
              :disabled="isSubmitting"
              @click="resetPassword()"
            >
              Şifreyi Güncelle
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import UserServiceNew from "../../services/UserServiceNew";
import BaseInput from "./RegisterForm/BaseInput.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

defineProps({
  zone: {
    type: Object,
    required: true,
  },
});

const userService = new UserServiceNew();
const store = useStore();
const route = useRoute();
const router = useRouter();
const logo_url = "/new-img/" + store.state.theme + "-logo.png";
const isSubmitting = ref(false);
const password = ref("");

const form = useForm({
  validationSchema: {
    password: yup
      .string()
      .matches(
        /* eslint-disable-next-line no-useless-escape */
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\]:;\"'|\\<>,.\/~`±§+-]).{8,30}$/
      )
      .required()
      .min(8),
    password_confirmation: yup
      .string()
      .required()
      .min(8)
      .test("passwords-match", "Passwords must match", function (value) {
        return password.value === value;
      }),
  },
});

async function resetPassword() {
  const { valid } = await form.validate();
  if (!valid) {
    return;
  }

  isSubmitting.value = true;
  try {
    await userService.resetPassword({
      token: route.params.token,
      email: route.query.email,
      ...form.values,
    });
    isSubmitting.value = false;
    Swal.fire({
      icon: "success",
      title: "Şifreniz başarıyla sıfırlandı.",
      confirmButtonText: "Tamam",
      timer: 3000,
    });
    router.push({ name: "login" });
  } catch (e) {
    isSubmitting.value = false;
    Swal.fire({
      icon: "error",
      title: "Bir hata oluştu. Şifrenizi kontrol edin.",
      confirmButtonText: "Tamam",
    });
  }
}
</script>

<style></style>

<template>
  <div
    class="d-flex justify-content-center align-items-center"
    :class="{
      left50: isFullScreen,
      'position-absolute': isFullScreen,
      'h-100': isFullScreen,
    }"
  >
    <template v-if="isFullScreen">
      <img class="image" :src="img" alt="" />
    </template>
    <template v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </template>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "LoadingAnimIcon",
  props: {
    isFullScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const img = "/new-img/" + store.state.theme + "-logo-anim.png";

    return {
      img,
    };
  },
};
</script>

<style scoped>
.left50 {
  left: 50%;
}
.image {
  height: 70px;
  width: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -35px 0 0 -35px;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

import axios from "axios";

export const createApiClient = () => {
  const apiClient = axios.create({
    baseURL: process.env.MIX_API_BASE_URL,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("api_token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return apiClient;
};

<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.03857 9.47852L12.8497 13.1579"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.10449 13.2239L12.7838 9.41277"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="11.312"
      cy="11.312"
      r="7.75"
      transform="rotate(-46.0077 11.312 11.312)"
      :stroke="color"
      stroke-width="0.5"
      stroke-dasharray="3 3"
    />
  </svg>
</template>

<script>
export default {
  name: "TagCloseIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

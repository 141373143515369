<template>
  <button
    :disabled="isLiking"
    class="like-button bg-transparent border-0 p-0"
    @click="handleLike"
  >
    <svg
      v-if="propIsLiked"
      class="mr-1"
      :width="size"
      :height="size"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 19.5625C10.9116 19.5625 10.8242 19.5403 10.7442 19.4958C10.6453 19.4407 8.29579 18.1183 5.91053 15.9519C4.49895 14.6707 3.37263 13.3599 2.56 12.0554C1.52421 10.392 1 8.73179 1 7.12132C1 4.05603 3.47895 1.5625 6.52632 1.5625C7.55789 1.5625 8.65368 1.95109 9.61158 2.65732C10.1716 3.07026 10.6484 3.56791 11 4.09626C11.3516 3.56791 11.8284 3.07026 12.3884 2.65732C13.3463 1.95109 14.4421 1.5625 15.4737 1.5625C18.5211 1.5625 21 4.05603 21 7.12132C21 8.73179 20.4747 10.392 19.44 12.0554C18.6284 13.3599 17.5011 14.6707 16.0905 15.9519C13.7053 18.1183 11.3558 19.4407 11.2568 19.4958C11.1768 19.5403 11.0895 19.5625 11.0011 19.5625H11Z"
        :fill="color"
        stroke="transparent"
        stroke-width="2"
      />
    </svg>
    <svg
      v-else
      class="mr-1"
      :width="size"
      :height="size"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 14C7.94097 14 7.88273 13.9852 7.82939 13.9555C7.76343 13.9188 6.19711 13.0372 4.60694 11.5929C3.66588 10.7388 2.91501 9.86494 2.37325 8.99529C1.68273 7.88635 1.33325 6.77953 1.33325 5.70588C1.33325 3.66235 2.98588 2 5.01746 2C5.70518 2 6.43571 2.25906 7.07431 2.72988C7.44764 3.00518 7.76553 3.33694 7.99992 3.68918C8.23431 3.33694 8.5522 3.00518 8.92553 2.72988C9.56413 2.25906 10.2947 2 10.9824 2C13.014 2 14.6666 3.66235 14.6666 5.70588C14.6666 6.77953 14.3164 7.88635 13.6266 8.99529C13.0855 9.86494 12.334 10.7388 11.3936 11.5929C9.80343 13.0372 8.23711 13.9188 8.17115 13.9555C8.11781 13.9852 8.05957 14 8.00062 14H7.99992Z"
        stroke="#AFBBDB"
        stroke-width="1.5"
      />
    </svg>
    <span v-if="isCountable" :class="counterClasses">{{ propLikeCount }}</span>
  </button>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import LikeService from "../../services/like/LikeService";
import ColorHelper from "../../helpers/ColorHelper";

export default {
  name: "FollowIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
    likeableType: {
      type: String,
      required: true,
    },
    likeableId: {
      type: Number,
      required: true,
    },
    isLiked: {
      type: Boolean,
      required: true,
    },
    isCountable: {
      type: Boolean,
      required: false,
      default: false,
    },
    likeCount: {
      type: Number,
      default: 0,
      required: false,
    },
    counterClasses: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const isLiking = ref(false);
    const propIsLiked = ref(props.isLiked);
    const propLikeCount = ref(props.likeCount);
    const color = ColorHelper[store.state.theme].blueText;

    const updateLike = () => {
      propIsLiked.value = !propIsLiked.value;
      if (propIsLiked.value === true) {
        propLikeCount.value += 1;
      } else {
        propLikeCount.value -= 1;
      }
    };

    const handleLike = () => {
      isLiking.value = true;
      updateLike();
      LikeService.like(user.value.id, props.likeableType, props.likeableId)
        .catch(() => {
          updateLike();
        })
        .finally(() => {
          isLiking.value = false;
        });
    };

    return {
      handleLike,
      propIsLiked,
      isLiking,
      propLikeCount,
      color,
    };
  },
};
</script>

<style></style>

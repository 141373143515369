<template>
  <div class="d-flex justify-content-center mb-4">
    <div
      v-if="store.state.settings?.facebook_account_url"
      class="
        height-50px
        width-50px
        border-radius-50
        bg-blue-300
        mr-3
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <a :href="store.state.settings.facebook_account_url">
        <facebook-icon color="white" />
      </a>
    </div>
    <div
      v-if="store.state.settings?.instagram_account_url"
      class="
        height-50px
        width-50px
        border-radius-50
        bg-blue-300
        mr-3
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <a :href="store.state.settings.instagram_account_url">
        <instagram-icon color="white" />
      </a>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
};
</script>

<style></style>

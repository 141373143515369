<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "SimplestLayout",
};
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .show-header {
    display: none;
  }
}
</style>

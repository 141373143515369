import { messaging } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";
import axios from "axios";

export async function requestNotificationPermission() {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    await saveMessagingDeviceToken();
  }
}

export async function saveMessagingDeviceToken() {
  try {
    const msg = await messaging();
    const fcmToken = await getToken(msg, {
      vapidKey: process.env.MIX_FIREBASE_VAPID_KEY,
    });

    if (fcmToken) {
      if (!localStorage.getItem("api_token")) return;

      axios.post(
        "/api/store-device",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("api_token"),
            "X-Device-FCM-Token": fcmToken,
            "X-Device-OS": navigator.platform,
            "X-Device-OS-Version": navigator.userAgent,
            "X-Device-Manufacturer": navigator.vendor,
          },
        }
      );

      onMessage(msg, (payload) => {
        const notification = new Notification(payload.data.title, {
          body: payload.data.body,
          icon: payload.data.image_url ?? payload.data.icon,
          tag: payload.data.tag,
        });
        notification.onclick = function (event) {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          notification.close();
          window.open(payload.data.click_action, "_blank");
        };
      });
    } else {
      requestNotificationPermission();
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
}

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8168 15.6205C15.8168 17.6135 14.4588 19.0215 12.2868 19.0215H6.06978C3.89978 19.0215 2.55078 17.6135 2.55078 15.6205V8.66049C2.55078 6.66849 3.89978 5.25049 6.08078 5.25049H12.2868C14.4588 5.25049 15.8168 6.66849 15.8168 8.66049V15.6205Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8164 11.2766L19.9134 7.9226C20.5634 7.3876 21.5504 7.8526 21.5504 8.6996V15.5716C21.5504 16.4196 20.5634 16.8826 19.9134 16.3486L15.8164 12.9946"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CameraIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "#7684AA",
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

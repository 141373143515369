<template>
  <div class="settings mb-5">
    <section
      class="
        settings-detail-main
        bg-image
        d-flex
        flex-column
        align-items-center
        mb-md-5
      "
    >
      <settings-hero :user="user" />
    </section>

    <section>
      <div class="container-xl">
        <div class="row">
          <!-- left-nav -->
          <div class="col-12 col-md-6 col-lg-4">
            <settings-left-navigation
              @set-tab="
                router.push({ name: 'settings', query: { page: $event } })
              "
            />
          </div>

          <!-- content -->
          <div class="col-12 col-md-6 col-lg-8 mb-5">
            <component
              :is="currentContentTab"
              :user="user"
              @update-success="userUpdated"
              @update-fail="userUpdateFailed($event)"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import SettingsGeneralInformation from "../components/Settings/SettingsGeneralInformation";
import SettingsPassword from "../components/Settings/SettingsPassword";
import SettingsProfessionalInformation from "../components/Settings/SettingsProfessionalInformation";
import SettingsPharmacyInformation from "../components/Settings/SettingsPharmacyInformation";
import SettingsNotification from "../components/Settings/SettingsNotification";
import SettingsLeftNavigation from "../components/Settings/SettingsLeftNavigation";
import SettingsAccountSettings from "../components/Settings/SettingsAccountSettings";
import { computed, ref } from "vue";
import SettingsHero from "../components/Settings/SettingsHero";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "../utils/toast";

export default {
  name: "Settings",
  components: {
    SettingsHero,
    SettingsLeftNavigation,
    SettingsNotification,
    SettingsProfessionalInformation,
    SettingsPharmacyInformation,
    SettingsPassword,
    SettingsGeneralInformation,
    SettingsAccountSettings,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const user = computed(() => store.state.user.user);
    const currentContentTab = ref(
      route.query.page ?? "settings-general-information"
    );

    if (route.query.alert_type == "missing_university_information") {
      Toast.fire({
        title: "Bilgilendirme",
        text: "Fakülte bilginizi doldurmanız gerekmektedir.",
        icon: "warning",
      });
    }

    return {
      currentContentTab,
      router,
      color: ColorHelper[store.state.theme].blueText,
      main_img: "url('/new-img/" + store.state.theme + "-content-main-bg.png')",
      user,
      userUpdated() {
        store.dispatch("user/fetchAndSetUserInformation");
        Swal.fire(
          "Başarılı!",
          "Bilgileriniz başarıyla güncellendi!",
          "success"
        );
      },
      userUpdateFailed(msg) {
        Swal.fire("Oops!", msg ? msg : "Form kaydedilemedi!", "error");
      },
    };
  },
};
</script>

<style lang="scss">
.settings-detail-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 470px;
}

.person-img {
  width: 125px;
  height: 125px;
}

.person-btn {
  width: 185px;
  height: 48px;
}

.nav-item {
  transition: 0.3s;

  &:hover {
    background-color: #daedf65b;
    cursor: pointer;
  }
}

.btn-save {
  min-width: 187px;
  height: 50px;
}

@media (max-width: 768px) {
  .change-settings-box {
    top: -50px;
  }
}
</style>

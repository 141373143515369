<template>
  <div class="p-4 d-flex flex-column box-shadow-4-20-3 border-radius">
    <div class="d-flex justify-content-between align-items-center">
      <h4
        class="font-s-18px font-w-500 text-info cursor-pointer m-0"
        @click="openCollapse = !openCollapse"
      >
        {{ collapse.title }}
      </h4>
      <bottom-arrow-icon
        v-if="!openCollapse"
        :color="colorDark"
        :size="24"
        class="cursor-pointer"
        @click="openCollapse = !openCollapse"
      />
      <close-icon
        v-else
        :size="24"
        :color="colorDark"
        class="cursor-pointer"
        @click="openCollapse = !openCollapse"
      />
    </div>
    <transition name="fade">
      <div v-show="openCollapse">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="
            text-secondary
            font-s-16px font-s-m-12px
            line-h-m-21px
            m-0
            mt-3
            pr-3
            line-h-28px
          "
          v-html="collapse.content"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "CalculateCollapse",
  props: {
    collapse: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const openCollapse = ref(false);

    return {
      colorDark,
      openCollapse,
    };
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  h4 {
    font-size: 14px !important;
  }
}
</style>

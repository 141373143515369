<template>
  <section class="mb-75px">
    <div class="container-xl">
      <div class="bg-white border-radius box-shadow-4-20 p-5">
        <h2 class="font-s-24px text-primary mb-5">Çeviri Talebi</h2>
        <p>
          10.000 kelime / 20 Sayfaya kadar olan içeriklerinizi aşağıdaki butona
          tıklayarak iletebilirsiniz.
        </p>
        <p>
          10.000 kelime /20 Sayfa üzeri içerikleriniz için butona tıklayarak
          talepte bulunabilirsiniz.
        </p>

        <div class="mb-4">
          <label class="d-block font-s-13px">İçerik Türü</label>
          <select
            v-model="titleValue"
            class="
              w-m-100 w-50
              px-3
              box-shadow-4-20-3
              border-none
              height-50px
              border-radius
            "
          >
            <option disabled value="">Seç</option>
            <option value="10.000 kelime / 20 sayfa">
              10.000 kelime / 20 sayfa
            </option>
            <option value="10.000 kelime / 20 sayfa üzeri">
              10.000 kelime / 20 sayfa üzeri
            </option>
          </select>
        </div>

        <div class="mb-4">
          <label class="d-block font-s-13px"
            >Açıklama (Talep Ayrıntılarınızı Buraya Yazınız)</label
          >
          <input
            v-model="contentValue"
            type="text"
            placeholder="Açıklama (Talep Ayrıntılarınızı Buraya Yazınız)"
            class="
              w-m-100 w-50
              px-3
              box-shadow-4-20-3
              border-none
              height-50px
              border-radius
            "
          />
        </div>

        <div class="mb-4">
          <label class="d-block font-s-13px">İçerik</label>
          <input ref="file" type="file" class="w-100" @change="selectFile" />
          <span class="font-s-10px">Maksimum 20mb yüklenebilir.</span>
        </div>

        <div class="mb-4">
          <remaining-user-content-info
            type="ceviri-talebi"
            @loaded="isLoading = false"
          />
        </div>

        <div>
          <button
            :disabled="isSending || isLoading"
            class="btn btn-primary border-radius px-4 py-2"
            @click="$emit('send')"
          >
            Oluştur
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref } from "vue";
import RemainingUserContentInfo from "./RemainingUserContentInfo.vue";

export default {
  components: {
    RemainingUserContentInfo,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:title", "update:content", "update:files", "send"],
  setup(props, ctx) {
    const file = ref(null);
    const isLoading = ref(true);

    const titleValue = computed({
      get() {
        return props.title;
      },
      set(value) {
        ctx.emit("update:title", value);
      },
    });

    const contentValue = computed({
      get() {
        return props.content;
      },
      set(value) {
        ctx.emit("update:content", value);
      },
    });

    const filesValue = computed({
      get() {
        return props.files;
      },
      set(value) {
        ctx.emit("update:files", value);
      },
    });

    const selectFile = () => {
      ctx.emit("update:files", file.value.files);
    };

    return {
      file,
      titleValue,
      contentValue,
      filesValue,
      selectFile,
      isLoading,
    };
  },
};
</script>

<style></style>

<template>
  <div class="login-info bg-image d-flex justify-content-center p-4">
    <div class="text-white max-w-455px login-info-content">
      <router-link
        v-for="(event, index) in events"
        :key="index"
        :to="{
          name: 'eventdetail',
          params: { slug: event.slug },
        }"
      >
        <div class="alert alert-danger d-flex align-items-center" role="alert">
          <span class="blink-circle"></span>
          <span class="ml-2">{{ event.title }}</span>
        </div>
      </router-link>

      <div>
        <p class="font-s-18px font-w-600">
          "{{ zone.name }}'da hemen yerinizi alın, ayrıcalıklardan yararlanma
          fırsatını kaçırmayın!"
        </p>
      </div>

      <div class="swiper-container mySwiperLogin mb-5">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in slider"
            :key="index"
            class="swiper-slide bg-transparent d-flex align-items-center"
          >
            <div>
              <login-slider-img :show="index + 1" />
            </div>
            <div class="d-flex flex-column align-items-start">
              <h4 class="font-weight-bold font-s-18px">{{ item.title }}</h4>
              <p class="font-s-12px text-left">
                {{ item.content }}
              </p>
            </div>
          </div>
        </div>
        <div class="swiper-pagination" />
      </div>

      <div>
        <router-link
          :to="'/' + (btnClass == 'register' ? 'login' : 'register')"
          :class="
            'btn-' +
            btnClass +
            ' btn bg-blue-300 font-s-16px text-white d-flex justify-content-center align-items-center border-radius'
          "
        >
          <span>{{ btnText }}</span>
          <right-arrow-icon color="white" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/bundle";
import { useStore } from "vuex";
import LoginSliderImg from "./LoginSliderImg.vue";
import { computed, onMounted, ref } from "vue";
import EventService from "../../services/event/EventService";
import Swal from "sweetalert2/dist/sweetalert2";
import { onBeforeRouteUpdate, useRouter } from "vue-router";

export default {
  name: "LoginInfo",
  components: {
    LoginSliderImg,
  },
  props: {
    btnText: {
      type: String,
      required: true,
    },
    btnClass: {
      type: String,
      required: true,
    },
    zone: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const theme = computed(() => store.state.theme);
    const login_bg = "url('/new-img/" + store.state.theme + "-login-bg.png')";
    const isEventsLoading = ref(false);
    const events = ref({});
    const router = useRouter();
    const slider =
      theme.value == "hemaworld"
        ? [
            {
              title: "Mesaj",
              content: `Mesaj modülü ile ${props.zone.name}'daki diğer insanlar ile mesajlaşın.`,
            },
          ]
        : [
            {
              title: "Videolar",
              content: `Düzenli olarak güncellenen, alanında uzman kişilerden ${props.zone.name}'a özel eğitim videoları.`,
            },
            {
              title: "Mesaj",
              content: `Mesaj modülü ile ${props.zone.name}'daki diğer insanlar ile mesajlaşın.`,
            },
            {
              title: "Pubmed",
              content: `Literatür tarama aracı ile ${props.zone.name}'dan makale talep edebilirsiniz.`,
            },
          ];

    const fetchEvents = () => {
      isEventsLoading.value = true;
      EventService.getActiveEvents(1, 4)
        .then((response) => {
          events.value = response.data.data.items.filter(
            (e) => e.tags.length > 0
          );
        })
        .finally(() => {
          isEventsLoading.value = false;
        });
    };

    const warnUserToLoginOrRegister = (title) => {
      // if redirect query parameter starts with /etkinlikler/ then warn user to login or register
      if (router.currentRoute.value.query.redirect) {
        if (
          router.currentRoute.value.query.redirect.startsWith("/etkinlikler/")
        ) {
          Swal.fire({
            title,
            icon: "warning",
            confirmButtonText: "Tamam",
          });
        }
      }
    };

    fetchEvents();

    onMounted(() => {
      warnUserToLoginOrRegister(
        "Etkinliğe gitmek için giriş yapmanız veya kayıt olmanız gerekmektedir."
      );
    });

    onBeforeRouteUpdate(() => {
      warnUserToLoginOrRegister(
        "Etkinliğe gitmek için giriş yapmanız veya kayıt olmanız gerekmektedir."
      );
    });

    return {
      isEventsLoading,
      events,
      login_bg,
      slider,
      theme: store.state.theme,
    };
  },
  mounted() {
    new Swiper(".mySwiperLogin", {
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.login-info {
  width: 50vw;
  background: v-bind("login_bg");
}

.btn-login {
  width: 213px;
  height: 42px;
}
.btn-register {
  width: 290px;
  height: 42px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-info-content {
  margin-top: 30vh;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -6px !important;
  width: 70% !important;
}

@media (max-width: 768px) {
  .login-info-content {
    max-width: 101% !important;
  }
}

@media (max-width: 992px) {
  .login-info-content {
    margin: 50px 0;
  }

  .btn-login,
  .btn-register {
    width: auto;
  }
}

.blink-circle {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

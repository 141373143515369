export const zoneLogos = [
  {
    path: "https://ahezone.com",
    logo: "/new-img/ahezone-logo.png",
    colorlessLogo: "/new-img/ahezone-colorless-logo.png",
  },
  {
    path: "https://norozone.com",
    logo: "/new-img/norozone-logo.png",
    colorlessLogo: "/new-img/norozone-colorless-logo.png",
  },
  {
    path: "https://dermazone.org",
    logo: "/new-img/dermazone-logo.png",
    colorlessLogo: "/new-img/dermazone-colorless-logo.png",
  },
  {
    path: "https://eczazone.com",
    logo: "/new-img/eczazone-logo.png",
    colorlessLogo: "/new-img/eczazone-colorless-logo.png",
  },
  {
    path: "https://kvczone.com",
    logo: "/new-img/kvczone-logo.png",
    colorlessLogo: "/new-img/kvczone-colorless-logo.png",
  },
  {
    path: "https://ftrzone.com",
    logo: "/new-img/ftrzone-logo.png",
    colorlessLogo: "/new-img/ftrzone-colorless-logo.png",
  },
  {
    path: "https://kbbzone.com",
    logo: "/new-img/kbbzone-logo.png",
    colorlessLogo: "/new-img/kbbzone-colorless-logo.png",
  },
  {
    path: "https://ortopedizone.com",
    logo: "/new-img/ortopedizone-logo.png",
    colorlessLogo: "/new-img/ortopedizone-colorless-logo.png",
  },
  {
    path: "https://psikezone.com",
    logo: "/new-img/psikezone-logo.png",
    colorlessLogo: "/new-img/psikezone-colorless-logo.png",
  },
  {
    path: "https://hemaworld.org",
    logo: "/new-img/hemaworld-logo.png",
    colorlessLogo: "/new-img/hemaworld-colorless-logo.png",
  },
];

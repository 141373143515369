<template>
  <div class="event-detail mb-100px">
    <section class="event-detail-main bg-image d-flex flex-column">
      <content-main
        v-if="eventDetail.title"
        :title="eventDetail.title"
        :nav="eventDetail.title"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />
    </section>
    <section class="mt-3">
      <div class="container-xl">
        <div>
          <form @submit.prevent="registerHandler">
            <div>
              <div class="row">
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    name="firstname"
                    placeholder="İsim"
                    :left-icon="{
                      name: 'name-icon',
                      class: 'input-left-svg',
                    }"
                    :show-error-msg="false"
                  />
                </div>
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    name="lastname"
                    placeholder="Soyisim"
                    :left-icon="{
                      name: 'name-icon',
                      class: 'input-left-svg',
                    }"
                    :show-error-msg="false"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col mb-4">
                  <base-select
                    name="title"
                    :options="titlePromise.results.value"
                    option-text-key="name"
                    option-value-key="name"
                    placeholder="Ünvanınız"
                    :loading="titlePromise.loading.value"
                  />
                </div>
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    type="number"
                    name="identity_number"
                    placeholder="TC Kimlik No"
                    min="11111111111"
                    max="99999999999"
                    :left-icon="{
                      name: 'name-icon',
                      class: 'input-left-svg',
                    }"
                    :show-error-msg="false"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    type="email"
                    name="email"
                    placeholder="E-Posta Adresiniz"
                    :left-icon="{
                      name: 'MailIcon',
                      color: '#7684aa',
                      class: 'input-left-svg',
                      size: 24,
                    }"
                    :show-error-msg="false"
                  />
                </div>
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    v-mask="'(5##) ### ####'"
                    name="phone"
                    :left-icon="{
                      name: 'tel-icon',
                      class: 'input-left-svg',
                    }"
                    type="tel"
                    placeholder="Tel"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    name="dob"
                    type="date"
                    :left-icon="{
                      name: 'name-icon',
                      class: 'input-left-svg',
                    }"
                    :show-error-msg="false"
                  />
                </div>
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    name="workplace"
                    placeholder="Çalıştığınız Kurum"
                    :left-icon="{
                      name: 'name-icon',
                      class: 'input-left-svg',
                    }"
                    :show-error-msg="false"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col mb-4 position-relative form-item">
                  <base-input
                    name="job_position"
                    placeholder="Pozisyon"
                    :left-icon="{
                      name: 'name-icon',
                      class: 'input-left-svg',
                    }"
                    :show-error-msg="false"
                  />
                </div>
                <div
                  v-if="eventDetail.requires_password"
                  class="col mb-2 position-relative form-item"
                >
                  <base-input
                    name="password"
                    type="password"
                    placeholder="Parola"
                  />
                </div>
              </div>
            </div>
            <div class="mb-4">
              <button
                :disabled="formSubmitting"
                type="submit"
                class="
                  btn btn-primary
                  text-white
                  height-50px
                  d-flex
                  w-100
                  border-radius
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                Etkinliğe Kayıt Ol
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import EventService from "../services/event/EventService";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import BaseSelect from "../components/ui/RegisterForm/BaseSelect.vue";
import BaseInput from "../components/ui/RegisterForm/BaseInput.vue";
import { mask } from "vue-the-mask";
import { useForm } from "vee-validate";
import * as yup from "yup";
import RegisterService from "../services/register/RegisterService";
import usePromise from "../shared/composables/use-promise";
import Swal from "sweetalert2/dist/sweetalert2";
import SwalError from "../helpers/SwalError";

export default {
  name: "MinistryReport",
  components: {
    ContentMain,
    BaseSelect,
    BaseInput,
  },
  directives: { mask },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const mainColor = ColorHelper[store.state.theme].articleDetailMain;
    const iconColor = ColorHelper[store.state.theme].blue300;
    const eventDetail = ref({});
    const isEventLoading = ref(false);
    const isUpcomingEventsLoading = ref(false);
    const upcomingEvents = ref([]);
    const loading = computed(
      () => isUpcomingEventsLoading.value || isEventLoading.value
    );
    const colorText = ColorHelper[store.state.theme].blueText;
    const user = computed(() => store.state.user.user);
    const formSubmitting = ref(false);
    const titlePromise = usePromise(
      () => RegisterService.getTitles(),
      (x) => {
        const titles = ref([]);

        Object.keys(x.data.data).map((key) => {
          titles.value.push({ name: x.data.data[key], id: key });
        });

        form.setFieldValue("title", x.data.data[user.value?.title_id] || null);

        return titles.value;
      }
    );

    const form = useForm({
      validationSchema: yup.object({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        title: yup.string().required(),
        identity_number: yup
          .number()
          .min(10000000000)
          .max(99999999999)
          .required(),
        email: yup.string().required().email(),
        phone: yup
          .string()
          .min(14)
          .required()
          .transform((_, val) =>
            typeof val === "string" ? (val.length ? val : null) : val
          ),
        dob: yup.string().required(),
        workplace: yup.string().required(),
        job_position: yup.string().required(),
        // password: yup,
      }),
      initialValues: {
        firstname: user.value?.firstname,
        lastname: user.value?.lastname,
        title: user.value?.title_id,
        identity_number: user.value?.identity_number,
        email: user.value?.email,
        phone: user.value?.phone,
        dob: user.value?.dob,
      },
    });

    const fetchEvent = (slug) => {
      isEventLoading.value = true;
      EventService.getEventBySlug(slug)
        .then((response) => {
          eventDetail.value = response?.data?.data?.[0];
          if (eventDetail.value.auth_ministry_reports?.length > 0) {
            router.push({ name: "eventdetail", params: { slug: slug } });
          }
        })
        .catch(() => {
          router.push({ name: "404" });
        })
        .finally(() => {
          isEventLoading.value = false;
        });
    };

    const registerHandler = async () => {
      formSubmitting.value = true;
      try {
        await form.validate();

        if (!form.meta.value.valid) {
          Swal.fire({
            title: "Hata",
            text: "Lütfen formu eksiksiz doldurunuz.",
            icon: "error",
            confirmButtonText: "Tamam",
          });

          formSubmitting.value = false;

          return;
        }

        EventService.registerMinistryReport(eventDetail.value.id, form.values)
          .then(() => {
            Swal.fire({
              title: "Başarılı",
              text: "Kaydınız başarıyla alınmıştır. Lütfen e-posta adresinizi kontrol ediniz.",
              icon: "success",
              confirmButtonText: "Tamam",
            });

            router.push({
              name: "eventdetail",
              params: { slug: eventDetail.value.slug },
            });
          })
          .catch((error) => {
            SwalError.fire(error);
          });
      } catch (error) {
        console.log(error);
        SwalError.fire(error);
      } finally {
        formSubmitting.value = false;
      }
    };

    watch(loading, (newVal) => {
      store.commit("setLoading", newVal);
    });

    onBeforeRouteUpdate((to) => {
      fetchEvent(to.params.slug);
    });

    fetchEvent(route.params.slug || "");

    titlePromise.createPromise();

    return {
      mainColor,
      isUpcomingEventsLoading,
      upcomingEvents,
      iconColor,
      eventDetail,
      dayjs,
      colorText,
      user,
      titlePromise,
      registerHandler,
      formSubmitting,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-detail-main {
  background: linear-gradient(0deg, v-bind("mainColor"), v-bind("mainColor")),
    linear-gradient(
      180deg,
      rgba(7, 38, 66, 0.2) 0%,
      rgba(11, 22, 32, 0.2) 48.44%
    ),
    url(/new-img/event-detail-bg.png), #081f32;
}
</style>

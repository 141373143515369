<template>
  <div class="channel-detail-card mb-4 mx-7px mx-sm-3 mx-xl-0">
    <div
      class="
        mb-2
        channel-detail-card-img
        bg-image
        border-radius
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <router-link :to="{ name: 'videodetail', params: { id: video.id } }">
        <play-icon :size="57" />
      </router-link>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="d-flex">
        <div class="mr-4 d-flex align-items-center">
          <follow-icon
            :size="16"
            likeable-type="video"
            :likeable-id="video.id"
            :is-liked="video.is_liked"
            counter-classes="font-s-12px gray-500"
            :like-count="video.like_count"
            :is-countable="true"
            class="mr-1"
          />
        </div>
        <div class="d-flex align-items-center">
          <comment-icon color="#AFBBDB" :size="16" class="mr-1" />
          <span class="font-s-12px gray-500">{{ video.comment_count }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <clock-icon :size="16" color="#7684AA" class="mr-1" />
        <span class="font-s-12px gray-500">{{ video.publish_on }}</span>
      </div>
    </div>
    <div>
      <h3 class="font-s-18px black-700 font-w-600">
        {{ video.title }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "BigVideoCard",

  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      thumbnail: "url(" + props.video.thumbnail + ")",
    };
  },
};
</script>

<style lang="scss" scoped>
.channel-detail-card {
  max-width: 350px;
  width: 100%;
}

.channel-detail-card-img {
  background: v-bind(thumbnail);
  max-width: 350px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
</style>

<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.7487 14.1665C12.154 14.1665 14.9154 11.4058 14.9154 7.99984C14.9154 4.5945 12.154 1.83317 8.7487 1.83317C5.34336 1.83317 2.58203 4.5945 2.58203 7.99984C2.58203 11.4058 5.34336 14.1665 8.7487 14.1665Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.78906 10.314L10.1131 7.99997L7.78906 5.68597"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "RightBtnIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

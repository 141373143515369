<template>
  <div class="container-xl">
    <div class="home-social-media">
      <div class="my-5 d-flex justify-content-center">
        <h3
          class="font-s-24px gray-600 max-w-360px text-center home-follow-text"
        >
          Sosyal Medyada
          <span class="font-weight-bold text-info">{{ zoneName }}</span
          >'u takip etmeyi unutmayın.
        </h3>
      </div>
      <div class="d-flex">
        <div class="swiper-container mySwiperInfo">
          <div id="instafeed" class="swiper-wrapper my-3"></div>
        </div>
      </div>
      <div
        class="
          d-flex
          flex-column flex-md-row
          justify-content-center
          align-items-center
          mt-40px
          mb-133px
        "
      >
        <a
          v-if="store.state.settings?.facebook_account_url"
          :href="store.state.settings.facebook_account_url"
          target="_blank"
          class="
            btn
            bg-blue-light
            border-radius
            d-flex
            align-items-center
            justify-content-center
            mr-4
            follow-button
          "
        >
          <facebook-icon :color="color" class="mr-2" />
          <span class="font-w-600 font-s-16px text-info"
            >Facebook'da Takip Et</span
          >
        </a>
        <a
          v-if="store.state.settings?.instagram_account_url"
          :href="store.state.settings.instagram_account_url"
          target="_blank"
          class="
            btn
            bg-blue-light
            border-radius
            d-flex
            align-items-center
            justify-content-center
            mr-4
            follow-button
          "
        >
          <instagram-icon :color="color" class="mr-2" />
          <span class="font-w-600 font-s-16px text-info"
            >Instagram'da Takip Et</span
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import Swiper from "swiper";
import { useStore } from "vuex";
import ColorHelper from "../../helpers/ColorHelper";
import { computed } from "vue";
import ZoneHelper from "../../shared/zone-helper";
import Instafeed from "instafeed.js";

export default {
  name: "SocialMedia",
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const color = ColorHelper[store.state.theme].blueText;
    const zoneHelper = new ZoneHelper();
    const zoneName = computed(() => zoneHelper.getZoneName(theme));

    onMounted(() => {
      new Swiper(".mySwiperInfo", {
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 15,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        mousewheel: false,
        breakpoints: {
          320: {
            slidesPerView: 1.3,
            spaceBetween: 15,
            centeredSlides: true,
          },
          400: {
            slidesPerView: 1.4,
            spaceBetween: 15,
            centeredSlides: true,
          },
          460: {
            slidesPerView: 1.7,
            spaceBetween: 15,
          },
          840: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        },
      });
    });

    onMounted(() => {
      const instafeedAccessToken = computed(
        () => store.state.settings.instagram_token
      );

      if (instafeedAccessToken.value) {
        const feed = new Instafeed({
          accessToken: instafeedAccessToken.value,
          limit: 10,
          template: `<div class="swiper-slide bg-transparent">
                <a href="{{link}}" target="_blank">
                  <div
                    class="bg-image height-263px min-w-255px border-radius box-shadow-4-20 text-white cursor-pointer"
                    style="background: url('{{image}}')"
                  >
                    <div
                      class="w-100 h-100 bg-blue-dark-80 border-radius d-flex flex-column justify-content-center align-items-center home-follow-card"
                    >
                      <div class="my-auto">
                        <div>
                          <p
                            class="font-s-16px font-w-600 text-center line-h-16px max-w-160px mb-2 post-title"
                          >
                            {{caption}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>`,
        });

        feed.run();
      }
    });

    return {
      defaultImage: "/new-img/home-follow-th-1.png",
      color,
      zoneName,
      store,
    };
  },
};
</script>

<style lang="scss">
.home-social-media {
  .follow-button {
    width: 238px;
    height: 49px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  }

  .swiper-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
  }

  .home-follow-card {
    opacity: 0;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  .post-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 992px) {
  .home-social-media {
    .home-follow-text {
      font-size: 18px !important;
    }
    .follow-button {
      margin: 0 !important;

      &:first-child {
        margin-bottom: 10px !important;
      }
    }
  }
}
</style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getLegalContentByType(type) {
    switch (type) {
      case "kvkk":
        return apiClient.get("/api/legalcontent/1");
      case "kvkk-yd":
        return apiClient.get("/api/legalcontent/2");
      case "etk":
        return apiClient.get("/api/legalcontent/3");
      default:
        return Promise.reject(new Error("Unknown legal content type"));
    }
  },
};

<template>
  <div>
    <page-intro page-title="Ürünler" page-nav="Ürünler" />

    <section class="mb-5">
      <div class="container-xl">
        <div v-if="!productPromise.loading.value" class="row">
          <template v-if="productPromise.results.value">
            <div
              v-for="(product, index) in productPromise.results.value"
              :key="index"
              class="col-6 col-sm-4 col-md-3 mb-4 height-296px"
            >
              <product-card :product="product" />
            </div>
          </template>
          <div v-else class="col-6 col-sm-4 col-md-3 mb-4 height-296px">
            Ürün bulunamadı!
          </div>
        </div>
        <loading-anim-icon v-else />
      </div>
    </section>
  </div>
</template>

<script>
import PageIntro from "../components/ui/PageIntro.vue";
import ProductCard from "../components/ui/ProductCard.vue";
import usePromise from "../shared/composables/use-promise";
import ProductService from "../services/product/ProductService";
import { useRoute } from "vue-router";

export default {
  name: "Products",
  components: {
    PageIntro,
    ProductCard,
  },
  setup() {
    const route = useRoute();

    const productPromise = usePromise(() => {
      return ProductService.searchByName("oxfet");
    });

    productPromise.createPromise();

    return {
      route,

      productPromise,
    };
  },
};
</script>

<style></style>

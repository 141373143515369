<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 12H8"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M18 7L6 7"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M10 17L14 17"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "FilterIcon",
  props: {
    color: {
      type: [Number, String],
      required: false,
      default: "#7684AA",
    },
    size: {
      type: Number,
      required: false,
      default: 16,
    },
  },
};
</script>

<style></style>

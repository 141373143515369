<template>
  <div class="d-flex flex-column flex-lg-row mt-m-60px">
    <register-form-eczazone v-if="theme === 'eczazone'" />
    <register-form v-else :zone="zone" />
    <login-info
      :zone="zone"
      btn-text="Bir Hesabınız Varsa Giriş Yapın"
      btn-class="register"
    />
  </div>
</template>

<script>
import RegisterForm from "../components/ui/RegisterForm.vue";
import RegisterFormEczazone from "../components/ui/RegisterFormEczazone.vue";
import LoginInfo from "../components/ui/LoginInfo.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import ZoneHelper from "../shared/zone-helper";

export default {
  name: "Register",
  components: {
    RegisterForm,
    RegisterFormEczazone,
    LoginInfo,
  },
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const zoneHelper = new ZoneHelper();
    const zone = computed(() => zoneHelper.getZoneInfo(theme));

    return {
      theme: store.state.theme,
      zone,
    };
  },
};
</script>

<style></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20Z"
      fill="url(#paint0_linear_2405_36727)"
    />
    <path
      d="M20.0002 25.4168V14.5835M25.4168 20.0002H14.5835H25.4168Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2405_36727"
        x1="0"
        y1="0"
        x2="47.1188"
        y2="11.9643"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="podcast1" />
        <stop offset="1" :stop-color="podcast2" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "PodcastPlayIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const podcast1 = ColorHelper[store.state.theme].podcast1;
    const podcast2 = ColorHelper[store.state.theme].podcast2;

    return {
      podcast1,
      podcast2,
    };
  },
};
</script>

<style></style>

<template>
  <div class="channels mb-100px">
    <section class="channels-main bg-image d-flex flex-column">
      <content-main
        title="Kanallar"
        nav="Kanallar"
        class="mt-md-100px mt-160px"
      />
    </section>

    <section>
      <div class="container-xl">
        <div
          class="
            d-flex
            flex-column flex-sm-row
            align-items-center
            justify-content-sm-between
            mt-5
            mb-4
          "
        >
          <div
            class="d-flex justify-content-between justify-content-sm-start mb-3"
          >
            <button
              :class="{ active: tab === 'all' }"
              class="
                btn
                border-radius
                font-s-12px font-w-500
                mr-3
                channels-nav-btn
              "
              @click="changeTab('all')"
            >
              Tüm Kanallar
            </button>
            <button
              :class="{ active: tab === 'special' }"
              class="btn border-radius font-s-12px font-w-500 channels-nav-btn"
              @click="changeTab('special')"
            >
              Özel Kanallar
            </button>
          </div>
          <div class="mb-3">
            <div
              class="
                position-relative
                btn-search
                px-3
                d-flex
                align-items-center
                justify-content-center
                btn
                bg-blue-light
                border-none border-radius
              "
            >
              <search-icon :show="true" :color="colorIcon" class="mr-1" />
              <input
                v-model="searchQuery"
                type="text"
                class="text-info font-s-12px font-w-500 bg-blue-light"
                placeholder="Kanal Ara"
                @focus="showInputBox = true"
                @blur="showInputBox = false"
                @keydown.enter="fetchVideoCategories(1)"
              />
              <div
                v-if="showInputBox"
                class="
                  position-absolute
                  z-index-5
                  input-open-box
                  d-flex
                  flex-column
                  bg-white
                  border-radius
                  box-shadow-4-20-2
                "
              >
                <!-- <span class="gray-500 font-s-12px">Tüm Hastalıklar</span>
                <span class="gray-500 font-s-12px"
                  >Demans ve Alzheimer Hastalığı</span
                >
                <span class="gray-500 font-s-12px">Parkinson Hastalığı</span>
                <span class="gray-500 font-s-12px">Epilepsi Hastalığı</span> -->
              </div>
            </div>
          </div>
        </div>

        <div>
          <channels-list
            :loading="loading"
            :channels="channels"
            @go-page="fetchVideoCategories($event)"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import { ref } from "vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import ChannelsList from "../components/Channels/ChannelsList";
import VideoCategoryServiceNew from "../services/video/VideoCategoryServiceNew";

const videoCategoryService = new VideoCategoryServiceNew();

export default {
  name: "Channel",
  components: {
    ChannelsList,
    ContentMain,
  },
  setup() {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueDark;
    const colorIcon = ColorHelper[store.state.theme].blueText;
    const tab = ref("all");
    const showInputBox = ref(false);
    const searchQuery = ref();
    const channels = ref(null);
    const loading = ref(false);
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    async function fetchVideoCategories(page = 1) {
      loading.value = true;
      const filters = { is_special: [tab.value !== "all"] };
      if (searchQuery.value) {
        filters.title = [searchQuery.value];
      }
      channels.value = await videoCategoryService.all({
        filters,
        page,
        per_page: 16,
        sorts: ["-created_at"],
      });
      loading.value = false;
    }

    const changeTab = (tabName) => {
      tab.value = tabName;
      channels.value = null;
      fetchVideoCategories(1);
    };

    fetchVideoCategories();

    return {
      loading,
      fetchVideoCategories,
      channels,
      changeTab,
      tab,
      color,
      colorIcon,
      main_img,
      showInputBox,
      searchQuery,
    };
  },
};
</script>

<style lang="scss" scoped>
.channels-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}

.channels-nav-btn {
  width: 145px;
  height: 45px;
  background-color: #f3f4f5;
  color: #7684aa;

  &.active {
    color: white;
    background-color: v-bind("color");
  }
}

.btn-search {
  height: 45px;

  input {
    border: none;
    width: 65px;
    transition: 0.3s;

    &::placeholder {
      color: v-bind("colorIcon");
    }

    &:focus {
      width: 300px;
    }
  }
}

.input-open-box {
  max-width: 360px;
  width: 100%;
  top: 55px;
  padding: 23px 0;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  span {
    padding: 10px 50px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: v-bind("colorIcon");
      background: rgba(218, 237, 247, 0.2);
    }
  }
}

@media (max-width: 768px) {
  .btn-search {
    width: 100%;
  }

  .channels-main {
    height: 180px !important;
  }
}
</style>

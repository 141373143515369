<template>
  <div
    class="
      my-4
      px-4
      py-3
      d-flex
      flex-column
      align-items-center
      flex-lg-row
      align-items-lg-center
      justify-content-between
      bg-white
      border-radius
      box-shadow-4-20
    "
  >
    <div class="d-flex align-items-center home-events-img-title">
      <div class="home-events-img mr-5">
        <img
          :src="event.image_url"
          class="img-fluid upcoming-event-img border-radius box-shadow-4-20"
          alt=""
        />
      </div>
      <div class="d-flex align-items-center mr-3">
        <div class="font-f-cormorant font-s-95px text-primary mr-2">
          <span class="position-relative events-date">{{ event.day }}</span>
        </div>
        <div class="d-flex flex-column align-items-center">
          <span class="font-s-18px font-weight-bold text-info">{{
            event.month
          }}</span>
          <span class="font-s-10px font-w-300">{{ event.week_day }}</span>
        </div>
      </div>
    </div>
    <div class="max-w-480px home-events-content">
      <div>
        <span
          v-for="(tag, i) in event.tags"
          :key="i"
          class="
            font-s-10px
            badge
            bg-success
            text-white
            font-weight-normal
            px-2
            py-1
            mr-2
          "
          >{{ tag.name?.tr }}</span
        >
        <span
          class="
            font-s-10px
            badge
            bg-blue-light
            black-700
            font-weight-normal
            px-2
            py-1
          "
          >{{ event.place }}</span
        >
      </div>
      <div class="font-s-18px font-weight-bold">
        {{ event.title }}
      </div>
      <div class="font-s-12px upcoming-event-subtitle">
        {{ event.subtitle ?? event.summary }}
      </div>
    </div>
    <div class="d-flex flex-lg-column">
      <router-link
        :to="{
          name: 'eventdetail',
          params: { slug: event.slug },
        }"
        class="btn btn-info border-radius mb-3"
      >
        <span class="font-s-12px font-w-500">Detaylar</span>
        <right-arrow-icon color="white" />
      </router-link>
      <save-icon
        favoritable-type="event"
        :favoritable-id="event.id"
        :is-button="true"
        :is-favorited="event.is_favorited"
        button-classes="btn btn-light border-radius"
        color="#1686D6"
        class="mr-1"
        text="Etkinliği Kaydet"
        favorited-text="Etkinlik Kaydedildi"
        text-classes="font-s-12px font-w-500 text-info"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mappedEvents() {
      return this.events.map((event) => {
        return {
          ...event,
          day: event.starts_at.split(" ")[0].split("-")[2],
          month: event.starts_at.split(" ")[0].split("-")[1],
          week_day: this.getWeekDay(event.starts_at),
        };
      });
    },
  },
  methods: {
    getWeekDay(date) {
      const weekDays = [
        "Pazar",
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi",
      ];
      return weekDays[new Date(date).getDay()];
    },
  },
};
</script>

<style lang="scss" scoped>
.events-date {
  top: -15px;
}

.home-events-img {
  width: 163px;
  height: 133px;
}

.upcoming-event-subtitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.upcoming-event-img {
  height: 133px;
  width: 160px;
  object-fit: cover;
}

@media (max-width: 400px) {
  .events-date {
    font-size: 50px;
    top: -20px;
  }
}

@media (max-width: 992px) {
  .home-events-img {
    margin-right: 20px !important;
  }
  .home-events-img-title {
    margin-bottom: 10px;
  }
  .home-events-content {
    margin-bottom: 20px;
  }
}
</style>

<template>
  <div class="Articles mb-100px">
    <section class="articles-main bg-image d-flex flex-column mb-5">
      <content-main
        :title="theme == 'eczazone' ? 'Haberler' : 'Makaleler'"
        :nav="theme == 'eczazone' ? 'Haberler' : 'Makaleler'"
        class="mt-m-100px mt-160px"
      />
    </section>

    <div v-if="!loading">
      <section class="mb-md-5">
        <div class="container-xl">
          <div class="row">
            <div class="col-12 col-md-7">
              <router-link
                :to="{ name: 'articledetail', params: { id: firstPost.id } }"
              >
                <img
                  :src="firstPost.image_url"
                  alt=""
                  class="border-radius article-headline-img object-fit-cover"
                />
              </router-link>
            </div>

            <div class="col-12 col-md-5 d-flex flex-column py-4">
              <div class="d-flex mb-3">
                <div class="d-flex align-items-center mr-3">
                  <article-icon :color="color" />
                  <span class="text-info font-s-13px">
                    {{ theme == "eczazone" ? "Haber" : "Makale" }}
                  </span>
                </div>
                <div class="d-flex align-items-center">
                  <clock-icon color="#AFBBDB" :size="20" />
                  <span class="gray-500 font-s-13px">{{
                    formatArticlePublishOnDate(firstPost)
                  }}</span>
                </div>
              </div>

              <div class="mb-3">
                <router-link
                  :to="{ name: 'articledetail', params: { id: firstPost.id } }"
                >
                  <h3 class="font-s-22px font-w-600 black-700 bigCardTitle m-0">
                    {{ firstPost.title }}
                  </h3>
                </router-link>
              </div>

              <div class="mb-4 d-none d-md-block">
                <p class="gray-500 text-secondary m-0">
                  {{ firstPost.subtitle ?? firstPost.summary }}
                </p>
              </div>

              <div class="d-flex align-items-center">
                <router-link
                  :to="{ name: 'articledetail', params: { id: firstPost.id } }"
                >
                  <span class="text-info font-s-12px">Detaylı Bilgi</span>
                  <right-arrow-icon :color="color" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-xl">
          <div class="row">
            <div
              v-for="(post, index) in otherPosts"
              :key="index"
              class="col-12 col-sm-6 col-md-4"
            >
              <article-sm :post="post" :color="color" class="mb-3 mb-md-5" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-xl">
          <div class="row">
            <div v-if="lastSidePost" class="col-12 col-md-4">
              <article-sm :post="lastSidePost" :color="color" class="mb-5" />
            </div>

            <div v-if="lastPost" class="col-12 col-md-8">
              <div
                class="
                  bg-primary
                  max-w-740px
                  border-radius
                  p-5
                  d-flex
                  flex-column
                  justify-content-center
                  min-h-454px
                "
              >
                <div class="d-flex mb-3">
                  <div class="d-flex align-items-center mr-4">
                    <article-icon color="white" />
                    <span class="text-white font-s-13px">
                      {{ theme == "eczazone" ? "Haber" : "Makale" }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center">
                    <clock-icon :size="20" color="white" />
                    <span class="text-white font-s-13px">{{
                      formatArticlePublishOnDate(lastPost)
                    }}</span>
                  </div>
                </div>

                <div class="mb-3">
                  <router-link
                    :to="{ name: 'articledetail', params: { id: lastPost.id } }"
                  >
                    <h3 class="font-s-24px font-weight-bold text-white">
                      {{ lastPost.title }}
                    </h3>
                  </router-link>
                </div>

                <div class="mb-4">
                  <p class="text-white font-s-16px">
                    {{ lastPost.subtitle ?? lastPost.summary }}
                  </p>
                </div>

                <div class="d-flex align-items-center">
                  <router-link
                    :to="{ name: 'articledetail', params: { id: lastPost.id } }"
                  >
                    <span class="text-white font-s-12px">Detaylı Bilgi</span>
                    <right-arrow-icon color="white" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-xl">
          <div class="row">
            <div class="d-flex justify-content-center align-items-center w-100">
              <pagination
                :disabled="loading"
                :total-pages="articles.last_page"
                :current-page="articles.current_page"
                @go-page="fetchPosts($event)"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <loading-anim-icon v-else />
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ArticleSm from "../components/ui/ArticleSm.vue";
import PostService from "../services/post/PostService";
import { ref } from "vue";
import dayjs from "dayjs";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import { ARTICLE_PUBLISHED_ON_FORMAT } from "../shared/date-utils";
import Pagination from "../components/ui/Pagination";

export default {
  name: "Articles",
  components: {
    Pagination,
    ContentMain,
    ArticleSm,
  },
  setup() {
    const firstPost = ref(null);
    const otherPosts = ref({});
    const lastPost = ref(null);
    const lastSidePost = ref(null);
    const store = useStore();
    const theme = ref(store.state.theme);
    const loading = ref(false);
    const color = ColorHelper[store.state.theme].blueDark;
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const articles = ref();

    function formatArticlePublishOnDate(article) {
      return dayjs(article.publish_on)
        .locale("tr")
        .format(ARTICLE_PUBLISHED_ON_FORMAT);
    }

    const fetchPosts = (page = 1) => {
      loading.value = true;
      PostService.getRecentPostsByCollection("haberler", page, 6)
        .then((response) => {
          articles.value = response.data.data;
          const articleItems = response.data.data.items;
          firstPost.value = articleItems[0];
          lastPost.value = articleItems[5];
          lastSidePost.value = articleItems[4];
          otherPosts.value = articleItems.slice(1, 4);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    fetchPosts();

    return {
      fetchPosts,
      articles,
      firstPost,
      otherPosts,
      lastPost,
      lastSidePost,
      loading,
      color,
      main_img,
      formatArticlePublishOnDate,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.articles-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}

.article-headline-img {
  width: 100%;
  height: 367px;
}

@media (max-width: 576px) {
  .article-headline-img {
    width: 100%;
    height: 153px;
  }
  .bigCardTitle {
    font-size: 18px !important;
  }
  .articles-main {
    height: 180px;
  }
}
</style>

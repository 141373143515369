import ApiService from "./ApiService";

export default class UserServiceNew extends ApiService {
  collection = "users";

  updateGeneralInformations(data) {
    return this.client.put("/api/me/update-general", data);
  }

  updateProfessionalInformations(data) {
    return this.client.put("/api/me/update-job", data);
  }

  updatePharmacyInformations(data) {
    return this.client.put("/api/me/update-pharmacy", data);
  }

  updatePassword(data) {
    return this.client.put("/api/me/update-password", data);
  }

  suspendAccount(data) {
    return this.client.post("/api/me/suspend", data);
  }

  removeAccount(data) {
    return this.client.post("/api/me/destroy", data);
  }

  forgotPassword(data) {
    return this.client.post("/api/forgot/password", data);
  }

  resetPassword(data) {
    return this.client.post("/api/password/reset", data);
  }
}

<template>
  <div>
    <svg
      :width="size"
      :height="size"
      viewBox="0 0 89 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="44.5"
        cy="45.4956"
        r="44.5"
        fill="#FBFCFF"
        fill-opacity="0.2"
      />
      <ellipse
        cx="44.5731"
        cy="45.5689"
        rx="32.2904"
        ry="32.2904"
        fill="#FBFCFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.9772 47.4674C52.7247 49.6541 46.5943 53.3542 43.458 54.5422C42.9574 54.7314 41.6813 55.132 41.4063 55.1375C40.8223 55.1542 40.2599 54.8593 39.988 54.3864C39.883 54.2028 39.574 53.0037 39.4751 52.4695C39.1816 50.8448 39.0302 48.3215 39.0332 45.7842C39.0302 43.1218 39.1939 40.4788 39.5215 38.8708C39.608 38.4368 39.8613 37.438 39.9355 37.2766C40.0746 36.9762 40.3279 36.7397 40.6338 36.5922C40.8687 36.4782 41.1375 36.417 41.4063 36.4253C41.6813 36.4309 42.7999 36.7842 43.183 36.9205C46.2049 37.9777 52.598 41.8114 54.9463 44.0649C55.1564 44.268 55.7342 44.8133 55.83 44.9246C56.0494 45.1749 56.1576 45.4782 56.1576 45.7842C56.1576 46.068 56.0618 46.3573 55.864 46.5994C55.7621 46.7246 55.1719 47.281 54.9772 47.4674Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "PlayIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueDark;

    return {
      color,
    };
  },
};
</script>

<style></style>

<template>
  <div class="header-search position-fixed bg-blue-footer">
    <div class="container-header d-flex flex-column align-items-center">
      <div class="w-100 d-none d-lg-flex justify-content-between">
        <router-link
          :to="{ name: 'home' }"
          class="navbar-brand search-logo"
          @click="$emit('update:showSearch', false)"
        >
          <img
            :src="logo"
            alt=""
            class="d-inline-block align-text-top header-logo cursor-pointer"
          />
        </router-link>
        <close-icon
          :size="36"
          color="white"
          class="cursor-pointer mt-50px mr-5"
          @click="$emit('update:showSearch', false)"
        />
      </div>

      <div
        v-if="theme !== 'hemaworld'"
        class="
          d-flex
          justify-content-center
          font-s-16px font-w-500
          text-white
          mt-4 mt-lg-0
          mb-5
          search-radio
        "
      >
        <div class="d-flex flex-column flex-lg-row ml-4">
          <div class="d-flex align-items-baseline mr-5 cursor-pointer">
            <input
              id="search-1"
              v-model="selectedFilter"
              value="all"
              type="radio"
              class="mr-1"
              name="search"
            />
            <label for="search-1">Hepsi</label>
          </div>
          <div class="d-flex align-items-baseline mr-5 cursor-pointer">
            <input
              id="search-2"
              v-model="selectedFilter"
              value="videos"
              type="radio"
              class="mr-1"
              name="search"
            />
            <label for="search-2">Sadece Videolarda</label>
          </div>
        </div>
        <div class="d-flex flex-column flex-lg-row ml-4">
          <div class="d-flex align-items-baseline mr-5 cursor-pointer">
            <input
              id="search-3"
              v-model="selectedFilter"
              value="posts"
              type="radio"
              class="mr-1"
              name="search"
            />
            <label for="search-3">
              {{
                theme == "eczazone" ? "Sadece Haberlerde" : "Sadece Makalelerde"
              }}
            </label>
          </div>
          <div class="d-flex align-items-baseline cursor-pointer">
            <input
              id="search-4"
              v-model="selectedFilter"
              value="calculations"
              type="radio"
              class="mr-1"
              name="search"
            />
            <label for="search-4">Sadece Hesaplama Cetvellerinde</label>
          </div>
        </div>
        <div class="d-flex flex-column flex-lg-row ml-4">
          <div class="d-flex align-items-baseline mr-5 cursor-pointer">
            <input
              id="search-5"
              v-model="selectedFilter"
              value="podcasts"
              type="radio"
              class="mr-1"
              name="search"
            />
            <label for="search-5">Sadece Podcastler</label>
          </div>
        </div>
      </div>

      <div class="px-4 d-flex flex-column max-w-940px w-100">
        <h5 class="font-s-16px font-w-500 text-white mb-5">
          Arama Yapmak İstediğiz Kelime
        </h5>
        <div class="position-relative">
          <search-icon
            color="white"
            class="search-icon cursor-pointer"
            @click="searchHandler"
          />
          <input
            v-model="keyword"
            type="text"
            class="mb-5 search-input bg-blue-footer blue-300 font-s-22px w-100"
            @focus="showInputBox = true"
            @blur="showInputBox = false"
            @keydown.enter="searchHandler"
          />
          <div
            v-if="
              false // TODO: complete autocomplete with showInputBox
            "
            class="
              position-absolute
              z-index-5
              input-open-box
              d-flex
              flex-column
              bg-white
              border-radius
              box-shadow-4-20-2
            "
          >
            <span class="gray-500 font-s-12px">Tüm Hastalıklar</span>
            <span class="gray-500 font-s-12px"
              >Demans ve Alzheimer Hastalığı</span
            >
            <span class="gray-500 font-s-12px">Parkinson Hastalığı</span>
            <span class="gray-500 font-s-12px">Epilepsi Hastalığı</span>
          </div>
        </div>
        <h5
          v-if="
            false // TODO: complete suggestions
          "
          class="
            text-center text-lg-left
            font-s-16px
            popular-words
            font-w-500
            text-info
            mb-4
          "
        >
          En Popüler Anahtar Kelimeler
        </h5>
        <div
          v-if="
            false // TODO: complete suggestions
          "
          class="
            d-flex
            flex-wrap
            justify-content-center justify-content-lg-start
            text-info
            opacity-0-5
          "
        >
          <span v-for="i in 8" :key="i" class="mr-2">Nöroloji,</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useRouter } from "vue-router";

export default {
  props: {
    showSearch: {
      type: Boolean,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
  },
  emits: ["update:showSearch"],
  setup(props, ctx) {
    const store = useStore();
    const theme = ref(store.state.theme);
    const router = useRouter();
    const colorSearch = ColorHelper[store.state.theme].blueText;
    const selectedFilter = ref("all");
    const showInputBox = ref(false);
    const keyword = ref("");

    const searchHandler = () => {
      if (keyword.value) {
        ctx.emit("update:showSearch", false);
        if (selectedFilter.value === "all") {
          return router.push({
            name: "search",
            params: { keyword: keyword.value },
          });
        }

        return router.push({
          name: "search",
          params: { keyword: keyword.value },
          query: { in: selectedFilter.value },
        });
      }
    };

    return {
      keyword,
      searchHandler,
      selectedFilter,
      showInputBox,
      colorSearch,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-header {
  max-width: 1194px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.header-logo {
  width: 213px;
}

.header-search {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 15;
}
.search-logo {
  margin: 40px 35px;
}
.search-input {
  border: none;
  border-bottom: 1px solid #fff;
  opacity: 0.9;
}
.search-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.input-open-box {
  max-width: 360px;
  width: 100%;
  top: 55px;
  padding: 23px 0;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  span {
    padding: 10px 50px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: v-bind("colorSearch");
      background: #daedf733;
    }
  }
}
@media (max-width: 992px) {
  .header-search {
    top: 59px;
  }
  .popular-words {
    font-size: 14px !important;
  }
  label {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .header-logo {
    width: 175px;
  }
}
</style>

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="Toplam Eozinofil Sayısı Hesaplama Aracı"
        nav3="Toplam Eozinofil Sayısı Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  >
                    {{ result }}
                  </h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                    >Toplam Eozinofil Sayısı</span
                  >
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="form_vya"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Eozinofil</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="ezf"
                  maxlength="5"
                  max="5"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Beyaz Küre Sayısı (WBC)
                </h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="wbc"
                  maxlength="5"
                  max="5"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "@vue/reactivity";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "EozinofilScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;
    const calculate = () => {
      let form = $("#form_vya");
      window.scrollTo({ top: 0, behavior: "smooth" });
      $(".return-text").slideDown();
      let error = 0;
      form.find(".is-invalid").removeClass("is-invalid");
      let wbc = form.find('input[name="wbc"]').val();
      let ezf = form.find('input[name="ezf"]').val();
      if (!wbc) {
        form.find('input[name="wbc"]').addClass("is-invalid");
        error++;
      }
      if (!ezf) {
        form.find('input[name="ezf"]').addClass("is-invalid");
        error++;
      }
      if (error) {
        $(".return-text").html("Eksik alanlar mevcut");
        return false;
      }
      let f = (wbc * ezf) / 100;
      $(".return-number").html(f);
    };

    function clearAll() {
      $("form")[0].reset();
      $(".return-number").slideUp();
    }

    return {
      calculate,
      clearAll,
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      apgar: [
        {
          title: "Eozinofil (EOS) Nedir?",
          content: ` <p>Bağışıklık sisteminin önemli bir parçası olan akyuvar ya da beyaz kan hücrelerinin bir çeşidi olan eozinofiller kemik iliğinde üretilir. Eozinofil hücreleri vücut içerisindeki dokularda depolanır ve birkaç hafta boyunca hayatta kalabilir.</p>
            <p>Akyuvarların %2-3’ünü oluşturan eozinofil hücrelerinin başlıca görevi parazitlerin sebep olduğu enfeksiyonlarla, alerjik reaksiyonlarla veya kanserle savaşmaktır.</p>
`,
        },
        {
          title: "Eozinofil Sayısı Nasıl Hesaplanır?",
          content: `<p>Eozinofil sayısı (EOS) kan testi sonuçlarında bulunabilir. Eozinofil sayısının (EOS) normal bir yetişkindeki değeri bir mikrolitrede 500 hücreden az olmalıdır. Kanda 500’den fazla eozinofil bulunması eozinofil bozukluğu anlamına gelmektedir ve eozinofil bozukluğu hafif, orta ve şiddetli olarak ayrılmaktadır. 500-1500 arası hafif yüksek değer olarak görülmektedir. 1500-5000 arası orta yüksek değer, 5000 üzerinde olan eozinofil sayısı ise şiddetli yüksek değer olarak geçmektedir.</p>
`,
        },
        {
          title: "Eozinofil (EOS) Yüksekliği",
          content: `
               <p>Eozinofil (EOS) yüksekliği eozinofili hastalığına işaret etmektedir. Vücutta eozinofil sayısının yüksek olması, parazite karşı savunma, alerji ya da kansere işaret edebilir. Eozinofil sayısının (EOS) yüksek olmasına şunlar sebep olabilir;</p>

            <ul>
                <li>Hipereozinofilik sendrom (lösemiye benzer ciddi bir hastalıktır)</li>
                <li>Bahar alerjisi ya da astım gibi alerjik hastalıkları</li>
                <li>Otoimmün hastalıklar</li>
                <li>Parazit ya da mantarın sebep olduğu durumlar</li>
                <li>Bazı ilaçlara karşı reaksiyonlar</li>
                <li>Cushing sendromunun ilk evreleri</li>
                <li>Egzama</li>
                <li>Lösemi ve diğer kan hastalıkları</li>
            </ul>

            <p>Bunların yanında, stres de eozinofil yüksekliğinin sebeplerinden biri olabilir. Daha birçok farklı hastalığın sebebi olan stres eozinofil seviyesinin yükselmesinde de rol oynayabilir. Stresten ve stresli durumlardan kaçınmak ve egzersiz yapmak da eozinofil seviyesini normal seviyede tutmaya yardımcı olabilir.</p>
`,
        },
        {
          title: "Eozinofil (EOS) Düşüklüğü",
          content: `  <p>Vücutta parazit ya da virüs gibi maddelerin bulunması sonucunda eozinofil sayısı genellikle yükselir. Fakat değerler yükselmek yerine sabit kalıyor ya da düşüyorsa, eozinofil düşüklüğünden bahsedilebilir. Eozinofil düşüklüğünün sebepleri şunlar olabilir;</p>

            <ul>
                <li>Alkol zehirlenmesi</li>
                <li>Steroid artışı</li>
                <li>Aşırı stres</li>
                <li>Vücudun kortizol gibi belli steroidleri çok fazla üretmes</li>
                <li>Kan şekeri düşüklüğü</li>
                <li>Karaciğer ve böbrek hastalıkları</li>
                <li>Doğum kontrol hapları</li>
                <li>Östrojen ve kortikosteroid içeren ilaçların kullanılması</li>
                <li>Böbreküstü bezi problemleri</li>
                <li><small>Cushing sendromu</li>
            </ul>

            <p>EOS yüksekliğinin farklı belirtileri olabilir. Bu belirtiler, EOS seviyesinin yükselmesine sebep olan hastalığa bağlı olarak değişir. Örneğin, astım hastalarında hapşırma ve nefes darlığı gibi belirtiler verirken, parazit kaynaklı enfeksiyonlarda karın ağrısı, ishal ve ateş gibi belirtiler verebilir. Kilo kaybı, gece terlemesi, lenf bezi büyümesi de eozinofil (EOS) yüksekliği belirtilerinden olabilir.</p>
            <p>EOS değeriniz yüksek çıktıysa, doktorunuz ilk olarak altındaki sebebi araştıracak ve size ona göre bir tedavi sunacaktır. Eozinofil yüksekliğinin altında yatan sebebi doğru anlamak ve ona uygun bir tedavi uygulamak oldukça önemlidir.</p>

        `,
        },
        {
          title: "Bazal Metobolizma Hızı Kaç Olmalıdır?",
          content: `<p>Bazal metabolizma hızı kaç olmalı diye merak edenler için, cevap oldukça değişkendir. Metabolizma hızının normal değerleri cinsiyet ve yaşa göre değişir. 20-40 yaş aralığındaki kadınların ideal bazal metabolizma hızı 1000 ile 1400 arasındadır. Erkeklerde ise bu değerler 1200 - 1600 arasında olmalıdır. 40 yaş ve üzerindeki kişilerde metabolizma hızı yavaşlamaya başlar. Ayrıca hızlı kilo değişimleri de bazal metabolizmayı olumsuz etkileyerek yavaşlamasına neden olur.</p>`,
        },
      ],
      references: [
        "https://www.mynet.com/eos-nedir-eos-normal-degeri-nedir-eos-dusuklugu-veya-yuksekligi-ne-anlama-gelir-110104485934#",
        "https://www.sozcu.com.tr/2018/saglik/eos-eozinofil-nedir-eozinofil-dusuklugu-ve-yuksekligi-ne-anlama-gelir-oub2-2764086/",
        "https://www.milliyet.com.tr/pembenar/eozinofil-nedir-eozinofil-kac-olmali-yuksekligi-ve-dusuklugu-nedenleri-6171638",
        "https://www.webmd.com/asthma/eosinophil-count-facts#1",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

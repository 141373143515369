import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Videos from "../pages/Videos";
import VideoDetail from "../pages/VideoDetail";
import Articles from "../pages/Articles";
import ArticleDetail from "../pages/ArticleDetail";
import store from "../store/store";
import pipeline from "./pipeline";
import auth from "./middleware/auth";
import guest from "./middleware/guest";
import NotFoundPage from "../pages/NotFoundPage";
import MaintenencePage from "../pages/MaintenencePage";
import EpiLibrary from "../pages/EpiLibrary";
import Channels from "../pages/Channels";
import ChannelDetail from "../pages/ChannelDetail";
import Pubmed from "../pages/Pubmed";
import Guides from "../pages/Guides";
import CalculateRules from "../pages/CalculateRules";
import SearchResult from "../pages/SearchResult";
import ComingYou from "../pages/ComingYou";
import Settings from "../pages/Settings";
import Products from "../pages/Products";
import AddContent from "../pages/AddContent";
import ProductCategory from "../pages/ProductCategory";
import Message from "../pages/Message";
import ProductDetail from "../pages/ProductDetail";
import Favorites from "../pages/Favorites";
import ProductSearch from "../pages/ProductSearch";
import EventDetail from "../pages/EventDetail";
import Questionnaire from "../pages/Questionnaire";
import Podcast from "../pages/Podcast";
import KvkkPage from "../pages/legal-content/KvkkPage";
import KvkkYdPage from "../pages/legal-content/KvkkYdPage";
import EtkPage from "../pages/legal-content/EtkPage";
import YasalUyariPage from "../pages/legal-content/YasalUyariPage";
import AydinlatmaMetniPage from "../pages/legal-content/AydinlatmaMetniPage";
import QuestionBank from "../pages/QuestionBank";
import GetAppPage from "../pages/GetAppPage";
import PodcastChannelDetail from "../pages/PodcastChannelDetail";
import PodcastChannels from "../pages/PodcastChannels";
// import CasesPage from "../pages/CasesPage";
// import CaseDetailPage from "../pages/CaseDetailPage";
// import CaseEditPage from "../pages/CaseEditPage.vue";
import HemaworldProducts from "../pages/HemaworldProducts";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";

import AsdasScore from "../pages/calculate/AsdasScore";
import ApgarScore from "../pages/calculate/ApgarScore";
import BazalScore from "../pages/calculate/BazalScore";
import CdaiScore from "../pages/calculate/CdaiScore";
import Das28Score from "../pages/calculate/Das28Score";
import EssScore from "../pages/calculate/EssScore";
import EdssScore from "../pages/calculate/EdssScore";
import FlipiScore from "../pages/calculate/FlipiScore";
import HaqScore from "../pages/calculate/HaqScore";
import IpiScore from "../pages/calculate/IpiScore";
import CreatineScore from "../pages/calculate/CreatineScore";
import MeldScore from "../pages/calculate/MeldScore";
import EozinofilScore from "../pages/calculate/EozinofilScore";
import LenfositScore from "../pages/calculate/LenfositScore";
import NotrofilScore from "../pages/calculate/NotrofilScore";
import BodyMass from "../pages/calculate/BodyMass";
import ChildBodyMass from "../pages/calculate/ChildBodyMass";
import WaistCircumferenceRiskCalculation from "../pages/calculate/WaistCircumferenceRiskCalculation";
import EnergyExpendedByActivity from "../pages/calculate/EnergyExpendedByActivity";
import BodySurface from "../pages/calculate/BodySurface";
import TagPage from "../pages/TagPage";
import MinistryReport from "../pages/MinistryReport.vue";

import UserService from "../services/UserService";

const routes = [
  {
    path: "",
    component: Home,
    name: "home",
    meta: {},
  },
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: {
      middleware: [guest],
      // layout: "simplest-layout",
    },
  },
  {
    path: "/register",
    component: Register,
    name: "register",
    meta: {
      middleware: [guest],
      // layout: "simplest-layout",
    },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    name: "forgot-password",
    meta: {
      middleware: [guest],
      // layout: "simplest-layout",
    },
  },
  {
    path: "/password/reset/:token",
    component: ResetPassword,
    name: "reset-password",
    meta: {
      middleware: [guest],
      // layout: "simplest-layout",
    },
  },
  {
    path: "/podcast",
    component: Podcast,
    name: "podcast",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/podcast-channels",
    component: PodcastChannels,
    name: "podcast-channels",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/podcastchanneldetail/:id",
    component: PodcastChannelDetail,
    name: "podcastchanneldetail",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/favorites",
    component: Favorites,
    name: "favorites",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/questionnaire",
    component: Questionnaire,
    name: "questionnaire",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/etkinlikler/:slug",
    component: EventDetail,
    name: "eventdetail",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/utt/:slug",
    component: MinistryReport,
    name: "ministryreport",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/settings",
    component: Settings,
    name: "settings",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/videos",
    component: Videos,
    name: "videos",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/videodetail/:id",
    component: VideoDetail,
    name: "videodetail",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/articles",
    component: Articles,
    name: "articles",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/epilibrary",
    component: EpiLibrary,
    name: "epilibrary",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/channels",
    component: Channels,
    name: "channels",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/channeldetail/:id",
    component: ChannelDetail,
    name: "channeldetail",
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/pubmed",
  //   component: Pubmed,
  //   name: "pubmed",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/translation-request",
  //   component: AddContent,
  //   name: "translation-request",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/clinical-key",
  //   component: AddContent,
  //   name: "clinical-key",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/academic-book",
  //   component: AddContent,
  //   name: "academic-book",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/statistics-support",
  //   component: AddContent,
  //   name: "statistics-support",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/redaction-request",
  //   component: AddContent,
  //   name: "redaction-request",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/kilavuzlar",
    component: Guides,
    name: "guides",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/question-bank/:id",
    component: QuestionBank,
    name: "questionbank",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/search/:keyword",
    component: SearchResult,
    name: "search",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/coming-you",
    component: ComingYou,
    name: "comingyou",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/products",
    component: Products,
    name: "products",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/hemaworld-products",
    component: HemaworldProducts,
    name: "hemaworld-products",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/message/:thread_id?",
    component: Message,
    name: "message",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/add-article",
    component: AddContent,
    name: "addarticle",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/add-video",
    component: AddContent,
    name: "addvideo",
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/add-clinic",
  //   component: AddContent,
  //   name: "addclinic",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/cases/new",
  //   component: CaseEditPage,
  //   name: "addcasereport",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/cases/:id/edit",
  //   component: CaseEditPage,
  //   name: "editcasereport",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/categories/:id/products",
    component: ProductCategory,
    name: "productcategory",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/search/products",
    component: ProductSearch,
    name: "productsearch",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/product-detail/:id",
    component: ProductDetail,
    name: "productdetail",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/hesaplama-cetvelleri",
    component: CalculateRules,
    name: "hesaplama-cetvelleri",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/hesaplama-cetvelleri/asdas",
    component: AsdasScore,
    name: "asdas",
  },
  {
    path: "/hesaplama-cetvelleri/apgar-skoru",
    component: ApgarScore,
    name: "apgar-skoru",
  },
  {
    path: "/hesaplama-cetvelleri/bazal-enerji-tuketimi",
    component: BazalScore,
    name: "bazal-enerji-tuketimi",
  },
  {
    path: "/hesaplama-cetvelleri/cdai-hesaplama",
    component: CdaiScore,
    name: "cdai-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/das-28-hesaplama",
    component: Das28Score,
    name: "das-28-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/ess-epworth-uykululuk-olcegi",
    component: EssScore,
    name: "ess-epworth-uykululuk-olcegi",
  },
  {
    path: "/hesaplama-cetvelleri/edss-hesaplama",
    component: EdssScore,
    name: "edss-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/flipi-follicular-lymphoma-international-prognostic-index",
    component: FlipiScore,
    name: "flipi-follicular-lymphoma-international-prognostic-index",
  },
  {
    path: "/hesaplama-cetvelleri/haq-score-saglik-degerlendirme-anketi",
    component: HaqScore,
    name: "haq-score-saglik-degerlendirme-anketi",
  },
  {
    path: "/hesaplama-cetvelleri/ipi-international-prognostic-index",
    component: IpiScore,
    name: "ipi-international-prognostic-index",
  },
  {
    path: "/hesaplama-cetvelleri/kreatinin-klirensi-hesaplama",
    component: CreatineScore,
    name: "kreatinin-klirensi-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/meld-skoru-hesaplama",
    component: MeldScore,
    name: "meld-skoru-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/toplam-eozinofil-sayisi-hesaplama",
    component: EozinofilScore,
    name: "toplam-eozinofil-sayisi-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/toplam-lenfosit-sayisi-hesaplama",
    component: LenfositScore,
    name: "toplam-lenfosit-sayisi-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/toplam-notrofil-sayisi-hesaplama",
    component: NotrofilScore,
    name: "toplam-notrofil-sayisi-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/vucut-kitle-endeksi",
    component: BodyMass,
    name: "vucut-kitle-endeksi",
  },
  {
    path: "/hesaplama-cetvelleri/cocuk-vucut-kitle-endeksi",
    component: ChildBodyMass,
    name: "cocuk-vucut-kitle-endeksi",
  },
  {
    path: "/hesaplama-cetvelleri/bel-cevresi-risk-hesaplama",
    component: WaistCircumferenceRiskCalculation,
    name: "bel-cevresi-risk-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/aktiviteye-gore-harcanan-enerji-hesaplama",
    component: EnergyExpendedByActivity,
    name: "aktiviteye-gore-harcanan-enerji-hesaplama",
  },
  {
    path: "/hesaplama-cetvelleri/vucut-yuzey-alani-hesaplama",
    component: BodySurface,
    name: "vucut-yuzey-alani-hesaplama",
  },
  {
    path: "/404",
    component: NotFoundPage,
    name: "404",
    meta: {
      layout: "simplest-layout",
    },
  },
  {
    path: "/maintenence",
    component: MaintenencePage,
    name: "maintenence",
    meta: {
      layout: "simplest-layout",
    },
  },
  {
    path: "/articledetail/:id",
    component: ArticleDetail,
    name: "articledetail",
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/cases",
  //   component: CasesPage,
  //   name: "cases",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/my-cases",
  //   component: CasesPage,
  //   name: "mycases",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  // {
  //   path: "/cases/:id",
  //   component: CaseDetailPage,
  //   name: "case-detail",
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/tags/:id",
    component: TagPage,
    name: "tags",
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/kisisel-verilerin-korunmasi-kanunu",
    alias: "/kvkk",
    name: "kvkk",
    component: KvkkPage,
  },
  {
    path: "/kisisel-verilerin-korunmasi-kanunu-yurtdisi",
    alias: "/kvkk-yd",
    name: "kvkk-yd",
    component: KvkkYdPage,
  },
  {
    path: "/elektronik-ticaretin-duzenlenmesi-kanunu",
    alias: "/etk",
    name: "etk",
    component: EtkPage,
  },
  {
    path: "/yasal-uyari",
    name: "yasal-uyari",
    component: YasalUyariPage,
  },
  {
    path: "/aydinlatma-metni",
    name: "aydinlatma-metni",
    component: AydinlatmaMetniPage,
  },
  {
    path: "/get-app",
    name: "get-app",
    component: GetAppPage,
    meta: {
      layout: "simplest-layout",
    },
  },
];

const theme = store.state.theme;

if (theme != "hemaworld") {
  routes.push({
    path: "/pubmed",
    component: Pubmed,
    name: "pubmed",
    meta: {
      middleware: [auth],
    },
  });
}

routes.push({
  path: "/:pathMatch(.*)*",
  redirect: "/404",
  meta: {
    layout: "simplest-layout",
  },
});

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash && document.querySelector(to.hash)) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const localStorageToken = localStorage.getItem("api_token");
  const localStorageRefreshToken = localStorage.getItem("api_refresh_token");
  const localStorageTokenExpiresIn = localStorage.getItem(
    "api_token_expires_in"
  );

  if (
    store.state.user.user === null &&
    localStorageToken &&
    localStorageToken.length > 1
  ) {
    await store.dispatch("setBearerToken", localStorageToken);

    try {
      store.commit("setAuthLoading", true);

      await store.dispatch("user/fetchAndSetUserInformation");

      store.commit("setAuthLoading", false);

      if (
        localStorageTokenExpiresIn &&
        localStorageTokenExpiresIn < 2592000 &&
        localStorageRefreshToken &&
        localStorageRefreshToken.length > 1
      ) {
        return UserService.refreshToken().then(async (resp) => {
          await store.dispatch("setBearerToken", resp.data.access_token);
          await localStorage.setItem(
            "api_refresh_token",
            resp.data.refresh_token
          );
          await localStorage.setItem(
            "api_token_expires_in",
            resp.data.expires_in
          );
          await store.dispatch("user/fetchAndSetUserInformation");
        });
      }
    } catch (e) {
      // if login or refresh token request failed
      await store.dispatch("setBearerToken", null); // remove token
    } finally {
      store.commit("setAuthLoading", false);
    }
  }

  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const routerContext = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...routerContext,
    next: pipeline(routerContext, middleware, 1),
  });
});

router.isReady().then(() => store.commit("setRouterReady", true));

export default router;

<template>
  <div>
    <section class="content-main bg-image d-flex flex-column">
      <content-main
        title="ETK - Elektronik Ticari Onay Metni"
        nav="ETK - Elektronik Ticari Onay Metni"
        class="mt-md-100px mt-160px"
      />
    </section>

    <section class="container-xl my-5">
      <div class="row">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="!isLoading" class="card-body" v-html="content.content"></div>
      </div>
    </section>
  </div>
</template>

<script>
import LegalContentService from "../../services/legal-content/LegalContentService";
import ContentMain from "../../components/ui/ContentMain.vue";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  components: {
    ContentMain,
  },
  setup() {
    const store = useStore();
    const content = ref();
    const isLoading = ref(false);
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    isLoading.value = true;
    store.dispatch("setLoading", true);
    LegalContentService.getLegalContentByType("etk")
      .then((response) => {
        content.value = response.data.data;
      })
      .finally(() => {
        isLoading.value = false;
        store.dispatch("setLoading", false);
      });

    return {
      isLoading,
      content,
      main_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}
</style>

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="FLIPI (Follicular Lymphoma International Prognostic Index)"
        nav="FLIPI (Follicular Lymphoma International Prognostic Index)"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                    >{{ result }}</span
                  >
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="ipi"
            class="
              d-flex
              flex-column
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-100">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Yaş</h5>
                <select
                  name="age"
                  class="
                    w-100
                    form-control
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option selected>Lütfen Seçiniz</option>
                  <option value="a">60 yaş ve altı</option>
                  <option value="b">60 yaş ve üzeri</option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div class="w-m-100 w-50">
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Hastalık Evresi
                  </h5>
                  <select
                    name="evre"
                    class="
                      w-100
                      form-control
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                  >
                    <option selected>Lütfen Seçiniz</option>
                    <option value="a">Evre I-II</option>
                    <option value="b">Evre III-IV</option>
                  </select>
                </div>
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    LDH Seviyesi
                  </h5>
                  <select
                    name="ldh"
                    class="
                      w-100
                      form-control
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                  >
                    <option selected>Lütfen Seçiniz</option>
                    <option value="a">Normal</option>
                    <option value="b">Yükselmiş</option>
                  </select>
                </div>
              </div>

              <div class="w-m-100 w-50">
                <div class="mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    ECOG Skoru
                  </h5>
                  <select
                    name="ecog"
                    class="
                      w-100
                      form-control
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                  >
                    <option selected>Lütfen Seçiniz</option>
                    <option value="a">0 veya 1</option>
                    <option value="b">2,3 veya 4</option>
                  </select>
                </div>
                <div class="mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    ECOG Skoru
                  </h5>
                  <select
                    name="eks"
                    class="
                      w-100
                      form-control
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                  >
                    <option selected>Lütfen Seçiniz</option>
                    <option value="a">Var</option>
                    <option value="b">Yok</option>
                  </select>
                </div>
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius btn-calculate"
              @click="showResult = true"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "IpiScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    $(document).ready(function () {
      let form = $("#ipi");
      $(".btn-calculate").on("click", function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
        $(".return-text").slideDown();
        let error = 0;
        let age = form.find('select[name="age"]').val();
        let evre = form.find('select[name="evre"]').val();
        let ldh = form.find('select[name="ldh"]').val();
        let ecog = form.find('select[name="ecog"]').val();
        let eks = form.find('select[name="eks"]').val();
        form.find(".is-invalid").removeClass("is-invalid");
        if (age == "Lütfen Seçiniz") {
          form.find('select[name="age"]').addClass("is-invalid");
          error++;
        }
        if (evre == "Lütfen Seçiniz") {
          form.find('select[name="evre"]').addClass("is-invalid");
          error++;
        }
        if (ldh == "Lütfen Seçiniz") {
          form.find('select[name="ldh"]').addClass("is-invalid");
          error++;
        }
        if (ecog == "Lütfen Seçiniz") {
          form.find('select[name="ecog"]').addClass("is-invalid");
          error++;
        }
        if (eks == "Lütfen Seçiniz") {
          form.find('select[name="eks"]').addClass("is-invalid");
          error++;
        }
        if (error) {
          showReturnHtml("Eksik alanlar mevcut");
          return false;
        }
        let bStatus = 0;
        form.find("select").each(function () {
          if ($(this).val() == "b") {
            bStatus++;
          }
        });
        if (bStatus < 2) {
          showReturnHtml("Düşük risk (0-1 puan) - 5 yıllık sağkalım 73%");
        } else if (bStatus == 2) {
          showReturnHtml("Düşük-Orta risk (2 puan) - 5 yıllık sağkalım 51%");
        } else if (bStatus == 3) {
          showReturnHtml("Yüksek-Orta risk - 5 yıllık sağkalım 43%");
        } else if (bStatus >= 4) {
          showReturnHtml("Yüksek risk (4-5 puan) - 5 yıllık sağkalım 26%");
        }
      });
    });

    function showReturnHtml(text) {
      $(".return-text").html(text);
    }

    function clearAll() {
      $("form")[0].reset();
      $(".return-text").slideUp();
    }

    $(document).ready(function () {
      $("form").submit(function (event) {
        event.preventDefault();
        $(".btn-calculate").click();
      });
    });

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      clearAll,
      apgar: [
        {
          title: "Non-Hodgkin Lenfoma Nedir?",
          content: `    <p>Non-hodgkin lenfoma, lenfosit olarak da adlandırılan beyaz kan hücrelerinde ortaya çıkan bir kanser türüdür. Genel olarak aynı özellikleri paylaşan farklı lenfoma türlerine verilen bir isimdir. Non-hodgkin lenfomanın birçok alt türü mevcuttur. Non-hodgkin lenfoma, bir diğer lenfoma tipi olan Hodgkin lenfomaya göre daha yaygındır.</p>

            <p>Hastalık koltuk altı, boyun ve kasık gibi yerlerdeki lenf bezlerinde ortaya çıkan ağrısız şişlikler, karın ağrısı ve şişliği, göğüs ağrısı, öksürük ve nefes almada güçlük, sürekli yorgunluk, ateş, gece terlemesi ve açıklanamayan kilo kaybı gibi belirtiler verebilir.</p>

            <p>Non-hodgkin lenfomanın kemoterapi ve radyasyon gibi farklı tedavi türleri mevcuttur.</p>


        `,
        },
        {
          title: "International Prognostic Index (IPI) Nedir?",
          content: `<p>Uluslararası Prognostik Endeks anlamına gelen International Prognostic Index (IPI), onkologlar tarafından Agresif Non-Hodgkin Lenfomanın prognozunu belirlemek belirlemek için üretilmiş tıbbi bir araçtır</p>
        `,
        },
        {
          title: "International Prognostic Index (IPI) Nasıl Ölçülür?",
          content: `   <p>International Prognostic Index hesaplanırken aşağıdaki her bir madde için bir puan eklenir;</p>

            <ul>
                <li>60 yaşın üstünde olmak</li>
                <li>Hastalığın 3. ya da 4. evresinde olmak</li>
                <li>Yükselmiş serum LDH seviyesi</li>
                <li>2, 3 ya da 4 ECOG/Zubrod performans seviyesi</li>
                <li>Birden fazla ekstranodal bölge</li>
            </ul>

            <p>Bu değerlendirmelerin sonucunda aşağıdaki değerlendirmelere ulaşılır;</p>

            <ul>
                <li>Düşük risk (0-1 puan) - %73 oranla 5 sene yaşama ihtimali</li>
                <li>Düşük-orta risk (2 puan) - %51 oranla 5 sene yaşama ihtimali</li>
                <li>Yüksek-orta risk (3 puan) - %43 oranla 5 sene yaşama ihtimali</li>
                <li>Yüksek risk (4-5 puan) - %26 oranla 5 sene yaşama ihtimali</li>
            </ul>

            <p>International Prognostic Index lenfomanın gidişanı öğrenmek ve hastaya uygun bir tedavi belirlemek açısından oldukça önemlidir. Fakat 90larda geliştirilmiş bir test olduğu için, testin günümüze için tamamiyle doğru bir sonuç veremeyeceğini çünkü lenfoma tedavisinin önemli ölçüde ilerlediğini düşünenler de vardır.</p>

            <p>Test, 1993 yılında, ABD, Kanada ve Avrupa’dan 2031 adet, tüm yaşlardan agresif Hodgkin dışı lenfoma hastasından toplanan verilerle yapılan bir çalışma sonucu ortaya çıkmıştır. Bu hastalara 1982 ve 1987 yılları arasında doksorubisin içeren kemoterapi uygulanmıştır.</p>

            <p>Farklı hastaların hayatta kalma seviyeleri analiz edildi, ve bu analizler sonucunda yukarıdaki 60 yaşın üstünde olmak, hastalığın 3. ya da 4. evresinde olmak, yükselmiş serum LDH seviyesi, 2, 3 ya da 4 ECOG/Zubrod performans seviyesi ve birden fazla ekstranodal bölge faktörlerinin benzer olduğu hastalarda benzer hayatta kalma seviyesi olduğu belirlendi.</p>

            <p>International Prognostic Index (IPI) hesaplamak için Medikaynak’ta yer alan International Prognostic Index (IPI) hesaplama aracını kullanabilirsiniz.</p>
`,
        },
      ],
      references: [
        "https://www.mdapp.co/lymphoma-international-prognostic-index-ipi-score-calculator-344/",
        "https://en.wikipedia.org/wiki/International_Prognostic_Index",
        "https://www.cancer.org/cancer/non-hodgkin-lymphoma/about/what-is-non-hodgkin-lymphoma.html",
        "https://www.mayoclinic.org/diseases-conditions/non-hodgkins-lymphoma/symptoms-causes/syc-20375680",
        "https://www.healthline.com/health/non-hodgkins-lymphoma#treatments",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

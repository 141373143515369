<template>
  <div>
    <cover-image-upload v-if="show" v-model:files="filesValue" :color="color" />

    <div class="d-flex flex-column align-items-center">
      <template v-if="!isEdit">
        <report-img-icon :size="45" :color="color" />
        <p class="font-s-12px blue-300 text-center mb-4">
          İçeriğiniz {{ zoneName }} editörleri tarafından değerlendirilecektir.
        </p>
      </template>
      <button
        class="btn btn-primary border-radius height-50px mb-2 w-100"
        :disabled="disabled"
        @click="$emit('send')"
      >
        <calculate-icon class="mr-1" />
        <span class="font-s-12px font-w-500">
          {{ isEdit ? "Kaydet" : "Onaya Gönder" }}</span
        >
      </button>
      <button
        v-if="!isEdit"
        class="btn bg-gray-300 border-radius text-white height-50px w-100"
        @click="$emit('save')"
      >
        <article-icon class="mr-1" color="white" />
        <span class="font-s-12px font-w-500">Taslak Olarak Kaydet</span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import CoverImageUpload from "./CoverImageUpload.vue";
import ZoneHelper from "../../shared/zone-helper";
import { useStore } from "vuex";

export default {
  name: "AddSidebar",
  components: {
    CoverImageUpload,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    files: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["send", "save", "update:files"],
  setup(props, ctx) {
    const store = useStore();
    const theme = store.state.theme;
    const zoneHelper = new ZoneHelper();
    const zoneName = computed(() => zoneHelper.getZoneName(theme));

    const filesValue = computed({
      get() {
        return props.files;
      },
      set(value) {
        ctx.emit("update:files", value);
      },
    });

    return {
      filesValue,
      zoneName,
    };
  },
};
</script>

<style></style>

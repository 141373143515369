<template>
  <router-link :to="{ name: 'videodetail', params: { id: video.id } }">
    <div
      class="
        d-flex
        flex-column flex-md-row flex flex-xl-column
        videos-card-xl
        box-shadow-4-20-2
        border-radius
      "
    >
      <div
        class="
          videos-card-xl-img
          bg-image
          border-radius
          d-flex
          align-items-center
          justify-content-center
        "
        :style="{ background: 'url(' + video.thumbnail + ')' }"
      >
        <play-icon :size="90" />
      </div>
      <div class="px-4">
        <div class="d-flex justify-content-between py-3">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <img
                :src="video.category.thumbnail"
                alt=""
                class="videos-card-xl-person border-radius-50"
              />
            </div>
            <div class="d-flex flex-column">
              <span class="black-700">{{ video.category.title }}</span>
              <span class="font-s-10px text-info"
                >{{ video.category.video_count }} Video</span
              >
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <save-icon
                favoritable-type="video"
                :favoritable-id="video.id"
                :is-favorited="false"
                color="#1686D6"
                text="Kaydet"
                favorited-text="Kaydedildi"
              />
            </div>
          </div>
        </div>
        <div class="pb-2">
          <h2 class="font-s-26px black-700 font-w-600">
            {{ video.title }}
          </h2>
          <p class="black-700 line-h-21px">
            {{ video.description }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "VideosCardmain",
  props: {
    video: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.videos-card-xl {
  width: 550px;
}

.videos-card-xl-img {
  //   width: 550px;
  height: 310px;
  object-fit: cover;
}

.videos-card-xl-person {
  width: 40px;
  height: 40px;
}

@media (max-width: 1200px) {
  .videos-card-xl {
    width: initial !important;
  }

  .videos-card-xl-img {
    min-width: 50vw;
  }
}
</style>

<template>
  <div v-if="user">
    <div class="position-relative btn-open">
      <a
        href="#"
        class="
          user-box
          d-none d-lg-flex
          align-items-lg-center
          btn btn-primary
          text-white
          border-radius
          font-s-12px
          nav-button
        "
      >
        <div class="mr-2">
          <img
            :src="user.avatar_url"
            alt=""
            class="person-img border-radius-50"
          />
        </div>
        <div class="d-flex flex-column align-items-center">
          <span>Merhaba</span>
          <bottom-arrow-icon color="white" :size="8" />
        </div>
      </a>
      <div
        class="
          position-absolute
          bg-primary
          border-radius
          flex-column
          btn-open-box
          w-100
          font-s-12px
        "
      >
        <router-link
          :to="{ name: 'settings' }"
          class="py-2 px-3 text-white bg-primary"
        >
          Profil Ayarları
        </router-link>
        <router-link
          :to="{ name: 'message' }"
          class="py-2 px-3 text-white bg-primary"
        >
          Mesajlar
        </router-link>
        <router-link
          v-if="theme !== 'hemaworld'"
          :to="{ name: 'favorites' }"
          class="py-2 px-3 text-white bg-primary"
        >
          Kaydettiklerim
        </router-link>
        <a
          class="
            py-2
            px-3
            text-white
            bg-primary
            bottom-left-radius bottom-right-radius
          "
          @click="$emit('logout')"
          >Çıkış</a
        >
      </div>
    </div>
    <person-icon
      v-if="!showProfileNav && !showSearch"
      :color="color"
      class="d-lg-none mr-2"
      @click="
        $emit('update:showProfileNav', true),
          $emit('update:showSearch', false),
          $emit('update:showNav', false)
      "
    />
    <person-icon
      v-if="!showProfileNav && showSearch"
      color="white"
      class="d-lg-none mr-2"
      @click="
        $emit('update:showProfileNav', true),
          $emit('update:showSearch', false),
          $emit('update:showNav', false)
      "
    />
    <close-icon
      v-if="showProfileNav"
      :color="color"
      :size="24"
      class="d-lg-none mr-2"
      @click="$emit('update:showProfileNav', false)"
    />
  </div>
  <div v-else>
    <router-link :to="{ name: 'login' }">
      <person-icon :color="color" class="d-lg-none mr-2" />
    </router-link>
    <router-link
      :to="{ name: 'login' }"
      class="
        d-none d-lg-block
        btn btn-primary
        text-white
        border-radius
        font-s-12px
        nav-button
      "
    >
      Giriş Yap
    </router-link>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "HeaderUserBox",
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: true,
    },
    showProfileNav: {
      type: Boolean,
      required: true,
    },
    showSearch: {
      type: Boolean,
      required: true,
    },
    showNav: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "update:showProfileNav",
    "update:showSearch",
    "update:showNav",
    "logout",
  ],
  setup() {
    const store = useStore();

    const theme = store.state.theme;

    return {
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-open:hover .user-box {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.person-img {
  width: 24px;
  height: 24px;
}

.btn-open:hover .btn-open-box {
  display: flex;
}

.btn-open:hover {
  transition: 0.3s;
}

.btn-open-box {
  top: 45px;
  display: none;
}
</style>

<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.317 55.6812C78.2436 58.745 77.2822 62.1158 75.1392 64.4963C71.6352 68.3884 65.1368 69.8653 60.6771 68.8112C52.5802 66.8999 54.0397 59.4169 49.9102 55.6812"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M43.3628 57.8937C42.2044 60.749 42.633 64.1894 44.4517 66.6683C46.2761 69.1529 49.4326 70.5893 52.4965 70.3345C52.4965 70.3345 55.6762 72.8481 56.0005 82.3119"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M61.8359 69.0139C61.8359 69.0139 64.6971 75.5992 62.8669 82.3177"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M36.2385 41.387C35.0395 38.6938 34.8426 35.8674 35.5666 33.0062C37.3215 26.1082 42.6384 24.7992 45.1578 22.3146C47.3819 20.1195 48.2854 16.0826 47.3297 13.1056"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M66.8203 53.2427C69.5714 49.3159 67.3937 45.8929 68.9864 43.1071C69.9015 41.5028 71.8186 40.7209 72.9133 39.2266C76.9444 33.7012 67.8223 26.4093 71.7375 20.8666"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M28.2592 50.0631C28.3345 52.7969 29.7072 55.351 31.9081 56.9785C34.1089 58.6002 36.9933 58.7508 39.837 58.4381C46.2428 57.4419 62.7783 49.3855 76.557 55.4205C79.0186 56.4978 82.2851 55.3568 83.9705 53.1386C91.1408 43.7385 79.8757 12.4859 45.2408 13.0999C19.4325 13.5632 6.30829 32.8904 12.4823 45.9915C16.1138 53.7004 25.8614 54.3722 30.5412 47.6306C32.9332 44.1845 32.6262 41.4971 41.0649 40.1592C42.2232 39.9738 43.8797 39.6784 45.4203 39.4004C48.3278 38.8676 50.8241 36.9273 51.9303 34.182C52.0172 33.9619 52.0925 33.7592 52.1388 33.5797C52.5848 31.9522 52.9207 30.1509 54.2007 29.0447C55.5907 27.84 57.6526 27.8574 59.2801 27.0002C60.9945 26.0967 62.0775 24.278 62.5177 22.3899C62.9579 20.5018 62.8305 18.5326 62.6509 16.6039"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SinirSistemi",
};
</script>

<style></style>

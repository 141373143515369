<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0002 3.33334C22.9947 3.33334 28.6668 9.00421 28.6668 16C28.6668 22.9958 22.9947 28.6667 16.0002 28.6667C9.00436 28.6667 3.3335 22.9958 3.3335 16C3.3335 9.00421 9.00436 3.33334 16.0002 3.33334Z"
      :stroke="color"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.5835 12C14.5835 11.3096 14.0239 10.75 13.3335 10.75C12.6431 10.75 12.0835 11.3096 12.0835 12H14.5835ZM12.0835 20C12.0835 20.6904 12.6431 21.25 13.3335 21.25C14.0239 21.25 14.5835 20.6904 14.5835 20H12.0835ZM12.0835 12V16H14.5835V12H12.0835ZM12.0835 16V20H14.5835V16H12.0835Z"
      :fill="color"
    />
    <path
      d="M19.917 12C19.917 11.3096 19.3573 10.75 18.667 10.75C17.9766 10.75 17.417 11.3096 17.417 12H19.917ZM17.417 20C17.417 20.6904 17.9766 21.25 18.667 21.25C19.3573 21.25 19.917 20.6904 19.917 20H17.417ZM17.417 12V16H19.917V12H17.417ZM17.417 16V20H19.917V16H17.417Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "PauseIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="d-flex flex-column align-items-center">
    <h1
      class="font-weight-bold text-center font-s-40px text-white title"
      :class="titleClass"
    >
      {{ title }}
    </h1>
    <div class="d-none d-md-block">
      <router-link :to="{ name: 'home' }" class="blue-300">
        Ana Sayfa
      </router-link>
      <span class="letter-spacing-m-4-5 text-white ml-1 mr-2">>></span>
      <span class="text-white">{{ nav }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentMain",
  props: {
    title: {
      type: String,
      required: true,
    },
    nav: {
      type: String,
      required: false,
      default: null,
    },
    titleClass: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .title {
    font-size: 30px;
    max-width: 300px;
  }
}
</style>

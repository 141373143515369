<template>
  <div class="bg-white p-4 mb-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <div class="comment-person-img mr-3">
          <img
            :src="comment.creator?.avatar_url ?? '/img/thumbnails/default.jpg'"
            alt=""
            class="img-fluid border-radius-50"
          />
        </div>
        <div class="d-flex flex-column">
          <span class="font-w-600">{{
            comment.creator?.fullname ?? "Silinmiş Kullanıcı"
          }}</span>
          <span class="font-s-10px">{{ comment.created_at_formatted }}</span>
        </div>
      </div>
      <!-- <div>
        <button class="btn btn-info border-radius font-s-10px">Cevapla</button>
      </div> -->
    </div>
    <div class="comment-text">
      <p class="gray-700 font-s-12px line-h-21px">
        {{ comment.body }}
      </p>
    </div>
    <div v-if="!comment.is_approved" class="comment-approve-text">
      <div class="alert alert-info" role="alert">
        <span>Yorumunuz yönetici onayının ardından yayınlanacaktır.</span>
      </div>
    </div>
    <div v-if="comment.children">
      <hr class="comment-hr my-4" />
      <single-video-comment
        v-for="(child, index) in comment.children"
        :key="index"
        :comment="child"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleVideoComment",
  props: {
    comment: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.comment-person-img {
  width: 45px;
  height: 45px;
}
.comment-text,
.comment-approve-text {
  padding: 0 60px;
}
.comment-hr {
  max-width: 800px;
}

@media (max-width: 768px) {
  .comment-title {
    font-size: 18px;
  }
}
</style>

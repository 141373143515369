<template>
  <div>
    <div class="mb-4">
      <h5 class="font-s-12px font-w-600 black-700 mb-2">
        Gönderilecek Kullanıcı(lar)
      </h5>
      <div
        class="
          send-user-box
          d-flex
          justify-content-between
          align-items-center
          border-radius
        "
      >
        <div class="d-flex align-items-center flex-wrap h-100 px-1 py-1">
          <span
            v-for="(item, index) in people"
            :key="index"
            class="
              mb-1
              font-s-12px
              text-primary
              border-radius
              bg-blue-light
              d-flex
              align-items-center
              px-2
              mr-2
              height-30px
              person-box
            "
          >
            <span class="mr-1">{{
              users.find((user) => user.id === item).fullname
            }}</span>
            <tag-close-icon
              :color="colorDark"
              class="cursor-pointer"
              @click="people.splice(index, 1)"
            />
          </span>
          <div class="position-relative">
            <input
              v-model="search"
              type="text"
              class="
                input-send-user
                border-none
                font-s-12px
                text-info
                border-radius
                height-30px
              "
              placeholder="Mesaj göndermek istediğiniz kulanıcı(lar)"
              @focus="showSuggestions = true"
              @blur="
                showSuggestions = false;
                search = '';
              "
            />
            <div
              v-if="showSuggestions && search.length > 2 && suggestions.length"
              class="
                position-absolute
                z-index-5
                input-open-box
                d-flex
                flex-column
                bg-white
                border-radius
                box-shadow-4-20-2
              "
            >
              <template v-if="suggestions.length">
                <span
                  v-for="user in suggestions"
                  :key="user.id"
                  class="gray-500 font-s-12px"
                  @mousedown="addPerson(user.id)"
                  >{{ user.fullname }}</span
                >
              </template>
            </div>
          </div>
        </div>
        <search-icon color="#374A59" :show="true" class="mr-2" />
      </div>
    </div>
    <div class="mb-3">
      <h5 class="font-s-12px font-w-600 black-700 mb-2">Mesajınız</h5>
      <textarea
        v-model="message"
        class="textarea-message"
        placeholder="Mesajınızı bu alana ekleyebilirsiniz..."
      />
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn bg-gray-500 text-white border-radius py-3 px-4 mr-3"
        @click="
          message = '';
          people = [];
        "
      >
        Temizle
      </button>
      <button
        class="btn bg-blue-300 text-white border-radius py-3 px-4"
        :disabled="message.length < 2 || people.length < 1 || sending"
        @click="sendMessage"
      >
        Mesajı Gönder
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import UserServiceNew from "../../services/UserServiceNew";
import { useStore } from "vuex";
import ColorHelper from "../../helpers/ColorHelper";
import ChatService from "../../services/chat/ChatService";
import SwalError from "../../helpers/SwalError";

const userService = new UserServiceNew();
const chatService = new ChatService();

export default {
  name: "MessagesForm",
  emits: ["created"],
  setup(props, ctx) {
    const showSuggestions = ref(false);
    const people = ref([]);
    const users = ref([]);
    const store = useStore();
    const search = ref("");
    const message = ref("");
    const sending = ref(false);
    const me = computed(() => store.state.user.user);

    async function fetchUsers() {
      users.value = await userService.all({
        fields: {
          users: ["id", "firstname", "fullname"],
        },
      });
    }

    const suggestions = computed(() => {
      if (search.value.length < 3) return [];
      return users.value
        .filter(
          (user) =>
            !people.value.includes(user.id) &&
            user.id !== me.value.id &&
            user.fullname.toLowerCase().includes(search.value.toLowerCase())
        )
        .slice(0, 5);
    });

    const sendMessage = () => {
      sending.value = true;
      chatService
        .start(people.value, message.value)
        .catch((error) => {
          SwalError.fire(error);
        })
        .then((response) => {
          ctx.emit("created", response.data.data);
          chatService.sendMessage(response.data.data, message.value);
        })
        .finally(() => {
          sending.value = false;
        });
    };

    fetchUsers();

    return {
      sendMessage,
      sending,
      message,
      users,
      showSuggestions,
      search,
      colorDark: ColorHelper[store.state.theme].blueDark,
      suggestions,
      people,
      addPerson(item) {
        search.value = "";
        people.value.push(item);
      },
      colorSearch: ColorHelper[store.state.theme].blueText,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-open-box {
  max-width: 360px;
  width: 100%;
  top: 40px;
  padding: 23px 0;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10%;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  span {
    padding: 10px 50px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: v-bind("colorSearch");
      background: #daedf733;
    }
  }
}
.textarea-message {
  width: 100%;
  border: 1px solid #374a59;
  border-radius: 10px;
  padding: 13px 10px;

  &::placeholder {
    font-size: 12px;
    color: #7684aa;
  }
}
.input-send-user {
  width: 280px;
  margin: 6px 7px;
  &::placeholder {
    font-size: 12px;
    color: #7684aa;
  }
}
.send-user-box {
  min-height: 45px;
  border: 1px solid #374a59;
}
.person-box {
  margin: 6px 7px;
}
.textarea-message {
  height: 314px;
}
</style>

<template>
  <div class="pubmed mb-100px">
    <section class="pubmed-main bg-image d-flex flex-column mb-75px">
      <content-main
        title="Literatür Tarama"
        nav="Literatür Tarama"
        class="mt-md-100px mt-160px"
      />
      <div class="d-flex mx-auto position-relative pubmed-article-search">
        <input
          v-model="searchTerm"
          type="text"
          placeholder="Aranacak Makale"
          class="
            input-group-text
            bg-white
            text-left
            border-radius-90px
            box-shadow-4-20
          "
          @keydown.enter="searchPubmed"
        />
        <button
          :disabled="isSearchButtonDisabled"
          class="
            btn btn-info
            border-radius-90px
            font-s-12px font-weight-bold
            h-100
            position-absolute
          "
          @click="searchPubmed"
        >
          Makale Ara
        </button>
      </div>
    </section>

    <section class="mb-5">
      <div class="container-xl">
        <div class="mb-4">
          <h3 class="font-s-24px font-weight-normal gray-700 mb-3">
            Pubmed <b class="text-info">Tam Metin Talebi</b>
          </h3>
          <div class="d-flex align-items-start">
            <point-text-icon :size="9" />
            <p class="gray-700">
              Aşağıdaki talep formunu kullanarak Santa Farma’dan
              <span class="text-info font-weight-bold"
                >aylık 3 adet ücretsiz tam metin talebinde
                bulunabilirsiniz.</span
              >
            </p>
          </div>
          <div class="d-flex align-items-start">
            <point-text-icon :size="9" />
            <p class="gray-700">
              Eğer ulaşmak istediğiniz makalenin detaylarına sahip değilseniz
              öncelikle PubMed arama formunu kullanarak PubMed sisteminde arama
              yapabilir, özetine ulaştığınız makalenin tam metin talebi için
              aşağıdaki formumuza makale linkini veya gerekli bilgileri
              girebilirsiniz.
            </p>
          </div>
          <div class="d-flex align-items-start">
            <point-text-icon :size="9" />
            <p class="gray-700">
              Talebiniz bize ulaştıktan sonra Medikal Müdürlerimiz tarafından
              hazırlanacak ve saha ekibimiz tarafından size iletilecektir.
            </p>
          </div>
          <div class="d-flex align-items-start">
            <point-text-icon :size="9" />
            <p class="gray-700 font-weight-bold">
              Telif hakları nedeniyle e-book ve tam metin talepleriniz yalnızca
              basılı olarak ekibimiz tarafından size ulaştırılacaktır,
              elektronik ortam üzerinden iletilememektedir.
            </p>
          </div>
          <div class="d-flex align-items-start">
            <point-text-icon :size="9" />
            <p class="gray-700 font-weight-bold">
              Talepte bulunduğunuz literatürün
              <span class="text-info">gönderim süresi ortalama 20 gündür.</span>
            </p>
          </div>
        </div>

        <div class="d-flex flex-column align-items-center">
          <div class="max-w-640px mb-3">
            <div class="d-flex align-items-start font-s-12px gray-700">
              <point-text-icon :size="5" />
              <p>
                Lütfen girdiğiniz url'in https://pubmed.ncbi.nlm.nih.gov/
                uzantılı olduğunu kontrol ediniz.
              </p>
            </div>
            <div class="d-flex align-items-start font-s-12px gray-700">
              <point-text-icon :size="5" />
              <p>
                Talep ettiğiniz makale basılı olarak teslim edileceğinden dolayı
                bilgilerinizin güncel olması çok önemlidir.
              </p>
            </div>
          </div>
          <div class="d-flex mx-auto position-relative pubmed-article-input">
            <input
              v-model="articleUrl"
              type="text"
              placeholder="Makale Linki"
              class="
                input-group-text
                bg-white
                text-left
                border-radius-90px
                box-shadow-4-20
              "
              @keydown.enter="sendPubmedRequest"
            />
            <button
              :disabled="isRequestButtonDisabled"
              class="
                btn btn-request
                text-white
                border-radius-90px
                font-s-12px font-weight-bold
                h-100
                position-absolute
              "
              @click="sendPubmedRequest"
            >
              Talepte Bulun
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div v-if="!isPubmedRequestsLoading" class="container-xl">
        <h3 class="font-s-24px font-weight-normal gray-700 mb-3">
          Önceki <b class="text-info">Pubmed Taleplerim</b>
        </h3>

        <div v-for="(pubmedRequest, index) in pubmedRequests" :key="index">
          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-between
              align-items-md-center
            "
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <clock-icon color="#1686D6" :size="20" class="mr-1" />
                <span class="font-s-13px text-info">{{
                  pubmedRequest.created_at
                }}</span>
              </div>
              <div>
                <p class="font-w-600 gray-700 max-w-10px">
                  {{ pubmedRequest.link }}
                </p>
              </div>
            </div>
            <div class="d-flex">
              <a
                :href="pubmedRequest.link"
                target="_blank"
                class="
                  btn btn-info btn-pubmed
                  d-flex
                  align-items-center
                  justify-content-center
                  border-radius
                  mr-2
                "
              >
                <right-arrow-icon color="#fff" />
                <span class="font-s-12px">Pubmed'e Git</span>
              </a>
              <button
                class="
                  btn
                  bg-blue-light
                  btn-pubmed
                  d-flex
                  align-items-center
                  justify-content-center
                  border-radius
                "
                @click="deletePubmedRequest(pubmedRequest.id)"
              >
                <delete-icon :class="color" />
                <span class="font-s-12px text-info">Talebi Sil</span>
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import PubmedService from "../services/pubmed/PubmedService";
import ColorHelper from "../helpers/ColorHelper";
import { ref } from "vue";
import dayjs from "dayjs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SwalError from "../helpers/SwalError";
import { useStore } from "vuex";

export default {
  name: "Pubmed",
  components: {
    ContentMain,
  },
  setup() {
    const pubmedRequests = ref([]);
    const isPubmedRequestsLoading = ref(true);
    const articleUrl = ref("");
    const searchTerm = ref("");
    const isRequestButtonDisabled = ref(false);
    const isSearchButtonDisabled = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const color = ColorHelper[store.state.theme].blueText;

    const fetchPubmedRequests = () => {
      PubmedService.getPubmedRequests()
        .then((response) => {
          isPubmedRequestsLoading.value = true;
          pubmedRequests.value = response.data.data.previous_user_requests.map(
            (request) => {
              return {
                ...request,
                created_at: dayjs(request.created_at)
                  .locale("tr")
                  .format("DD.MM.YYYY"),
              };
            }
          );
        })
        .finally(() => {
          isPubmedRequestsLoading.value = false;
        });
    };

    const searchPubmed = () => {
      isSearchButtonDisabled.value = true;
      PubmedService.searchPubmed(searchTerm.value)
        .then((response) => {
          window.open(response.data.data.url, "_blank");
        })
        .catch((error) => {
          SwalError.fire(error);
        })
        .finally(() => {
          isSearchButtonDisabled.value = false;
        });
    };

    const sendPubmedRequest = () => {
      isRequestButtonDisabled.value = true;
      PubmedService.createPubmedRequest(articleUrl.value)
        .then(() => {
          articleUrl.value = "";
          fetchPubmedRequests();
          Swal.fire({
            title: "Başarılı!",
            text: "Talebiniz başarıyla gönderildi.",
            icon: "success",
            confirmButtonText: "Tamam",
          });
        })
        .catch((error) => {
          SwalError.fire(error);
        })
        .finally(() => {
          isRequestButtonDisabled.value = false;
        });
    };

    const deletePubmedRequest = (id) => {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Bu talebi silmek istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.value) {
          PubmedService.deletePubmedRequest(id)
            .then(() => {
              fetchPubmedRequests();
              Swal.fire({
                title: "Başarılı!",
                text: "Talebiniz başarıyla silindi.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Hata!",
                text: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      });
    };

    fetchPubmedRequests();

    return {
      pubmedRequests,
      isPubmedRequestsLoading,
      isRequestButtonDisabled,
      isSearchButtonDisabled,
      articleUrl,
      searchTerm,
      sendPubmedRequest,
      searchPubmed,
      deletePubmedRequest,
      main_img,
      color,
    };
  },
};
</script>

<style lang="scss" scoped>
.pubmed-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 354px;
}

.pubmed-article-search {
  top: 80px;
}

.pubmed-article-search,
.pubmed-article-input {
  width: 645px;
  height: 70px;

  input {
    padding: 20px 220px 20px 45px;
    width: 100%;
    border: none;

    &::placeholder {
      text-align: left;
      font-size: 16px;
      color: #7684aa;
    }
  }

  button {
    width: 190px;
    right: 0;
  }
}

.btn-pubmed {
  width: 122px;
  height: 36px;
}

.btn-request {
  background: linear-gradient(319.36deg, #ff6188 2.25%, #ffb199 95.74%);
}

@media (max-width: 768px) {
  .pubmed-main {
    height: 200px;

    h1 {
      font-size: 30px !important;
    }
  }

  .pubmed-article-search,
  .pubmed-article-input {
    max-width: 320px;
    height: 49px;

    button {
      width: 120px;
    }
  }

  .pubmed-article-search,
  .pubmed-article-input {
    input {
      font-size: 12px !important;
      padding: 20px 160px 20px 30px;

      &::placeholder {
        font-size: 12px !important;
      }
    }
  }

  .pubmed-article-search {
    top: 35px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  .btn-pubmed {
    width: 100%;
  }
}
</style>

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="EDSS Hesaplama Aracı"
        nav="EDSS Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  >
                    {{ result }}
                  </h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                    >Edss Skoru</span
                  >
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Görsel Fonksiyonlar
                </h5>
                <select
                  v-model="q1"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Normal</option>
                  <option value="1">
                    1 - Düzeltilmiş görme keskinliğinin 20/30'dan iyi olduğu
                    skotom
                  </option>
                  <option value="2">
                    2 - Kötü gözde maksimum düzeltilmiş görme keskinliği 20/30 -
                    20/59 arasında
                  </option>
                  <option value="3">
                    3 - Kötü gözde geniş skotom, ya da görme alanında derecede
                    azalma ancak maksimum düzeltilmiş görme keskinliği 20/60 ile
                    20/99 arasında
                  </option>
                  <option value="4">
                    4 - Kötü gözde görme alanında belirgin azalma ve maksimum
                    düzeltilmiş görme keskinliği 20/100- 20/200 arasında; 3.
                    derece artı iyi gözde maksimum görme keskinliği 20/60 veya
                    daha az
                  </option>
                  <option value="5">
                    5 - Kötü gözde düzeltilmiş maksimum görme keskinliği
                    20/200'den az; 4.derece artı iyi gözde maksimum görme
                    keskinliği 20/60 veya daha az
                  </option>
                  <option value="6">
                    6 - Beşinci derece artı iyi gözde maksimum görme keskinliği
                    20/60 ya da daha az
                  </option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Piramidal Fonksiyonlar
                </h5>
                <select
                  v-model="q2"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Normal</option>
                  <option value="1">
                    1 - Özürlülük olmaksızın anormal bulgular
                  </option>
                  <option value="2">2 - Minimal özürlülük</option>
                  <option value="3">
                    3 - Hafif ya da orta paraparezi veya hemiparezi; ağır
                    monoparezi.
                  </option>
                  <option value="4">
                    4 - Belirgin paraparezi veya hemiparezi; orta kuadriparezi;
                    ya da monopleji.
                  </option>
                  <option value="5">
                    5 - Parapleji, hemipleji, ya da belirgin kuadriparezi.
                  </option>
                  <option value="6">6 - Kuadripleji</option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Duysal Fonksiyonlar
                </h5>
                <select
                  v-model="q3"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Normal</option>
                  <option value="1">
                    1 - Bir ya da iki ekstremitede yalnızca vibrasyon veya şekil
                    çizmede azalma
                  </option>
                  <option value="2">
                    2 - Bir ya da iki ekstremitede dokunma, ağrı veya pozisyon
                    duyusundan hafif azalma, ve/veya bir veya iki ekstremitede
                    vibrasyonda orta derecede azalma; ya da 3-4 ekstremitede tek
                    başına vibrasyon kusuru
                  </option>
                  <option value="3">
                    3 - Bir yada iki ekstremitede dokunma, ağrı veya pozisyon
                    duyusunda orta derecede azalma, ve/veya temel olarak
                    vibrasyon kaybı; ya da 3-4 ekstremitede hafif derecede
                    dokunma, ağrı ve/veya orta derecede tüm propriseptif
                    testlerde bozukluk
                  </option>
                  <option value="4">
                    4 - Bir ya da iki ekstremitede tek başına ya da kombine
                    olarak, belirgin derecede dokunma, ağrı duyusunda azalma ya
                    da propriosepsiyon kaybı; ya da ikiden fazla ekstremitede
                    orta derecede dokunma, ağrı ve/veya ağır propriosepsiyon
                    kaybı
                  </option>
                  <option value="5">
                    5 - Bir ya da iki ekstremitede duyu kaybı (temel olarak); ya
                    da dokunma, ağrı duyularında orta derecede azalma ve/veya
                    propriosepsiyonda vücudun kafa altında kalan bölümlerinin
                    çoğunda kayıp
                  </option>
                  <option value="6">
                    6 - Kafa altında kalan bölümlerde temel olarak duyu kaybı
                  </option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Serebral Fonksiyonlar
                </h5>
                <select
                  v-model="q4"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Normal</option>
                  <option value="1">
                    1 - Mental fonksiyonlara ilişkin semptonlar; hafif yorgun
                  </option>
                  <option value="2">
                    2 - Mental fonksiyonlarda hafif azalma
                  </option>
                  <option value="3">
                    3 - Mental fonksiyonlarda orta derecede bozulma
                  </option>
                  <option value="4">
                    4 - Mental fonksiyonlarda ileri derecede bozulma
                  </option>
                  <option value="5">5 - Demans</option>
                </select>
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Beyin Sapı Fonksiyonları
                </h5>
                <select
                  v-model="q5"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Normal</option>
                  <option value="1">1 - Yalnızca bulgular</option>
                  <option value="2">
                    2 - Orta derecede nistagmus ya da diğer hafif özürlülükler
                  </option>
                  <option value="3">
                    3 - Ağır nistagmus, belirgin ekstraoküler güç kaybı veya
                    diğer kranial sinirlerde orta derecede özürlülük
                  </option>
                  <option value="4">
                    4 - Belirgin dizartri ya da belirgin başka özürlülük
                  </option>
                  <option value="5">
                    5 - Yutma ya da konuşma yeteneğinin kaybı
                  </option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Serebellar Fonksiyonlar
                </h5>
                <select
                  v-model="q6"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Normal</option>
                  <option value="1">
                    1 - Özürlülük olmaksızın anormal bulgular
                  </option>
                  <option value="2">2 - Hafif ataksi</option>
                  <option value="3">
                    3 - Orta trunkal ya da ekstremite ataksisi
                  </option>
                  <option value="4">4 - Ağı ataksi, tüm ekstremiteler</option>
                  <option value="5">
                    5 - Ataksiye bağlı olarak koordine hareket edememe
                  </option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Bağırsak ve Mesane Fonksiyonları
                </h5>
                <select
                  v-model="q7"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Normal</option>
                  <option value="1">
                    1 - İdrara başlamada hafif derecede duraklama, idrara
                    sıkışma hissi ya da idrar retansiyonu
                  </option>
                  <option value="2">
                    2 - Orta derecede idrar duraklaması, idrara sıkışma,
                    bağırsak veya mesanede retansiyon ya da nadir idrar kaçırma
                  </option>
                  <option value="3">3 - Sık idrar kaçırma</option>
                  <option value="4">
                    4 - Neredeyse devamlı olarak kalıcı kateterizasyon gereği
                  </option>
                  <option value="5">5 - Mesane fonksiyonunun kaybı</option>
                  <option value="6">
                    6 - Mesane ve bağırsak fonksiyonunun kaybı
                  </option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Ambulasyon Skoru
                </h5>
                <select
                  v-model="q8"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                >
                  <option value="0" selected>0 - Kısıtlanmamış</option>
                  <option value="1">1 - Tam ambulatuar hasta</option>
                  <option value="2">
                    2 - Yardımsız ve dinlenmeden 300-500 metre arası mesafeyi
                    yürüyebilir (EDSS 4.5 veya 5.0)
                  </option>
                  <option value="3">
                    3 - Yardımsız ve dinlenmeden 200-300 metre arası mesafeyi
                    yürüyebilir (EDSS 5.0)
                  </option>
                  <option value="4">
                    4 - Yardımsız ve dinlenmeden 100-200 metre arası mesafeyi
                    yürüyebilir (EDSS 5.5)
                  </option>
                  <option value="5">
                    5 - Yardımsız 100 metreden kısa mesafeyi yürüyebilir (EDSS
                    6.0)
                  </option>
                  <option value="6">
                    6 - 50 metreden fazla mesafeyi dinlenmeden yürüyebilmek için
                    tek taraflı sabit desteğe ihtiyaç duyar (EDSS 6.0)
                  </option>
                  <option value="7">
                    7 - 120 metreden fazla mesafeyi dinlenmeden yürüyebilmek
                    için çift taraflı sabit desteğe ihtiyaç duyar (EDSS 6.0)
                  </option>
                  <option value="8">
                    8 - 50 metreden kısa mesafeyi dinlenmeden yürüyebilmek için
                    tek taraflı sabit desteğe ihtiyaç duyar (EDSS 6.5)
                  </option>
                  <option value="9">
                    9 - 5-120 metre arası mesafeyi dinlenmeden yürüyebilmek için
                    çift taraflı sabit desteğe ihtiyaç duyar (EDSS 6.5)
                  </option>
                  <option value="10">
                    10 - Yardımla bile 5 metrenin ötesinde yürüyemez, esas
                    olarak tekerlekli sandalyeye bağımlıdır; tekerlekleri
                    kendisi çevirir ve kendisi tekerlekli sandalyeye geçebilir;
                    yaklaşık günde 12 saat ya da daha fazla tekerlekli
                    sandalyede geçirebilir (EDSS 7.0)
                  </option>
                  <option value="11">
                    11 - Birkaç adımdan fazlasını atamaz; tekerlekli sandalyeye
                    bağımlıdır; tekerlekli sandalyeye geçişte yardım gerekebilir
                    (EDSS 7.5)
                  </option>
                  <option value="12">
                    12 - Esas olarak yatağa ya da sandalyeye bağımlı, ya da
                    tekerlekli sandalyede ambule olabilir, günün çoğunu yatak
                    dışında geçirebilir; birçok işini kendisi görebilir.
                    Kollarını etkili kullanabilir (EDSS 8.0)
                  </option>
                  <option value="13">
                    13 - Günün çoğunda yatağa bağımlıdır; kolunu/kollarını bir
                    dereceye kadar etkili olarak kullanabilir; bazı işlerini
                    kendisi görebilir (EDSS 8.5)
                  </option>
                  <option value="14">
                    14 - Ümitsizce yatağa bağlı hasta; iletişim kurabilir ve
                    yiyebilir (EDSS 9.0)
                  </option>
                  <option value="15">
                    15 - Tümüyle ümitsiz, yatağa bağlı hasta; etkin iletişim
                    kuramaz ya da yutmayeme bozulmuştur (EDSS 9.5)
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import { ref } from "@vue/reactivity";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "EdssScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const result = ref();
    const q1 = ref(0);
    const q2 = ref(0);
    const q3 = ref(0);
    const q4 = ref(0);
    const q5 = ref(0);
    const q6 = ref(0);
    const q7 = ref(0);
    const q8 = ref(0);

    const calculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      let fc = [0, 0, 0, 0, 0, 0, 0];
      fc[q1.value]++;
      fc[q2.value]++;
      fc[q3.value]++;
      fc[q4.value]++;
      fc[q5.value]++;
      fc[q6.value]++;
      fc[q7.value]++;

      let edss = 0;
      if (q8.value < 4) {
        if ((q8.value == 0) & (fc[0] == 7)) {
          edss = 0;
        } else if ((q8.value == 0) & (fc[0] == 6) & (fc[1] == 1)) {
          edss = 1;
        } else if (
          (q8.value == 0) &
          ((fc[1] > 1) & (fc[1] < 8)) &
          (fc[2] + fc[3] + fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 1.5;
        } else if (
          (q8.value < 2) &
            (fc[2] == 1) &
            (fc[3] + fc[4] + fc[5] + fc[6] == 0) ||
          (q8 == 1) & (fc[2] + fc[3] + fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 2;
        } else if (
          (q8.value < 2) &
          (fc[2] == 2) &
          (fc[3] + fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 2.5;
        } else if (
          (q8.value < 2) &
          ((fc[2] == 3) | (fc[2] == 4)) &
          (fc[3] + fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 3;
        } else if (
          (q8.value < 2) &
          ((fc[2] == 0) & (fc[3] == 1)) &
          (fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 3;
        } else if (
          (q8.value < 2) &
          (fc[2] == 5) &
          (fc[3] + fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 3.5;
        } else if (
          (q8.value < 2) &
          ((fc[3] == 1) & ((fc[2] == 1) | (fc[2] == 2))) &
          (fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 3.5;
        } else if (
          (q8.value < 2) &
          ((fc[3] == 2) & (fc[2] == 0)) &
          (fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 3.5;
        } else if (
          (q8.value < 2) &
          ((fc[2] == 6) | (fc[2] == 7)) &
          (fc[3] + fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 4;
        } else if (
          (q8.value < 2) &
          ((fc[3] == 2) | (fc[3] == 3) | (fc[3] == 4)) &
          (fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 4;
        } else if (
          (q8.value < 2) &
          (fc[2] == 0) &
          (fc[3] == 0) &
          (fc[4] == 1) &
          (fc[5] + fc[6] == 0)
        ) {
          edss = 4;
        } else if (
          (q8.value == 2) &
          (fc[3] == 5) &
          (fc[4] + fc[5] + fc[6] == 0)
        ) {
          //Yeni
          edss = 4.5;
        } else if ((q8.value == 2) & (fc[3] == 5)) {
          //Yeni
          edss = 5;
        } else if ((q8.value == 2) & (fc[6] > 0) & (fc[5] > 0) & (fc[4] > 2)) {
          //Yeni
          edss = 5;
        } else if (q8.value == 2) {
          //Yeni
          edss = 4.5;
        } else if (
          (q8.value == 3) &
          (fc[3] == 5) &
          (fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 4.5;
        } else if (
          (q8.value < 3) &
          (((fc[3] == 1) | (fc[3] == 2)) & (fc[4] == 1)) &
          (fc[5] + fc[6] == 0)
        ) {
          edss = 4.5;
        } else if (fc[4] == 2) {
          edss = 4.5;
        } else if (
          (q8.value < 4) &
          ((fc[3] == 6) | (fc[3] == 7)) &
          (fc[4] + fc[5] + fc[6] == 0)
        ) {
          edss = 5;
        } else if (
          (q8.value < 4) &
          ((fc[4] > 1) | (fc[4] < 8)) &
          (fc[5] + fc[6] == 0)
        ) {
          edss = 5;
        } else if (
          (q8.value < 4) &
          ((fc[5] > 0) | (fc[5] < 8) | ((fc[6] > 0) | (fc[6] < 8)))
        ) {
          edss = 5;
        }
      } else if (q8.value == 4) {
        edss = 5.5;
      } else if ((q8.value == 5) | (q8.value == 6) | (q8.value == 7)) {
        edss = 6;
      } else if ((q8.value == 8) | (q8.value == 9)) {
        edss = 6.5;
      } else if (q8.value == 10) {
        edss = 7;
      } else if (q8.value == 11) {
        edss = 7.5;
      } else if (q8.value == 12) {
        edss = 8;
      } else if (q8.value == 13) {
        edss = 8.5;
      } else if (q8.value == 14) {
        edss = 9;
      } else if (q8.value == 15) {
        edss = 9.5;
      }

      result.value = edss.toFixed(1);
    };

    function clearAll() {
      q1.value = 0;
      q2.value = 0;
      q3.value = 0;
      q4.value = 0;
      q5.value = 0;
      q6.value = 0;
      q7.value = 0;
      q8.value = 0;
      result.value = null;
    }

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      result,
      calculate,
      clearAll,
      apgar: [
        {
          title: "EDSS Nedir?",
          content: `<p>EDSS, İngilizce Expanded Disability Status Scale anlamına gelen Genişletilmiş Özürlülük Durum Ölçeğinin kısaltmasıdır. EDSS, multipl skleroz (MS) hastalığının değerlendirmesinde kullanılır. EDSS sayesinde bir kişinin MS’ten ne seviyede etkilendiği görülebilir.&nbsp;</p>
            <p>MS hastalığında, doktor her muayenede EDSS puanınızı düzenli olarak kaydeder. Bu da, özürlülüğün ortaya çıkıp çıkmadığını ya da özürlülükte artış olup olmadığını görmeye yarar.&nbsp;</p>
            <p>EDSS puanı aynı zamanda çeşitli MS ilaçları için yapılan klinik deneylerde hangi hastaların yer alabileceğine karar verirken kullanılır. Örneğin geçmişte skoru 6.5’tan yüksek olan hastalar deneylerde yer almamıştır.&nbsp;</p>`,
        },
        {
          title: "EDSS Skoru Nasıl Hesaplanır?",
          content: `<p>EDSS skoru 0 ila 10 arasında yer alır. Bu skoru hesaplarken şunlar incelenir; kas zayıflığı, kol ve bacakları hareket ettirme gücü, denge, koordinasyon ve tremor, kontrol edilemeyen göz hareketleri, konuşma ve yutkunma, alışılmadık hassasiyet ve uyuşma, bağırsak ve idrar kesesi, görüş, düşünme ve hafıza, diğer fonksiyonlar.&nbsp;</p>
            <p>EDSS skoru genel olarak MS hastalığının yürümenizi nasıl etkilediğine odaklanır. Ayrıca, idrar kesesi, bağırsak, görüş ve denge, yutkunma ve konuşma zorluklarını da inceler. MS’in kaslarınızı, hafızanızı ve düşüncelerinizi nasıl etkilediğini de gözden geçirir.&nbsp;</p>
            <p>EDSS skoru MS’in ağrı, yorgunluk ve depresyon gibi görülemeyen semptomlarını göz önünde bulundurmaz. Ayrıca MS’in kol, el ve parmaklarınızı kullanmanızı ne derece etkilediğini de detaylı olarak incelemez.&nbsp;</p>`,
        },
        {
          title: "EDSS Skor Tablosu",
          content: `<p>Daha önce de belirtildiği üzere, EDSS skoru 0 ila 10 arasında yer alır. Her skorun ise farklı bir anlamı vardır.&nbsp;</p>
            <p>0.0 muayenede her şey normaldir</p>
            <p>1.0 özürlülük durumu yok, bir fonksiyonun normal olmadığına dair minimal belirtiler mevcut</p>
            <p>1.5 özürlülük durumu yok, birden fazla fonksiyonun normal olmadığına dair minimal belirtiler mevcut</p>
            <p>2.0 bir fonksiyonda minimal özürlülük mevcut</p>
            <p>2.5 bir fonksiyonda hafif özürlülük ya da iki fonksiyonda minimal özürlülük mevcut</p>
            <p>3.0 bir fonksiyonda orta derecede özürlülük ya da üç veya dört fonksiyonda hafif özürlülük mevcut. Yürümede problem yok</p>
            <p>3.5 bir fonksiyonda orta derece özürlülük ve diğer birkaç fonksiyonda hafif ya da minimal özürlülük mevcut. Yürümede problem yok.</p>
            <p>4.0 önemli seviyede özürlülük mevcut fakat 500 metre boyunca yardım almadan yürünebilir.</p>
            <p>4.5 önemli seviyede özürlülük mevcut fakat günün büyük bir bölümü ayakta geçirilir. Çalışırken yardıma ihtiyaç duyulabilir. 300 metre boyunca yardım almadan yürünebilir</p>
            <p>5.0 özürlülük günlük aktivitelerin önüne geçmektedir fakat 200 metre boyunca yardım almadan yürünebilir.</p>
            <p>5.5 özürlülük bütün günlük aktiviteleri engeller. 100 metre boyunca yardım almadan yürünebilir.</p>
            <p>6.0 100 metre boyunca baston ve koltuk değneği olmadan, dinlenerek veya dinlenmeden yürünebilir.</p>
            <p>6.5 iki adet baston ya da koltuk değneği yardımıyla 20 metre boyunca yürünebilir. Dinlenmeye gerek yoktur.</p>
            <p>7.0 tekerlekli sandalye kullanımı zorunludur fakat tüm gün aktif olunabilir. Yardım olmadan 5 metreden fazla yürünemez.&nbsp;</p>
            <p>7.5 sadece birkaç adım atılabilir. Tekerlekli sandalye kullanımı zorunludur, tekerlekli sandalyeye binerken ve inerken yardım gerekebilir. Motorlu tekerlekli sandalye gerekebilir.</p>
            <p>8.0 tekerlekli sandalye ya da yatakta olmak zorunludur. Günün çoğunluğu yataktan dışarıda geçirilebilir. Kollar kullanılabilir.</p>
            <p>8.5 günün çoğunluğu yatakta geçirilir. Kollar bir ölçüye kadar kullanılabilir.</p>
            <p>9.0 sürekli olarak yatakta durulur. Konuşulabilir, yemek yenilebilir, yutkunulabilir.</p>
            <p>9.5 yatak zorunludur. Konuşulamazi yemek yenilemez, yutkunulamaz.</p>
            <p>10 MS sebebiyle ölüm</p>`,
        },
      ],
      references: [
        "https://www.mssociety.org.uk/about-ms/treatments-and-therapies/getting-treatment-for-ms/expanded-disability-status-scale",
        "https://www.mstrust.org.uk/a-z/expanded-disability-status-scale-edss",
        "http://norolojiklinigi.info/edss/",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <div>
    <div class="mb-3 position-relative">
      <router-link :to="{ name: 'articledetail', params: { id: post.id } }">
        <img
          :src="post.image_url ?? post.thumbnail"
          alt=""
          class="img-fluid border-radius object-fit-cover"
        />
      </router-link>

      <save-icon
        class="mr-1"
        favoritable-type="post"
        :favoritable-id="post.id"
        :is-favorited="post.is_favorited"
        favorited-text="Kaydedildi"
        text="Kaydet"
        text-classes="font-s-10px font-w-500"
        :is-button="true"
        button-classes="
          btn btn-primary
          border-radius
          d-flex
          align-items-center
          justify-content-center
          position-absolute
          btn-article-save
          "
      />
    </div>

    <div class="d-flex mb-3">
      <div class="d-flex align-items-center mr-3">
        <article-icon :color="color" class="mr-1" />
        <span class="text-info font-s-13px">
          {{ theme == "eczazone" ? "Haber" : "Makale" }}
        </span>
      </div>
      <div class="d-flex align-items-center">
        <clock-icon :size="20" color="#AFBBDB" />
        <span class="gray-500 font-s-13px">{{ formattedDate }}</span>
      </div>
    </div>

    <router-link :to="{ name: 'articledetail', params: { id: post.id } }">
      <h3 class="font-s-18px font-w-600 black-700">
        {{ post.title }}
      </h3>
    </router-link>

    <div>
      <p class="text-secondary subtitle">
        {{ post.subtitle ?? post.summary }}
      </p>
    </div>

    <div class="d-flex align-items-center">
      <router-link :to="{ name: 'articledetail', params: { id: post.id } }">
        <span class="text-info font-s-12px">Detaylı Bilgi</span>
        <right-arrow-icon :color="color" />
      </router-link>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { computed, ref } from "@vue/reactivity";
import { ARTICLE_PUBLISHED_ON_FORMAT } from "../../shared/date-utils";
import { useStore } from "vuex";

export default {
  name: "ArticleSm",
  props: {
    post: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    dateFormat: {
      type: String,
      required: false,
      default: ARTICLE_PUBLISHED_ON_FORMAT,
    },
  },
  setup(props) {
    const store = useStore();
    const theme = ref(store.state.theme);
    const formattedDate = computed(() => {
      return dayjs(props.post.publish_on).locale("tr").format(props.dateFormat);
    });

    return {
      formattedDate,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

img {
  height: 200px;
  width: 100%;
}

.btn-article-save {
  top: 15px;
  left: 10px;
}

@media (max-width: 576px) {
  img {
    height: 153px !important;
  }
}
</style>

<template>
  <div>
    <section class="content-main bg-image d-flex flex-column">
      <content-main
        title="Yasal Uyarı"
        nav="Yasal Uyarı"
        class="mt-md-100px mt-160px"
      />
    </section>

    <section class="container-xl my-5">
      <div class="row">
        <div class="card-body">
          <p>
            Şu anda ziyaret etmekte olduğunuz internet sitesi ve içinde
            düzenlenmiş olan tüm eserler Santa Farma İlaç Sanayii A.Ş.'ye(Bundan
            sonra SANTA FARMA olarak anılacaktır) aittir. İşbu sitenin herhangi
            bir sayfasını ziyaret etmekle, aşağıda belirtilen ve yasal
            düzenlemelerle getirilen bütün kurallara koşulsuz uymayı taahhüt
            etmektesiniz. SANTA FARMA'nın söz konusu şartlarda, bildirimde
            bulunulmaksızın değişiklik yapma hakkı saklıdır, bu nedenle işbu
            internet sitesini her ziyaret edişinizde yasal uyarı bölümünü
            yeniden gözden geçirmenizi rica ederiz.
          </p>
          <p>
            1. Bu internet sitesi ve içeriği Türkiye Cumhuriyeti yasalarına ve
            yönetmeliklerine uygun bir şekilde hazırlanmıştır. Bu internet
            sitesi ve içeriği sadece Türkiye Cumhuriyetinde görev yapan Sağlık
            mensuplarının erişim sağlamaları ve kullanmaları amacı ile
            hazırlanmıştır. SANTA FARMA internet sitesinde yer alan bilgilerin
            kullanılabilirliği, doğruluğu ve eksiksizliği konusunda herhangi bir
            taahhütte bulunmamakta veya garanti vermemekte ve kanunen gerekli
            olan durumlar dışında bu bilgileri güncelleme yükümlülüğüne sahip
            olmadığını açıkça beyan etmektedir.
          </p>
          <p>
            2. SANTA FARMA ayrıca internet sitesinin içeriğindeki herhangi bir
            hataya veya eksikliğe ilişkin olarak herhangi bir yükümlülük veya
            sorumluluk üstlenmemektedir.
          </p>
          <p>
            3. Bu internet sitesi zaman zaman sağlık, tıp ve fiziksel zindelik
            ile ilgili çeşitli sorunlara ve bunların tedavilerine ilişkin
            bilgiler içerebilecektir. Bu bilgiler profesyonel bir sağlık uzmanı
            tarafından verilecek tavsiyelerin yerine kullanılmaları amacı ile
            sunulmamaktadır. Sağlık veya fiziksel zindelik ile ilgili sorunları
            ya da hastalıkları teşhis etmek için burada yer alan bilgileri
            kullanmamalısınız. Daima profesyonel bir sağlık uzmanına
            başvurulmalısınız. İnternet sitesini herhangi bir kısıtlamaya tabi
            olmaksızın inceleyebilirsiniz.
          </p>
          <p>
            4. İnternet sitesinden herhangi bir kısıtlamaya tabi olmaksızın
            materyal indirebilirsiniz. İndirdiğiniz her türlü materyali ticari
            olmayan kişisel amaçlarla kullanabilirsiniz. Ancak SANTA FARMA'nın
            açıkça verilmiş yazılı izni olmaksızın bu materyalleri dağıtamaz,
            üzerlerinde herhangi bir değişiklik yapamaz, iletemez, yeniden
            kullanamaz, yeniden yükleyemez ve kamusal ya da ticari amaçlar için
            kullanamazsınız. İndirdiğiniz materyallerde yer alan telif haklarına
            ve diğer mülkiyet haklarına ilişkin tüm beyanlar muhafaza
            edilmelidir.
          </p>
          <p>
            5. Aksi belirtilmediği takdirde, internet sitesinin tüm içeriğinin
            telif hakkına tabi olduğunu ve burada belirtilen koşulların dışında
            ve SANTA FARMA'nın açıkça verilmiş yazılı izni olmaksızın
            kullanılamayacağını kabul etmektesiniz. İnternet sitesinde
            görüntülenen kişilerin veya yerlerin resimleri SANTA FARMA'nın
            mülkiyetindedir veya izin alınmak suretiyle kullanılmaktadır.
          </p>
          <p>
            6. İnternet sitesinin Hüküm ve Koşullar bölümünde ya da diğer
            bölümlerinde açık bir şekilde izin verilmediği takdirde bu
            görüntülerin tarafınızdan kullanılması yasaktır. Bu görüntülerin
            izin alınmadan kullanılması telif hakkı, ticari marka, gizlilik
            ve/veya reklam ile ilgili yasaların yanı sıra iletişim ile ilgili
            yönetmeliklerin ve tüzüklerin ihlaline neden olabilecektir.
          </p>
          <p>
            7. İnternet sitesinin kullanımından kaynaklanabilecek riskler
            kullanan kişiye aittir. SANTA FARMA veya internet sitesinin
            oluşturulmasında, hazırlanmasında ya da kullanıma sunulmasında görev
            alan diğer kişi ve/veya kuruluşlar internet sitesine erişim
            sağlamanızdan veya internet sitesini kullanmanızdan ya da
            incelemenizden kaynaklanabilecek doğrudan, arızi, bu kullanımın
            sonucu olarak ortaya çıkan, dolaylı veya tazminat doğuran hiçbir
            zarardan kesinlikle sorumlu değildir. Bilgisayar ekipmanınızın veya
            diğer mal varlıklarınızın görebileceği hasarlar veya bunlara
            bulaşabilecek virüslerden kaynaklanabilecek hasarlar da bu kapsama
            dahildir.
          </p>
          <p>
            8. Her türlü veri, soru, yorum, öneri ve benzerleri de dahil olmak
            üzere elektronik posta yoluyla veya diğer yöntemlerle internet
            sitesine ilettiğiniz veya gönderdiğiniz her türlü ileti veya
            materyal gizli olmayan ve mülkiyet hakkı içermeyen bilgi niteliğine
            sahiptir ve bu şekilde değerlendirilecektir. SANTA FARMA, bu tür
            iletileri veya materyalleri, çoğaltma, ifşa, iletme, yayınlama,
            yayımlama ve benzeri diğer işlemler de dahil olmak ancak bunlarla
            sınırlı kalmamak kaydıyla herhangi bir amaç için kullanabilecektir.
            SANTA FARMA ayrıca, internet sitesine gönderdiğiniz her türlü
            iletide veya materyalde yer alan tüm fikirleri, kavramları, teknik
            bilgileri veya teknikleri, ürün geliştirme, imal etme ve pazarlama
            da dahil olmak ancak bunlarla sınırlı kalmamak kaydıyla, her türlü
            amaç için kullanmakta serbesttir.
          </p>
          <p>
            9. İnternet sitesinde görüntülenen ticari markalar, logolar ve
            hizmet markaları (topluca "Ticari Markalar") SANTA FARMA'nın ve
            diğer kişi ve/veya kuruluşların müseccel veya tescil edilmemiş
            ticari markalarıdır. Bu internet sitesinde yer alan hiçbir şey,
            SANTA FARMA ya da ilgili üçüncü şahıs tarafından açıkça verilmiş
            yazılı bir izin olmaksızın, söz konusu Ticari Markalar üzerinde
            zımni, hukuki engel yoluyla veya başka bir şekilde herhangi bir
            lisans veya hak verildiği şeklinde yorumlanmamalıdır. İnternet
            sitesinde ve içeriğinde yer alan Markaların, Hüküm ve Koşullar
            bölümünde açıkça belirtilen durumlar dışında, suiistimali kesinlikle
            yasaktır.
          </p>
          <p>
            10. SANTA FARMA, cezai yaptırım talebinde bulunmak da dahil olmak
            üzere, internet sitesi ve içeriği üzerindeki fikri mülkiyet
            haklarını, yasaların izin verdiği azami ölçüde koruyacak ve
            kullanacaktır. SANTA FARMA bu internet sitesi ile bağlantı kurmuş
            olabilecek diğer internet sitelerinin tümünü incelememiştir ve bu
            internet sitesi ile bağlantı kurmuş olabilecek, site dışı internet
            sayfalarının veya diğer internet sitelerinin içeriğinden sorumlu
            değildir. Bu internet sitesinde verilen bağlantılar yoluyla bu
            internet sitesinin dışındaki herhangi bir internet sayfasına veya
            internet sitesine erişim sağlamanızdan kaynaklanabilecek riskler
            tamamen size aittir. Bu internet sitesinde verilen bağlantılar
            yoluyla başka bir internet sitesine erişim sağladığınızda, lütfen
            erişim sağladığınız bu internet sitesinin yasal bildirimlerini ve
            gizlilik politikasını inceleyiniz. Bu yasal bildirimler ve gizlilik
            politikaları bu internet sitesinde öngörülenlerden farklı
            olabilecektir.
          </p>
          <p>
            11. SANTA FARMA internet sitesinde yer alan tartışmaları,
            sohbetleri, mesajları, iletileri ve benzerlerini zaman zaman izlese
            veya incelese dahi, SANTA FARMA yasalar veya yönetmelikler
            tarafından öngörülen durumlar dışında, bunları izlemekle veya
            incelemekle hiçbir şekilde yükümlü değildir. SANTA FARMA internet
            sitesinin içeriğinden veya internet sitesinde sunulan bilgilerde yer
            alabilecek herhangi bir hataya, hakarete, iftiraya, karalamaya,
            noksanlığa, yalana, müstehcenliğe, pornografik malzemeye, küfre,
            tehlikeye veya kusura ilişkin olarak herhangi bir sorumluluk veya
            yükümlülük üstlenmemektedir.
          </p>
          <p>
            12. Kanuna aykırı, tehditkar, hakaret içeren, iftira içerikli,
            müstehcen, kışkırtıcı, pornografik veya küfür içeren materyalleri
            veya suç sayılabilecek davranışlar teşkil eden veya bu tür
            davranışlara teşvik eden, hukuki sorumluluk doğuran veya kanuna
            aykırı herhangi bir materyali gönder-meniz veya iletmeniz yasaktır.
            SANTA FARMA bu tür bilgileri veya materyalleri gönderen kişilerin
            kimliğini açıklamasını talep eden veya emreden icra makamları ile
            mutlak bir işbirliğinde bulunacak ve bu konudaki mahkeme emirlerine
            tamamen uyacaktır.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import { useStore } from "vuex";

export default {
  components: {
    ContentMain,
  },
  setup() {
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    return {
      main_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}
</style>

<template>
  <div v-if="channel" class="channel-detail mb-100px">
    <section
      class="channel-detail-main bg-image d-flex flex-column align-items-center"
    >
      <div
        class="
          d-flex
          flex-column
          align-items-center
          mt-md-100px mt-160px
          max-w-390px
        "
      >
        <img
          :src="channel.thumbnail"
          alt=""
          class="person-img object-fit-cover border-radius-50 mb-3"
        />
        <h1 class="font-s-30px font-weight-bold text-white text-center">
          {{ channel.title }}
        </h1>
        <p class="font-s-12px text-white text-center mb-4 px-5 px-md-0">
          {{ channel.description }}
        </p>
        <button
          v-if="!channel.is_subscribed"
          :disabled="isSubscribing"
          class="btn btn-light border-radius person-btn mb-3"
          @click="subscribe"
        >
          <notification-icon :color="color" :size="16" class="mr-2" />
          <span class="text-primary font-s-12px font-w-500"
            >Kanala Abone Ol!</span
          >
        </button>
        <button
          v-else
          :disabled="isSubscribing"
          class="btn btn-light border-radius person-btn mb-3"
          @click="unsubscribe"
          @mouseover="handleUnsubcribeHover(true)"
          @mouseleave="handleUnsubcribeHover(false)"
        >
          <notification-icon color="#1686D6" :size="16" class="mr-1" />
          <span class="font-s-12px font-w-500">{{ unsubscribeText }}</span>
        </button>
        <div class="d-none d-md-block font-s-12px">
          <router-link :to="{ name: 'home' }" class="blue-300">
            Ana Sayfa
          </router-link>
          <span class="letter-spacing-m-4-5 blue-300 ml-1 mr-2">>></span>
          <router-link :to="{ name: 'videos' }" class="blue-300">
            Videolar
          </router-link>
          <span class="letter-spacing-m-4-5 blue-300 ml-1 mr-2">>></span>
          <router-link :to="{ name: 'channels' }" class="blue-300">
            Kanallar
          </router-link>
          <span class="letter-spacing-m-4-5 text-white ml-1 mr-2">>></span>
          <span class="text-white">{{ channel.title }}</span>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div
          class="
            mt-5
            d-flex
            justify-content-between
            justify-content-sm-center
            justify-content-xl-start
          "
        >
          <button
            :class="{ active: showNavBtn === 0 }"
            :disabled="!recentVideos"
            class="
              font-s-12px
              btn
              gray-500
              mr-sm-3
              mb-4
              border-radius
              btn-channel
            "
            @click="
              changeTab(0);
              changeShowNavBtn(0);
            "
          >
            Tüm Videolar
          </button>
          <button
            :class="{ active: showNavBtn === 1 }"
            :disabled="!popularVideos"
            class="
              font-s-12px
              btn
              gray-500
              mr-sm-3
              mb-4
              border-radius
              btn-channel
            "
            @click="
              changeTab(1);
              changeShowNavBtn(1);
            "
          >
            En Çok İzlenen Videolar
          </button>
        </div>

        <div v-if="tab === 0 && recentVideos">
          <div
            class="
              d-flex
              flex-wrap
              justify-content-center justify-content-xl-between
            "
          >
            <big-video-card
              v-for="(video, index) in recentVideos.items"
              :key="index"
              :video="video"
            />
          </div>
          <pagination
            :disabled="loading"
            :total-pages="recentVideos.last_page"
            :current-page="recentVideos.current_page"
            @go-page="fetchChannelRecentVideos(id, $event)"
          />
        </div>

        <div v-else-if="tab === 1 && popularVideos">
          <div
            class="
              d-flex
              flex-wrap
              justify-content-center justify-content-xl-between
            "
          >
            <big-video-card
              v-for="(video, index) in popularVideos.items"
              :key="index"
              :video="video"
            />
          </div>
          <pagination
            :disabled="loading"
            :total-pages="popularVideos.last_page"
            :current-page="popularVideos.current_page"
            @go-page="fetchChannelPopularVideos(id, $event)"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import VideoCategoryService from "../services/video/VideoCategoryService";
import SubscriptionService from "../services/subscription/SubscriptionService";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { watch } from "@vue/runtime-core";
import BigVideoCard from "../components/ui/videos/BigVideoCard.vue";
import Pagination from "../components/ui/Pagination.vue";
import dayjs from "dayjs";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  name: "ChannelDetail",
  components: {
    BigVideoCard,
    Pagination,
  },
  setup() {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueDark;
    const mainColor = ColorHelper[store.state.theme].channelDetailMain;
    const img_url = "url(/new-img/main-slider.png)";
    const channel = ref(null);
    const route = useRoute();
    const router = useRouter();
    const isSubscribing = ref(false);
    const unsubscribeText = ref("Kanala Abonesin");
    const popularVideos = ref(null);
    const recentVideos = ref(null);
    const tab = ref(0);
    const loading = ref(false);

    const changeTab = (tabNumber) => {
      tab.value = tabNumber;
    };

    const fetchChannel = (channelId) => {
      loading.value = true;

      VideoCategoryService.getVideoCategory(channelId)
        .then((response) => {
          channel.value = response.data.data;
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            router.push({ name: "404" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const fetchChannelRecentVideos = (channelId, page = 1) => {
      loading.value = true;

      VideoCategoryService.getCategoriesRecentVideos(channelId, page, 9)
        .then((response) => {
          recentVideos.value = response.data.data;
          recentVideos.value.items = response.data.data.items.map((video) => {
            return {
              ...video,
              publish_on: dayjs(video.publish_on)
                .locale("tr")
                .format("DD MMMM YYYY"),
            };
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const fetchChannelPopularVideos = (channelId, page = 1) => {
      loading.value = true;

      VideoCategoryService.getCategoriesPopularVideos(channelId, page, 9)
        .then((response) => {
          popularVideos.value = response.data.data;

          popularVideos.value.items = response.data.data.items.map((video) => {
            return {
              ...video,
              publish_on: dayjs(video.publish_on)
                .locale("tr")
                .format("DD MMMM YYYY"),
            };
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const subscribe = () => {
      isSubscribing.value = true;
      channel.value.is_subscribed = true;
      SubscriptionService.subscribe("video_category", channel.value.id)
        .catch(() => {
          channel.value.is_subscribed = false;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    const unsubscribe = () => {
      isSubscribing.value = true;
      channel.value.is_subscribed = false;
      SubscriptionService.unsubscribe("video_category", channel.value.id)
        .catch(() => {
          channel.value.is_subscribed = true;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    const handleUnsubcribeHover = (isHover) => {
      if (isHover) {
        unsubscribeText.value = "Abonelikten Çık";
      } else {
        unsubscribeText.value = "Kanala Abonesin";
      }
    };

    const fetchData = (id) => {
      Axios.all([
        fetchChannel(id),
        fetchChannelRecentVideos(id),
        fetchChannelPopularVideos(id),
      ]);
    };

    if (route.params.id) {
      fetchData(route.params.id);
    }

    onBeforeRouteUpdate((to) => {
      fetchData(to.params.id);
    });

    watch(
      loading,
      (newVal) => {
        store.commit("setLoading", newVal);
      },
      { immediate: true }
    );

    return {
      id: route.params.id,
      channel,
      unsubscribeText,
      isSubscribing,
      subscribe,
      unsubscribe,
      handleUnsubcribeHover,
      recentVideos,
      popularVideos,
      tab,
      changeTab,
      color,
      mainColor,
      img_url,
      loading,
      fetchChannelRecentVideos,
      fetchChannelPopularVideos,
    };
  },
  data() {
    return {
      showNavBtn: 0,
    };
  },
  methods: {
    changeShowNavBtn(id) {
      this.showNavBtn = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-detail-main {
  height: 550px;
  background: linear-gradient(0deg, v-bind("mainColor"), v-bind("mainColor")),
    v-bind("img_url");
}

.btn-channel {
  background-color: #f3f4f5;
  padding: 13px 32px;

  &.active {
    background-color: v-bind("color") !important;
    color: white !important;
    box-shadow: none !important;
  }
}

.person-img {
  width: 125px;
  height: 125px;
}

.person-btn {
  width: 185px;
  height: 48px;
}

@media (max-width: 768px) {
  .channel-detail-main {
    height: 340px;

    .person-img {
      width: 68px;
      height: 68px;
    }
    h1 {
      font-size: 18px !important ;
    }
    p {
      font-size: 10px !important;
    }
    .person-btn {
      width: 165px;
      height: 35px;
    }
  }

  .btn-channel {
    font-size: 10px;
    padding: 10px !important;
  }
}
</style>

<template>
  <div class="products mb-100px">
    <section class="products-main bg-image d-flex flex-column mb-75px">
      <content-main title="Ürünler" nav="Ürünler" class="mt-160px" />
      <product-search-input />
    </section>

    <section class="mb-5">
      <div class="container-xl">
        <h4 class="font-s-24px text-secondary mb-5">
          <b class="text-info">Terapötik</b> Kategoriler
        </h4>

        <div v-if="!categoryPromise.loading.value" class="row">
          <div
            v-for="item in categoryPromise.results.value"
            :key="item.slug"
            class="col-6 col-sm-4 col-md-3 mb-5"
          >
            <product-category-card :product-category="item" />
          </div>
        </div>
        <loading-anim-icon v-else />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <h4 class="font-s-24px text-secondary mb-5">
          <b class="text-info">Yeni</b> Ürünler
        </h4>

        <div
          v-if="!newProductPromise.loading.value"
          class="d-flex justify-content-between"
        >
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div
                v-for="(newProduct, index) in newProductPromise.results.value"
                :key="index"
                class="swiper-slide"
              >
                <product-card :product="newProduct" />
              </div>
            </div>
            <div class="swiper-pagination" />
          </div>
        </div>
        <loading-anim-icon v-else />
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import Swiper from "swiper";
import ContentMain from "../components/ui/ContentMain.vue";
import ProductCard from "../components/ui/ProductCard.vue";
import { useStore } from "vuex";
import usePromise from "../shared/composables/use-promise";
import ProductCategoryService from "../services/product/ProductCategoryService";
import ProductCategoryCard from "../components/ui/products/ProductCategoryCard.vue";
import ProductService from "../services/product/ProductService";
import ProductSearchInput from "../components/ui/products/ProductSearchInput.vue";
import LoadingAnimIcon from "../components/icon/LoadingAnimIcon.vue";

export default {
  name: "Products",
  components: {
    ContentMain,
    ProductCard,
    ProductCategoryCard,
    ProductSearchInput,
    LoadingAnimIcon,
  },
  setup() {
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    const categoryPromise = usePromise(() =>
      ProductCategoryService.getCategories()
    );

    const newProductPromise = usePromise(() => ProductService.getNewProducts());

    onMounted(async () => {
      new Swiper(".mySwiper", {
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 20,
        on: {
          init: await newProductPromise.createPromise(),
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          400: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      });
    });

    categoryPromise.createPromise();

    return {
      categoryPromise,
      newProductPromise,
      main_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.products-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 354px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  height: 296px;
}

.swiper-container {
  height: 350px;
}
</style>

<template>
  <div v-if="channel" class="channel-detail mb-100px">
    <section
      class="channel-detail-main bg-image d-flex flex-column align-items-center"
    >
      <div
        class="
          d-flex
          flex-column
          align-items-center
          mt-md-100px mt-160px
          max-w-390px
        "
      >
        <img
          :src="channel.thumbnail"
          alt=""
          class="person-img object-fit-cover border-radius-50 mb-3"
        />
        <h1 class="font-s-30px font-weight-bold text-white text-center">
          {{ channel.title }}
        </h1>
        <p class="font-s-12px text-white text-center mb-4 px-5 px-md-0">
          {{ channel.description }}
        </p>
        <button
          v-if="!channel.is_subscribed"
          :disabled="isSubscribing"
          class="btn btn-light border-radius person-btn mb-3"
          @click="subscribe"
        >
          <notification-icon :color="color" :size="16" class="mr-2" />
          <span class="text-primary font-s-12px font-w-500"
            >Kanala Abone Ol!</span
          >
        </button>
        <button
          v-else
          :disabled="isSubscribing"
          class="btn btn-light border-radius person-btn mb-3"
          @click="unsubscribe"
          @mouseover="handleUnsubcribeHover(true)"
          @mouseleave="handleUnsubcribeHover(false)"
        >
          <notification-icon color="#1686D6" :size="16" class="mr-1" />
          <span class="font-s-12px font-w-500">{{ unsubscribeText }}</span>
        </button>
        <div class="d-none d-md-block font-s-12px">
          <router-link :to="{ name: 'home' }" class="blue-300">
            Ana Sayfa
          </router-link>
          <span class="letter-spacing-m-4-5 blue-300 ml-1 mr-2">>></span>
          <router-link :to="{ name: 'videos' }" class="blue-300">
            Videolar
          </router-link>
          <span class="letter-spacing-m-4-5 blue-300 ml-1 mr-2">>></span>
          <router-link :to="{ name: 'channels' }" class="blue-300">
            Kanallar
          </router-link>
          <span class="letter-spacing-m-4-5 text-white ml-1 mr-2">>></span>
          <span class="text-white">{{ channel.title }}</span>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div
          class="mt-5 d-flex justify-content-center justify-content-md-start"
        >
          <button
            :class="{ active: activeBtn === 0 }"
            class="font-s-12px btn gray-500 mr-3 mb-4 border-radius btn-channel"
            @click="changeBtn(0)"
          >
            Tüm Podcastler
          </button>
          <button
            :class="{ active: activeBtn === 1 }"
            class="font-s-12px btn gray-500 mr-3 mb-4 border-radius btn-channel"
            @click="changeBtn(1)"
          >
            En Çok Dinlenen Podcastler
          </button>
        </div>

        <div
          v-if="activeBtn === 0"
          class="
            d-flex
            flex-column
            justify-content-center justify-content-xl-between
          "
        >
          <podcast-card
            v-for="(podcast, index) in podcasts"
            :key="index"
            :podcast="podcast"
            :index="index"
          />
        </div>
        <div
          v-else-if="activeBtn === 1"
          class="
            d-flex
            flex-column
            justify-content-center justify-content-xl-between
          "
        >
          <podcast-card
            v-for="(podcast, index) in podcasts"
            :key="index"
            :podcast="podcast"
            :index="index"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import PodcastChannelService from "../services/podcast/PodcastChannelService";
import SubscriptionService from "../services/subscription/SubscriptionService";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import PodcastService from "../services/podcast/PodcastService";
import PodcastCard from "../components/ui/PodcastCard.vue";

export default {
  name: "PodcastChannelDetail",
  components: {
    PodcastCard,
  },
  setup() {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueDark;
    const mainColor = ColorHelper[store.state.theme].channelDetailMain;
    const img_url = "url(/new-img/main-slider.png)";
    const isPodcastsLoading = ref(false);
    const podcastService = new PodcastService();
    const podcasts = ref([]);
    const channel = ref(null);
    const route = useRoute();
    const router = useRouter();
    const isSubscribing = ref(false);
    const unsubscribeText = ref("Kanala Abonesin");
    const activeBtn = ref(0);

    const podcastChannelService = new PodcastChannelService();

    const fetchChannel = (channelId) => {
      store.commit("setLoading", true);
      podcastChannelService
        .first(channelId, "id")
        .then((response) => {
          channel.value = response;
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            router.push({ name: "404" });
          }
        })
        .finally(() => {
          store.commit("setLoading", false);
        });
    };

    const changeBtn = (id) => {
      activeBtn.value = id;
      switch (id) {
        case 0:
          fetchPodcasts();
          break;
        case 1:
          fetchPodcasts(["listen_count"]);
          break;
        default:
          break;
      }
    };

    const fetchPodcasts = (sorts = ["-publish_on"]) => {
      isPodcastsLoading.value = true;
      store.commit("setLoading", true);
      podcastService
        .all({
          filters: { is_active: [true], channel_id: [route.params.id] },
          includes: ["channel"],
          sorts,
        })
        .then((response) => {
          podcasts.value = response;
        })
        .finally(() => {
          isPodcastsLoading.value = false;
          store.commit("setLoading", false);
        });
    };

    const subscribe = () => {
      isSubscribing.value = true;
      channel.value.is_subscribed = true;
      SubscriptionService.subscribe("podcast_channel", channel.value.id)
        .catch(() => {
          channel.value.is_subscribed = false;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    const unsubscribe = () => {
      isSubscribing.value = true;
      channel.value.is_subscribed = false;
      SubscriptionService.unsubscribe("podcast_channel", channel.value.id)
        .catch(() => {
          channel.value.is_subscribed = true;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    const handleUnsubcribeHover = (isHover) => {
      if (isHover) {
        unsubscribeText.value = "Abonelikten Çık";
      } else {
        unsubscribeText.value = "Kanala Abonesin";
      }
    };

    if (route.params.id) {
      fetchChannel(route.params.id);
      fetchPodcasts();
    }

    onBeforeRouteUpdate((to) => {
      fetchChannel(to.params.id);
      fetchPodcasts();
    });

    return {
      channel,
      unsubscribeText,
      isSubscribing,
      subscribe,
      unsubscribe,
      handleUnsubcribeHover,
      changeBtn,
      color,
      mainColor,
      img_url,
      fetchPodcasts,
      activeBtn,
      podcasts,
    };
  },
  data() {
    return {
      showNavBtn: 0,
    };
  },
  methods: {
    changeShowNavBtn(id) {
      this.showNavBtn = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-detail-main {
  height: 550px;
  background: linear-gradient(0deg, v-bind("mainColor"), v-bind("mainColor")),
    v-bind("img_url");
}

.btn-channel {
  background-color: #f3f4f5;
  padding: 13px 32px;

  &.active {
    background-color: v-bind("color") !important;
    color: white !important;
    box-shadow: none !important;
  }
}

.person-img {
  width: 125px;
  height: 125px;
}

.person-btn {
  width: 185px;
  height: 48px;
}

@media (max-width: 768px) {
  .channel-detail-main {
    height: 340px;

    .person-img {
      width: 68px;
      height: 68px;
    }
    h1 {
      font-size: 18px !important ;
    }
    p {
      font-size: 10px !important;
    }
    .person-btn {
      width: 165px;
      height: 35px;
    }
  }

  .btn-channel {
    font-size: 10px;
    padding: 10px !important;
  }
}
</style>

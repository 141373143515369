<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="Kreatinin Klirensi Hesaplama Aracı"
        nav="Kreatinin Klirensi Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-30px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="bet"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Cinsiyet</h5>
                <select
                  name="gender"
                  class="
                    form-control
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    gender
                  "
                >
                  <option selected disabled>Lütfen Seçiniz</option>
                  <option value="2">Erkek</option>
                  <option value="1">Kadın</option>
                </select>
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Serum Kreatinini (mg/dL)
                </h5>
                <input
                  type="text"
                  class="
                    form-control
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="age"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Yaş</h5>
                <input
                  type="text"
                  class="
                    form-control
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="kilo"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Kilo(kg)</h5>
                <input
                  type="text"
                  class="
                    form-control
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="skn"
                  maxlength="5"
                  max="5"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius btn-calculate"
              @click="showResult = true"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "CreatineScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    $(document).ready(function () {
      $(".btn-calculate").on("click", function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
        $(".return-text").slideDown();
        let form = $("#bet");
        let error = 0;
        form.find(".is-invalid").removeClass("is-invalid");
        let gender = form.find('select[name="gender"]').val();
        let kilo = form.find('input[name="kilo"]').val();
        let age = form.find('input[name="age"]').val();
        let skn = form.find('input[name="skn"]').val();
        if (!gender) {
          form.find('select[name="gender"]').addClass("is-invalid");
          error++;
        }
        if (!kilo) {
          form.find('input[name="kilo"]').addClass("is-invalid");
          error++;
        }
        if (!age) {
          form.find('input[name="age"]').addClass("is-invalid");
          error++;
        }
        if (!skn) {
          form.find('input[name="skn"]').addClass("is-invalid");
          error++;
        }
        if (error) {
          $(".return-text").html("Hesaplama Sonucu: Eksik alanlar mevcut");
          return false;
        }
        let f = ((140 - age) * kilo) / (72 * skn);
        if (age < 3) {
          f = f * 0.45;
        } else if (
          ((age < 14) & (gender == 2)) |
          ((age < 17) & (gender == 1))
        ) {
          f = f * 0.55;
        } else if ((age > 13) | (age < 17) | (gender == 1)) {
          f = f * 0.7;
        } else if (gender == 2) {
          f = f * 0.85;
        }
        returnData(parseFloat(f.toFixed(2)), null, "mL/dak");
      });
    });

    function clearAll() {
      $("form")[0].reset();
      $(".return-text").slideUp();
    }

    $(document).ready(function () {
      $("form").submit(function (event) {
        event.preventDefault();
        $(".btn-calculate").click();
      });
    });

    function returnData(data = null, string = null, type = "") {
      if (!data || data < 0) {
        data = -1;
      } else {
        data = parseFloat(data);
      }
      if (!string) {
        string = -1;
      }
      if (data == -1 && string == -1) {
        string = "-";
      }
      if (type) {
        if (data > 0) {
          $(".return-number").html(data);
          $(".return-text").html(type);
        } else {
          $(".return-text").html(type);
        }
      } else {
        if (data > 0) {
          $(".return-number").html(data);
        }
      }
    }

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      clearAll,
      apgar: [
        {
          title: "Kreatinin Klirensi Hesaplama",
          content: `<p>Böbreklerdeki kreatinin temizlediği kan miktarına kreatinin klirensi denir. Kreatinin klirensini hesaplama ise, kan ve idrarda bulunan kreatin miktarına göre değişir. Klirens testi, dakika bazında kreatinle temizlenen plazma miktarını gösterir. Bu test genellikle şüpheli böbrek hastalıklarında ve hastalıkların şiddetini ölçmede kullanılır. Hesaplama sonuçlarına göre böbrek fonksiyonlarının işlevini yerine getirip getirmediği kontrol edilir.</p>
        `,
        },
        {
          title: "Kreatinin Klirensi Nasıl Hesaplanır?",
          content: `<p>Hesaplama yapılırken serum kreatinini, cinsiyet, yaş ve kilo baz alınır. Yakın zamanda yapılan testlerdeki kreatinin değeri biliniyorsa, hesaplama internet üzerinden de yapılabilir. Ayrıca kreatinin gfr hesaplaması da yapılarak daha verimli sonuçlar alınır. Egfr hesaplamasının normal değeri 90 ile 120 mililitre aralığında olmalıdır. Yaşlılarda kreatin sayımı azaldığı için, bu değer daha düşük çıkabilir.</p>
        `,
        },
        {
          title: "Gfr Testi Ne Zaman Yaptırılmalıdır?",
          content: `
          <p>Gfr testi genel sağlık taramalarında her yıl yaptırılabilir. Bunun dışında hasta veya doktorun böbrek hastalıklarından şüphelenmesi durumunda gfr testi istenir. Genel bilgilerle birlikte koldan alınan kan numunesi ile test sonuçları değerlendirilir. Pişmiş et yemek kreatinin seviyesini geçici olarak yükselttiği için, kan alınmadan önce tüketilmemesi gerekir. Son bir ay içerisinde kreatinin değerini ölçtürdüyseniz gfr hesaplamasını internet üzerinden yapabilirsiniz.</p>
          `,
        },
        {
          title: "Kreatinin Klirensi Normal Değerleri Nedir?",
          content: ` <p>Kreatinin klirensi sağlıklı insanlarda cinsiyet, yaş ve kilo gibi unsurlara bağlı olarak değişir. Kandaki kreatinin değeri; kadınlarda normal değer 95, erkeklerde ise 120 mililitredir. İdrardaki kreatinin değeri ise; kadınlarda 85-110, erkeklerde 105-140 mililitre aralığındadır. Kreatinin klirensi testi böbreklerdeki gfr oranını da belirler. Böbreklerin performansını ve böbrek hastalıklarının şiddetini değerlendirmek amacıyla yapılan gfr testi, kreatinin ile bağlantılıdır. Egfr hesaplaması yapabilmek için, kreatin sayımının bilinmesi gerekir. Böbreklerdeki kreatinin sayımı 30 yaş itibariyle azalmaya başlar. Bu da yetişkin bireylerde böbrek hastalığı riskinin daha fazla olduğunu gösterir.</p>

        `,
        },
        {
          title: "Kreatinin Klirensi Yüksekliği ve Nedenleri",
          content: ` <p>Kreatinin klirensinin yükselmesi böbrek fonksiyonlarında bir sorun olduğunu gösterir. Kreatinin yükselmesindeki başlıca nedenler;</p>

            <ul>
                <li>Ağır spor antrenmanları,</li>
                <li>Aşırı et tüketimi,</li>
                <li>İlaçların yan etkisi,</li>
                <li>İdrar yolu tıkanıklığı,</li>
                <li>Vücudun yeterince su almaması,</li>
                <li>Hamilelikte yaşanan problemler,</li>
                <li>Böbrek hasarı ve enfeksiyonu,</li>
                <li>Böbreklere giden kan akışında azalmalar görülmesi,</li>
                <li>Böbrek yetmezliğidir.</li>
            </ul>

            <p>Böbrekteki kreatinin yükseldiğinde; güçsüzlük, yorgunluk, nefes darlığı ve susuzluk gibi belirtiler görülür. Kreatinin yükselmesine diyabet ve tansiyon hastalarında daha fazla rastlanır. Doğru tedaviyi bulabilmek için kan ve idrar testi yapılır. Şüphelenilen bir hastalık varsa, röntgen gibi görüntüleme teknikleri kullanılır. Test sonuçları değerlendirildikten sonra uygun teşhis konulup tedaviye başlanır. Kreatinin seviyesinin yükselmemesi için, susuz kalınmamalı ve et tüketimi ölçülü bir seviyede olmalıdır.</p>
            <hr>
            <p>Kreatinin klirensi değerinin düşmesindeki başlıca nedenler;</p>

            <ul>
                <li>Kas hastalıkları</li>
                <li>Su Kaybı</li>
                <li>Böbrek enfeksiyonu</li>
                <li>Böbreklerin hasar görmesi</li>
                <li>Mesane çıkışında tıkanıklık oluşması</li>
                <li>Böbrek yetmezliği</li>
                <li>Kanser</li>
                <li>Kalp yetmezliğidir</li>
            </ul>

            <p>Kreatinin klirensi düşüklüğünde çok fazla belirti gözlemlenmese de, hastalığa bağlı olarak kusma, iştahsızlık ve halsizlik gibi sorunlar yaşanabilir. Kreatinin düşüklüğü olan hastalarda doğru teşhis konulabilmesi için; idrar, kan ve gfr testi yapılır. Test sonuçlarına göre hasta için uygun tedavi yöntemlerine başvurulur. Böbrek hastalıklarında erken teşhisin önemi oldukça büyüktür. Doğru tedavinin uygulanabilmesi için, kreatinin ve gfr testi mutlaka yaptırılmalıdır</p>
        `,
        },
        {
          title: "Kreatinin Klirens Değerlendirme [1]",
          content: ` <p>Yetişkinler için serum kreatinin normal değeri, erkeklerde 0.50 mg/dL ile 1.40 mg/dL iken, kadınlarda 0.50 mg/dL ile 1.30 mg/dL'dir. Kreatinin klirensi olarak tanımlanan ve 24 saatlik idrarın toplanması ile yapılan testin referans aralığı aşağıdaki gibidir</small></p>

            <ul>
                <li>13-50 yaş: Erkeklerde 90-137 mL/dk. Kadınlarda 80-125 mL/dk</li>
                <li>51-60 yaş: Erkeklerde 85-132 mL/dk. Kadınlarda 75-120 mL/dk</li>
                <li>60 yaş ve üzeri: Erkeklerde 80-132 mL/dk. Kadınlarda 70-120 mL/dk</li>
            </ul>
`,
        },
      ],
      references: ["https://www.medicalpark.com.tr/kreatinin/hg-2177"],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.7652 62.4816C63.7652 70.4536 58.3332 76.0856 49.6452 76.0856H24.7772C16.0972 76.0856 10.7012 70.4536 10.7012 62.4816V34.6416C10.7012 26.6736 16.0972 21.0016 24.8212 21.0016H49.6452C58.3332 21.0016 63.7652 26.6736 63.7652 34.6416V62.4816Z"
      stroke="#7684AA"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.7656 45.1064L80.1536 31.6904C82.7536 29.5504 86.7016 31.4104 86.7016 34.7984V62.2864C86.7016 65.6784 82.7536 67.5304 80.1536 65.3944L63.7656 51.9784"
      stroke="#7684AA"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "VideoImg",
};
</script>

<style></style>

<template>
  <div
    v-if="thread"
    class="d-flex justify-content-between py-3 px-4 cursor-pointer"
    @click="$emit('click', thread)"
  >
    <div>
      <img
        :src="getProfilePicture(contactPeople[0])"
        alt=""
        class="person-img border-radius-50 mr-2"
      />
      <span class="font-s-12px text-info">{{
        contactPeople.map((p) => p.fullname).join(", ")
      }}</span>
    </div>
    <div>
      <right-arrow-icon :color="color" />
    </div>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { getProfilePicture } from "../../shared/utils";
import { child, onValue } from "firebase/database";
import { threadsDocRef } from "../../services/FirebaseChatService";

export default {
  name: "MessagesThread",
  props: {
    threadId: {
      type: Number,
      required: true,
    },
  },
  emits: ["click"],
  setup(props) {
    const store = useStore();
    const thread = ref(null);
    const user = computed(() => store.state.user.user);
    const threadRef = child(threadsDocRef, props.threadId.toString());

    onValue(threadRef, (snapshot) => {
      thread.value = snapshot.val();
    });

    const contactPeople = computed(() => {
      if (!thread.value || !thread.value.participants) return {};
      return thread.value.participants.filter(
        (participant) => participant.id !== user.value.id
      );
    });

    return {
      color: ColorHelper[store.state.theme].blueText,
      thread,
      getProfilePicture,
      contactPeople,
    };
  },
};
</script>

<style lang="scss" scoped>
.person-img {
  width: 28px;
  height: 28px;
}

.person-img-large {
  width: 50px;
  height: 50px;
}
</style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getLatestSurvey() {
    return apiClient.get("/api/surveys/published/last");
  },
  sendSurveyAnswer(survey_id, user_id, answers) {
    return apiClient.post(
      `/api/surveys/${survey_id}/users/${user_id}/answers`,
      {
        questions: answers,
      }
    );
  },
  getSurveyAnswers(survey_id, user_id) {
    return apiClient.get(`/api/surveys/${survey_id}/users/${user_id}`);
  },
};

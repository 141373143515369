<template>
  <div
    class="
      h-100
      calculate-card
      d-flex
      flex-column
      align-items-center
      justify-content-between
      box-shadow-4-20-3
      py-4
      px-3
    "
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="calculate-card-img mb-2" v-html="calculation.web_icon" />
    <h3 class="font-s-22px font-w-600 gray-500 text-center">
      {{ calculation.web_title }}
    </h3>
    <h5 class="font-s-12px font-w-500 gray-300">
      {{ calculation.subtitle }}
    </h5>
    <!-- <p class="font-s-12px gray-500 text-center">{{ calculation.subtitle }}</p> -->
    <router-link
      :to="{ name: calculation.segments.slug }"
      class="
        btn
        bg-gray-500
        text-white
        border-radius
        d-flex
        justify-content-center
        align-items-center
        w-100
      "
    >
      <span class="font-s-12px mr-1">Hesaplama Aracı</span>
      <right-arrow-icon color="white" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CalculateCard",
  components: {},
  props: {
    calculation: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.calculate-card-img {
  width: 96px;
  height: 96px;
}

.calculate-card {
  &:hover h3,
  &:hover h5 {
    color: v-bind("color");
  }

  &:hover a {
    background-color: v-bind("color");
  }
}
</style>

<template>
  <svg
    width="12"
    height="21"
    viewBox="0 0 12 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7427 11.7364L11.3141 8.13963H7.77129V5.77928C7.77129 4.7677 8.28557 3.81232 9.82843 3.81232H11.4284V0.721378C11.4284 0.721378 9.94272 0.496582 8.57129 0.496582C5.71415 0.496582 3.82843 2.23875 3.82843 5.38589V8.13963H0.571289V11.7364H3.82843V20.5034H7.82843V11.7364H10.7427Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "FacebookIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

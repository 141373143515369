<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-normal font-s-24px mb-4 d-none d-md-block">
      Bilgilendirme <b class="text-info">Ayarları</b>
    </h4>

    <div class="d-flex flex-column mb-4">
      <span class="font-s-12px font-weight-bold text-info mb-2">Arama</span>
      <div class="d-flex">
        <div class="d-flex align-items-baseline mr-3">
          <input id="call-1" type="radio" class="mr-1" name="call" />
          <label class="text-secondary font-s-12px gray-500" for="call-1"
            >Ayda 1</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="call-2" type="radio" class="mr-1" name="call" />
          <label class="text-secondary font-s-12px gray-500" for="call-2"
            >Haftada 3</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="call-3" type="radio" class="mr-1" name="call" />
          <label class="text-secondary font-s-12px gray-500" for="call-3"
            >Haftada 2</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="call-4" type="radio" class="mr-1" name="call" />
          <label class="text-secondary font-s-12px gray-500" for="call-4"
            >İstemiyorum</label
          >
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mb-4">
      <span class="font-s-12px font-weight-bold text-info mb-2">Sms</span>
      <div class="d-flex">
        <div class="d-flex align-items-baseline mr-3">
          <input id="sms-1" type="radio" class="mr-1" name="sms" />
          <label class="text-secondary font-s-12px gray-500" for="sms-1"
            >Belirtmek İstemiyorum</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="sms-2" type="radio" class="mr-1" name="sms" />
          <label class="text-secondary font-s-12px gray-500" for="sms-2"
            >Haftada 3</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="sms-3" type="radio" class="mr-1" name="sms" />
          <label class="text-secondary font-s-12px gray-500" for="sms-3"
            >Haftada 2</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="sms-4" type="radio" class="mr-1" name="sms" />
          <label class="text-secondary font-s-12px gray-500" for="sms-4"
            >İstemiyorum</label
          >
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mb-4">
      <span class="font-s-12px font-weight-bold text-info mb-2"
        >E-Posta (Zorunlu)</span
      >
      <div class="d-flex">
        <div class="d-flex align-items-baseline mr-3">
          <input id="mail-1" type="radio" class="mr-1" name="mail" />
          <label class="text-secondary font-s-12px gray-500" for="mail-1"
            >Haftada 4</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="mail-2" type="radio" class="mr-1" name="mail" />
          <label class="text-secondary font-s-12px gray-500" for="mail-2"
            >Haftada 3</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="mail-3" type="radio" class="mr-1" name="mail" />
          <label class="text-secondary font-s-12px gray-500" for="mail-3"
            >Haftada 2</label
          >
        </div>
        <div class="d-flex align-items-baseline mr-3">
          <input id="mail-4" type="radio" class="mr-1" name="mail" />
          <label class="text-secondary font-s-12px gray-500" for="mail-4"
            >Daha az</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsNotification",
};
</script>

<style scoped></style>

<template>
  <div v-if="video" class="video-detail">
    <div class="video-detail-main d-flex flex-column">
      <div class="container-video-detail video-detail-main-top">
        <div
          class="
            d-flex
            flex-column flex-sm-row
            justify-content-between
            align-items-center
            mb-3 mb-sm-5
          "
        >
          <router-link
            :to="{ name: 'channeldetail', params: { id: video.category.id } }"
            class="
              d-flex
              flex-column flex-sm-row
              align-items-center
              justify-content-center
            "
          >
            <div class="mr-0 mr-sm-3 mb-2 mb-md-0">
              <img
                :src="video.category.thumbnail"
                alt=""
                class="video-detail-person object-fit-cover border-radius-50"
              />
            </div>
            <div
              class="
                text-white
                d-flex
                flex-column
                align-items-center align-items-sm-start
                mb-3 mb-sm-0
              "
            >
              <span class="font-s-20px font-w-600">{{
                video.category.title
              }}</span>
              <span class="font-s-12px"
                >{{ video.category.video_count }} Video</span
              >
            </div>
          </router-link>
          <div>
            <button
              v-if="!video.category.is_subscribed"
              :disabled="isSubscribing"
              class="
                btn btn-light btn-p-15px-30px
                d-flex
                align-items-center
                border-radius
                subs-btn
              "
              @click="subscribe"
            >
              <notification-icon :size="16" :color="color" class="mr-1" />
              <span class="font-s-12px font-w-500 text-info"
                >Kanala Abone Ol!</span
              >
            </button>
            <button
              v-else
              :disabled="isSubscribing"
              class="
                btn btn-light btn-p-15px-30px
                d-flex
                align-items-center
                border-radius
                subs-btn
              "
              @click="unsubscribe"
              @mouseover="handleUnsubcribeHover(true)"
              @mouseleave="handleUnsubcribeHover(false)"
            >
              <notification-icon :size="16" color="#1686D6" class="mr-1" />
              <span class="font-s-12px font-w-500">{{ unsubscribeText }}</span>
            </button>
          </div>
        </div>
        <div class="video-detail-play w-100 position-relative">
          <router-link
            v-if="previousVideo"
            :to="{ name: 'videodetail', params: { id: previousVideo.id } }"
            class="prev-video-th position-absolute"
          >
            <div
              class="
                bg-image
                cursor-pointer
                border-radius
                text-white
                d-flex
                align-items-center
                justify-content-center
                p-35px
                sm-video
              "
              :style="{
                background:
                  'linear-gradient(#00000066, #00000066), url(' +
                  previousVideo.thumbnail +
                  ')',
              }"
            >
              <p class="text-center text-white font-w-600 m-0 sm-video-title">
                {{ previousVideo.title }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center mt-2">
              <left-arrow-icon :color="color" class="ml-1" />
              <span class="text-info font-s-16px font-w-500">Önceki</span>
            </div>
          </router-link>
          <vue-vimeo-player
            class="embed-container video-main w-100 border-radius"
            :video-id="
              video.bumper_video_id ? video.bumper_video_id : video.video_id
            "
            :options="{ responsive: true }"
            @ended="videoEnded"
            @play="videoPlayed"
          />

          <router-link
            v-if="nextVideo"
            :to="{ name: 'videodetail', params: { id: nextVideo.id } }"
            class="next-video-th position-absolute"
          >
            <div
              class="
                bg-image
                cursor-pointer
                border-radius
                text-white
                d-flex
                align-items-center
                justify-content-center
                p-35px
                sm-video
              "
              :style="{
                background:
                  'linear-gradient(#00000066, #00000066), url(' +
                  nextVideo.thumbnail +
                  ')',
              }"
            >
              <p class="text-center text-white font-w-600 m-0 sm-video-title">
                {{ nextVideo.title }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center mt-2">
              <span class="text-info mr-1 font-s-16px font-w-500">Sonraki</span>
              <right-arrow-icon :color="color" />
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container-video-detail">
        <div
          class="d-flex justify-content-between mt-sm-4 mb-5 main-video-info"
        >
          <div class="d-flex">
            <div class="d-flex align-items-center mr-3">
              <follow-icon
                :size="24"
                likeable-type="video"
                :likeable-id="video.id"
                :is-liked="video.is_liked"
                class="d-none d-sm-block"
                counter-classes="gray-500"
                :like-count="video.like_count"
                :is-countable="true"
              />
              <follow-icon
                :size="15"
                likeable-type="video"
                :likeable-id="video.id"
                :is-liked="video.is_liked"
                class="d-sm-none"
                counter-classes="gray-500"
                :like-count="video.like_count"
                :is-countable="true"
              />
            </div>
            <div class="d-flex align-items-center mr-3">
              <comment-icon
                color="#AFBBDB"
                :size="24"
                class="d-none d-sm-block"
              />
              <comment-icon color="#AFBBDB" :size="15" class="d-sm-none" />
              <span class="gray-500">{{ video.comment_count }}</span>
            </div>
            <div class="d-flex align-items-center mr-3">
              <clock-icon
                color="#AFBBDB"
                :size="24"
                class="d-none d-sm-block"
              />
              <clock-icon color="#AFBBDB" :size="15" class="d-sm-none" />
              <span class="gray-500">{{ video.publish_on }}</span>
            </div>
          </div>

          <div class="d-flex align-items-center mr-1">
            <save-icon
              favoritable-type="video"
              :favoritable-id="video.id"
              :is-favorited="false"
              :color="color"
              class="mr-1"
              text="Daha Sonra İzlemek İçin Kaydet"
              favorited-text="Daha Sonra İzlemek İçin Kaydedildi"
              text-classes="text-info font-s-12px"
            />
          </div>
        </div>

        <div>
          <h4 class="font-s-18px font-w-600 mb-2">
            {{ video.title }}
          </h4>
          <p class="black-700 line-h-25px">
            {{ video.description }}
          </p>
        </div>

        <div class="text-info mt-2 mb-4">
          <span class="font-s-12px mr-2">Etiketler:</span>
          <router-link
            v-for="(tag, index) in video.tags"
            :key="index"
            :to="{ name: 'tags', params: { id: tag.id } }"
            class="font-s-10px bg-blue-light video-detail-tag mr-2"
          >
            {{ tag.name.tr }}
          </router-link>
        </div>
        <hr />
        <video-comments
          v-if="route.params.id"
          :video-id="route.params.id"
          @commentAdded="video.comment_count++"
        />
      </div>
    </div>

    <div v-if="mainSuggestedVideo" class="featured-videos bg-image">
      <div class="container-xl py-5">
        <div>
          <h3 class="font-s-24px gray-700">
            Önerilen <span class="text-info font-weight-bold">Videolar</span>
          </h3>
        </div>
        <div class="mb-5">
          <div
            class="
              d-flex
              flex-column flex-lg-row
              align-items-center align-items-lg-start
              border-radius
              bg-white
              my-5
              pr-0 pr-xl-5
            "
          >
            <div
              class="
                videos-card-img
                border-radius
                d-flex
                align-items-center
                justify-content-center
              "
              :style="{
                backgroundImage: 'url(' + mainSuggestedVideo.thumbnail + ')',
              }"
            >
              <router-link
                :to="{
                  name: 'videodetail',
                  params: { id: mainSuggestedVideo.id },
                }"
              >
                <play-icon :size="90" />
              </router-link>
            </div>
            <div class="p-3 p-md-5 w-100">
              <div
                class="d-flex justify-content-between mb-3 video-card-info-text"
              >
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-3">
                    <follow-icon
                      :size="24"
                      likeable-type="video"
                      :likeable-id="video.id"
                      :is-liked="video.is_liked"
                      counter-classes="gray-500"
                      :like-count="mainSuggestedVideo.like_count"
                      class="mr-2"
                      :is-countable="true"
                    />
                  </div>
                  <div class="d-flex align-items-center mr-2">
                    <comment-icon color="#AFBBDB" :size="24" class="mr-2" />
                    <span class="gray-500">{{
                      mainSuggestedVideo.comment_count
                    }}</span>
                  </div>
                </div>
                <div class="">
                  <clock-icon color="#AFBBDB" :size="24" class="mr-2" />
                  <span class="gray-500">{{
                    mainSuggestedVideo.publish_on
                  }}</span>
                </div>
              </div>
              <router-link
                :to="{
                  name: 'channeldetail',
                  params: { id: video.category.id },
                }"
                class="d-flex mb-3"
              >
                <div class="mr-3">
                  <img
                    :src="mainSuggestedVideo.category.thumbnail"
                    class="person-img border-radius-50"
                    alt=""
                  />
                </div>
                <div class="d-flex flex-column">
                  <span class="gray-500 font-w-600">{{
                    mainSuggestedVideo.category.title
                  }}</span>
                  <span class="gray-500 font-s-10px"
                    >{{ mainSuggestedVideo.category.video_count }} Video</span
                  >
                </div>
              </router-link>
              <div>
                <router-link
                  :to="{
                    name: 'videodetail',
                    params: { id: mainSuggestedVideo.id },
                  }"
                >
                  <h2
                    class="
                      font-s-24px font-w-600
                      black-700
                      mb-3
                      video-card-title
                    "
                  >
                    {{ mainSuggestedVideo.title }}
                  </h2>
                </router-link>
                <p class="gray-700">
                  {{ mainSuggestedVideo.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div
              v-for="(suggestedVideo, index) in otherSuggestedVideos"
              :key="index"
              class="col-12-col-sm-6 col-md-4 col-lg-3"
            >
              <videos-card
                :video="suggestedVideo"
                class="mr-md-4 video-card mx-sm-3 mx-xl-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import VideosCard from "../components/ui/VideosCard.vue";
import VideoComments from "../components/ui/comments/VideoComments.vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import VideoService from "../services/video/VideoService";
import SubscriptionService from "../services/subscription/SubscriptionService";
import dayjs from "dayjs";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "VideoDetail",
  components: {
    VideosCard,
    VideoComments,
    vueVimeoPlayer,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const video = ref(null);
    const mainSuggestedVideo = ref(null);
    const nextVideo = ref(null);
    const previousVideo = ref(null);
    const otherSuggestedVideos = ref([]);
    const isVideoWatched = ref(false);
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueText;
    const videoDetailMainColor1 =
      ColorHelper[store.state.theme].videoDetailMain1;
    const videoDetailMainColor2 =
      ColorHelper[store.state.theme].videoDetailMain2;
    const isSubscribing = ref(false);
    const unsubscribeText = ref("Kanala Abonesin");
    const img_url = "url(/new-img/video-detail-main-bg.png)";

    const fetchVideo = (videoId) => {
      store.dispatch("setLoading", true);
      VideoService.getVideo(videoId)
        .then((res) => {
          // video.value/ = res.data.data;
          video.value = {
            ...res.data.data,
            publish_on: dayjs(res.data.data.publish_on)
              .locale("tr")
              .format("DD MMMM YYYY"),
          };

          VideoService.getVideo(video.value.next_video_id).then((res) => {
            nextVideo.value = res.data.data;
          });

          VideoService.getVideo(video.value.previous_video_id).then((res) => {
            previousVideo.value = res.data.data;
          });
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            router.push({ name: "404" });
          }
        })
        .finally(() => {
          store.dispatch("setLoading", false);
        });
    };

    const fetchGetSuggestedVideosByVideo = (videoId) => {
      VideoService.getSuggestedVideosByVideo(videoId, 5).then((res) => {
        mainSuggestedVideo.value = {
          ...res.data.data[0],
          publish_on: dayjs(res.data.data[0].publish_on)
            .locale("tr")
            .format("DD MMMM YYYY"),
        };
        otherSuggestedVideos.value = res.data.data.slice(1).map((video) => {
          return {
            ...video,
            publish_on: dayjs(video.publish_on)
              .locale("tr")
              .format("DD.MM.YYYY"),
          };
        });
      });
    };

    const subscribe = () => {
      isSubscribing.value = true;
      video.value.category.is_subscribed = true;
      SubscriptionService.subscribe("video_category", video.value.category.id)
        .catch(() => {
          video.value.category.is_subscribed = false;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    const unsubscribe = () => {
      isSubscribing.value = true;
      video.value.category.is_subscribed = false;
      SubscriptionService.unsubscribe("video_category", video.value.category.id)
        .catch(() => {
          video.value.category.is_subscribed = true;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    function videoPlayed() {
      if (!isVideoWatched.value) {
        VideoService.markVideoAsWatched(video.value.id);
        isVideoWatched.value = true;
      }
    }

    function videoEnded() {
      video.value.bumper_video_id
        ? (video.value.bumper_video_id = null)
        : video.value.video_id;
    }

    const handleUnsubcribeHover = (isHover) => {
      if (isHover) {
        unsubscribeText.value = "Abonelikten Çık";
      } else {
        unsubscribeText.value = "Kanala Abonesin";
      }
    };

    if (route.params.id) {
      fetchVideo(route.params.id);
      fetchGetSuggestedVideosByVideo(route.params.id);
    }

    onBeforeRouteUpdate((to) => {
      fetchVideo(to.params.id);
      fetchGetSuggestedVideosByVideo(to.params.id);
    });

    return {
      video,
      mainSuggestedVideo,
      otherSuggestedVideos,
      nextVideo,
      previousVideo,
      route,
      isSubscribing,
      subscribe,
      unsubscribe,
      unsubscribeText,
      handleUnsubcribeHover,
      color,
      videoDetailMainColor1,
      videoDetailMainColor2,
      img_url,
      videoEnded,
      videoPlayed,
    };
  },
};
</script>

<style lang="scss" scoped>
.video-card {
  width: 260px;
}

.container-video-detail {
  max-width: 970px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}

.sm-video {
  height: 120px;
}

.sm-video-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.video-main {
  height: 550px;
}

.video-detail-main {
  background: linear-gradient(
      0deg,
      v-bind("videoDetailMainColor1") 15.25%,
      rgba(22, 134, 214, 0) 90.53%
    ),
    linear-gradient(
      0deg,
      v-bind("videoDetailMainColor2"),
      v-bind("videoDetailMainColor2")
    ),
    v-bind("img_url"), linear-gradient(180deg, #072642 0%, #0b1620 48.44%),
    #081f32;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100vw 572px;
}

.video-detail-person {
  width: 100px;
  height: 100px;
}

.prev-video-th,
.next-video-th {
  width: 238px;
  height: 123px;
  transition: 0.3s;

  &:hover {
    width: 250px;
    height: 129px;
  }
}

.prev-video-th {
  top: 200px;
  left: -250px;
}
.next-video-th {
  top: 200px;
  right: -250px;
}

.video-detail-tag {
  border-radius: 3px;
  padding: 3px 5px;
}

.person-img {
  width: 45px;
  height: 45px;
}

.featured-videos {
  background: url("/new-img/upcoming-events-bg.png");
}

.videos-card-img {
  min-width: 555px;
  height: 380px;
  background-size: cover;
}

.video-detail-main-top {
  margin-top: 160px;
}

@media (max-width: 1300px) {
  .prev-video-th,
  .next-video-th {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .video-detail-main-top {
    margin-top: 115px !important;
  }

  .video-main {
    height: 51vw;
  }

  .videos-card-img {
    width: 100%;
    height: 226px;
    min-width: initial;
  }

  .video-card-title {
    font-size: 20px !important;
  }

  .video-card {
    width: 100% !important;
  }
  .video-card-info-text {
    span {
      font-size: 10px !important;
    }
  }
}

@media (max-width: 768px) {
  .video-detail-person {
    width: 75px;
    height: 75px;
  }
  .subs-btn {
    padding: 10px 20px;
  }
  .main-video-info {
    font-size: 10px !important;
  }
  .video-bottom-space {
    height: 210px !important;
  }
}

@media (max-width: 576px) {
  .container-video-detail {
    margin-top: 15px !important;
  }
  .video-detail-main {
    margin-top: 60px;
    background-size: 200vw 350px;
  }
}
</style>

export default class ZoneHelper {
  themeDetail = [
    {
      theme: "ahezone",
      name: "Ahezone",
      deparment: "Aile",
      podcast: "Aile Hekimi",
    },
    {
      theme: "norozone",
      name: "Nörozone",
      deparment: "Nöroloji",
      podcast: "Nörozone",
    },
    {
      theme: "dermazone",
      name: "Dermazone",
      deparment: "Dermatoloji",
      podcast: "Dermazone",
    },
    {
      theme: "eczazone",
      name: "Eczazone",
      deparment: "Ecza",
      podcast: "Eczazone",
    },
    {
      theme: "kvczone",
      name: "Kvczone",
      deparment: "Kvc",
      podcast: "Kvczone",
    },
    {
      theme: "ftrzone",
      name: "Ftrzone",
      deparment: "Ftr",
      podcast: "Ftrzone",
    },
    {
      theme: "kbbzone",
      name: "Kbbzone",
      deparment: "Kbb",
      podcast: "Kbbzone",
    },
    {
      theme: "ortopedizone",
      name: "Ortopedizone",
      deparment: "Ortopedi",
      podcast: "Orto",
    },
    {
      theme: "psikezone",
      name: "Psikezone",
      deparment: "Psikiyatri",
      podcast: "Psikezone",
    },
    {
      theme: "hemaworld",
      name: "Hemaworld",
      deparment: "Hematoloji",
      podcast: "Hemaworld",
    },
  ];

  getZoneInfo(theme) {
    const zone = this.themeDetail.find((i) => i.theme == theme);

    return zone;
  }

  getZoneName(theme) {
    const zone = this.themeDetail.find((i) => i.theme == theme);

    if (!zone) {
      return "";
    }

    return zone.name;
  }
}

<template>
  <div
    class="
      position-absolute
      w-100
      h-100
      delete-conversation
      d-flex
      flex-column
      align-items-center
      justify-content-center
    "
  >
    <report-img-icon :size="90" color="#ff0000" class="mb-3" />
    <p class="text-primary font-s-18px font-w-500">
      <b class="font-weight-bold blue-300">⁦{{ contactPeople[0].fullname }}⁩</b>
      ile olan tüm mesajlarınızı silmek üzeresiniz.
    </p>
    <div class="d-flex flex-column flex-md-row justify-content-md-center w-100">
      <button
        class="
          w-m-100
          mb-3 mb-md-0
          btn
          bg-gray-500
          width-84px
          height-50px
          font-s-12px font-w-500
          text-white
          border-radius
          mr-4
        "
        :disabled="disableButtons"
        @click="deny"
      >
        Vazgeç
      </button>
      <button
        class="
          w-m-100
          btn
          bg-blue-300
          width-120px
          height-50px
          font-s-12px font-w-500
          text-white
          border-radius
        "
        :disabled="disableButtons"
        @click="accept"
      >
        Mesajları Sil
      </button>
    </div>
  </div>
</template>

<script>
import ThreadService from "../../../services/chat/ThreadService";
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import SwalError from "../../../helpers/SwalError";

export default {
  props: {
    thread: {
      type: Object,
      required: true,
    },
  },
  emits: ["accept", "deny"],
  setup(props, { emit }) {
    const store = useStore();
    const threadService = new ThreadService();
    const disableButtons = ref(false);

    const user = computed(() => store.state.user.user);
    const contactPeople = computed(() => {
      if (!props.thread.participants) return {};
      return props.thread.participants.filter(
        (participant) => participant.id !== user.value.id
      );
    });

    const accept = () => {
      disableButtons.value = true;
      threadService
        .delete(props.thread.id)
        .catch((error) => {
          SwalError.fire(error);
        })
        .then(() => {
          emit("accept", props.thread);
        })
        .finally(() => {
          disableButtons.value = false;
        });
    };
    const deny = () => {
      emit("deny");
    };

    return {
      contactPeople,
      disableButtons,
      accept,
      deny,
    };
  },
};
</script>

<style lang="scss" scoped>
.delete-conversation {
  background: rgba(251, 252, 255, 0.8);
  backdrop-filter: blur(5px);
  padding: 100px 100px 300px;
}

@media (max-width: 768px) {
  .delete-conversation {
    padding: 0 !important;
  }
}
</style>

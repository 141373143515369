<template>
  <div
    class="
      bg-message
      px-3
      mb-4
      border-radius-90px
      d-flex
      justify-content-between
      align-items-center
      message-text-box
    "
  >
    <input
      v-model="message"
      type="text"
      placeholder="Mesajınızı buraya yazınız..."
      class="w-100 pr-5 bg-transparent border-none font-s-12px message-input"
      @keydown.enter="sendMessage"
    />
    <button
      class="
        bg-info
        border-radius-50
        send-btn
        d-flex
        align-items-center
        justify-content-center
        border-none
      "
      @click="sendMessage"
    >
      <send-icon />
    </button>
  </div>
</template>

<script>
import ChatService from "../../../services/chat/ChatService";
import { ref } from "vue";

const chatService = new ChatService();
export default {
  name: "ConversationSendButton",
  props: {
    threadId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const message = ref();
    return {
      message,
      sendMessage() {
        if (!message.value || message.value.length < 1) return;
        chatService.sendMessage(props.threadId, message.value);
        message.value = undefined;
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.message-text-box {
  height: 55px;
}
.message-input {
  &::placeholder {
    font-size: 12px;
    color: #52596b;
  }
}
.send-btn {
  width: 40px;
  height: 40px;
}
</style>

require("bootstrap");
require("sweetalert2");
import { registerGlobalComponents } from "./vue-utils";
import { createApp } from "vue";
import router from "./src/router/router";
import store from "./src/store/store";
import App from "./src/App";
import * as yup from "yup";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import dayjs from "dayjs";
import "dayjs/locale/tr";

SwiperCore.use([Navigation, Pagination]);
const yupTR = require("./yup-tr-locale").default;

yup.setLocale(yupTR);
dayjs.locale("tr");

if (document.getElementById("app")) {
  const app = createApp(App);

  registerGlobalComponents(app);
  app.use(router);
  app.use(store);
  app.mount("#app");
}

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="DAS-28 Hesaplama Aracı"
        nav="DAS-28 Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  >
                    {{ result }}
                  </h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                    >{{ result2 }}</span
                  >
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <form
            id="bet"
            class="
              d-flex
              flex-column
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-100">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Cinsiyet</h5>
                <select
                  v-model="type"
                  name="type"
                  class="
                    form-control
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    dastype
                  "
                  :class="{ 'is-invalid': typeError }"
                >
                  <option value="-1" selected disabled hidden>
                    Lütfen Seçiniz
                  </option>
                  <option value="1">DAS28 (ESR)</option>
                  <option value="2">DAS28 (CRP)</option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div class="w-m-100 w-50">
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Ağrılı Eklem Sayısı
                  </h5>
                  <input
                    v-model="aes"
                    type="text"
                    class="
                      form-control
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                    :class="{ 'is-invalid': aesError }"
                  />
                </div>
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Patient Global Health
                  </h5>
                  <input
                    v-model="gm"
                    type="text"
                    class="
                      form-control
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                    :class="{ 'is-invalid': gmError }"
                  />
                </div>
              </div>

              <div class="w-m-100 w-50">
                <div class="mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Şiş Eklem Sayısı
                  </h5>
                  <input
                    v-model="ses"
                    type="text"
                    class="
                      form-control
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                    :class="{ 'is-invalid': sesError }"
                  />
                </div>
                <div class="mb-5">
                  <h5
                    v-if="type == 1"
                    class="font-s-20px mb-3 font-w-600 black-700"
                  >
                    ESR (mm/hr)
                  </h5>
                  <h5 v-else class="font-s-20px mb-3 font-w-600 black-700">
                    CRP (mg/l)
                  </h5>
                  <input
                    v-model="esr"
                    type="text"
                    class="
                      form-control
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                    "
                    :class="{ 'is-invalid': esrError }"
                  />
                </div>
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="handleClear()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="handleCalculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "Das28Score",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const type = ref(-1);
    const aes = ref();
    const ses = ref();
    const esr = ref();
    const gm = ref();
    const typeError = ref(false);
    const aesError = ref(false);
    const sesError = ref(false);
    const esrError = ref(false);
    const gmError = ref(false);
    const result = ref();
    const result2 = ref();

    const handleClear = () => {
      aes.value = null;
      ses.value = null;
      esr.value = null;
      gm.value = null;
      result.value = null;
      result2.value = null;
    };

    const handleCalculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      let error = 0;

      if (!aes.value | (aes.value > 28) | (aes.value < 0)) {
        aesError.value = true;
        error++;
      }
      if (!ses.value | (ses.value > 28) | (ses.value < 0)) {
        sesError.value = true;

        error++;
      }
      if (!esr.value) {
        esrError.value = true;
        error++;
      }
      if (!gm.value) {
        gmError.value = true;
        error++;
      }
      if (error > 0) {
        return false;
      }

      let f = "";
      if (type.value == 1) {
        /********** FORMULA: DAS28(4) = (0.56*sqrt(t28) + 0.28*sqrt(sw28) + 0.70*Ln(ESR) + 0.014*GH ***********/
        f =
          0.56 * Math.sqrt(aes.value) +
          0.28 * Math.sqrt(ses.value) +
          0.7 * Math.log(esr.value) +
          0.014 * gm.value;
        result.value = `DAS28: ${parseFloat(f.toFixed(2))}`;
      } else {
        /********* FORMULA: DAS28-CRP(4) = 0.56*sqrt(TJC28) + 0.28*sqrt(SJC28) + 0.36*ln(CRP+1) + 0.014*GH + 0.96 **********************/
        f =
          0.56 * Math.sqrt(aes.value) +
          0.28 * Math.sqrt(ses.value) +
          0.36 * Math.log(esr.value + 1) +
          0.014 * gm.value +
          0.96;
        result.value = `DAS28-CRP: ${parseFloat(f.toFixed(2))}`;
      }
      f = f.toFixed(2);

      let returnText = "";
      if (f < 2.6) {
        returnText = "Remisyon";
      } else if (f < 3.2) {
        returnText = "Düşük Hastalık Aktivitesi";
      } else if (f >= 3.2 && f <= 5.1) {
        returnText = "Orta Hastalık Aktivitesi";
      } else if (f > 5.1) {
        returnText = "Şiddetli Hastalık Aktivitesi";
      }
      result2.value = `${returnText}`;
      return returnText;
    };

    watch(type, () => {
      typeError.value = type.value < 0;
    });

    watch(aes, () => {
      aesError.value = !aes.value | (aes.value > 28) | (aes.value < 0);
    });

    watch(esr, () => {
      esrError.value = !esr.value;
    });

    watch(gm, () => {
      gmError.value = !gm.value;
    });

    watch(ses, () => {
      sesError.value = !ses.value | (ses.value > 28) | (ses.value < 0);
    });

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      type,
      aes,
      ses,
      esr,
      gm,
      typeError,
      aesError,
      sesError,
      esrError,
      gmError,
      result,
      result2,
      handleClear,
      handleCalculate,
      apgar: [
        {
          title: "Romatoid Artrit Nedir?",
          content: `<p>Romatoid Artrit bir eklem iltihabıdır. Normalde, bağışıklık sistemi vücudumuzu bakteri, virüs ve
            mikroplardan korurken, Romatoid Artritte bağışıklık sistemi sağlıklı dokulara saldırmaya başlar.
            Eklemlere zarar verir ve eklem hasarına sebep olur.</p>
            <p>Romatoid Artrit, el ve ayak kemikleri gibi daha çok küçük eklemlerde olmak üzere vücuttaki birçok
            eklemi etkileyen bir romatizma türüdür. Dünyada en fazla görülen romatizma türü olan Romatoid Artrid
            aynı zamanda en çok şekil bozukluğuna sebebiyet veren romatizma türüdür.</p>
            <p>Romatoid Artrit’in kesin bir tedavisi olmamakla birlikte, semptomlar hafifletilebilir ve daha
            rahat hareket etmenizi sağlayacak ilaçlar verilebilir.</p>`,
        },
        {
          title: "Romatoid Artrit Neden Olur?",
          content: `<p>Romatoid Artritin neden olduğu bilinmemektedir. Hastalık genel olarak genetik yatkınlıkla ortaya
            çıksa da kesin bir sebebi yoktur.</p>
            <p>Çocuklar ve yaşlılar da dahil olmak üzere herkeste görülebilen bir rahatsızlıktır. En çok 20-50
            yaşları arasında görülür. Genel olarak kadınlarda erkeklere oranla 2-3 kat daha fazla görülür. Bazı
            ırklarda görülme sıklığı ise daha fazla olabilir.</p>`,
        },
        {
          title: "Romatoid Artrit Belirtileri",
          content: `<p>Romatoid Artrit hem eklem için hem de eklem dışı belirtiler gösterebilir. Eklem içi belirtileri,
            eklem ağrısı, eklem şişmesi, eklemlerde sıcaklık, eklemlerde hassasiyet ve hareket kısıtlılığı,
            ekremlerde sertliktir. Eklem dışı belirtiler ise halsizlik, anemi, ateş ve kilo kaybı
            olabilir.</p>
            <p>Romatoid Artrit genellikle ilk önce el ve ayak eklemleri gibi küçük eklemleri etkiler. Hastalık
            ilerledikçe ise diz, kalça ve omuz gibi daha büyük eklemlere de yayılır. Genellikle Romatoid Artrit
            belirtileri simetrik olarak görülür ve vücutta karşılıklı olarak yer alan eklemlerde ortaya
            çıkar.</p>`,
        },
        {
          title: "DAS28 Skoru Nedir?",
          content: `<p>Farklı kronik hastalıkların takibinde kullanılan ölçekler gibi Romatoid Artritte de ölçek
            kullanılır. Dünyada en sık kullanılan Romatoid Artrit ölçeği DAS28’dir. DAS28 skoru, “Hastalık
            Aktivite Skoru” anlamına gelen İngilizce “Disease Activity Score” teriminin kısaltmasıdır. 28 sayısı
            ise değerlendirmede incelenen 28 eklemi temsil eder.</p>`,
        },
        {
          title: "DAS28 Skoru Nasıl Hesaplanır?",
          content: `<p>DAS28 skorunuzu belirlemek için genellikle doktorunuz şu adımları uygulayacaktır; 28 eklem
            arasında şişmiş eklemlerinizi saymak, 28 eklem arasında hassas eklemlerinizi saymak, ESR ve CRP
            oranınızı ölçmek için kan almak, hastaya ‘global sağlık değerlendirmesi’ yapmak, bu değerlendirmede
            hastadan kendisine 0-100 mm arasındaki görsel skalada puan vermesi istenir.</p>
            <p>Bu değerlendirmeler sonucunda doktor hastalığın hafif, orta ya da yüksek seviyede olduğuna karar
            verir ve bu karar hastalıkta uygulanacak tedaviyi etkiler. DAS28 hastanın o andaki hastalık aktivite
            durumunu gösterir ve doktora sonraki değerlendirmelerde de hastalığın seyrini görmesi açısından
            yardımcı olur.</p>
            <p>DAS28 skorunu kolay yoldan ve doğru bir şekilde hesaplamak için Medikaynak’ta yer alan DAS28
            Hesaplama Aracını kullanabilirsiniz. Bu hesaplama aracı sayesinde, Romatoid Artrid’den
            şüpheleniyorsanız kendinizin ya da doktorsanız hastanızın DAS-28 skorunu doğru bir şekilde
            öğrenebilirsiniz.</p>`,
        },
      ],
      references: [
        "http://romatizmatv.org/romatoid-artritte-hastaligin-takibinde-kullanilan-das28-ne-demektir/",
        "https://www.nras.org.uk/the-das28-score",
        "https://www.medicalpark.com.tr/romatoid-artrit-nedir-teshisi-ve-tedavisi-nelerdir/hg-132#5",
        "https://hisarhospital.com/romatoid-artrit-hakkinda-merak-edilenler/",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <svg
    width="21"
    height="1"
    viewBox="0 0 21 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="1"
      width="1"
      height="21"
      rx="0.5"
      transform="rotate(-90 0 1)"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "SmallLineIcon",
};
</script>

<style></style>

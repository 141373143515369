<template>
  <div class="d-flex mx-auto position-relative products-article-search">
    <input
      v-model="keyword"
      type="text"
      :placeholder="
        type === 'active-ingredient'
          ? 'İsim ile arama'
          : 'Etken madde ile arama'
      "
      class="
        input-group-text
        bg-white
        text-left
        border-radius-90px
        box-shadow-4-20
      "
      @keyup.enter="searchHandler()"
    />
    <div class="position-absolute search-buttons h-100">
      <button
        class="
          btn btn-info
          text-white
          search-button
          font-s-12px font-weight-bold
          h-100
        "
        @click="searchHandler()"
      >
        Etken Madde İle Ara
      </button>
      <button
        class="
          btn btn-primary
          text-white
          switch-button
          font-s-12px font-weight-bold
          h-100
        "
        @click="switchByNameHandler()"
      >
        İsim İle Ara
      </button>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const keyword = ref(route.query.keyword ?? "");
    const type = ref(route.query.type ?? "");

    const searchHandler = () => {
      if (keyword.value) {
        router.push({
          name: "productsearch",
          query: {
            keyword: keyword.value,
            type: "active-ingredient",
          },
        });
      }
    };

    const switchByNameHandler = () => {
      router.push({
        name: "productsearch",
        query: {
          keyword: keyword.value,
          type: "",
        },
      });
    };

    return {
      keyword,
      type,
      searchHandler,
      switchByNameHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.products-article-search {
  width: 645px;
  height: 70px;
  top: 80px;

  input {
    padding: 20px 260px 20px 45px;
    width: 100%;
    border: none;

    &::placeholder {
      text-align: left;
      font-size: 16px;
      color: #7684aa;
    }
  }

  .search-button {
    width: 120px;
    border-top-left-radius: 90px;
    border-bottom-left-radius: 90px;
  }

  .switch-button {
    width: 120px;
    border-top-right-radius: 90px;
    border-bottom-right-radius: 90px;
  }
  .search-buttons {
    right: 0;
  }
}

@media (max-width: 768px) {
  .products-article-search {
    width: 300px;
    height: 50px;
    top: 80px;

    input {
      padding: 20px;
    }

    .search-buttons {
      right: 23px;
      top: 45px;
    }
  }
}
</style>

<template>
  <div>
    <conversation-header
      :contact-people="contactPeople"
      @ask-deleting="$emit('askDeleting')"
    />
    <div
      ref="messageBox"
      class="p-4 mb-4 height-450px overflow-y-auto message-box"
    >
      <div v-for="(group, index) in groupedMessages" :key="index">
        <div v-if="user.id !== group[0].user_id" class="d-flex mb-3">
          <div>
            <img
              :src="
                getProfilePicture(
                  contactPeople.find((person) => person.id === group[0].user_id)
                )
              "
              :alt="
                contactPeople.find((person) => person.id === group[0].user_id)
                  .fullname
              "
              class="border-radius-50 person-img-large mr-2"
            />
          </div>
          <div>
            <div
              v-for="msg in group"
              :key="msg.id"
              class="
                bg-message
                border-radius
                mb-1
                p-3
                max-w-360px
                width-fit-content
              "
            >
              <p class="font-s-12px text-secondary m-0">
                {{ msg.body }}
              </p>
            </div>
            <span class="font-s-10px"
              >{{ formatDate(group[group.length - 1].created_at) }}
            </span>
          </div>
        </div>

        <div
          v-else
          class="d-flex flex-column align-items-end mb-3 message-box-person"
        >
          <div
            v-for="msg in group"
            :key="msg.id"
            class="
              bg-info
              border-radius
              mb-1
              p-3
              max-w-360px
              width-fit-content
              m-0
            "
          >
            <p class="font-s-12px text-white m-0">
              {{ msg.body }}
            </p>
          </div>
          <span class="text-info font-s-10px"
            >{{ formatDate(group[group.length - 1].created_at) }}
            <span v-if="group[group.length - 1].is_seen">√√</span>
            <span v-else>√</span></span
          >
        </div>
      </div>
    </div>
    <conversation-send-button :thread-id="thread.id" />
    <div href="" class="d-flex justify-content-center align-items-center mb-5">
      <a class="cursor-pointer d-none d-md-block" @click="showReport = true">
        <warning-icon class="mr-2" color="#D5293B" />
        <span class="font-w-500 font-s-12px red">Bu Sohbeti Bildir</span>
      </a>
      <!-- <a
        class="font-w-500 font-s-12px red d-md-none"
        @click="showMessage = false"
        >Geri Dön</a
      > -->
    </div>
  </div>
</template>

<script>
import ConversationHeader from "./Conversation/ConversationHeader";
import { computed, ref, watch, onUpdated } from "vue";
import { useStore } from "vuex";
import ConversationSendButton from "./Conversation/ConversationSendButton";
import { getProfilePicture } from "../../shared/utils";
import dayjs from "dayjs";
import ChatService from "../../services/chat/ChatService";
import { child, onValue } from "firebase/database";
import { messagesDocRef } from "../../services/FirebaseChatService";

export default {
  name: "MessagesConversation",
  components: { ConversationSendButton, ConversationHeader },
  props: {
    thread: {
      type: Object,
      required: true,
    },
  },
  emits: ["askDeleting"],
  setup(props) {
    const chatService = new ChatService();
    const messageBox = ref();
    const messages = ref([]);
    const store = useStore();
    const user = computed(() => store.state.user.user);

    watch(
      () => props.thread,
      () => {
        if (props.thread) {
          chatService.seenMessage(props.thread.id);
          messages.value = [];
          const messagesDocRefChild = child(
            messagesDocRef,
            props.thread.id.toString()
          );
          onValue(messagesDocRefChild, (snapshot) => {
            const value = snapshot.val();
            if (!value || typeof value !== "object") {
              return (messages.value = []);
            }
            messages.value = Object.values(snapshot.val());
          });
        }
      },
      {
        immediate: true,
      }
    );

    function scrollToBottom() {
      messageBox.value.scrollTop = messageBox.value.scrollHeight;
    }

    const contactPeople = computed(() => {
      if (!props.thread.participants) return {};
      return props.thread.participants.filter(
        (participant) => participant.id !== user.value.id
      );
    });

    const groupedMessages = computed(() => {
      const groupedMessages = [];

      messages.value.forEach((message) => {
        if (!groupedMessages.length) {
          groupedMessages.push([message]);
        } else {
          if (
            message.user_id ===
            groupedMessages[groupedMessages.length - 1][0].user_id
          ) {
            groupedMessages[groupedMessages.length - 1].push(message);
          } else {
            groupedMessages.push([message]);
          }
        }
      });

      return groupedMessages;
    });

    onUpdated(() => scrollToBottom());

    return {
      messages,
      messageBox,
      contactPeople,
      groupedMessages,
      user,
      getProfilePicture,
      formatDate(date) {
        return dayjs(date).locale("tr").format("h:m");
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.person-img {
  width: 28px;
  height: 28px;
}

.person-img-large {
  width: 50px;
  height: 50px;
}
</style>

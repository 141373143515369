<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.1523 51.0578L44.5598 44.6999C44.5598 44.6999 48.5421 42.7719 51.9621 44.6999L63.4384 51.0693"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M52.419 18.3273C52.419 18.3273 51.9141 30.1594 51.9141 31.9497C51.9141 33.74 52.3157 37.0223 58.8687 40.1553L66.8219 44.5737"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M44.1685 18.3273C44.1685 18.3273 44.6734 30.1594 44.6734 31.9497C44.6734 33.74 44.2717 37.0223 37.7187 40.1553L29.7656 44.5737"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M37.983 26.5213C35.5959 24.6736 32.0612 23.5604 27.5395 26.682C27.5395 26.682 7.2608 40.7979 10.4398 72.5989C10.4398 72.5989 10.6349 77.1665 15.8107 76.7763L37.5928 71.0152C37.5928 71.0152 43.6409 69.2707 44.1573 61.4783V45.0442"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M58.6076 26.5213C60.9947 24.6736 64.5294 23.5604 69.0511 26.682C69.0511 26.682 89.3298 40.7979 86.1509 72.5989C86.1509 72.5989 85.9558 77.1665 80.7799 76.7763L58.9863 71.0152C58.9863 71.0152 52.9383 69.2707 52.4219 61.4783V45.0442"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SolunumSistemi",
};
</script>

<style></style>

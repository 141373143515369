<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.20388 10.3786L9.64188 14.334C9.74855 14.5073 9.91521 14.5053 9.98255 14.496C10.0499 14.4866 10.2119 14.4453 10.2705 14.2486L13.3192 3.95196C13.3725 3.76996 13.2745 3.64596 13.2305 3.60196C13.1879 3.55796 13.0659 3.46396 12.8892 3.51396L2.58521 6.53129C2.38988 6.58863 2.34721 6.75263 2.33788 6.81996C2.32855 6.88863 2.32588 7.05863 2.49855 7.16729L6.49921 9.66929L10.0339 6.09729C10.2279 5.90129 10.5445 5.89929 10.7412 6.09329C10.9379 6.28729 10.9392 6.60463 10.7452 6.80063L7.20388 10.3786ZM9.93061 15.4999C9.46661 15.4999 9.04128 15.2639 8.79061 14.8586L6.20595 10.6646L1.96861 8.0146C1.51195 7.7286 1.27328 7.21926 1.34728 6.68393C1.42061 6.1486 1.78795 5.72326 2.30395 5.57193L12.6079 2.5546C13.0819 2.41593 13.5899 2.54726 13.9393 2.89526C14.2886 3.2466 14.4186 3.75993 14.2773 4.23593L11.2286 14.5319C11.0759 15.0499 10.6493 15.4159 10.1153 15.4873C10.0526 15.4953 9.99195 15.4999 9.93061 15.4999V15.4999Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CalculateIcon",
};
</script>

<style></style>

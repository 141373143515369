<template>
  <div class="d-flex flex-column flex-lg-row mt-m-60px">
    <reset-password-form :zone="zone" />
    <login-info :zone="zone" btn-text="Ücretsiz Üye Olun" btn-class="login" />
  </div>
</template>

<script setup>
import LoginInfo from "../components/ui/LoginInfo.vue";
import ResetPasswordForm from "../components/ui/ResetPasswordForm.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import ZoneHelper from "../shared/zone-helper";

const store = useStore();
const theme = store.state.theme;
const zoneHelper = new ZoneHelper();
const zone = computed(() => zoneHelper.getZoneInfo(theme));
</script>

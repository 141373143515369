<template>
  <div
    class="
      d-flex
      flex-column
      p-3
      bg-white
      border-radius
      box-shadow-4-20-2
      h-100
    "
  >
    <img
      :src="product.thumbnail.permalink"
      alt=""
      class="new-product-card-img mb-3"
    />
    <h5 class="black-700 font-s-18px font-w-600 mr-auto">
      {{ product.title }}
    </h5>
    <router-link
      :to="{ name: 'productdetail', params: { id: product.id } }"
      class="btn btn-info border-radius w-100 font-s-12px font-w-500 mt-auto"
    >
      Detaylı Bilgi
    </router-link>

    <!-- <button @click="showModal = true" class="btn btn-info border-radius w-100 font-s-12px font-w-500 mt-auto">Detaylı Bilgi</button> -->

    <!-- <transition name="fade">
      <div v-if="showModal" class="position-fixed product-modal d-flex justify-content-center align-items-center">
        <div class="container-xl">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8">
              <div class="bg-white border-radius py-4 px-5 position-relative">
                <close-modal class="position-absolute close-modal cursor-pointer" @click="showModal = false" />

                <div class="bg-blue-light mb-3 d-flex justify-content-center py-3 top-left-radius top-right-radius">
                  <h5 class="font-s-20px font-w-600 m-0">Flexo Ampul</h5>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Ürün Adı</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">Flexo Ampul</p>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Endikasyon</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">Osteoartrit, romatoid artrit ve ankilozan spondilit belirti ve bulgularının tedavisi ile akut gut artriti, akut kas iskelet sistemi ağrıları, postoperatif ağrı ve dismenore tedavisinde endikedir.</p>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Form</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">Ampul</p>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Etken Madde</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">Etofenamat</p>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Etken Madde Miktarı</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">1,0 g/2 ml ampul</p>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Ticari Şekli</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">1 ampul içeren ambalajlarda</p>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Kullanma Talimatı</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">Detay</p>
                  </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-3">
                  <div class="max-w-160px w-100 mr-3">
                    <h5 class="m-0 font-s-16px font-w-500 black-700">Kısa Ürün Bilgisi</h5>
                  </div>
                  <div>
                    <p class="m-0 text-secondary">Detay</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
// import CloseModal from '../icon/CloseModal';

export default {
  name: "ProductCard",
  // components: {
  //   CloseModal,
  // },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.new-product-card-img {
  width: 100%;
  height: 146px;
  object-fit: cover;
}

.product-modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(17, 77, 174, 0.6);
  backdrop-filter: blur(10px);
}

.close-modal {
  top: -40px;
  right: -40px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div
    v-if="!subcategoryPromise.loading.value && !categoryPromise.loading.value"
    class="new-products mb-100px"
  >
    <section class="new-products-main bg-image d-flex flex-column mb-75px">
      <content-main
        :title="categoryPromise.results.value[0].title"
        :nav="categoryPromise.results.value[0].title"
        class="mt-160px"
      />
    </section>

    <section class="mb-5">
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-md-5 mb-4">
            <select
              v-model="selectedSubcategory"
              class="
                w-100
                height-50px
                border-radius border-none
                box-shadow-4-20-2
                px-3
              "
            >
              <option
                v-if="subcategoryPromise.loading.value"
                hidden
                value="null"
                selected
                disabled
              >
                Kategoriler yükleniyor...
              </option>
              <template v-else>
                <option
                  v-for="category in subcategoryPromise.results.value"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.title }}
                </option>
              </template>
            </select>
          </div>
        </div>

        <div v-if="categories[selectedSubcategory]" class="row">
          <template v-if="categories[selectedSubcategory].length">
            <div
              v-for="(product, index) in categories[selectedSubcategory]"
              :key="index"
              class="col-6 col-sm-4 col-md-3 mb-4 height-296px"
            >
              <product-card :product="product" />
            </div>
          </template>
          <div v-else>Bu kategoride ürün bulunamadı!</div>
        </div>
        <loading-anim-icon v-else />
      </div>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import usePromise from "../shared/composables/use-promise";
import ProductCategoryService from "../services/product/ProductCategoryService";
import ContentMain from "../components/ui/ContentMain.vue";
import ProductCard from "../components/ui/ProductCard.vue";
import { ref } from "vue";
import router from "../router/router";
import { watchEffect } from "vue";

export default {
  name: "ProductCategory",
  components: {
    ProductCard,
    ContentMain,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const selectedSubcategory = ref(null);
    const categories = ref({});
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    watchEffect(async () => {
      if (
        selectedSubcategory.value in categories.value ||
        !selectedSubcategory.value
      ) {
        return;
      }

      const response = await ProductCategoryService.getProductsById(
        selectedSubcategory.value
      );
      categories.value[selectedSubcategory.value] = response.data.data;
    });

    const categoryPromise = usePromise(() =>
      ProductCategoryService.getCategoryById(route.params.id)
    );

    const subcategoryPromise = usePromise(() =>
      ProductCategoryService.getSubCategoriesById(route.params.id)
    );

    store.dispatch("setLoading", true);
    subcategoryPromise
      .createPromise()
      .then(() => {
        if (subcategoryPromise.results.value.length === 0) {
          store.dispatch("setLoading", false);
          return router.push({ name: "404" });
        }

        selectedSubcategory.value = subcategoryPromise.results.value[0].id;
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });

    categoryPromise
      .createPromise()
      .then(() => {
        console.log(subcategoryPromise.results.value);
        if (subcategoryPromise.results.value.length === 0) {
          store.dispatch("setLoading", false);
          return router.push({ name: "404" });
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });

    return {
      selectedSubcategory,
      subcategoryPromise,
      categoryPromise,
      main_img,
      categories,
    };
  },
};
</script>

<style lang="scss" scoped>
.new-products-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}
</style>

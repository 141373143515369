<template>
  <router-link class="col-6 col-sm-4 col-md-3" :to="to">
    <div
      class="
        coming-you-card
        d-flex
        flex-column
        align-items-center
        border-radius
        box-shadow-4-20
        p-3
        mb-4
      "
    >
      <div class="coming-you-card-img">
        <slot name="icon" class="mb-3" />
      </div>
      <h5 class="font-s-22px font-w-600 gray-500 mb-3 coming-you-card-title">
        {{ title }}
      </h5>
      <button class="btn bg-gray-500 w-100 border-radius mb-4 coming-you-btn">
        <span class="font-s-12px font-w-500 text-white">İçerik Oluştur</span>
        <right-arrow-icon color="white" />
      </button>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ComingYouCard",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .coming-you-card-title {
    font-size: 16px !important;
  }
}
</style>

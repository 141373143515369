<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9819 20.2757H11.7383"
      stroke="#7684AA"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0717 1C16.0717 1 8.62433 1.00486 8.61269 1.00486C5.93523 1.02553 4.27734 3.23359 4.27734 6.6016V17.7829C4.27734 21.168 5.94784 23.3845 8.64858 23.3845C8.64858 23.3845 16.095 23.3809 16.1076 23.3809C18.7851 23.3602 20.4439 21.1509 20.4439 17.7829V6.6016C20.4439 3.21657 18.7725 1 16.0717 1Z"
      stroke="#7684AA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "TelIcon",
};
</script>

<style></style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getRecentPostsByCollection(collection, page = 1, per_page = 15) {
    return apiClient.get(`/api/collections/${collection}/posts/recent`, {
      params: {
        page: page,
        per_page: per_page,
      },
    });
  },
  getPost(post_id) {
    return apiClient.get(`/api/post/${post_id}`);
  },
  getPostSuggestionByPost(collection, post_id, limit = 5) {
    return apiClient.get(
      `/api/collections/${collection}/posts/${post_id}/suggest`,
      {
        params: {
          limit: limit,
        },
      }
    );
  },
};

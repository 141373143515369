import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getCities() {
    return apiClient.get("/api/cities");
  },
  getTitles() {
    return apiClient.get("/api/titles");
  },
  checkEmail(email) {
    return apiClient.post("/api/check-email", {
      email,
    });
  },
  checkGlnNumber(gln_no) {
    return apiClient.post("/api/gln-check", {
      gln_no,
    });
  },
  checkAllowance(email, phone, hospital_id, firstname, lastname) {
    return apiClient.post("/api/check-user-allowance", {
      email,
      phone,
      hospital_id,
      firstname,
      lastname,
    });
  },
  checkAllowanceEczazone(email, phone, firstname, lastname, gln_no) {
    return apiClient.post("/api/check-user-allowance", {
      email,
      phone,
      firstname,
      lastname,
      gln_no,
    });
  },
  register({
    email,
    phone,
    hospital,
    firstname,
    lastname,
    password,
    passwordConfirmation,
    city,
    title,
    uni,
    checkedCompliances,
    smsCodes,
  }) {
    return apiClient.post("/api/register", {
      email,
      phone,
      hospital_id: hospital,
      firstname,
      lastname,
      password,
      password_confirmation: passwordConfirmation,
      city_id: city,
      title_id: title,
      university_id: uni,
      compliances: {
        personal_data: checkedCompliances.includes("1"),
        personal_data_abroad: checkedCompliances.includes("2"),
        gsm: checkedCompliances.includes("5"),
        email: checkedCompliances.includes("6"),
        sms: checkedCompliances.includes("8"),
      },
      validation_codes: {
        personal_data: `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`,
        personal_data_abroad: `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`,
        gsm: checkedCompliances.includes("5")
          ? `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`
          : null,
        email: checkedCompliances.includes("6")
          ? `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`
          : null,
        sms: checkedCompliances.includes("8")
          ? `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`
          : null,
      },
    });
  },
  registerEczazone({
    email,
    phone,
    gln_no,
    pharmacy_name,
    firstname,
    lastname,
    password,
    passwordConfirmation,
    city,
    checkedCompliances,
    smsCodes,
  }) {
    return apiClient.post("/api/register", {
      email,
      phone,
      gln_no,
      pharmacy_name,
      firstname,
      lastname,
      password,
      password_confirmation: passwordConfirmation,
      city_id: city,
      compliances: {
        personal_data: checkedCompliances.includes("1"),
        personal_data_abroad: checkedCompliances.includes("2"),
        gsm: checkedCompliances.includes("5"),
        email: checkedCompliances.includes("6"),
        sms: checkedCompliances.includes("8"),
      },
      validation_codes: {
        personal_data: `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`,
        personal_data_abroad: `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`,
        gsm: checkedCompliances.includes("5")
          ? `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`
          : null,
        email: checkedCompliances.includes("6")
          ? `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`
          : null,
        sms: checkedCompliances.includes("8")
          ? `${smsCodes.value[0].smsCode},${smsCodes.value[0].smsId}`
          : null,
      },
    });
  },
};

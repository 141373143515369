<template>
  <section class="question-bank-main d-flex flex-column mb-5">
    <content-main
      title="Soru Bankası"
      nav="Soru Bankası"
      class="mt-m-100px mt-160px mb-5"
    />
    <div class="container-xl">
      <div
        v-if="!startQuestions"
        class="bg-white border-radius p-5 box-shadow-4-20-2"
      >
        <div class="d-flex justify-content-center mb-4">
          <img
            src="/new-img/question-bank.png"
            alt=""
            class="img-fluid question-bank-img"
          />
        </div>
        <div class="mb-4">
          <p class="font-s-24px text-primary text-center px-5">
            1051 sorudan oluşan NöroZone Soru Bankası,<br />
            <b>Prof. Dr. İsmet M. Melek</b> ve
            <b>Dr. Ergin Eroğlu</b> katkılarıyla hazırlanmıştır.
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <button
            class="
              btn btn-info
              border-radius
              d-flex
              justify-content-center
              align-items-center
              btn-start
            "
            @click="startQuestions = true"
          >
            <calculate-icon class="mr-1" />
            <span class="font-w-500">Başla</span>
          </button>
        </div>
      </div>

      <div
        v-if="startQuestions"
        class="bg-white border-radius questions-box box-shadow-4-20-2"
      >
        <template v-if="!isQuestionsLoading">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="d-flex align-items-center">
              <span class="font-s-40px font-weight-bold blue-300 mr-1">{{
                questions.current_page
              }}</span>
              <span class="font-s-20px font-w-500 gray-300">
                | {{ questions.last_page }}</span
              >
            </div>
            <div
              class="position-relative cursor-pointer"
              @click="openFilter = !openFilter"
            >
              <filter-icon :color="colorDark" :size="24" />
              <span class="font-w-500 text-primary">Filtrele</span>
              <div
                v-if="openFilter"
                class="
                  position-absolute
                  filter-box
                  bg-white
                  box-shadow-4-2-5
                  d-flex
                  flex-column
                  align-items-center
                  border-radius
                "
              >
                <span @click="updateQuestionsPerPage(5)"
                  >Her sayfada 5 soru</span
                >
                <span @click="updateQuestionsPerPage(10)"
                  >Her sayfada 10 soru</span
                >
                <span @click="updateQuestionsPerPage(20)"
                  >Her sayfada 20 soru</span
                >
                <span @click="updateQuestionsPerPage(50)"
                  >Her sayfada 50 soru</span
                >
                <span @click="updateQuestionsPerPage(100)"
                  >Her sayfada 100 soru</span
                >
              </div>
            </div>
          </div>
          <question
            v-for="(question, index) in questions.items"
            :key="index"
            :index="index"
            :question="question"
            :per-page="questions.per_page"
            :current-page="questions.current_page"
            :color="colorLight"
            :question-bank-id="questionBankId"
            class="mb-4"
          />
        </template>
        <loading-anim-icon v-else class="m-3 mb-5" />
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <button
              class="btn border-radius text-white mr-2 btn-prev"
              :disabled="page === 1 || isQuestionsLoading"
              @click="previousPage"
            >
              <left-arrow-icon color="#7684AA" />
              <span class="font-s-12px font-w-500 gray-500">Önceki Sayfa</span>
            </button>
            <button
              class="btn btn-info border-radius btn-next"
              :disabled="page >= questions.last_page || isQuestionsLoading"
              @click="nextPage"
            >
              <span class="font-s-12px font-w-500">Sonraki Sayfa</span>
              <right-arrow-icon color="white" />
            </button>
          </div>
          <div v-if="questions.current_page">
            <pagination
              :current-page="questions.current_page"
              :total-pages="questions.last_page"
              :disabled="isQuestionsLoading"
              @go-page="page = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import Question from "../components/ui/Question.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Pagination from "../components/ui/Pagination.vue";
import QuestionBankService from "../services/question-bank/QuestionBankService";
import { watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import SwalError from "../helpers/SwalError";

export default {
  name: "QuestionBank",
  components: {
    ContentMain,
    Question,
    Pagination,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const startQuestions = ref(false);
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorLight = ColorHelper[store.state.theme].blue300;
    const openFilter = ref(false);
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const questionBankService = new QuestionBankService();
    const questions = ref([]);
    const questionPerPage = ref(5);
    const isQuestionsLoading = ref(false);
    const page = ref(1);
    const questionBankId = computed(() => parseInt(route.params.id));

    const updateQuestionsPerPage = (perPage) => {
      questionPerPage.value = perPage;
    };

    const fetchQuestions = (newPage) => {
      page.value = newPage;
      isQuestionsLoading.value = true;
      questionBankService
        .getQuestionsWithAnswers(
          questionBankId.value,
          page.value,
          questionPerPage.value
        )
        .then((response) => {
          questions.value = response.data.data;
        })
        .catch((error) => {
          SwalError.fire(error);
        })
        .finally(() => {
          isQuestionsLoading.value = false;
        });
    };

    const previousPage = () => {
      if (page.value > 1) {
        page.value--;
      }
    };

    const nextPage = () => {
      if (page.value < questions.value.last_page) {
        page.value++;
      }
    };

    fetchQuestions(page.value);

    watch([questionPerPage, page], () => {
      fetchQuestions(page.value);
    });

    return {
      fetchQuestions,
      updateQuestionsPerPage,
      previousPage,
      nextPage,
      questionBankId,
      questionPerPage,
      page,
      isQuestionsLoading,
      questions,
      colorDark,
      main_img,
      startQuestions,
      colorLight,
      openFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-bank-main {
  background: v-bind("main_img");
  background-size: 100vw 532px;
  background-repeat: no-repeat !important;
}

.question-bank-img {
  width: 507px;
  height: 482px;
}

.btn-start {
  width: 124px;
  height: 53px;
}
.questions-box {
  padding: 40px 65px;
}
.filter-box {
  right: -20px;
  top: 70px;
  width: 223px;
  span {
    padding: 10px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    color: #7684aa;
    &:hover {
      color: #1686d6;
      background-color: #daedf654;
    }
  }
}
.btn-prev {
  background-color: #f3f4f5;
}
.btn-next,
.btn-prev {
  width: 163px;
  height: 44px;
}

@media (max-width: 768px) {
  .question-bank-main {
    background-size: 300vw 400px;
    background-position: top;
  }
}
</style>

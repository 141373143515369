<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.61803C0 0.874652 0.782313 0.391156 1.44721 0.723607L17.1056 8.55279C17.4741 8.73705 17.4741 9.26295 17.1056 9.44721L1.44721 17.2764C0.782312 17.6088 0 17.1253 0 16.382V12.6634C0 11.8162 0.533744 11.061 1.33231 10.7781L5.82063 9.18853C5.99827 9.12561 5.99827 8.87439 5.82063 8.81147L1.33231 7.22186C0.533744 6.93903 0 6.18377 0 5.3366V1.61803Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "SendIcon",
};
</script>

<style></style>

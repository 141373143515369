<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <p
        class="mb-0 font-s-12px font-w-500 text-info cursor-pointer"
        @click="openCollapse = !openCollapse"
      >
        {{ text }}
      </p>
      <div>
        <bottom-arrow-icon
          v-if="!openCollapse"
          :size="24"
          :color="color"
          class="cursor-pointer"
          @click="openCollapse = true"
        />
        <close-icon
          v-if="openCollapse"
          :size="24"
          :color="color"
          class="cursor-pointer"
          @click="openCollapse = false"
        />
      </div>
    </div>
    <transition name="fade">
      <div v-show="openCollapse">
        <vue-editor v-model="contentValue" />
      </div>
    </transition>
  </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import { computed, ref } from "vue";

export default {
  name: "AddCollapse",
  components: {
    VueEditor,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:content"],
  setup(props, ctx) {
    const openCollapse = ref(false);
    const contentValue = computed({
      get() {
        return props.content;
      },
      set(value) {
        ctx.emit("update:content", value);
      },
    });

    return {
      contentValue,
      openCollapse,
    };
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

const colors = {
  ahezone: {
    mainSlider1: "#56040C",
    mainSlider2: "#ffffff00",
    homeVideo: "#35126A8a",
    homeArticle: "#35126A",
    homeCard1: "#FF8C44",
    homeCard2: "#F96878",
    facultyTable: "#CD7AFF",
    videoDetailMain1: "#35126acc",
    videoDetailMain2: "#35126a73",
    channelDetailMain: "#35126a9c",
    articleDetailMain: "#35126ab5",
    guidesMain1: "#35126ab5",
    guidesMain2: "#35126acc",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#FD6B1A",
    loginImg2: "#FF9900",

    blueText: "#5B25AD",
    blueDark: "#35126A",
    blueLight: "#E0DAF6",
    blue300: "#7E38EA",
    red200: "#ff808f",
  },
  norozone: {
    mainSlider1: "#0345abb3",
    mainSlider2: "#ffffff00",
    homeVideo: "#053D8FCC",
    homeArticle: "#043e94cc",
    homeCard1: "#4481EB",
    homeCard2: "#04BEFE",
    facultyTable: "#F4F9FD",
    videoDetailMain1: "#053C8F",
    videoDetailMain2: "#003da699",
    channelDetailMain: "#003da699",
    articleDetailMain: "#0345abb3",
    guidesMain1: "#053d8fb3",
    guidesMain2: "#0345abcc",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#1686D6",
    loginImg2: "#18BEFF",

    blueText: "#1686D6",
    blueDark: "#114DAE",
    blueLight: "#DAEDF6",
    blue300: "#18BEFF",
    red200: "#ff808f",
  },
  dermazone: {
    mainSlider1: "#067642",
    mainSlider2: "#ffffff00",
    homeVideo: "#02a75ab6",
    homeArticle: "#067642",
    homeCard1: "#1B9352",
    homeCard2: "#47D074",
    facultyTable: "#47D074",
    videoDetailMain1: "#049f0269",
    videoDetailMain2: "#14761b78",
    channelDetailMain: "#14761b78",
    articleDetailMain: "#237d0ca1",
    guidesMain1: "#288617a3",
    guidesMain2: "#149536ad",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#48D276",
    loginImg2: "#02A75A",

    blueText: "#02A75A",
    blueDark: "#077743",
    blueLight: "#DDF8E6",
    blue300: "#48D276",
    red200: "#ff808f",
  },
  eczazone: {
    mainSlider1: "#56040C",
    mainSlider2: "#ffffff00",
    homeVideo: "#d5293ab7",
    homeArticle: "#9A0011",
    homeCard1: "#F96878",
    homeCard2: "#B41F25",
    facultyTable: "#B41F25",
    videoDetailMain1: "#af1a1ad9",
    videoDetailMain2: "#a529297a",
    channelDetailMain: "#a529297a",
    articleDetailMain: "#a529297a",
    guidesMain1: "#aa00007a",
    guidesMain2: "#9728288c",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#BD2A31",
    loginImg2: "#D6293C",

    blueText: "#D6293C",
    blueDark: "#D6293C",
    blueLight: "#FFF0F2",
    blue300: "#BD2A31",
    red200: "#ff808f",
  },
  ftrzone: {
    mainSlider1: "#A53257",
    mainSlider2: "#ffffff00",
    homeVideo: "#dd608ac0",
    homeArticle: "#ab03877a",
    homeCard1: "#E5326D",
    homeCard2: "#F0709A",
    facultyTable: "#F0709A",
    videoDetailMain1: "#b82792b5",
    videoDetailMain2: "#df5bdb69",
    channelDetailMain: "#df5bdb69",
    articleDetailMain: "#df5bdb69",
    guidesMain1: "#aa009d7a",
    guidesMain2: "#9728848c",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#DD6089",
    loginImg2: "#BB4068",

    blueText: "#DD6089",
    blueDark: "#8A1C3D",
    blueLight: "#FFE5EE",
    blue300: "#BB4068",
    red200: "#ff808f",
  },
  kbbzone: {
    mainSlider1: "#34016A",
    mainSlider2: "#ffffff00",
    homeVideo: "#7904f68a",
    homeArticle: "#5805B2",
    homeCard1: "#5401AF",
    homeCard2: "#CD7AFF",
    facultyTable: "#CD7AFF",
    videoDetailMain1: "#660acbb5",
    videoDetailMain2: "#8b13c98c",
    channelDetailMain: "#8b13c98c",
    articleDetailMain: "#8b13c98c",
    guidesMain1: "#7000aa7a",
    guidesMain2: "#6c28978c",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#7904F6",
    loginImg2: "#B144F4",

    blueText: "#7904F6",
    blueDark: "#5702B2",
    blueLight: "#F7F3FA",
    blue300: "#B144F4",
    red200: "#ff808f",
  },
  kvczone: {
    mainSlider1: "#553727",
    mainSlider2: "#ffffff00",
    homeVideo: "#cb4700a9",
    homeArticle: "#863C13",
    homeCard1: "#FFBB8A",
    homeCard2: "#FC5900",
    facultyTable: "#FFBB8A",
    videoDetailMain1: "#cb510ab5",
    videoDetailMain2: "#df8c5b69",
    channelDetailMain: "#df8c5b69",
    articleDetailMain: "#df8c5b69",
    guidesMain1: "#df8c5bb0",
    guidesMain2: "#df8c5b69",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#FD6B1A",
    loginImg2: "#FF792E",

    blueText: "#FD6B1A",
    blueDark: "#D75209",
    blueLight: "#FFF7F1",
    blue300: "#FF792E",
    red200: "#ff808f",
  },
  ortopedizone: {
    mainSlider1: "#03293A",
    mainSlider2: "#ffffff00",
    homeVideo: "#587c8b98",
    homeArticle: "#00000069",
    homeCard1: "#B2D4E3",
    homeCard2: "#3E6272",
    facultyTable: "#B2D4E3",
    videoDetailMain1: "#03293aad",
    videoDetailMain2: "#03293a80",
    channelDetailMain: "#03293a80",
    articleDetailMain: "#03293a80",
    guidesMain1: "#03293a80",
    guidesMain2: "#03293a80",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#65929C",
    loginImg2: "#5D8090",

    blueText: "#65929C",
    blueDark: "#3E6272",
    blueLight: "#EEF5F6",
    blue300: "#5D8090",
    red200: "#ff808f",
  },
  psikezone: {
    mainSlider1: "#8A4401",
    mainSlider2: "#ffffff00",
    homeVideo: "#f0a11a9f",
    homeArticle: "#CF7007",
    homeCard1: "#FFC62B",
    homeCard2: "#D97001",
    facultyTable: "#FFC62B",
    videoDetailMain1: "#ffc62b75",
    videoDetailMain2: "#ffc62b94",
    channelDetailMain: "#ffc62b94",
    articleDetailMain: "#ffc62b94",
    guidesMain1: "#ffc62b94",
    guidesMain2: "#ffc62b94",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#FB9B31",
    loginImg2: "#FFC020",

    blueText: "#FB9B31",
    blueDark: "#FB9B31",
    blueLight: "#FFF5EB",
    blue300: "#FFC020",
    red200: "#ff808f",
  },
  hemaworld: {
    mainSlider1: "#03293A",
    mainSlider2: "#ffffff00",
    homeVideo: "#587c8b98",
    homeArticle: "#00000069",
    homeCard1: "#B2D4E3",
    homeCard2: "#3E6272",
    facultyTable: "#B2D4E3",
    videoDetailMain1: "#03293aad",
    videoDetailMain2: "#03293a80",
    channelDetailMain: "#03293a80",
    articleDetailMain: "#03293a80",
    guidesMain1: "#03293a80",
    guidesMain2: "#03293a80",
    podcast1: "#82E8FF",
    podcast2: "#379FFF",
    loginImg1: "#65929C",
    loginImg2: "#5D8090",

    blueText: "#092D74",
    blueDark: "#092D74",
    blueLight: "#EEF5F6",
    blue300: "#5D8090",
    red200: "#ff808f",
  },
};

export default colors;

<template>
  <div class="coming-you mb-100px">
    <section class="coming-you-main bg-image d-flex flex-column mb-5">
      <content-main
        title="Sizden Gelenler"
        nav="Sizden Gelenler"
        class="mt-m-100px mt-160px"
      />
    </section>

    <section>
      <div class="container-xl">
        <div class="row justify-content-center">
          <coming-you-card
            :to="{ name: 'addarticle' }"
            icon="article-img"
            title="Makale"
          >
            <template #icon>
              <article-img />
            </template>
          </coming-you-card>
          <coming-you-card
            :to="{ name: 'addvideo' }"
            icon="video-img"
            title="Video"
          >
            <template #icon>
              <video-img />
            </template>
          </coming-you-card>
          <!-- Disable addClinic
          <template v-if="theme !== 'eczazone'">
            <coming-you-card
              :to="{ name: 'addclinic' }"
              icon="clinic-img"
              title="Klinik Tanıtımı"
            >
              <template #icon>
                <clinic-img />
              </template>
            </coming-you-card>
            <coming-you-card
            :to="{ name: 'addcasereport' }"
            icon="phenomenon-img"
            title="Olgu"
            >
            <template #icon>
              <case-img />
            </template>
          </coming-you-card>
        </template>
        --></div>
      </div>
    </section>
  </div>
</template>

<script>
import ArticleImg from "../components/icon/img/ArticleImg.vue";
// import ClinicImg from "../components/icon/img/ClinicImg.vue";
// import CaseImg from "../components/icon/img/CaseImg.vue";
import VideoImg from "../components/icon/img/VideoImg.vue";
import ContentMain from "../components/ui/ContentMain.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import ComingYouCard from "../components/ComingYou/ComingYouCard";

export default {
  name: "ComingYou",
  components: {
    ComingYouCard,
    ContentMain,
    ArticleImg,
    VideoImg,
    // ClinicImg,
    // CaseImg,
  },
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const color = ColorHelper[theme].blueText;
    const main_img = "url('/new-img/" + theme + "-content-main-bg.png')";

    return {
      color,
      main_img,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.coming-you-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}

.coming-you-btn {
  height: 36px;
}

.coming-you-card:hover h5 {
  color: v-bind("color");
}

.coming-you-card:hover button {
  background-color: v-bind("color");
}

@media (max-width: 576px) {
  .coming-you-main {
    height: 200px;
  }
}
</style>

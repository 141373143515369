<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 89 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.5002 81.5835V69.8616"
      :stroke="color"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.501 55.0616V55.0616C36.1791 55.0616 29.4385 48.2892 29.4385 39.932V22.5501C29.4385 14.1929 36.1791 7.41663 44.501 7.41663C52.819 7.41663 59.5597 14.1929 59.5597 22.5501V39.932C59.5597 48.2892 52.819 55.0616 44.501 55.0616Z"
      :stroke="color"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M74.1663 40.0519C74.1663 56.5126 60.8832 69.8584 44.4997 69.8584C28.1123 69.8584 14.833 56.5126 14.833 40.0519"
      :stroke="color"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M52.1719 25.0527H59.5497"
      :stroke="color"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.4688 37.4298H59.5568"
      :stroke="color"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "VoiceIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="mb-5 py-5 pb-1 home-videos bg-image">
    <div v-if="popularVideos.total" class="container-xl">
      <section-title
        title-dark="En Çok İzlenen"
        title-blue="Videolar"
        :more-title="{ title: 'Tüm Videolar', routeName: 'videos' }"
      />

      <div
        class="
          d-flex
          align-items-center
          flex-column flex-lg-row
          align-items-lg-start
        "
      >
        <div
          v-if="mainVideo"
          class="mr-4 w-100 home-video d-flex flex-column align-items-center"
        >
          <div
            class="
              d-flex
              flex-column
              videos-xl-th
              bg-image
              border-radius
              mb-4
              px-2 px-md-4
              pt-2 pt-md-4
            "
            :style="{ background: mainVideoBackground }"
          >
            <div class="d-flex justify-content-between text-white">
              <div class="d-flex align-items-center">
                <div class="home-video-person mr-2">
                  <img
                    :src="mainVideo.category.thumbnail"
                    class="rounded-circle img-fluid"
                    alt=""
                  />
                </div>
                <div class="d-flex flex-column">
                  <span class="font-w-600 home-video-name">{{
                    mainVideo.category.title
                  }}</span>
                  <span class="font-s-12px home-video-number">{{
                    mainVideo.title
                  }}</span>
                </div>
              </div>
              <div class="d-flex flex-column-reverse flex-sm-row">
                <div class="d-flex align-items-center mr-md-5">
                  <span>
                    <view-icon class="mr-2" />
                  </span>
                  <span class="font-s-12px">{{ mainVideo.view_count }}</span>
                </div>
                <div class="d-flex align-items-center mr-2 mr-md-3">
                  <span>
                    <share-icon class="mr-2" />
                  </span>
                  <span class="font-s-12px">Paylaş</span>
                </div>
              </div>
            </div>
            <div
              class="
                cursor-pointer
                h-100
                d-flex
                justify-content-center
                align-items-center
                flex-1
                pb-70px
              "
            >
              <router-link
                :to="{ name: 'videodetail', params: { id: mainVideo.id } }"
              >
                <play-icon :size="90" class="d-none d-sm-block" />
                <play-icon :size="60" class="d-sm-none" />
              </router-link>
            </div>
          </div>
          <div
            class="
              d-flex
              flex-column
              justify-content-start
              flex-sm-row
              justify-content-sm-between
              align-items-center
              w-100
            "
          >
            <h4 class="font-s-20px font-weight-bold">
              {{ mainVideo.title }}
            </h4>
            <div class="d-flex">
              <save-icon
                favoritable-type="video"
                :favoritable-id="mainVideo.id"
                :is-favorited="mainVideo.is_favorited"
                :color="color"
                class="mr-1"
                text="Daha Sonra izlemek için Kaydet"
                favorited-text="Daha Sonra izlemek için Kaydedildi"
                text-classes="font-s-12px text-info"
              />
            </div>
          </div>
        </div>

        <div class="swiper-videos" style="width: 360px">
          <div class="swiper-container mySwiperVideos">
            <div class="swiper-wrapper">
              <div
                v-for="(item, index) in sliderVideos"
                :key="index"
                class="
                  swiper-slide
                  d-flex
                  flex-column flex-lg-row
                  cursor-pointer
                  bg-transparent
                "
              >
                <div class="mr-2 home-video-slider-image">
                  <router-link
                    :to="{ name: 'videodetail', params: { id: item.id } }"
                  >
                    <img
                      :src="item.thumbnail"
                      alt=""
                      class="img-fluid home-video-slider-th border-radius"
                    />
                  </router-link>
                </div>
                <div class="d-flex flex-column justify-content-start">
                  <router-link
                    :to="{ name: 'videodetail', params: { id: item.id } }"
                  >
                    <h4
                      class="
                        font-s-16px font-weight-bold
                        text-left
                        slider-item-title
                        black-700
                      "
                    >
                      {{ item.title }}
                    </h4>
                  </router-link>
                  <span class="font-s-12px text-left">{{
                    item.category.title
                  }}</span>
                </div>
              </div>
            </div>
            <div class="swiper-pagination" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container-xl">Video Bulunamadı.</div>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle.vue";
import Swiper from "swiper/bundle";
import { onMounted, ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "HomeVideos",
  components: {
    SectionTitle,
  },
  props: {
    popularVideos: { type: Object, required: true },
  },
  setup(props) {
    const sliderVideos = ref([]);
    const mainVideo = ref({});
    const store = useStore();
    const homeVideo = ColorHelper[store.state.theme].homeVideo;
    const color = ColorHelper[store.state.theme].blueText;
    const mainVideoBackground = ref(
      props.popularVideos.items[0]
        ? "linear-gradient(0deg, " +
            homeVideo +
            ", " +
            homeVideo +
            "), url(" +
            props.popularVideos.items[0].thumbnail +
            ")"
        : ""
    );

    sliderVideos.value = props.popularVideos.items.filter(
      (item, index) => index > 0
    );
    mainVideo.value = props.popularVideos.items.filter(
      (item, index) => index == 0
    )[0];

    onMounted(() => {
      new Swiper(".mySwiperVideos", {
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        slidesPerColumn: 4,
        spaceBetween: 24,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            slidesPerColumn: 1,
            spaceBetween: 24,
          },
          640: {
            slidesPerView: 2,
            slidesPerColumn: 1,
            spaceBetween: 24,
          },
          992: {
            slidesPerView: 1,
            slidesPerColumn: 4,
            spaceBetween: 24,
          },
        },
      });
    });

    return {
      sliderVideos,
      mainVideo,
      mainVideoBackground,
      color,
    };
  },
};
</script>

<style lang="scss" scoped>
.home-videos {
  background-image: url("/new-img/home-videos-bg.png");
}

.swiper-videos {
  .swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 470px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 87px;
    justify-content: inherit !important;
  }

  .home-video-slider-image {
    width: 116px;
  }
}

.home-video-person {
  min-width: 56px;
  max-width: 56px;
  min-height: 56px;
  max-height: 56px;
}

.videos-xl-th {
  max-width: 740px;
  width: 100%;
  height: 420px;
}

.slider-item-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.home-video-slider-th {
  min-width: 116px;
  height: 87px;
  object-fit: cover;
}

@media (max-width: 992px) {
  .home-video {
    margin: 0 !important;
  }
  .swiper-container {
    height: 240px !important;
    margin-top: 20px;
    overflow: inherit;
  }
  .home-video-slider-th {
    min-width: 170px !important;
    object-fit: cover;
  }
  .home-video-slider-image {
    margin: 0 0 10px 0 !important;
    width: initial !important;
  }
  .swiper-slide {
    height: 215px !important;
  }
  .slider-item-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 576px) {
  .videos-xl-th {
    height: 275px;
  }
  .home-video-name {
    font-size: 12px;
  }
  .home-video-number {
    font-size: 10px;
  }
}
</style>

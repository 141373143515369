<template>
  <div class="d-flex justify-content-between align-items-start mb-35">
    <h3 class="section-title font-s-25px font-weight-normal">
      <span :class="titleDarkClass">{{ titleDark }}</span>
      <span class="text-info font-weight-bold">{{ titleBlue }}</span>
    </h3>
    <div v-if="moreTitle" class="d-flex align-items-center">
      <router-link
        :to="{ name: moreTitle.routeName }"
        class="text-info font-s-12px mr-2"
      >
        {{ moreTitle.title }}
      </router-link>
      <more-arrow-icon :color="colorMoreIcon" />
    </div>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "SectionTitle",
  props: {
    titleDark: {
      type: String,
      required: true,
    },
    titleDarkClass: {
      type: String,
      required: false,
      default: "mr-2",
    },
    titleBlue: {
      type: String,
      required: true,
    },
    moreTitle: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const store = useStore();
    const colorMoreIcon = ColorHelper[store.state.theme].blueText;

    return {
      colorMoreIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .section-title {
    font-size: 18px;
  }
}
</style>

<template>
  <button
    v-if="user"
    :disabled="isFavoriting"
    :class="
      buttonClasses + (isButton === true ? '' : 'bg-transparent border-0 p-0')
    "
    @click="handleFavorite"
  >
    <svg
      v-if="propIsFavorited"
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.22 3.28065C1.0802 3.15596 0.896982 3.09081 0.709581 3.09914C0.52218 3.10747 0.345506 3.18863 0.217404 3.32523C0.154691 3.39253 0.106058 3.47161 0.0743475 3.55785C0.0426367 3.64409 0.0284833 3.73576 0.0327138 3.82752C0.0369444 3.91927 0.0594742 4.00927 0.0989872 4.09225C0.1385 4.17522 0.194204 4.24952 0.262846 4.3108L3.60284 7.31018C3.79856 7.48475 4.05509 7.57597 4.31746 7.5643C4.57983 7.55262 4.82718 7.43898 5.00652 7.24772C5.02207 7.23079 5.02207 7.23079 5.03689 7.21339L9.80401 1.57867C9.8636 1.50848 9.90854 1.42714 9.9362 1.33942C9.96387 1.2517 9.97371 1.15935 9.96513 1.0678C9.95656 0.976243 9.92975 0.887312 9.88627 0.806212C9.84279 0.725112 9.78352 0.653471 9.71193 0.595484C9.56654 0.477319 9.38052 0.420607 9.1937 0.437495C9.00688 0.454384 8.83412 0.543532 8.71244 0.68584L4.2309 5.98417L1.22 3.28065Z"
        :fill="color"
        :stroke="color"
        stroke-width="0.5"
      />
    </svg>
    <svg
      v-else
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3924 2.99561H4.31669C4.15835 2.99561 4 3.15213 4 3.30865V14.6826C4 14.7869 4.05278 14.8913 4.15835 14.9434C4.26391 14.9956 4.36948 14.9956 4.47504 14.9434L8.32816 12.5956L12.1813 14.9434C12.2341 14.9956 12.2868 14.9956 12.3396 14.9956C12.3924 14.9956 12.4452 14.9956 12.498 14.9434C12.6035 14.8913 12.6563 14.7869 12.6563 14.6826V3.30865C12.7091 3.15213 12.5507 2.99561 12.3924 2.99561ZM12.0229 14.1086L8.4865 11.9695C8.38094 11.9173 8.27537 11.9173 8.16981 11.9695L4.63339 14.1086V3.62169H12.0229V14.1086ZM8.69763 9.72604C8.69763 9.88256 8.53929 10.0391 8.38094 10.0391C8.27537 10.0391 8.22259 9.98691 8.16981 9.93474C8.11703 9.88256 8.06424 9.77821 8.06424 9.72604V8.16082H6.48077C6.21686 8.16082 6.1113 8.0043 6.1113 7.79561C6.1113 7.63908 6.26964 7.48256 6.42799 7.48256H8.01146V5.91734C8.01146 5.70865 8.16981 5.6043 8.32816 5.6043C8.4865 5.6043 8.64485 5.76082 8.64485 5.91734V7.48256H10.2283C10.3867 7.48256 10.545 7.63908 10.545 7.79561C10.545 7.95213 10.3867 8.10865 10.2283 8.10865H8.64485L8.69763 9.72604Z"
        :fill="color"
        :stroke="color"
        stroke-width="0.5"
      />
    </svg>
    <span v-if="text !== null" :class="textClasses" class="ml-1 save-text">{{
      spanText
    }}</span>
  </button>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import FavoriteService from "../../services/favorite/FavoriteService";
import { useStore } from "vuex";

export default {
  name: "SaveIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
    text: {
      type: String,
      default: null,
    },
    favoritedText: {
      type: String,
      default: null,
    },
    textClasses: {
      type: String,
      required: false,
      default: "",
    },
    buttonClasses: {
      type: String,
      required: false,
      default: "",
    },
    isButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFavorited: {
      type: Boolean,
      required: true,
    },
    favoritableType: {
      type: String,
      required: true,
    },
    favoritableId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const propIsFavorited = ref(props.isFavorited);
    const isFavoriting = ref(false);
    const spanText = computed(() => {
      return propIsFavorited.value ? props.favoritedText : props.text;
    });

    const handleFavorite = () => {
      isFavoriting.value = true;
      propIsFavorited.value = !propIsFavorited.value;
      FavoriteService.favorite(
        user.value.id,
        props.favoritableType,
        props.favoritableId
      )
        .catch(() => {
          propIsFavorited.value = !propIsFavorited.value;
        })
        .finally(() => {
          isFavoriting.value = false;
        });
    };

    return {
      propIsFavorited,
      isFavoriting,
      handleFavorite,
      spanText,
      user,
    };
  },
};
</script>

<style scoped>
.btn:focus {
  box-shadow: none;
}

.btn[disabled] {
  opacity: 1;
}

@media (max-width: 576px) {
  .save-text {
    font-size: 10px !important;
  }
}
</style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1609 12.0531C15.1609 13.7991 13.7449 15.2141 11.9989 15.2141C10.2529 15.2141 8.83789 13.7991 8.83789 12.0531C8.83789 10.3061 10.2529 8.89108 11.9989 8.89108C13.7449 8.89108 15.1609 10.3061 15.1609 12.0531Z"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.48888 15.806 4.75089 11.998 4.75089H12.002C8.194 4.75089 4.711 7.48888 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M3.5 20L12 12L20.5 3.5" stroke="#AFBBDB" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  name: "HidePassword",
};
</script>

<style></style>

<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.3277 31.2736C75.3277 33.8414 74.2563 35.6464 72.9224 35.6464C71.5885 35.6464 70.4922 33.8456 70.4922 31.2736C70.4922 28.7058 71.576 26.6216 72.9099 26.6216C74.2438 26.6258 75.3277 28.7058 75.3277 31.2736Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.4291 55.747C32.4291 55.747 23.0333 44.5462 29.2985 36.0508C31.5037 33.062 35.0761 31.3904 38.7902 31.3904H46.9355"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.4922 48.0144C68.7511 48.6564 69.5973 48.6355 70.1767 48.3854C73.4282 46.9806 72.5486 36.4009 70.2392 35.7673"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M65.1367 35.8799C68.4173 35.8799 72.9235 35.6465 72.9235 35.6465"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.715 39.1396C60.6983 39.2063 60.6566 39.3355 60.6316 39.5064C60.1731 42.2326 63.1452 44.6587 64.9585 46.2302C65.5505 46.743 68.385 48.5104 68.5893 48.9189C70.6485 53.0624 71.9158 57.6561 71.8908 62.304C71.8616 67.4438 70.2734 72.6711 68.1891 77.344C66.6051 80.8914 63.9956 85.4935 59.627 85.7894C56.8299 85.977 54.0329 85.0391 51.4817 83.9803C48.2011 82.613 44.6579 80.6163 42.0651 78.1527C40.777 76.9313 31.6396 68.2858 32.4358 55.747C32.7026 51.5493 33.9781 48.5438 34.9036 46.3595C35.8873 44.0376 39.7098 35.3754 49.2641 30.1023C49.3933 30.0314 49.5309 29.9564 49.6726 29.8813C53.7494 27.7095 58.3139 26.6257 62.9285 26.6257H64.5292H72.9162"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M65.1298 35.8757H63.5166C54.5543 35.8757 47.0176 42.7621 46.3923 51.6994C46.3923 51.7161 46.3882 51.7953 46.3798 51.912C46.2548 53.2793 45.5295 54.3256 44.825 55.0634C43.662 55.7179 42.499 56.3765 41.3359 57.0309"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.6445 32.1074C35.4491 28.8476 37.1998 25.7546 39.5425 23.6245C39.5759 23.5911 39.6092 23.5619 39.6426 23.5286C40.7764 22.4323 41.2224 20.8024 40.7264 19.2976L39.9927 17.0507C39.8343 16.5839 40.1053 16.0795 40.5722 15.9294L42.8899 15.2124C43.3484 15.0665 43.8444 15.325 43.9987 15.7835L44.6906 17.8928C45.095 19.11 46.3289 19.8436 47.5836 19.606L47.6294 19.6019C47.6294 19.6019 47.6461 19.5977 47.6753 19.5935C48.7841 19.3601 49.6137 18.4472 49.7429 17.3133L49.993 14.8247C50.043 14.3745 50.439 14.0411 50.8934 14.0827L53.4195 14.3037C53.8822 14.3412 54.224 14.758 54.174 15.2166L53.8822 18.0678C53.7905 18.9599 54.3574 19.7853 55.2287 20.0104L55.2703 20.0229C56.1624 20.2521 57.0795 19.7936 57.4213 18.9391L58.4926 16.2795C58.6927 15.7752 59.2513 15.5292 59.7682 15.7126L61.8316 16.4671C62.361 16.6589 62.6278 17.255 62.4193 17.776L61.4147 20.3105C60.927 21.5277 61.223 22.92 62.1734 23.812C63.078 24.6583 63.8825 25.6003 64.5745 26.6216"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39.543 23.6203C40.0765 23.1201 41.0186 22.3823 42.3776 21.9696C42.9737 21.7904 43.5156 21.7112 43.9741 21.6862"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.6289 19.606C47.6289 19.606 49.9466 19.0974 50.9804 19.7018"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.2695 20.027C55.6447 20.1562 56.1533 20.3855 56.6868 20.7815C57.1078 21.0941 57.4205 21.4276 57.6372 21.6944"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.1937 41.5824V20.027C27.1937 20.027 27.0686 13.1115 21.4453 11.8734"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.9297 10.4728C28.9297 10.4728 32.252 11.4774 33.1941 18.7098C33.3358 19.7811 34.232 20.5939 35.3158 20.5939H37.2"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "KardiyovaskulerSistem",
};
</script>

<style></style>

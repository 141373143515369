<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="ASDAS Skoru Hesaplama Aracı"
        nav="ASDAS Skoru Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="
                      red-200
                      font-s-30px font-weight-bold
                      return-result-number
                    "
                  ></h4>
                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-result-text
                      text-center
                    "
                  >
                    <p>
                      ASDAS-CRP: <strong><span id="crp_out" /></strong>
                    </p>
                    <p>
                      ASDAS-ESR: <strong><span id="esr_out" /></strong>
                    </p>
                  </span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-16px mb-3 font-w-600 black-700">
                  Back Pain (BASDAI Question 2) [0-10]
                </h5>
                <input
                  id="bp"
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="bp"
                  :size="6"
                  tabindex="1"
                  maxlength="6"
                  @onkeyup="validateVAS('bp', 'Back Pain', false)"
                />
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-16px mb-3 font-w-600 black-700">
                  Duration Morning Stiffness (BASDAI Question 6) [0-10]
                </h5>
                <input
                  id="dms"
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="dms"
                  :size="6"
                  tabindex="3"
                  maxlength="6"
                  @onkeyup="
                    validateVAS('dms', 'Duration Morning Stiffness', false)
                  "
                />
              </div>
              <div class="mr-md-5 mb-5 d-flex flex-column">
                <h5 class="font-s-16px mb-3 font-w-600 black-700">
                  C-Reactive Protein
                </h5>
                <div class="d-flex">
                  <div class="d-flex align-items-baseline mr-3 mb-2">
                    <input
                      id="crp_unit_l"
                      type="radio"
                      class="mr-1"
                      name="crp_unit"
                      checked="checked"
                    />
                    <label class="text-secondary" for="crp_unit_l">mg/l</label>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="crp_unit_dl"
                      type="radio"
                      class="mr-1"
                      name="crp_unit"
                    />
                    <label class="text-secondary" for="crp_unit_dl"
                      >mg/dl</label
                    >
                  </div>
                </div>
                <input
                  id="crp"
                  type="text"
                  class="
                    w-100
                    px-3
                    mb-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="crp"
                  :size="6"
                  tabindex="5"
                  maxlength="6"
                  @onblur="validateCRP('crp')"
                />
                <span
                  >A CRP value ( 2mg/l (0.2 mg/dl) is not allowed. If CRP is
                  below the limit of detection or is ( 2 mg/l (( 0.2 mg/dl), the
                  fixed value of 2 mg/l (0.2 mg/dl) will be entered.</span
                >
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-16px mb-3 font-w-600 black-700">
                  Peripheral Pain/Swelling (BASDAI Question 3) [0-10]
                </h5>
                <input
                  id="pps"
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="pps"
                  :size="6"
                  tabindex="2"
                  maxlength="6"
                  @onkeyup="
                    validateVAS('pps', 'Peripheral Pain/Swelling', false)
                  "
                />
              </div>
              <div class="mb-5">
                <h5 class="font-s-16px mb-3 font-w-600 black-700">
                  Patient Global [0-10]
                </h5>
                <input
                  id="pg"
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="pg"
                  :size="6"
                  tabindex="4"
                  maxlength="6"
                  @onkeyup="validateVAS('pg', 'Patient Global', false)"
                />
              </div>
              <div class="mb-45">
                <h5 class="font-s-16px mb-3 font-w-600 black-700">
                  Erythrocyte Sedimentation Rate (mm/hr)
                </h5>
                <input
                  id="esr"
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="esr"
                  :size="6"
                  tabindex="6"
                  maxlength="6"
                  @onkeyup="validateESR('esr')"
                />
              </div>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius btn-calculate"
              @click="
                showResult = true;
                calcASDAS();
              "
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";
import { ref } from "vue";
import $ from "jquery";

export default {
  name: "AsdasScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const clearall = () => {
      document.getElementById("bp").value = "";
      document.getElementById("dms").value = "";
      document.getElementById("pg").value = "";
      document.getElementById("pps").value = "";
      document.getElementById("crp").value = "";
      document.getElementById("esr").value = "";
      $("#crp_out").html("");
      $("#esr_out").html("");
      document.getElementById("bp").focus();
      $(".return-result-text").slideUp();
    };

    const calcASDAS = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      comma_to_dot();
      $(".return-result-text").slideDown();
      $("#crp_out").html("");
      $("#esr_out").html("");
      if (validateEmptyFields()) {
        if (validateVAS("bp", "Back Pain", true)) {
          if (validateVAS("dms", "Duration Morning Stiffness", true)) {
            if (validateVAS("pg", "Patient Global", true)) {
              if (validateVAS("pps", "Peripheral Pain/Swelling", true)) {
                if (validateCRPESR()) {
                  calcASDASCRP();
                  calcASDASESR();
                }
              }
            }
          }
        }
      }
    };

    const validateEmptyFields = () => {
      var crsField = "";
      var nEmptyFields = 0;

      if (document.getElementById("bp").value == "") {
        if (crsField == "") crsField = "bp";
        nEmptyFields++;
      }
      if (document.getElementById("dms").value == "") {
        if (crsField == "") crsField = "dms";
        nEmptyFields++;
      }
      if (document.getElementById("pg").value == "") {
        if (crsField == "") crsField = "pg";
        nEmptyFields++;
      }
      if (document.getElementById("pps").value == "") {
        if (crsField == "") crsField = "pps";
        nEmptyFields++;
      }
      if (
        document.getElementById("crp").value == "" &&
        document.getElementById("esr").value == ""
      ) {
        if (crsField == "") crsField = "crp";
        nEmptyFields++;
      }
      if (nEmptyFields <= 1) return true;
      else {
        alert("Input missing values, please.");
        document.getElementById(crsField).focus();
        return false;
      }
    };

    const comma_to_dot = () => {
      document.getElementById("bp").value = document
        .getElementById("bp")
        .value.replace(/,/, ".");
      document.getElementById("dms").value = document
        .getElementById("dms")
        .value.replace(/,/, ".");
      document.getElementById("pg").value = document
        .getElementById("pg")
        .value.replace(/,/, ".");
      document.getElementById("pps").value = document
        .getElementById("pps")
        .value.replace(/,/, ".");
      document.getElementById("crp").value = document
        .getElementById("crp")
        .value.replace(/,/, ".");
      document.getElementById("esr").value = document
        .getElementById("esr")
        .value.replace(/,/, ".");
    };

    const validateVAS = (fieldName, fieldDesc, emptyField) => {
      var vasValue = document.getElementById(fieldName).value.replace(/,/, ".");

      if (vasValue.length < 1 && emptyField) {
        alert("Input " + fieldDesc + ", please.");
        document.getElementById(fieldName).focus();
        return false;
      }

      if (isNaN(vasValue)) {
        alert("Incorrect input.");
        document.getElementById(fieldName).focus();
        document.getElementById(fieldName).select();
        return false;
      }

      if (vasValue < 0) {
        alert("Input is less than zero.");
        document.getElementById(fieldName).focus();
        document.getElementById(fieldName).select();
        return false;
      }

      if (vasValue > 10) {
        alert("Input is greater than ten.");
        document.getElementById(fieldName).focus();
        document.getElementById(fieldName).select();
        return false;
      }

      return true;
    };

    const validateCRP = () => {
      var value = document.getElementById("crp").value.replace(/,/, ".");
      if (document.getElementById("crp_unit_dl").checked) value = value * 10;

      if (isNaN(value)) {
        alert("Incorrect input.");
        document.getElementById("crp").focus();
        document.getElementById("crp").select();
        return false;
      }

      if (value < 0) {
        alert("Input is less than zero.");
        document.getElementById("crp").focus();
        document.getElementById("crp").select();
        return false;
      }

      if (value < 2) {
        //alert('A CRP value <2mg/l (0.2 mg/dl) is not allowed. If CRP is below the limit of detection or is <2 mg/l (<0.2 mg/dl), the fixed value of 2 mg/l (0.2 mg/dl) should be entered.');
        if (document.getElementById("crp_unit_dl").checked)
          document.getElementById("crp").value = 0.2;
        //alert('A CRP value <2mg/l (0.2 mg/dl) is not allowed. If CRP is below the limit of detection or is <2 mg/l (<0.2 mg/dl), the fixed value of 2 mg/l (0.2 mg/dl) should be entered.');
        else document.getElementById("crp").value = 2;
        //document.getElementById('crp').focus();
        //document.getElementById('crp').select();
        return false;
      }

      return true;
    };

    const validateESR = () => {
      var value = document.getElementById("esr").value.replace(/,/, ".");

      if (isNaN(value)) {
        alert("Incorrect input.");
        document.getElementById("esr").focus();
        document.getElementById("esr").select();
        return false;
      }

      if (value < 0) {
        alert("Input is less than zero.");
        document.getElementById("esr").focus();
        document.getElementById("esr").select();
        return false;
      }

      return true;
    };

    const validateCRPESR = () => {
      if (
        document.getElementById("crp").value.length < 1 &&
        document.getElementById("esr").value.length < 1
      ) {
        alert("Input either CRP or ESR, please.");
        document.getElementById("crp").focus();
        return false;
      }

      if (document.getElementById("crp").value.length > 0) {
        if (!validateCRP("crp")) return false;
      }

      if (document.getElementById("esr").value.length > 0) {
        if (!validateESR("esr")) return false;
      }

      return true;
    };

    const round1 = (x) => {
      var k = (Math.round(x * 10) / 10).toString();
      k += k.indexOf(".") == -1 ? ".0" : "0";
      return k.substring(0, k.indexOf(".") + 2);
    };

    const round2 = (x) => {
      var k = (Math.round(x * 100) / 100).toString();
      k += k.indexOf(".") == -1 ? ".00" : "00";
      return k.substring(0, k.indexOf(".") + 3);
    };

    const calcASDASCRP = () => {
      if (document.getElementById("crp").value.length < 1) return;
      var crp = document.getElementById("crp").value;
      if (document.getElementById("crp_unit_dl").checked) crp = crp * 10;
      var result =
        0.12 * document.getElementById("bp").value +
        0.06 * document.getElementById("dms").value +
        0.11 * document.getElementById("pg").value +
        0.07 * document.getElementById("pps").value +
        0.58 * Math.log(1 + crp * 1);
      $("#crp_out").html(round1(result));
    };

    const calcASDASESR = () => {
      if (document.getElementById("esr").value.length < 1) return;

      var result =
        0.08 * document.getElementById("bp").value +
        0.07 * document.getElementById("dms").value +
        0.11 * document.getElementById("pg").value +
        0.09 * document.getElementById("pps").value +
        0.29 * Math.sqrt(document.getElementById("esr").value);
      $("#esr_out").html(round1(result));
    };

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      clearall,
      validateVAS,
      validateCRP,
      calcASDAS,
      round2,
      apgar: [
        {
          title: "ASDAS Disease Activity States",
          content: `<img src="/new-img/asdas-1.png" class="img-fluid mt-3" alt="">`,
        },
        {
          title: "ASDAS Improvement Criteria",
          content: `<img src="/new-img/asdas-2.png" class="img-fluid mt-3" alt="">`,
        },
      ],
      references: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

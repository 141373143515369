<template>
  <div class="mt-4 mt-md-0">
    <h4 class="font-weight-normal font-s-24px mb-4 d-none d-md-block">
      Genel <b class="text-info">Bilgiler</b>
    </h4>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">İsim</span>
          <base-input type="text" name="firstname" :show-error-msg="false" />
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Soyisim</span>
          <base-input type="text" name="lastname" :show-error-msg="false" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >E-Posta Adresi</span
          >
          <base-input
            type="email"
            :disabled="true"
            name="email"
            :show-error-msg="false"
            info-message="Email değiştirilemez!"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Doğum Tarihi</span>
          <base-input name="dob" type="date" :show-error-msg="false" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">GSM No</span>
          <base-input
            v-mask="'(5##) ### ####'"
            type="tel"
            :disabled="true"
            name="phone"
            info-message="GSM No değiştirilemez!"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex mb-4">
          <span class="font-s-12px font-w-600 gray-500 mr-4">Cinsiyet</span>
          <div class="mr-3">
            <base-input
              id="genderNo"
              type="radio"
              name="gender"
              value=""
              label="Belirtmek İstemiyorum"
            />
          </div>

          <div class="mr-3">
            <base-input
              id="genderFemale"
              type="radio"
              name="gender"
              value="female"
              label="Kadın"
            />
          </div>

          <div class="mr-3">
            <base-input
              id="genderMale"
              type="radio"
              name="gender"
              value="male"
              label="Erkek"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Üniversite</span>
          <base-select
            name="university_id"
            :options="universities"
            placeholder="Mezun Olduğunuz Üniversite"
            option-value-key="id"
            option-text-key="name"
            :loading="!universities.length"
          />
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-6">
            <div class="d-flex flex-column mb-4">
              <span class="font-s-12px font-w-600 gray-500 mb-3"
                >Giriş Tarihi</span
              >
              <base-input type="date" name="uni_start_date" />
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex flex-column mb-4">
              <span class="font-s-12px font-w-600 gray-500 mb-3"
                >Bitiş Tarihi</span
              >
              <base-input type="date" name="uni_end_date" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3"
            >Kısa Özgeçmiş</span
          >
          <base-input name="bio" type="textarea" cols="30" rows="10" />
        </div>
      </div>
    </div>
    <div class="row">
      <settings-action-buttons
        submit-button-text="Genel Bilgileri Güncelle"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import * as yup from "yup";
import { mask } from "vue-the-mask";
import SettingsActionButtons from "./SettingsActionButtons";
import BaseInput from "../ui/RegisterForm/BaseInput";
import BaseSelect from "../ui/RegisterForm/BaseSelect";
import { useForm } from "vee-validate";
import UniversityService from "../../services/university/UniversityService";
import { ref } from "vue";
import UserServiceNew from "../../services/UserServiceNew";
import { showError } from "../../shared/utils";

const universityService = new UniversityService();
const userService = new UserServiceNew();

export default {
  name: "SettingsGeneralInformation",
  directives: { mask },
  components: { BaseSelect, BaseInput, SettingsActionButtons },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["update-success", "update-fail"],
  setup(props, { emit }) {
    const universities = ref([]);

    const form = useForm({
      validationSchema: yup.object({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().required().email(),
        phone: yup.string().min(14).required(),
        gender: yup.mixed().nullable().required(),
        bio: yup.string().required(),
        university_id: yup.number().required(),
        uni_start_date: yup.date().required(),
        uni_end_date: yup.date().required(),
      }),
      initialValues: {
        firstname: props.user.firstname,
        lastname: props.user.lastname,
        email: props.user.email,
        phone: props.user.phone,
        dob: props.user.dob,
        gender: props.user.gender,
        bio: props.user.bio,
        university_id: props.user.university_id,
        uni_start_date: props.user.uni_start_end_year?.split("@")[0],
        uni_end_date: props.user.uni_start_end_year?.split("@")[1],
      },
    });

    const fieldLabels = {
      firstname: "İsim",
      lastname: "Soyisim",
      email: "E-Posta Adresi",
      phone: "GSM No",
      gender: "Cinsiyet",
      bio: "Kısa Özgeçmiş",
      university_id: "Üniversite",
      uni_start_date: "Üniversite Giriş Tarihi",
      uni_end_date: "Üniversite Bitiş Tarihi",
    };

    async function handleSubmit() {
      const { valid, errors } = await form.validate();
      if (!valid) {
        return showError(valid, errors, fieldLabels);
      }

      const values = {
        ...form.values,
        uni_start_end_year:
          form.values.uni_start_date + "@" + form.values.uni_end_date,
      };
      try {
        await userService.updateGeneralInformations(values);
        emit("update-success");
      } catch (e) {
        emit("update-fail");
      }
    }

    async function fetchUniversities() {
      universities.value = await universityService.all();
    }

    fetchUniversities();

    return {
      form,
      universities,
      handleSubmit,
    };
  },
};
</script>

<style scoped></style>

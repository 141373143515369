<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3485 41.4434C26.5441 41.4434 27.5134 40.4884 27.5134 39.3105C27.5134 38.1325 26.5441 37.1776 25.3485 37.1776C24.1529 37.1776 23.1836 38.1325 23.1836 39.3105C23.1836 40.4884 24.1529 41.4434 25.3485 41.4434Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.286 83.8804C69.4816 83.8804 70.4509 82.9255 70.4509 81.7475C70.4509 80.5695 69.4816 79.6146 68.286 79.6146C67.0904 79.6146 66.1211 80.5695 66.1211 81.7475C66.1211 82.9255 67.0904 83.8804 68.286 83.8804Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M54.2979 33.7424V37.6288H50.4676V41.4023H46.5333V37.6288H42.7031V33.7424H46.5333V29.974H50.4676V33.7424H54.2979Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.0992 47.3856C61.6232 44.7862 63.174 41.269 63.174 37.4032C63.1688 30.2611 51.2983 13.3776 48.4985 9.49634C45.7039 13.3776 33.8281 30.2611 33.8281 37.398C33.8281 41.3408 35.4362 44.9144 38.0434 47.5241"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3711 68.1299C45.3615 68.5913 46.5741 74.9182 79.8959 74.9182"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.6836 67.3301C59.6979 68.9092 67.7174 70.4935 82.6272 70.714"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3711 43.2173C29.7076 44.2786 30.9149 52.0256 48.4996 52.0256C66.0842 52.0256 67.2968 44.2786 82.628 43.2173"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.4335 55.1942C28.996 52.9587 25.1449 49.498 16.0586 48.5135"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M75.4097 49.5442C66.2921 52.0206 62.847 57.1784 48.4993 57.1784C46.3708 57.1784 44.4869 57.0656 42.7852 56.8657"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3711 75.6001V68.1299V43.2173"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M82.6289 75.6001V70.714V43.2173"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Dermatoloji",
};
</script>

<style></style>

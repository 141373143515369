<template>
  <div class="video-card mb-30px">
    <div
      class="
        mb-2
        bg-image
        video-card-img
        border-radius
        d-flex
        align-items-center
        justify-content-center
      "
      :style="{ background: 'url(' + video.thumbnail + ')' }"
    >
      <router-link :to="{ name: 'videodetail', params: { id: video.id } }">
        <play-icon :size="57" />
      </router-link>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="font-s-12px d-flex">
        <div class="d-flex align-items-center mr-2">
          <follow-icon
            :size="16"
            likeable-type="video"
            :likeable-id="video.id"
            :is-liked="video.is_liked"
            counter-classes="gray-300"
            :like-count="video.like_count"
            :is-countable="true"
            class="mr-1"
          />
        </div>
        <div class="d-flex align-items-center mr-2">
          <comment-icon color="#AFBBDB" :size="16" class="mr-1" />
          <span class="gray-300">{{ video.comment_count }}</span>
        </div>
        <div class="d-flex align-items-center mr-2">
          <clock-icon :size="16" color="#AFBBDB" class="mr-1" />
          <span class="gray-300">{{ formattedDate }}</span>
        </div>
      </div>
      <save-icon
        favoritable-type="video"
        :favoritable-id="video.id"
        :is-favorited="video.is_favorited"
        button-classes="d-flex font-s-12px align-items-center mr-2"
        :color="color"
        class="mr-1 bg-transparent"
        text="Kaydet"
        favorited-text="Kaydedildi"
        text-classes="text-info font-w-500"
      />
    </div>
    <div>
      <router-link :to="{ name: 'videodetail', params: { id: video.id } }">
        <p
          class="
            font-s-18px font-w-600
            mb-2
            line-h-23px
            black-700
            video-card-title
          "
        >
          {{ video.title }}
        </p>
      </router-link>
    </div>
    <router-link
      v-if="video.category"
      :to="{ name: 'channeldetail', params: { id: video.category.id } }"
      class="d-flex align-items-center mb-2"
    >
      <div class="video-card-sm-img mr-2">
        <img
          :src="video.category.thumbnail"
          :alt="video.category.title"
          class="person-img border-radius-50"
        />
      </div>
      <div class="d-flex flex-column">
        <span class="font-s-12px font-w-600 gray-500">{{
          video.category.title
        }}</span>
        <span class="font-s-10px gray-500"
          >{{ video.category.video_count }} Video</span
        >
      </div>
    </router-link>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed } from "vue";
import dayjs from "dayjs";

export default {
  name: "VideosCard",
  components: {},
  props: {
    video: { type: Object, required: true },
    dateFormat: {
      type: String,
      required: false,
      default: "DD.MM.YYYY",
    },
  },
  setup(props) {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueText;
    const formattedDate = computed(() => {
      return dayjs(props.video.publish_on)
        .locale("tr")
        .format(props.dateFormat);
    });

    return {
      formattedDate,
      color,
    };
  },
};
</script>

<style lang="scss" scoped>
.video-card {
  max-width: 355px;
}
.video-card-img {
  max-width: 370px;
  width: 100%;
  height: 147px;
  object-fit: cover;
}
.video-card-sm-img {
  max-width: 25px;
  max-height: 25px;
}
.video-card-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.person-img {
  width: 25px;
  height: 25px;
}

@media (max-width: 804px) {
  .video-card,
  .video-card-img {
    max-width: 450px;
  }
}
</style>

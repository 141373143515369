<template>
  <div class="mb-100px">
    <section-title
      class="mb-0"
      title-dark="Öne Çıkan"
      title-blue="Kanallar"
      :more-title="{
        title: 'Tüm Kanallar',
        routeName: channelType === 'video' ? 'channels' : 'podcast-channels',
      }"
    />
    <div class="d-flex justify-content-between">
      <div class="swiper-container mySwiperChannelList w-100">
        <div class="swiper-wrapper">
          <div
            v-for="(channel, index) in channels"
            :key="index"
            class="swiper-slide"
          >
            <component :is="card" :channel="channel" />
          </div>
        </div>
        <div class="swiper-pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import Swiper from "swiper";
import SectionTitle from "./SectionTitle.vue";
import VideoChannelCard from "./VideoChannelCard.vue";
import PodcastChannelCard from "./PodcastChannelCard.vue";

export default {
  components: {
    SectionTitle,
    VideoChannelCard,
    PodcastChannelCard,
  },
  props: {
    channels: {
      type: Object,
      required: true,
    },
    card: {
      type: String,
      required: false,
      default: "video-channel-card",
    },
    channelType: {
      type: String,
      required: false,
      default: "video",
    },
  },
  setup() {
    onMounted(() => {
      new Swiper(".mySwiperChannelList", {
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          400: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-container {
  height: 430px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -6px !important;
}
</style>

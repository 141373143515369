<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.42 15.7985V32.1005C42.42 38.1405 38.64 42.4005 32.6 42.4005H15.3C9.26 42.4005 5.5 38.1405 5.5 32.1005V15.7985C5.5 9.75849 9.28 5.50049 15.3 5.50049H32.6C38.64 5.50049 42.42 9.75849 42.42 15.7985Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5625 32.862L13.6185 29.636C14.6805 28.51 16.4505 28.456 17.5785 29.516C17.6125 29.55 19.4525 31.42 19.4525 31.42C20.5625 32.55 22.3765 32.568 23.5065 31.46C23.5805 31.388 28.1745 25.816 28.1745 25.816C29.3585 24.378 31.4845 24.172 32.9245 25.358C33.0205 25.438 37.3605 29.892 37.3605 29.892"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.6254 18.2663C20.6254 20.2043 19.0554 21.7743 17.1174 21.7743C15.1794 21.7743 13.6094 20.2043 13.6094 18.2663C13.6094 16.3283 15.1794 14.7583 17.1174 14.7583C19.0554 14.7603 20.6254 16.3283 20.6254 18.2663Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ImageIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

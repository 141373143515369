<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.313 68.0961C15.6967 62.5917 13.5898 56.0098 13.5898 48.9425C13.5898 29.6556 29.2818 13.9636 48.5688 13.9636"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M72.2394 23.2124C74.3515 25.1592 76.229 27.3621 77.8132 29.7729C81.4348 35.2827 83.547 41.87 83.547 48.9425C83.547 68.2295 67.855 83.9215 48.5681 83.9215C40.5461 83.9215 33.1481 81.2066 27.2383 76.6515"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.5707 77.3609C64.2657 77.3609 76.9891 64.6376 76.9891 48.9425C76.9891 33.2475 64.2657 20.5242 48.5707 20.5242C32.8757 20.5242 20.1523 33.2475 20.1523 48.9425C20.1523 64.6376 32.8757 77.3609 48.5707 77.3609Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M52.4659 48.4678C52.4659 51.3534 50.1191 53.6949 47.2388 53.6949C44.3532 53.6949 42.0117 51.3481 42.0117 48.4678C42.0117 45.5823 44.3586 43.2407 47.2388 43.2407"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.451 49.4066C27.3975 49.4066 25.7227 47.7318 25.7227 45.6783C25.7227 43.6248 27.3975 41.95 29.451 41.95C31.5045 41.95 33.1793 43.6248 33.1793 45.6783"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M66.4002 47.3904C68.4537 47.3904 70.1285 49.0652 70.1285 51.1187C70.1285 53.1722 68.4537 54.847 66.4002 54.847C64.3467 54.847 62.6719 53.1722 62.6719 51.1187"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.2088 61.2316C38.2623 61.2316 39.9371 62.9064 39.9371 64.9599C39.9371 67.0134 38.2623 68.6882 36.2088 68.6882C34.1553 68.6882 32.4805 67.0134 32.4805 64.9599"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.1496 32.2052C66.2912 32.2052 68.8461 34.7601 68.8461 37.9017C68.8461 41.0433 66.2912 43.5981 63.1496 43.5981C60.008 43.5981 57.4531 41.0433 57.4531 37.9017"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.4805 36.8188C32.4805 33.6772 35.0353 31.1223 38.1769 31.1223C41.3185 31.1223 43.8734 33.6772 43.8734 36.8188C43.8734 39.9604 41.3185 42.5153 38.1769 42.5153"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M64.6781 63.4504C64.6781 66.592 62.1232 69.1469 58.9816 69.1469C55.84 69.1469 53.2852 66.592 53.2852 63.4504C53.2852 60.3088 55.84 57.7539 58.9816 57.7539"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.3867 39.8538C51.1733 39.2511 50.4586 36.9842 51.4667 34.424C52.7148 31.2664 55.6057 30.1517 56.0697 29.9863"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.4922 27.8848C41.8816 27.8475 43.3323 27.7514 44.7031 28.7275C46.3939 29.9276 46.6339 31.8105 46.6659 32.1091"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.0803 51.1187C59.9736 50.8253 59.6216 49.7106 60.1176 48.4518C60.731 46.8997 62.1498 46.3556 62.3792 46.2703"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M54.643 52.1642C54.5737 52.7082 54.2536 54.7297 52.4881 56.3139C50.312 58.266 47.6557 58.01 47.2344 57.962"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.2084 52.1642C36.0271 52.6869 35.291 54.6231 33.2162 55.8178C30.6613 57.2899 28.0904 56.4845 27.6797 56.3512"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.7831 62.2823C49.8685 62.7997 50.1298 64.7306 48.9511 66.6507C47.5003 69.0242 45.0041 69.5096 44.6094 69.5843"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EndokrinMetobolizma",
};
</script>

<style></style>

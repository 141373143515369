<template>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2155 7.16767C10.2155 7.24789 10.1395 7.288 9.98746 7.288C9.9199 7.288 9.87768 7.25844 9.86079 7.19933C9.67924 6.604 9.41957 6.18389 9.08179 5.939C8.74401 5.68989 8.24368 5.56533 7.58079 5.56533H6.98546C6.77857 5.56533 6.63713 5.59911 6.56113 5.66667C6.48513 5.73422 6.44713 5.86089 6.44713 6.04667V12.475C6.44713 12.7663 6.48301 12.9732 6.55479 13.0957C6.63079 13.2139 6.76168 13.3089 6.94746 13.3807C7.13746 13.4482 7.40768 13.5031 7.75813 13.5453C7.82568 13.5538 7.85946 13.6108 7.85946 13.7163C7.85946 13.8346 7.82568 13.8937 7.75813 13.8937C7.47524 13.8937 7.13746 13.881 6.74479 13.8557C6.37324 13.8303 6.0629 13.8177 5.81379 13.8177C5.55624 13.8177 5.23957 13.8303 4.86379 13.8557C4.47113 13.881 4.13335 13.8937 3.85046 13.8937C3.7829 13.8937 3.74913 13.8346 3.74913 13.7163C3.74913 13.6108 3.7829 13.5538 3.85046 13.5453C4.36135 13.4862 4.70757 13.3912 4.88913 13.2603C5.0749 13.1294 5.16779 12.8677 5.16779 12.475V6.04667C5.16779 5.86089 5.12979 5.73422 5.05379 5.66667C4.97779 5.59911 4.83424 5.56533 4.62313 5.56533H4.12913C3.53379 5.56533 2.99335 5.71733 2.50779 6.02133C2.02224 6.32111 1.70557 6.71167 1.55779 7.193C1.53668 7.25633 1.49024 7.288 1.41846 7.288C1.2749 7.288 1.20312 7.24156 1.20312 7.14867C1.43957 6.51111 1.66335 5.78911 1.87446 4.98267C2.44868 5.10089 3.76179 5.16 5.81379 5.16C7.92068 5.16 9.26124 5.10089 9.83546 4.98267C9.89457 5.53155 10.0212 6.25989 10.2155 7.16767Z"
      :fill="color"
    />
    <path
      d="M11.918 10.2454H17.7797"
      :stroke="color"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M11.918 7.66357H17.7802"
      :stroke="color"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M11.918 13.0309H17.7802"
      :stroke="color"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EditorIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="d-flex justify-content-center justify-content-xl-between mb-5">
      <div
        v-if="leftSideVideos"
        class="d-none max-w-260px d-xl-flex flex-column"
      >
        <videos-card
          v-for="(video, index) in leftSideVideos"
          :key="index"
          class="mx-sm-3 mx-xl-0"
          :video="video"
        />
      </div>
      <div v-if="mainVideo">
        <videos-cardmain :video="mainVideo" />
      </div>
      <div
        v-if="rightSideVideos"
        class="d-none max-w-260px d-xl-flex flex-column"
      >
        <videos-card
          v-for="(video, index) in rightSideVideos"
          :key="index"
          class="mx-sm-3 mx-xl-0"
          :video="video"
        />
      </div>
    </div>
    <div
      v-if="otherVideos"
      class="d-flex flex-wrap justify-content-center justify-content-xl-between"
    >
      <videos-card
        v-for="(video, index) in leftSideVideos"
        :key="index"
        class="mx-sm-3 mx-xl-0 width-355px d-xl-none"
        :video="video"
      />
      <videos-card
        v-for="(video, index) in rightSideVideos"
        :key="index"
        class="mx-sm-3 mx-xl-0 width-355px d-xl-none"
        :video="video"
      />
      <videos-card
        v-for="(video, index) in otherVideos"
        :key="index"
        class="mx-sm-3 mx-xl-0 width-355px"
        :video="video"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import VideosCard from "../VideosCard.vue";
import VideosCardmain from "../VideosCardmain.vue";

export default {
  name: "VideoList",
  components: {
    VideosCard,
    VideosCardmain,
  },
  props: {
    videos: { type: Object, required: true },
  },
  setup(props) {
    const mainVideo = ref(null);
    const leftSideVideos = ref(null);
    const rightSideVideos = ref(null);
    const otherVideos = ref(null);

    mainVideo.value = props.videos.slice(0, 1)[0];
    leftSideVideos.value = props.videos.slice(1, 3);
    rightSideVideos.value = props.videos.slice(3, 5);
    otherVideos.value = props.videos.slice(5, 14);

    return {
      mainVideo,
      leftSideVideos,
      rightSideVideos,
      otherVideos,
    };
  },
};
</script>

<style></style>

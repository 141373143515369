<template>
  <div class="tags mb-100px">
    <section class="page-main mb-5">
      <div class="tags-main bg-image d-flex flex-column">
        <content-main
          title="Etiketler"
          nav="Etiketler"
          class="mt-m-100px mt-160px"
        />
      </div>
    </section>

    <section class="mb-5">
      <tag-content
        type="video"
        :content-response="videoResponse"
        @go-page="getTagVideos($event)"
      />
    </section>

    <section class="mb-5">
      <tag-content
        type="post"
        :content-response="postResponse"
        @go-page="getTagPosts($event)"
      />
    </section>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import PostQbService from "../services/post/PostQbService";
import VideoQbService from "../services/video/VideoQbService";
import ContentMain from "../components/ui/ContentMain.vue";
import TagContent from "../components/ui/tag/TagContent.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";

const postQbService = new PostQbService();
const videoQbService = new VideoQbService();

export default {
  components: {
    ContentMain,
    TagContent,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const tagId = computed(() => route.params.id);
    const postResponse = ref({ loading: true, data: [] });
    const videoResponse = ref({ loading: true, data: [] });

    const qbConfig = {
      includes: ["tags"],
      filters: {
        "tags.id": [tagId.value],
      },
    };

    const getTagPosts = (page) => {
      postResponse.value.loading = true;
      postQbService
        .all({ ...qbConfig, page })
        .then((response) => {
          postResponse.value.data = response;
        })
        .finally(() => {
          postResponse.value.loading = false;
        });
    };

    const getTagVideos = (page) => {
      videoResponse.value.loading = true;
      videoQbService
        .all({ ...qbConfig, page })
        .then((response) => {
          videoResponse.value.data = response;
        })
        .finally(() => {
          videoResponse.value.loading = false;
        });
    };

    getTagPosts(1);
    getTagVideos(1);

    return {
      color: ColorHelper[store.state.theme].blueDark,
      postResponse,
      videoResponse,
      getTagVideos,
      getTagPosts,
    };
  },
};
</script>

<style lang=""></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5022 11.16V3.11333C15.5022 2.31333 14.8489 1.71999 14.0556 1.78666H14.0156C12.6156 1.90666 10.4889 2.61999 9.30225 3.36666L9.18891 3.43999C8.99558 3.55999 8.67558 3.55999 8.48225 3.43999L8.31558 3.33999C7.12892 2.59999 5.00891 1.89333 3.60891 1.77999C2.81558 1.71333 2.16891 2.31333 2.16891 3.10666V11.16C2.16891 11.8 2.68891 12.4 3.32891 12.48L3.52225 12.5067C4.96892 12.7 7.20225 13.4333 8.48225 14.1333L8.50892 14.1467C8.68892 14.2467 8.97558 14.2467 9.14892 14.1467C10.4289 13.44 12.6689 12.7 14.1222 12.5067L14.3422 12.48C14.9822 12.4 15.5022 11.8 15.5022 11.16Z"
      :stroke="color"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.83557 3.66V13.66"
      :stroke="color"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.00223 5.66H4.50223"
      :stroke="color"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.50223 7.66H4.50223"
      :stroke="color"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ClinicIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "#7684AA",
    },
    size: {
      type: Number,
      required: false,
      default: 16,
    },
  },
};
</script>

<style></style>

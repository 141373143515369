<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00098 9.57567C6.72498 9.57567 6.50098 9.35167 6.50098 9.07567V1.04834C6.50098 0.77234 6.72498 0.54834 7.00098 0.54834C7.27698 0.54834 7.50098 0.77234 7.50098 1.04834V9.07567C7.50098 9.35167 7.27698 9.57567 7.00098 9.57567Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.0012 9.57561C6.86853 9.57561 6.74053 9.52295 6.6472 9.42828L4.7032 7.47695C4.50853 7.28095 4.5092 6.96428 4.70453 6.76961C4.90053 6.57495 5.21653 6.57495 5.4112 6.77095L7.0012 8.36761L8.5912 6.77095C8.78587 6.57495 9.10187 6.57495 9.29787 6.76961C9.4932 6.96428 9.49387 7.28095 9.2992 7.47695L7.3552 9.42828C7.26187 9.52295 7.13387 9.57561 7.0012 9.57561Z"
      :fill="color"
    />
    <mask
      id="mask0"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="3"
      width="14"
      height="10"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333984 3.70337H13.6672V12.866H0.333984V3.70337Z"
        fill="white"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7173 12.866H3.29065C1.66065 12.866 0.333984 11.54 0.333984 9.90937V6.6527C0.333984 5.02604 1.65732 3.70337 3.28465 3.70337H3.91198C4.18798 3.70337 4.41198 3.92737 4.41198 4.20337C4.41198 4.47937 4.18798 4.70337 3.91198 4.70337H3.28465C2.20865 4.70337 1.33398 5.57737 1.33398 6.6527V9.90937C1.33398 10.9887 2.21132 11.866 3.29065 11.866H10.7173C11.792 11.866 12.6673 10.9907 12.6673 9.91604V6.66004C12.6673 5.5807 11.7893 4.70337 10.7113 4.70337H10.09C9.81398 4.70337 9.58998 4.47937 9.58998 4.20337C9.58998 3.92737 9.81398 3.70337 10.09 3.70337H10.7113C12.3413 3.70337 13.6673 5.03004 13.6673 6.66004V9.91604C13.6673 11.5427 12.3433 12.866 10.7173 12.866Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <section class="articles-main d-flex flex-column mb-5">
    <content-main
      :title="zoneName + ' Anket'"
      :nav="zoneName + ' Anket'"
      class="mt-m-100px mt-160px mb-5"
    />

    <div class="container-xl">
      <div
        class="
          bg-white
          border-radius
          box-shadow-4-20-2
          py-5
          mb-5
          position-relative
        "
      >
        <div
          v-if="showSendImg"
          class="position-absolute w-100 h-100 send-box px-5 z-index-10"
        >
          <div class="d-flex justify-content-end mb-4">
            <close-icon
              :size="24"
              :color="color"
              class="mr-1 cursor-pointer"
              @click="showSendImg = false"
            />
          </div>
          <div class="d-flex justify-content-center">
            <img
              src="/new-img/questionnaire-send.png"
              alt=""
              class="img-fluid send-img"
            />
          </div>
          <div class="d-flex flex-column align-items-center">
            <h2 class="font-s-35px text-primary">Teşekkürler!</h2>
            <p class="text-secondary">
              Anketimize katıldığınız için teşekkür ederiz, cevaplarınız kayıt
              edilmiştir.
            </p>
          </div>
        </div>
        <div v-if="!isSurveyLoading" class="row">
          <div class="col-12 col-md-6">
            <div class="px-3 pl-md-0 pl-md-5 py-md-4">
              <h4 class="font-s-24px font-w-600 text-primary">
                {{ survey.title }}
              </h4>
              <!-- <div class="mb-38px">
                <h5 class="font-s-16px font-w-500 black-700">
                  Beğendiğiniz video kanallarını takip etmeye başladınız mı?
                </h5>
                <div class="d-flex">
                  <div class="d-flex align-items-baseline mr-4">
                    <input
                      id="question-3"
                      class="mr-1"
                      type="radio"
                      name="q2"
                      value="0"
                    />
                    <label class="text-secondary" for="question-3">Evet</label>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="question-4"
                      class="mr-1"
                      type="radio"
                      name="q2"
                      value="0"
                    />
                    <label class="text-secondary" for="question-4">Evet</label>
                  </div>
                </div>
              </div> -->

              <div
                v-for="(question, index) in survey.questions"
                :key="index"
                class="mb-38px"
              >
                <h5 class="font-s-16px font-w-500 black-700">
                  {{ question.title }}
                </h5>

                <div v-if="question.type === 'select'">
                  <base-select
                    :name="`${question.id}`"
                    :options="question.options"
                    option-text-key="title"
                    option-value-key="value"
                    :placeholder="question.title"
                  />
                  <input
                    v-if="
                      form.values[question.id] == 'diger_belirtiniz' &&
                      question.questions
                    "
                    v-model="form.values[question.questions[0].id]"
                    type="text"
                    class="question-input"
                    placeholder="Diğer belirtiniz"
                  />
                </div>
                <input
                  v-else
                  v-model="form.values[question.id]"
                  type="text"
                  class="question-input"
                />
              </div>

              <div class="d-flex justify-content-center">
                <button
                  class="btn d-flex align-items-center mr-2"
                  @click="clearForm"
                >
                  <close-icon :size="16" :color="color" class="mr-1" />
                  <span class="font-w-500 text-info">Temizle</span>
                </button>
                <button
                  class="btn btn-info border-radius d-flex align-items-center"
                  :disabled="isFormSending"
                  @click="sendForm"
                >
                  <calculate-icon class="mr-1" />
                  <span class="font-w-500">Cevapları Gönder</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="d-none d-md-block p-4">
              <img src="/new-img/questionnaire.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <loading-anim-icon v-else />
      </div>
    </div>
  </section>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import SurveyService from "../services/survey/SurveyService";
import { useForm } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import ZoneHelper from "../shared/zone-helper";
import BaseSelect from "../components/ui/RegisterForm/BaseSelect.vue";

export default {
  name: "Questionnaire",
  components: {
    ContentMain,
    BaseSelect,
  },
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const zoneHelper = new ZoneHelper();
    const zoneName = computed(() => zoneHelper.getZoneName(theme));
    const showSendImg = ref(false);
    const color = ColorHelper[store.state.theme].blueText;
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const isFormSending = ref(false);
    const isSurveyLoading = ref(false);
    const survey = ref();
    const user = computed(() => {
      return store.state.user.user;
    });
    const form = useForm({});

    const sendForm = async () => {
      isFormSending.value = true;

      await form.validate();

      if (!form.meta.value.valid) {
        Swal.fire({
          title: "Dikkat!",
          text: "Lütfen tüm alanları doldurunuz.",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
        isFormSending.value = false;
        return;
      }

      const formRequest = ref([]);
      for (const [key, value] of Object.entries(form.values)) {
        formRequest.value.push({
          question_id: key,
          answer_body: value,
        });
      }

      SurveyService.sendSurveyAnswer(
        survey.value.id,
        user.value.id,
        formRequest.value
      )
        .then(() => {
          showSendImg.value = true;
        })
        .catch((error) => {
          Swal.fire({
            title: "Dikkat!",
            text: error.response.data.message,
            icon: "warning",
            confirmButtonText: "Tamam",
          });
        })
        .finally(() => {
          isFormSending.value = false;
        });
    };

    const fetchLatestSurvey = async () => {
      isSurveyLoading.value = true;

      await SurveyService.getLatestSurvey().then((response) => {
        survey.value = response.data.data;
      });

      const specs = Object.assign(
        {},
        ...survey.value.questions.map((x) => ({
          [x.id]: yup.string().required(),
        }))
      );

      form.validationSchema = yup.object(specs);

      await SurveyService.getSurveyAnswers(survey.value.id, user.value.id).then(
        (response) => {
          const initialValues = ref({});
          response.data.data.questionUserAnswers.forEach((x) => {
            initialValues.value[x.question_id] = x.answer_body;
          });
          form.setValues(initialValues.value);
          if (
            response.data.data.questionUserAnswers.length >=
            survey.value.questions.length
          ) {
            showSendImg.value = true;
          }
        }
      );

      isSurveyLoading.value = false;
    };

    const clearForm = () => {
      form.resetForm();
    };

    fetchLatestSurvey();

    return {
      survey,
      form,
      isSurveyLoading,
      isFormSending,
      sendForm,
      clearForm,
      color,
      main_img,
      showSendImg,
      zoneName,
    };
  },
};
</script>

<style lang="scss" scoped>
.articles-main {
  background: v-bind("main_img");
  background-size: 100vw 532px;
  background-repeat: no-repeat !important;
}
.question-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid#AFBBDB;
}
.send-img {
  max-width: 450px;
}
.send-box {
  background: rgba(251, 252, 255, 0.9);
  backdrop-filter: blur(10px);
}
@media (max-width: 768px) {
  .articles-main {
    background-size: 300vw 400px;
    background-position: top;
  }
}
</style>

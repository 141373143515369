<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2049:33454)">
      <path
        d="M6.34326 12H17.657"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 17.6569L12 6.34316"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2049:33454">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.686523 12) rotate(-45)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PlusIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#7684AA",
    },
  },
};
</script>

<style></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1609 12.0531C15.1609 13.7991 13.7449 15.2141 11.9989 15.2141C10.2529 15.2141 8.83789 13.7991 8.83789 12.0531C8.83789 10.3061 10.2529 8.89111 11.9989 8.89111C13.7449 8.89111 15.1609 10.3061 15.1609 12.0531Z"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.48885 15.806 4.75085 11.998 4.75085H12.002C8.194 4.75085 4.711 7.48885 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ViewInput",
};
</script>

<style></style>

export const message = [
  {
    id: 153,
    thread_id: 25,
    user_id: 2301,
    user: {
      id: 2301,
      university_id: 14,
      hospital_id: 843,
      fullname: "Serkan Şahin",
      firstname: "Serkan",
      lastname: "Şahin",
      email: "serkan.sahin@epigra.com",
      slug: "serkan-sahin",
      identity_number: null,
      is_active: true,
      is_approved: true,
      pharmacy_id: null,
      pharmacy_role: null,
      bio: null,
      phone: "+90 534 968 71 74",
      city_id: 34,
      avatar_url:
        "https://www.gravatar.com/avatar/241e676b542c14bfd5daf81bd68150e0?d=mp",
      gender: "male",
      dob: "2000-08-22",
      kusman_id: "204173",
      santafarma_id: null,
      referred_by: null,
      ref_id: null,
      title_id: 4,
      title: "Uzm. Dr.",
      job_start_end_year: "2012-2013",
      uni_start_end_year: "2012-2013",
      job_position: "hey",
    },
    body: "helloo",
    created_at: "2021-08-19T16:52:14.000000Z",
    type: "text",
  },
  {
    id: 153,
    thread_id: 25,
    user_id: 2301,
    user: {
      id: 2301,
      university_id: 14,
      hospital_id: 843,
      fullname: "Serkan Şahin",
      firstname: "Serkan",
      lastname: "Şahin",
      email: "serkan.sahin@epigra.com",
      slug: "serkan-sahin",
      identity_number: null,
      is_active: true,
      is_approved: true,
      pharmacy_id: null,
      pharmacy_role: null,
      bio: null,
      phone: "+90 534 968 71 74",
      city_id: 34,
      avatar_url:
        "https://www.gravatar.com/avatar/241e676b542c14bfd5daf81bd68150e0?d=mp",
      gender: "male",
      dob: "2000-08-22",
      kusman_id: "204173",
      santafarma_id: null,
      referred_by: null,
      ref_id: null,
      title_id: 4,
      title: "Uzm. Dr.",
      job_start_end_year: "2012-2013",
      uni_start_end_year: "2012-2013",
      job_position: "hey",
    },
    body: "lorem ipsum fdsfsdf ffsdfd lorem ipsum fdsfsdf ffsdfdlorem ipsum fdsfsdf ffsdfd lorem ipsum fdsfsdf ffsdfd",
    created_at: "2021-08-19T16:52:14.000000Z",
    type: "text",
  },
  {
    id: 154,
    thread_id: 25,
    user_id: 1,
    user: {
      id: 1,
      university_id: 9,
      hospital_id: 44421,
      fullname: "Uğur Aydoğdu",
      firstname: "Uğur",
      lastname: "Aydoğdu",
      email: "ugur.aydogdu@epigra.com",
      slug: "ugur-aydogdu",
      identity_number: null,
      is_active: true,
      is_approved: true,
      pharmacy_id: null,
      pharmacy_role: null,
      bio: "Neo",
      phone: "+90 544 374 47 20",
      city_id: 34,
      avatar_url:
        "https://staging.norozone.com/storage/545/image_picker8211619481212841868.jpg",
      gender: "male",
      dob: "1988-06-16",
      kusman_id: null,
      santafarma_id: null,
      referred_by: null,
      ref_id: null,
      title_id: 1,
      title: "Prof. Dr.",
      job_start_end_year: "2015-2018",
      uni_start_end_year: "2015-2020",
      job_position: "Uzman Acil Doktor",
    },
    body: "msj",
    created_at: "2021-08-19T17:22:38.000000Z",
    type: "text",
  },
  {
    id: 176,
    thread_id: 25,
    user_id: 1,
    user: {
      id: 1,
      university_id: 9,
      hospital_id: 44421,
      fullname: "Uğur Aydoğdu",
      firstname: "Uğur",
      lastname: "Aydoğdu",
      email: "ugur.aydogdu@epigra.com",
      slug: "ugur-aydogdu",
      identity_number: null,
      is_active: true,
      is_approved: true,
      pharmacy_id: null,
      pharmacy_role: null,
      bio: "Neo",
      phone: "+90 544 374 47 20",
      city_id: 34,
      avatar_url:
        "https://staging.norozone.com/storage/545/image_picker8211619481212841868.jpg",
      gender: "male",
      dob: "1988-06-16",
      kusman_id: null,
      santafarma_id: null,
      referred_by: null,
      ref_id: null,
      title_id: 1,
      title: "Prof. Dr.",
      job_start_end_year: "2015-2018",
      uni_start_end_year: "2015-2020",
      job_position: "Uzman Acil Doktor",
    },
    body: "deneme1",
    created_at: "2021-09-23T08:35:27.000000Z",
    type: "text",
  },
  {
    id: 177,
    thread_id: 25,
    user_id: 1,
    user: {
      id: 1,
      university_id: 9,
      hospital_id: 44421,
      fullname: "Uğur Aydoğdu",
      firstname: "Uğur",
      lastname: "Aydoğdu",
      email: "ugur.aydogdu@epigra.com",
      slug: "ugur-aydogdu",
      identity_number: null,
      is_active: true,
      is_approved: true,
      pharmacy_id: null,
      pharmacy_role: null,
      bio: "Neo",
      phone: "+90 544 374 47 20",
      city_id: 34,
      avatar_url:
        "https://staging.norozone.com/storage/545/image_picker8211619481212841868.jpg",
      gender: "male",
      dob: "1988-06-16",
      kusman_id: null,
      santafarma_id: null,
      referred_by: null,
      ref_id: null,
      title_id: 1,
      title: "Prof. Dr.",
      job_start_end_year: "2015-2018",
      uni_start_end_year: "2015-2020",
      job_position: "Uzman Acil Doktor",
    },
    body: "deneme2",
    created_at: "2021-09-23T08:35:35.000000Z",
    type: "text",
  },
  {
    id: 153,
    thread_id: 25,
    user_id: 2301,
    user: {
      id: 2301,
      university_id: 14,
      hospital_id: 843,
      fullname: "Serkan Şahin",
      firstname: "Serkan",
      lastname: "Şahin",
      email: "serkan.sahin@epigra.com",
      slug: "serkan-sahin",
      identity_number: null,
      is_active: true,
      is_approved: true,
      pharmacy_id: null,
      pharmacy_role: null,
      bio: null,
      phone: "+90 534 968 71 74",
      city_id: 34,
      avatar_url:
        "https://www.gravatar.com/avatar/241e676b542c14bfd5daf81bd68150e0?d=mp",
      gender: "male",
      dob: "2000-08-22",
      kusman_id: "204173",
      santafarma_id: null,
      referred_by: null,
      ref_id: null,
      title_id: 4,
      title: "Uzm. Dr.",
      job_start_end_year: "2012-2013",
      uni_start_end_year: "2012-2013",
      job_position: "hey",
    },
    body: "son mesaj",
    created_at: "2021-08-19T16:52:14.000000Z",
    type: "text",
  },
];

<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.446 11.0477H32.834C24.598 11.0477 17.498 17.7237 17.498 25.9637V68.8157C17.498 77.5197 24.134 84.4597 32.834 84.4597H64.79C73.03 84.4597 79.706 77.0597 79.706 68.8157V32.1517L59.446 11.0477Z"
      stroke="#7684AA"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.3945 11.0009V22.6369C58.3945 28.3169 62.9905 32.9249 68.6665 32.9369C73.9345 32.9489 79.3225 32.9529 79.6865 32.9289"
      stroke="#7684AA"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.6349 62.2313H36.0469"
      stroke="#7684AA"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.4689 42.4224H36.0449"
      stroke="#7684AA"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArticleImg",
};
</script>

<style></style>

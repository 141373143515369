import UserService from "../../services/UserService";
import { getProfilePicture } from "../../shared/utils";

export const state = {
  user: null,
};

export const mutations = {
  setUser(state, data) {
    state.user = data;
  },
};

export const actions = {
  fetchAndSetUserInformation(ctx) {
    return UserService.getAuthUserInformation().then((resp) => {
      ctx.commit("setUser", resp.data.data);
    });
  },
};

export const getters = {
  getProfilePicture(state) {
    return getProfilePicture(state.user);
  },
};

export const namespaced = true;

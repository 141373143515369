<template>
  <div v-if="remainingAndLimitsResponse.isLoading">Lütfen Bekleyin...</div>
  <div v-else-if="remainingAndLimitsResponse.error">
    <span>Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.</span>
  </div>
  <div v-else-if="remainingAndLimitsResponse.data?.limit">
    <span
      >Kalan Redaksiyon Talebi Hakkınız:
      {{ remainingAndLimitsResponse.data.remaining }} /
      {{ remainingAndLimitsResponse.data.limit }}</span
    >
    <div v-if="remainingAndLimitsResponse.data?.available_at">
      <span
        >Bir Sonraki Redaksiyon Talebi Hakkınız:
        {{
          dayjs(remainingAndLimitsResponse.data?.available_at).format(
            "DD.MM.YYYY HH:mm"
          )
        }}
      </span>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { ref } from "vue";
import UserContentService from "../../services/user-content/UserContentService";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  emits: ["loaded"],
  setup(props, { emit }) {
    const userContentService = new UserContentService();
    const remainingAndLimitsResponse = ref({
      isLoading: true,
      data: null,
      error: null,
    });

    userContentService
      .getRemainingAndLimits(props.type)
      .then((response) => {
        remainingAndLimitsResponse.value.data = response.data.data;
      })
      .catch((error) => {
        remainingAndLimitsResponse.value.error = error;
      })
      .finally(() => {
        remainingAndLimitsResponse.value.isLoading = false;
        emit("loaded");
      });

    return {
      remainingAndLimitsResponse,
      dayjs,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <svg
    width="116"
    height="111"
    viewBox="0 0 116 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="path-fill"
      d="M94.2423 12.2571C91.2699 12.2571 88.8379 14.6871 88.8379 17.6571V21.5143H99.6467V17.6571C99.6467 14.6871 97.2147 12.2571 94.2423 12.2571Z"
      fill="#F3F4F5"
    />
    <path
      d="M10.0879 101.743V48.5143C10.0879 46.3851 11.8173 44.6571 13.9482 44.6571H27.0732"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M88.8379 44.6571H101.963C104.094 44.6571 105.823 46.3851 105.823 48.5143V101.743"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M88.8382 50.8286H99.261C99.4772 50.8286 99.647 50.9983 99.647 51.2143V101.743H16.2646V51.2143C16.2646 50.9983 16.4345 50.8286 16.6507 50.8286H27.0735"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M88.838 90.9429V17.6571C88.838 14.9494 90.8607 12.7123 93.4703 12.3343V12.2571C93.4703 12.2571 32.7402 12.2571 32.4777 12.2571C29.5052 12.2571 27.0732 14.6871 27.0732 17.6571V90.9429"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.8828 75.7715H76.4858"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.8828 81.9429H50.2358"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M54.8672 81.9429H64.1319"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.0901 101.743C62.5574 102.661 61.5691 103.286 60.4265 103.286H55.4853C54.3426 103.286 53.3621 102.661 52.8217 101.743H7V102.514C7 105.5 9.41654 107.914 12.4044 107.914H103.507C106.495 107.914 108.912 105.5 108.912 102.514V101.743H63.0901Z"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="path-fill"
      d="M17.8088 33.8571C18.6616 33.8571 19.3529 33.1664 19.3529 32.3143C19.3529 31.4622 18.6616 30.7714 17.8088 30.7714C16.956 30.7714 16.2646 31.4622 16.2646 32.3143C16.2646 33.1664 16.956 33.8571 17.8088 33.8571Z"
      fill="#7684AA"
    />
    <path
      d="M99.6467 21.5143V17.6571C99.6467 14.6871 97.2147 12.2571 94.2423 12.2571C91.2699 12.2571 88.8379 14.6871 88.8379 17.6571V21.5143H99.6467Z"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.8828 61.457H76.4858"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.8828 67.6284H54.8681"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="path-fill"
      d="M53.3701 34.2212V45.0982C53.3701 45.0982 57.5517 44.6115 57.5517 48.6961V38.4024C57.5517 34.5127 53.3701 34.2212 53.3701 34.2212Z"
      fill="#7684AA"
    />
    <path
      class="path-fill"
      d="M55.3281 33.457V43.0277C55.3281 43.0277 57.6366 43.4731 57.6366 47.5573C57.6366 51.6419 57.6482 39.9587 57.6482 37.7705C57.6486 33.8811 55.3281 33.457 55.3281 33.457Z"
      fill="#AFBBDB"
    />
    <path
      class="path-fill"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M57.7119 48.2036V38.3052C57.7119 38.3052 59.8331 32.3118 68.1753 38.3894C68.1753 39.434 68.1716 41.4883 68.1678 43.636C68.1636 45.9883 68.1592 48.4526 68.1592 49.825C59.7195 42.5804 57.7119 48.2036 57.7119 48.2036ZM36.3292 45.7142H34.4561V38.4846H37.334C38.8883 38.4846 39.9631 39.2046 39.9631 40.8631C39.9631 42.5692 39.04 43.3169 37.3961 43.3169H36.3292V45.7142ZM36.6488 39.9827H36.3292V41.8181H36.6488C37.3366 41.8181 38.0147 41.8181 38.0147 40.9204C38.0147 39.9929 37.3849 39.9827 36.6488 39.9827ZM40.712 43.7556V40.8069H42.4727V43.4196C42.4727 44.0392 42.6275 44.4779 43.3531 44.4779C44.0788 44.4779 44.2339 44.0392 44.2339 43.4196V40.8069H45.9946V43.7556C45.9946 45.3684 44.843 45.8644 43.3531 45.8644C41.8632 45.8644 40.712 45.368 40.712 43.7556ZM48.8044 45.7142H47.0436L47.044 37.7717H48.8047V41.2733C49.1767 40.8339 49.7589 40.6189 50.322 40.6189C51.7725 40.6189 52.7754 41.8267 52.7754 43.2368C52.7754 44.6559 51.7632 45.864 50.3029 45.864C49.7301 45.864 49.1385 45.6479 48.8235 45.1676H48.8044V45.7142ZM50.9772 43.2469C50.9772 42.634 50.564 42.1178 49.8533 42.1178C49.1423 42.1178 48.7294 42.634 48.7294 43.2469C48.7294 43.8793 49.1426 44.3655 49.8533 44.3655C50.5644 44.3655 50.9772 43.8793 50.9772 43.2469ZM61.0009 41.5516H60.9818L60.3775 45.7141H58.5073L59.735 38.4841H61.586L63.0538 42.3481L64.6082 38.4841H66.4783L67.573 45.7141H65.6916L65.1641 41.5516H65.145L63.409 45.7141H62.6605L61.0009 41.5516Z"
      fill="#AFBBDB"
    />
    <path
      class="path-fill"
      d="M70.683 42.5299C70.7883 42.0151 71.2105 41.7431 71.7185 41.7431C72.1882 41.7431 72.6198 42.0541 72.7071 42.5299H70.683ZM74.3266 43.3589C74.3266 41.6768 73.3339 40.6193 71.6192 40.6193C70.0109 40.6193 68.8848 41.6068 68.8848 43.2465C68.8848 44.9436 70.1076 45.8641 71.7458 45.8641C72.8727 45.8641 73.9805 45.3373 74.2599 44.1782H72.5771C72.3853 44.5038 72.1163 44.6282 71.7417 44.6282C71.0209 44.6282 70.6455 44.2464 70.6455 43.5414H74.3266V43.3589ZM78.8121 45.7142H80.5732V37.7721H78.8121V41.2737C78.449 40.8343 77.8571 40.6193 77.2944 40.6193C75.8435 40.6193 74.841 41.8274 74.841 43.2368C74.841 44.6461 75.8626 45.8637 77.3135 45.8637C77.8856 45.8637 78.4873 45.6472 78.7922 45.1673H78.8121V45.7142ZM77.7635 42.1178C78.4738 42.1178 78.8874 42.634 78.8874 43.2469C78.8874 43.8793 78.4745 44.3655 77.7635 44.3655C77.0532 44.3655 76.6396 43.8793 76.6396 43.2469C76.6396 42.634 77.0524 42.1178 77.7635 42.1178Z"
      fill="#AFBBDB"
    />
    <path
      d="M32.4777 3C24.3865 3 17.8086 9.57257 17.8086 17.6571V27.6857"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PubmedImg",
};
</script>

<style></style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getRecentVideos(page = 1, per_page = 15, filters = null) {
    return apiClient.get("/api/video", {
      params: {
        page: page,
        per_page: per_page,
        qb: true,
        sort: "-publish_on",
        "filter[published]": true,
        ...filters,
      },
    });
  },
  getPopularVideos(page = 1, per_page = 15, filters = null) {
    return apiClient.get("/api/video", {
      params: {
        page: page,
        per_page: per_page,
        qb: true,
        sort: "-view_count",
        "filter[published]": true,
        ...filters,
      },
    });
  },
  getVideo(id) {
    return apiClient.get(`/api/video/${id}`);
  },
  markVideoAsWatched(id) {
    return apiClient.post(`/api/video/${id}/watched`);
  },
  getSuggestedVideosByVideo(id, limit = 5) {
    return apiClient.get(`/api/video/${id}/suggest`, {
      params: {
        limit: limit,
      },
    });
  },
  getRecentEpiLibraryVideos(page = 1, per_page = 15) {
    return apiClient.get("/api/epilibrary/videos/recent", {
      params: {
        page,
        per_page,
      },
    });
  },
};

<template>
  <div
    :class="
      route.name == 'login' ||
      route.name == 'register' ||
      route.name == 'forgot-password' ||
      route.name == 'reset-password'
        ? 'show-header'
        : ''
    "
  >
    <!-- search -->
    <transition name="fade">
      <search
        v-if="showSearch"
        v-model:show-search="showSearch"
        :logo="search_logo_url"
      />
    </transition>

    <!-- mobile nav -->
    <transition name="fade">
      <mobile-nav
        v-if="showNav || showProfileNav"
        :header-menu="headerMenu"
        :show-nav="showNav"
        :show-profile-nav="showProfileNav"
        @show-nav="showNav = $event"
        @show-profile-nav="showProfileNav = $event"
        @logout="logout()"
      />
    </transition>

    <!-- fixed-bg -->
    <transition name="fade">
      <div
        v-if="showSSS != 0 || showLogoBox == true"
        class="d-none d-xl-block position-fixed z-index-10 sss-bg"
        @click="showLogoBox = false"
      ></div>
    </transition>
    <!-- walk-through -->
    <walk-through
      v-if="headerMenu && theme != 'hemaworld'"
      v-model:showSSS="showSSS"
      :header-menu="headerMenu"
    />

    <!-- header -->
    <div
      v-if="showSSS == 0"
      class="
        header
        position-fixed
        z-index-5
        d-flex
        align-items-center
        w-100
        mt-4
        z-index-10
      "
      :class="
        showSearch == true || showNav == true || showProfileNav == true
          ? 'position-fixed'
          : ''
      "
    >
      <div
        class="
          container-header
          p-3
          px-4
          border-radius
          transition-0-3
          box-shadow-4-20
        "
        :class="showSearch == false ? 'bg-white' : 'bg-blue-footer'"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center position-relative">
            <close-icon
              v-if="showNav"
              :size="30"
              :color="color"
              class="mx-2 m-lg-none"
              @click="showNav = false"
            />
            <menu-icon
              v-if="!showSearch && !showNav"
              :color="color"
              class="d-lg-none mx-2 cursor-pointer"
              @click="
                (showNav = true), (showProfileNav = false), (showSearch = false)
              "
            />
            <menu-icon
              v-if="showSearch && !showNav"
              color="white"
              class="d-lg-none mx-2 cursor-pointer"
              @click="
                (showNav = true), (showProfileNav = false), (showSearch = false)
              "
            />
            <router-link
              :to="{ name: 'home' }"
              class="navbar-brand mr-2 height-50px d-flex align-items-center"
            >
              <img
                v-if="!showSearch"
                :src="logo_url"
                alt=""
                class="d-inline-block align-text-top header-logo"
              />
              <img
                v-else
                :src="search_logo_url"
                alt=""
                class="d-inline-block align-text-top header-logo"
              />
            </router-link>
            <div
              v-if="!showSearch"
              class="
                border-radius-5px
                bg-blue-light
                cursor-pointer
                d-none d-lg-block
              "
              @click="showLogoBox = !showLogoBox"
            >
              <bottom-arrow-icon :size="14" :color="color" class="mx-1" />
            </div>
            <transition name="fade">
              <zone-logos
                v-if="showLogoBox"
                :zone-logos="zoneLogos"
                :theme="theme"
              />
            </transition>
          </div>
          <div class="d-none d-lg-flex align-items-center font-w-500">
            <router-link
              v-for="(item, index) in headerMenu"
              :key="index"
              :to="{ name: item.router_name }"
              class="nav-link position-relative"
            >
              {{ item.name }}
              <point-text-icon
                v-if="route.name == item.router_name"
                :size="8"
                color="bg-blue-300"
                class="position-absolute point-nav"
              />
            </router-link>
          </div>
          <div class="d-flex align-items-center cursor-pointer">
            <close-icon
              v-if="showSearch"
              class="mx-3"
              color="white"
              :size="24"
              @click="showSearch = false"
            />
            <search-icon
              v-if="!showSearch"
              class="d-none d-lg-block mx-3"
              :show="true"
              :color="color"
              @click="
                (showSearch = true), (showNav = false), (showProfileNav = false)
              "
            />
            <search-icon
              v-if="!showSearch"
              class="d-lg-none mx-3"
              :color="color"
              @click="
                (showSearch = true), (showNav = false), (showProfileNav = false)
              "
            />

            <header-user-box
              v-model:showProfileNav="showProfileNav"
              v-model:showSearch="showSearch"
              v-model:showNav="showNav"
              :user="user"
              :color="color"
              @logout="logout()"
            />
          </div>
        </div>
      </div>
    </div>

    <dummy-icon
      v-if="route.name == 'home' && theme != 'hemaworld'"
      class="
        d-none d-xl-block
        z-index-5
        position-absolute
        dummy-icon
        cursor-pointer
      "
      @click="showSSS = 1"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import BottomArrowIcon from "../../components/icon/BottomArrowIcon.vue";
import ColorHelper from "../../helpers/ColorHelper";
import WalkThrough from "../../components/ui/WalkThrough.vue";
import Search from "../../components/ui/Search.vue";
import MobileNav from "../../components/ui/MobileNav.vue";
import { onMounted } from "@vue/runtime-core";
import { zoneLogos } from "../../helpers/ZoneLogos";
import HeaderUserBox from "../../components/ui/HeaderUserBox.vue";
import ZoneLogos from "../../components/ui/ZoneLogos.vue";

export default {
  name: "Header",
  components: {
    BottomArrowIcon,
    Search,
    MobileNav,
    WalkThrough,
    HeaderUserBox,
    ZoneLogos,
  },
  props: {
    headerMenu: { type: Object, required: true },
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const theme = store.state.theme;
    const color = ColorHelper[store.state.theme].blueDark;
    const logo_url = "/new-img/" + store.state.theme + "-logo.png";

    const search_logo_url =
      "/new-img/" + store.state.theme + "-search-logo.png";

    const showSearch = ref(false);
    const showNav = ref(false);
    const showProfileNav = ref(false);
    const showSSS = ref(0);
    const showLogoBox = ref(false);
    const router = useRouter();
    const route = useRoute();

    const logout = () =>
      store.dispatch("logout").then(() => router.replace({ name: "home" }));

    onMounted(() => {
      if (
        user.value &&
        !localStorage.getItem("walk_through") &&
        window.screen.width > 992
      ) {
        localStorage.setItem("walk_through", 1);
        showSSS.value = 1;
      }
    });

    return {
      theme,
      router,
      loading: computed(() => store.state.authLoading),
      user,
      logout,
      color,
      showSearch,
      showNav,
      showProfileNav,
      showSSS,
      showLogoBox,
      zoneLogos,
      logo_url,
      search_logo_url,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
.dummy-icon {
  top: 590px;
  right: 5vw;
}

.sss-bg {
  width: 100vw;
  height: 100vh;
  background: rgba(17, 77, 174, 0.6);
  backdrop-filter: blur(10px);
}

.point-nav {
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.header {
  height: 85px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.header-logo {
  width: 213px;
}

.container-header {
  max-width: 1194px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 992px) {
  .header {
    height: 60px;
    margin-top: 0 !important;
  }
  .container-header {
    border-radius: 0;
    padding: 0.5rem !important;
  }
  .header-logo {
    width: 175px;
  }
}
@media (min-width: 992px) {
  .show-header {
    display: none;
  }
}
</style>

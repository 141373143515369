<template>
  <div
    class="
      d-flex
      flex-column
      align-items-center
      mt-m-100px mt-160px
      max-w-390px
    "
  >
    <img
      :src="profilePicture"
      alt=""
      class="person-img object-fit-cover border-radius-50 mb-3"
    />
    <h1 class="font-s-30px font-weight-bold text-white text-center">
      {{ user.fullname }}
    </h1>
    <p
      v-if="theme !== 'eczazone'"
      class="font-s-12px text-white text-center mb-4"
    >
      {{ user.university?.name }} | {{ user.hospital?.name }} -
      {{ zoneHelper.getZoneInfo(theme)?.deparment }}
    </p>
    <p v-else class="font-s-12px text-white text-center mb-4">
      {{ user.pharmacy?.name }} -
      {{ zoneHelper.getZoneInfo(theme)?.deparment }}
    </p>
    <button class="btn btn-light border-radius person-btn mb-3 d-none">
      <notification-icon :color="color" :size="16" class="mr-2" />
      <span class="text-primary font-s-12px font-w-500">Profili Görüntüle</span>
    </button>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import ColorHelper from "../../helpers/ColorHelper";
import ZoneHelper from "../../shared/zone-helper";

const zoneHelper = new ZoneHelper();

export default {
  name: "SettingsHero",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const profilePicture = computed(
      () => store.getters["user/getProfilePicture"]
    );
    const theme = computed(() => store.state.theme);

    return {
      zoneHelper,
      theme,
      profilePicture,
      color: ColorHelper[store.state.theme].blueText,
    };
  },
};
</script>

<style scoped></style>

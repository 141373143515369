export const queryBuilder = (config) => {
  let q = "qb=true";

  if (config.filters) {
    for (let key in config.filters) {
      q += `&filter[${key}]=${config.filters[key].join(",")}`;
    }
  }

  if (config.page) {
    q += `&page=${config.page}`;
  }

  if (config.per_page) {
    q += `&per_page=${config.per_page}`;
  }

  if (config.sorts) {
    q += `&sort=${config.sorts.join(",")}`;
  }

  if (config.includes) {
    q += `&include=${config.includes.join(",")}`;
  }

  if (config.fields) {
    for (let key in config.fields) {
      q += `&field[${key}]=${config.fields[key].join(",")}`;
    }
  }

  return q;
};

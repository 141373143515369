<template>
  <div>
    <section class="not-found-page-main">
      <div
        class="
          container-xl
          text-white
          d-flex
          flex-column
          align-items-center
          justify-content-center
          h-100
        "
      >
        <h1 class="title-404 font-weight-bold mb-4">Uups...</h1>
        <p class="subtitle text-center font-s-20px">
          Bu bölümde bakım çalışması yapılmaktadır. En kısa zamanda tekrar
          hizmete girmesi için çalışmaktayız...
        </p>
        <div class="my-4" align="center">
          <router-link :to="{ name: 'home' }" class="btn btn-light">
            Ana sayfaya dön
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "MaintenencePage",
  setup() {
    const store = useStore();
    const main_img = "url('/new-img/" + store.state.theme + "-404.png')";

    return {
      main_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.not-found-page-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  background-position: center;
  width: 100vw;
  height: 100vh;
}

.title-404 {
  font-size: 100px;
}
</style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getPubmedRequests() {
    return apiClient.get("/api/pubmed");
  },
  createPubmedRequest(article_url) {
    return apiClient.post("/api/pubmed", {
      article_url: article_url,
    });
  },
  searchPubmed(keyword) {
    return apiClient.get("/api/pubmed-search", {
      params: {
        keyword: keyword,
      },
    });
  },
  deletePubmedRequest(id) {
    return apiClient.delete("/api/pubmed/" + id);
  },
};

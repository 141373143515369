<template>
  <div
    class="
      bg-info
      mb-4
      top-left-radius top-right-radius
      d-flex
      justify-content-between
      p-4
    "
  >
    <div class="d-flex align-items-center">
      <img
        :src="getProfilePicture(contactPeople[0])"
        :alt="contactPeople[0].fullname"
        class="border-radius-50 person-img-large mr-2"
      />
      <div class="d-flex flex-column">
        <span class="text-white font-w-600">{{
          contactPeople.map((p) => p.fullname).join(", ")
        }}</span>
        <span class="font-s-10px blue-footer-logo d-none"
          >Hacettepe Üniversitesi</span
        >
      </div>
    </div>
    <div class="d-flex">
      <button
        class="
          d-flex
          align-items-center
          btn
          bg-primary-30
          text-white
          border-radius
          delete-btn
          mr-2 mr-md-0
        "
        @click="$emit('askDeleting')"
      >
        <delete-icon color="#fff" class="mr-1" />
        <span class="d-none d-md-block font-s-12px font-w-500"
          >Mesajları Sil</span
        >
      </button>
      <button
        class="d-md-none btn bg-primary-30 text-white border-radius mobile-btn"
        @click="showReport = true"
      >
        <warning-icon color="white" />
      </button>
    </div>
  </div>
</template>

<script>
import { getProfilePicture } from "../../../shared/utils";

export default {
  name: "ConversationHeader",
  props: {
    contactPeople: {
      type: Object,
      required: true,
    },
  },
  emits: ["askDeleting"],
  setup() {
    return { getProfilePicture };
  },
};
</script>

<style lang="scss" scoped>
.delete-btn {
  width: 125px;
  height: 42px;
}
.person-img {
  width: 28px;
  height: 28px;
}

.person-img-large {
  width: 50px;
  height: 50px;
}
@media (max-width: 768px) {
  .delete-btn,
  .mobile-btn {
    width: 48px;
    height: 40px;
  }
}
</style>

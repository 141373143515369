import RegisterService from "../services/register/RegisterService";
import Swal from "sweetalert2";

export async function checkEmail(email) {
  try {
    await RegisterService.checkEmail(email);
    return "valid";
  } catch (error) {
    if (error.response.status === 422) {
      return "E-posta adresi geçersiz. Lütfen geçerli bir e-posta adresi girin.";
    } else {
      return '<p>Sistemimize belirtmiş olduğunuz e-posta adresi ile kayıtlı bir kullanıcı gözükmektedir. \
        Eğer şifrenizi unuttuysanız <a href="/password/reset">buraya</a> tıklayarak sıfırlayabilir, \
        bir hata olduğunu düşünüyorsanız <a href="mailto:npuge@santafarma.com.tr">buraya</a> \
        tıklayarak bizimle iletişime geçebilirsiniz.</p>';
    }
  }
}

export async function checkGlnNumberIsValid(glnNumber) {
  try {
    await RegisterService.checkGlnNumber(glnNumber);
    return true;
  } catch (error) {
    return false;
  }
}

export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\]:;"'|\\<>,.~`±§+-]).{8,30}$/;

export function getProfilePicture(user) {
  const profile_picture = user.profile_picture;

  if (user.avatar_url) {
    return user.avatar_url;
  }

  if (profile_picture && profile_picture.length > 0) {
    return profile_picture;
  }

  return "/images/no-profile-image.jpeg";
}

export function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function getSlideTypeByNamespace(namespace) {
  switch (namespace) {
    case "Epigra\\Videos\\Models\\Video":
      return "Video";
    case "Epigra\\Posts\\Models\\Post":
      return "Makale";
    case "Epigra\\Podcast\\Models\\PodcastChannel":
      return "Podcast Kanalı";
    default:
      return "";
  }
}

export function getRouteNameByNamespace(namespace) {
  switch (namespace) {
    case "Epigra\\Videos\\Models\\Video":
      return "videodetail";
    case "Epigra\\Posts\\Models\\Post":
      return "articledetail";
    case "Epigra\\Podcast\\Models\\PodcastChannel":
      return "podcastchanneldetail";
    default:
      return "";
  }
}

export function showError(valid, errors, fieldLabels) {
  if (!valid) {
    const errorFields = Object.keys(errors).map(
      (fieldName) => fieldLabels[fieldName] || fieldName
    );

    const errorMessage =
      errorFields.length > 1
        ? `Lütfen ${errorFields.join(", ")} alanlarını kontrol ediniz.`
        : `Lütfen ${errorFields[0]} alanını kontrol ediniz.`;

    const errorTitle =
      errorFields.length > 1 ? "Formda hatalar var" : "Formda hata var";

    return Swal.fire({
      title: errorTitle,
      html: errorMessage,
      icon: "error",
      confirmButtonText: "Tamam",
    });
  }
}

<template>
  <div class="calculate-rules mb-100px">
    <section class="calculate-rules-main bg-image d-flex flex-column mb-5">
      <content-main
        title="Hesaplama Cetvelleri"
        nav="Hesaplama Cetvelleri"
        class="mt-md-100px mt-160px"
      />
    </section>

    <section class="mb-5">
      <div class="container-xl d-none d-md-flex justify-content-center">
        <button
          v-for="(category, i) in categories"
          :key="i"
          class="
            btn
            bg-white
            font-w-500
            text-info
            box-shadow-4-20-2
            border-radius
            font-s-12px
            calculate-nav-btn
          "
          :class="{ active: showNavBtn === category }"
          @click="showNavBtn = category"
        >
          {{ category }}
        </button>
      </div>
      <div class="container-xl d-md-none">
        <select
          v-model="showNavBtn"
          class="
            w-100
            border-none
            height-50px
            border-radius
            box-shadow-4-20-2
            font-s-12px
            gray-500
            px-3
          "
        >
          <option
            v-for="(category, i) in categories"
            :key="i"
            :value="category"
            :selected="i === 0"
          >
            {{ category }}
          </option>
        </select>
      </div>
    </section>

    <section v-if="!isCalculationsLoading">
      <div class="container-xl">
        <div class="row">
          <div
            v-for="(calculation, index) in filteredCalculations"
            :key="index"
            class="col-6 col-sm-4 col-md-3 mb-4"
          >
            <calculate-card :calculation="calculation" :color="color" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import CalculateCard from "../components/ui/CalculateCard.vue";
import ContentMain from "../components/ui/ContentMain.vue";
import CalculationService from "../services/calculation/CalculationService";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "CalculateRules",
  components: {
    CalculateCard,
    ContentMain,
  },
  setup() {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueText;
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const isCalculationsLoading = ref(false);
    const calculations = ref(null);
    const showNavBtn = ref("Hepsi");
    const filteredCalculations = computed(() => {
      if (showNavBtn.value !== "Hepsi") {
        return calculations.value.filter((calculation) => {
          return calculation.subtitle === showNavBtn.value;
        });
      }
      return calculations.value;
    });

    const categories = computed(() => {
      if (calculations.value) {
        let categoriesArray = Array.from(
          new Set(calculations.value.map((obj) => obj.subtitle))
        );

        // if array includes "Diğer" then move it to end of the array
        if (categoriesArray.includes("Diğer")) {
          categoriesArray.push(
            categoriesArray.splice(categoriesArray.indexOf("Diğer"), 1)[0]
          );
        }

        return ["Hepsi", ...categoriesArray];
      }

      return ["Hepsi"];
    });

    const fetchCalculations = () => {
      isCalculationsLoading.value = true;
      store.dispatch("setLoading", true);
      CalculationService.getCalculations()
        .then((response) => {
          calculations.value = response.data.data;
        })
        .finally(() => {
          isCalculationsLoading.value = false;
          store.dispatch("setLoading", false);
        });
    };

    fetchCalculations();

    return {
      filteredCalculations,
      isCalculationsLoading,
      color,
      main_img,
      showNavBtn,
      categories,
    };
  },
};
</script>

<style lang="scss" scoped>
.calculate-rules-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}

.calculate-nav-btn {
  padding: 10px 20px;
  margin: 0 10px;

  &.active {
    background-color: v-bind("color") !important;
    color: white !important;
    box-shadow: none !important;
  }
}

@media (max-width: 768px) {
  .calculate-rules-main {
    height: 200px;
  }
}
</style>

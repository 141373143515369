<template>
  <div
    class="
      d-flex
      justify-content-center
      text-white
      position-absolute
      home-info-cards
      w-100
    "
  >
    <div
      class="
        home-info-card
        p-20px
        mr-3
        d-flex
        flex-column
        justify-content-between
        border-radius
        position-relative
      "
      :class="classCardBlue"
      :style="{ background: mainBackground }"
    >
      <svg
        class="position-absolute"
        width="103"
        height="98"
        viewBox="0 0 103 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.6">
          <path
            d="M72.3342 26.3293V9.36979C72.3342 5.8664 69.4539 3 65.9336 3H20.8576L3 18.4308V88.6259C3 92.1292 5.88026 94.9957 9.40057 94.9957H65.9336C69.4539 94.9957 72.3342 92.1292 72.3342 88.6259V84.7084"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.6503 4.2262V23.8292H14.6172"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M77.5665 77.622C89.7885 77.622 99.6965 67.7617 99.6965 55.5985C99.6965 43.4352 89.7885 33.575 77.5665 33.575C65.3444 33.575 55.4365 43.4352 55.4365 55.5985C55.4365 67.7617 65.3444 77.622 77.5665 77.622Z"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M67.4375 54.7386L76.4463 62.016L87.9833 47.1744"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5055 48.4006H17.1294V49.7701H18.5055V48.4006Z"
            :fill="color"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.4023 49.0854H49.3083"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5055 61.204H17.1294V62.5735H18.5055V61.204Z"
            :fill="color"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.4023 61.8888H49.4203"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5055 74.0072H17.1294V75.3767H18.5055V74.0072Z"
            :fill="color"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.4023 74.6919H55.4688"
            :stroke="color"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
      <h4 class="home-info-card-title font-s-25px font-weight-bold z-index-5">
        {{
          theme == "norozone"
            ? "Soru Bankası"
            : theme == "hemaworld"
            ? "Pubmed"
            : "Hesaplama Cetvelleri"
        }}
      </h4>
      <p v-if="theme == 'norozone'" class="z-index-5 font-s-12px">
        <span class="d-none d-md-block"
          >1051 sorudan oluşan NöroZone Soru Bankası,</span
        >
        Prof. Dr. İsmet M. Melek ve Dr. Ergin Eroğlu katkılarıyla
        hazırlanmıştır.
      </p>
      <p v-else-if="theme == 'hemaworld'" class="z-index-5 font-s-12px">
        Pubmed full text taraması yaparak ücretsiz tam metin talebinde
        bulunabilirsiniz.
      </p>
      <p v-else class="z-index-5 font-s-12px">
        Hesaplama Cetvelleri ile birçok değeri hesaplayabilirsiniz.
      </p>
      <router-link
        v-if="theme == 'norozone'"
        :to="{ name: 'questionbank', params: { id: 1 } }"
        class="
          d-flex
          align-items-center
          z-index-5
          justify-content-end
          cursor-pointer
          text-white
        "
      >
        <span class="font-s-12px"> Sonuçları Gör </span>
        <right-arrow-icon color="white" />
      </router-link>
      <router-link
        v-else-if="theme == 'hemaworld'"
        :to="{ name: 'pubmed' }"
        class="
          d-flex
          align-items-center
          z-index-5
          justify-content-end
          cursor-pointer
          text-white
        "
      >
        <span class="font-s-12px"> Talepte Bulunun </span>
        <right-arrow-icon color="white" />
      </router-link>
      <router-link
        v-else
        :to="{ name: 'hesaplama-cetvelleri' }"
        class="
          d-flex
          align-items-center
          z-index-5
          justify-content-end
          cursor-pointer
          text-white
        "
      >
        <span class="font-s-12px"> Hesaplama Cetvellerine Git </span>
        <right-arrow-icon color="white" />
      </router-link>
    </div>
    <div
      class="
        home-info-card
        p-20px
        ml-3
        d-flex
        flex-column
        justify-content-between
        home-info-card-bgRed
        border-radius
      "
      :class="classCardRed"
    >
      <svg
        class="position-absolute"
        width="125"
        height="104"
        viewBox="0 0 125 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.6" clip-path="url(#clip0)">
          <path
            d="M25.3341 54.1519C25.3341 54.8892 24.7314 55.4893 23.9908 55.4893C23.2502 55.4893 22.6475 54.8892 22.6475 54.1519C22.6475 53.4146 23.2502 52.8145 23.9908 52.8145C24.7314 52.8145 25.3341 53.4146 25.3341 54.1519Z"
            fill="#FFC1AF"
            stroke="#FFC1AF"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M38.8884 54.1519C38.8884 54.8892 38.2856 55.4893 37.545 55.4893C36.8044 55.4893 36.2017 54.8892 36.2017 54.1519C36.2017 53.4146 36.8044 52.8145 37.545 52.8145C38.3028 52.8145 38.8884 53.4146 38.8884 54.1519Z"
            fill="#FFC1AF"
            stroke="#FFC1AF"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M52.4596 54.1519C52.4596 54.8892 51.8569 55.4893 51.1163 55.4893C50.3757 55.4893 49.7729 54.8892 49.7729 54.1519C49.7729 53.4146 50.3757 52.8145 51.1163 52.8145C51.8569 52.8145 52.4596 53.4146 52.4596 54.1519Z"
            fill="#FFC1AF"
            stroke="#FFC1AF"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M80.7386 56.1752H90.6759L108.294 76.5283V56.1752H113.065C118.438 56.1752 122.83 51.8028 122.83 46.453V12.3655C122.83 7.01571 118.438 2.64331 113.065 2.64331H61.7768C56.4034 2.64331 52.0117 7.01571 52.0117 12.3655V19.91"
            stroke="#FFC1AF"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linejoin="round"
          />
          <path
            d="M16.6885 101.357V81.0036H11.9179C6.54454 81.0036 2.15283 76.6312 2.15283 71.2814V37.1938C2.15283 31.8441 6.54454 27.4717 11.9179 27.4717H63.2062C68.5795 27.4717 72.9712 31.8441 72.9712 37.1938V71.2814C72.9712 76.6312 68.5795 81.0036 63.2062 81.0036H34.307L16.6885 101.357Z"
            stroke="#FFC1AF"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M86.6973 30.6437C87.4393 30.6437 88.0407 30.045 88.0407 29.3063C88.0407 28.5677 87.4393 27.9689 86.6973 27.9689C85.9554 27.9689 85.354 28.5677 85.354 29.3063C85.354 30.045 85.9554 30.6437 86.6973 30.6437Z"
            fill="#FFC1AF"
            stroke="#FFC1AF"
            stroke-width="5"
            stroke-miterlimit="10"
          />
          <path
            d="M100.269 30.6437C101.011 30.6437 101.612 30.045 101.612 29.3063C101.612 28.5677 101.011 27.9689 100.269 27.9689C99.5267 27.9689 98.9253 28.5677 98.9253 29.3063C98.9253 30.045 99.5267 30.6437 100.269 30.6437Z"
            fill="#FFC1AF"
            stroke="#FFC1AF"
            stroke-width="5"
            stroke-miterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="125"
              height="103"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
      <h4 class="home-info-card-title font-s-25px font-weight-bold z-index-5">
        {{ theme == "hemaworld" ? "Clinical Key" : "Sizden Gelenler" }}
      </h4>
      <p class="z-index-5 font-s-12px">
        {{
          theme == "hemaworld"
            ? "Clinical Key üzerinden geniş bir kütüphane ile en güncel bilimsel verilere ulaşabilirsiniz."
            : zoneName + " sizlerden gelen içeriklerle artık daha güncel."
        }}
      </p>
      <router-link
        :to="{ name: theme == 'hemaworld' ? 'clinical-key' : 'comingyou' }"
        class="
          d-flex
          align-items-center
          justify-content-end
          z-index-5
          cursor-pointer
          text-white
        "
      >
        <span class="font-s-12px">
          {{
            theme == "hemaworld"
              ? "Ulaşmak İçin Tıklayınız "
              : "Yeni İçerik Oluştur"
          }}
        </span>
        <right-arrow-icon color="white" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed } from "vue";
import ZoneHelper from "../../shared/zone-helper";

export default {
  name: "MainCards",
  components: {},
  props: {
    classCardBlue: {
      type: String,
      required: false,
      default: "",
    },
    classCardRed: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const homeCard1 = ColorHelper[store.state.theme].homeCard1;
    const homeCard2 = ColorHelper[store.state.theme].homeCard2;
    const color = ColorHelper[store.state.theme].blueText;
    const theme = store.state.theme;
    const zoneHelper = new ZoneHelper();
    const zoneName = computed(() => zoneHelper.getZoneName(theme));

    const mainBackground = computed(() => {
      return (
        "linear-gradient(321.69deg, " +
        homeCard1 +
        " 0%, " +
        homeCard2 +
        " 100%)"
      );
    });

    return {
      mainBackground,
      color,
      theme: store.state.theme,
      zoneName,
    };
  },
};
</script>

<style lang="scss" scoped>
.home-info-cards {
  bottom: -50px;
}

.home-info-card {
  max-width: 457px;
  width: 100%;
  height: 150px;
}

@media (max-width: 768px) {
  .home-info-card {
    margin-right: 0 !important;
    margin-left: 0 !important;
    border-radius: 0 !important;

    &-title {
      font-size: 18px;
    }
  }
}

@media (max-width: 382px) {
  .home-info-card {
    padding: 10px;
  }
}
</style>

<template>
  <div
    v-if="
      router.currentRoute.value.name != 'login' &&
      router.currentRoute.value.name != 'register' &&
      (podcastList.length || activePodcast.id)
    "
    class="player position-fixed box-shadow-5-20"
  >
    <div class="position-relative">
      <transition name="slide-fade">
        <div
          v-show="openPlayer"
          class="player-stick position-absolute box-shadow-4-40 py-2"
        >
          <div
            class="
              container
              d-flex
              flex-column flex-md-row
              justify-content-md-between
              align-items-md-center
            "
          >
            <div class="d-flex mb-2 mb-md-0">
              <div class="player-stick-img bg-blue-300 border-radius mr-2">
                <voice-icon :size="36" color="white" class="" />
              </div>
              <div
                v-if="activePodcast.channel"
                class="d-flex flex-column justify-content-center"
              >
                <router-link
                  :to="{
                    name: 'podcastchanneldetail',
                    params: { id: activePodcast.channel.id },
                  }"
                  class="font-s-12px blue-300"
                  >Konuk: {{ activePodcast.channel.title }}
                </router-link>
                <p class="player-text font-w-500 black-700 m-0">
                  {{ activePodcast.title }}
                </p>
              </div>
              <div v-else class="d-flex align-items-center">
                Oynatılan podcast bulunmamaktadır...
              </div>
            </div>
            <div class="player-stick-range d-flex align-items-center">
              <pause-icon
                v-if="isPlaying"
                :size="32"
                :color="color"
                class="mr-2 cursor-pointer"
                @click="pause"
              />
              <podcast-play-icon
                v-else
                :size="32"
                :color="color"
                class="mr-2 cursor-pointer"
                @click="play"
              />

              <div class="volume-icon position-relative mr-2">
                <volume-icon :size="32" class="cursor-pointer" />
                <div class="volume-icon-box position-absolute">
                  <input
                    id="volume"
                    v-model="volume"
                    type="range"
                    min="0"
                    max="100"
                    @change="volume_change"
                  />
                </div>
              </div>
              <input
                v-model="slider"
                type="range"
                min="0"
                max="100"
                class="player-podcast-bar mr-2 w-100"
                @change="change_duration"
              />
              <span
                v-if="activePodcast.duration"
                class="gray-500 font-s-12px mr-2"
              >
                {{ calcDuration(activePodcast.duration) }}
              </span>
              <button
                v-if="activePodcast.id"
                class="btn font-weight-bold speed-btn"
                @click="changeSpeed(parseFloat(speeds[speedIndex + 1]))"
              >
                {{ speedIndex == -1 ? "0.5" : speeds[speedIndex] }}x
              </button>
              <div class="position-relative">
                <transition name="slide-fade">
                  <div
                    v-show="openPlayerList"
                    class="
                      player-list-box
                      box-shadow-15-50
                      position-absolute
                      p-3 p-md-5
                    "
                  >
                    <h4 class="font-s-16px font-weight-normal">
                      <span :class="theme == 'ortopedizone' ? 'mr-0' : 'mr-1'">
                        {{ zone.podcast }}
                      </span>
                      <span class="font-weight-bold text-info">Podcast</span>
                    </h4>
                    <div class="player-list-box-content">
                      <div
                        v-for="(podcast, index) in podcastList"
                        :key="index"
                        class="podcast-item d-flex align-items-center py-2"
                      >
                        <div
                          class="
                            player-stick-img
                            bg-blue-300
                            border-radius
                            mr-2
                          "
                        >
                          <voice-icon :size="32" color="white" />
                        </div>
                        <div
                          class="
                            w-100
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="d-flex flex-column">
                            <template v-if="podcast.channel">
                              <router-link
                                :to="{
                                  name: 'podcastchanneldetail',
                                  params: { id: podcast.channel.id },
                                }"
                                class="font-s-12px blue-300"
                                >Konuk: {{ podcast.channel.title }}
                              </router-link>
                            </template>
                            <p
                              class="
                                player-list-text
                                font-s-12px
                                text-secondary
                                m-0
                              "
                            >
                              {{ podcast.title }}
                            </p>
                          </div>
                          <div class="d-flex mr-3">
                            <div
                              class="d-flex flex-column align-items-center mr-3"
                            >
                              <pause-icon
                                v-if="
                                  isPlaying && activePodcast.id == podcast.id
                                "
                                :size="24"
                                :color="color"
                                class="cursor-pointer"
                                @click="pause"
                              />
                              <podcast-play-icon
                                v-else
                                :size="24"
                                :color="color"
                                class="cursor-pointer"
                                @click="play(podcast)"
                              />
                              <span
                                v-if="podcast.duration"
                                class="font-s-10px text-info"
                              >
                                {{ calcDuration(podcast.duration) }}
                              </span>
                            </div>
                            <div
                              class="cursor-pointer"
                              @click="deletePodcastList(podcast.id)"
                            >
                              <close-icon :color="color" :size="20" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <div
                  v-if="openPlayerList"
                  class="
                    player-list-close
                    bg-blue-300
                    border-radius-5px
                    cursor-pointer
                  "
                  @click="openPlayerList = !openPlayerList"
                >
                  <close-icon color="white" :size="20" class="m-2" />
                </div>
                <div
                  v-else
                  class="player-list-open cursor-pointer"
                  @click="openPlayerList = !openPlayerList"
                >
                  <podcast-list-icon
                    :size="20"
                    :color="colorDark"
                    class="m-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div
        class="bg-blue-300 border-radius-50 cursor-pointer"
        @click="openPlayer = !openPlayer"
      >
        <voice-icon :size="25" color="white" class="m-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ColorHelper from "../../helpers/ColorHelper";
import ZoneHelper from "../../shared/zone-helper";
import dayjs from "dayjs";
import PodcastService from "../../services/podcast/PodcastService";

export default {
  name: "PodcastPlayer",
  setup() {
    const store = useStore();
    const router = useRouter();
    const podcastService = new PodcastService();
    const theme = store.state.theme;

    const color = ColorHelper[store.state.theme].blueText;
    const colorDark = ColorHelper[store.state.theme].blueDark;

    const zoneHelper = new ZoneHelper();
    const zone = computed(() => zoneHelper.getZoneInfo(theme));

    const openPlayer = ref(false);
    const openPlayerList = ref(true);

    const speedIndex = ref(0);
    const speeds = [1, 1.5, 2, 0.5];

    const slider = ref(0);
    const volume = ref(70);
    const current = ref();
    const index = ref(0);
    const isPlaying = ref(false);
    const player = new Audio();
    let timer = null;
    let playerTime = computed(() => player.time);

    const podcastList = computed(() => {
      return store.getters.getPodcastList;
    });

    const activePodcast = computed(() => {
      return store.getters.getActivePodcast;
    });

    if (podcastList.value.length) {
      current.value = podcastList.value[index.value];
      player.src = current.value.file_url;
    }

    const play = (song) => {
      if (typeof song.file_url != "undefined") {
        current.value = song;
        player.src = current.value.file_url;
      }
      store.commit("setActivePodcast", current.value);
      timer = setInterval(range_slider, 500);
      player.play();
      player.muted = false;
      isPlaying.value = true;

      podcastService.show(song.id);
    };

    const range_slider = () => {
      if (!isNaN(player.duration)) {
        slider.value = player.currentTime * (100 / player.duration);
      }
    };

    const init = () => {
      player.addEventListener("ended", function () {
        clearInterval(timer);
        index.value++;
        if (index.value > podcastList.value.length - 1) {
          index.value = 0;
        }

        current.value = podcastList.value[index.value];
        play(current.value);
      });
    };

    init();

    const pause = () => {
      player.pause();
      isPlaying.value = false;
      clearInterval(timer);
    };

    const change_duration = (e) => {
      player.currentTime = e.target.value * (player.duration / 100);
    };

    const volume_change = () => {
      player.volume = volume.value / 100;
    };

    const changeSpeed = (speed) => {
      player.playbackRate = speed;
      if (speedIndex.value < 2) {
        speedIndex.value++;
      } else {
        speedIndex.value = -1;
      }
    };

    const deletePodcastList = (id) => {
      store.dispatch("deleteStoragePodcastList", id);
    };

    watch(activePodcast, () => {
      play(activePodcast.value);
      openPlayer.value = true;
      speedIndex.value = 0;
    });

    const calcDuration = (duration) => {
      if (parseInt(duration) % 60 < 10) {
        return (
          parseInt(parseInt(duration) / 60) +
          ":" +
          "0" +
          (parseInt(duration) % 60)
        );
      } else {
        return (
          parseInt(parseInt(duration) / 60) + ":" + (parseInt(duration) % 60)
        );
      }
    };

    return {
      player,
      router,
      color,
      slider,
      speeds,
      speedIndex,
      changeSpeed,
      pause,
      volume,
      play,
      isPlaying,
      change_duration,
      playerTime,
      openPlayer,
      openPlayerList,
      colorDark,
      podcastList,
      deletePodcastList,
      activePodcast,
      volume_change,
      zone,
      theme,
      dayjs,
      calcDuration,
    };
  },
};
</script>

<style lang="scss" scoped>
.player {
  right: 50px;
  bottom: 20vh;
  z-index: 11;

  &-stick {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(50px);
    width: 1000px;
    padding: 0 8rem 0 0;
    right: -50px;
    top: -15px;
    z-index: -1;
    display: flex;
    align-items: center;

    &-img {
      min-width: 62px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-range {
      width: 500px;
    }
  }

  &-list-box {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(50px);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    bottom: 51px;
    right: 0;
    width: 515px;

    &-content {
      max-height: 290px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 90px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 90px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: v-bind("color");
      }

      .podcast-item {
        border-top: 2px solid #fff;
      }
    }
  }

  &-text {
    max-width: 490px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &-list-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.volume-icon:hover .volume-icon-box {
  opacity: 1;
  visibility: visible;
}
.volume-icon-box {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all ease;
  top: -82px;
  left: -45px;
  z-index: 1;
  transform: rotate(-90deg);
  background-color: white;
  height: 40px;
  width: 120px;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    -webkit-appearance: none;
    background-color: lightgray;
    border-radius: 5px;
    height: 10px;
    width: 100px;
    cursor: pointer;
  }
}

.speed-btn {
  min-width: 55px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media (max-width: 768px) {
  .player-stick {
    width: 100vw;
    padding: 0;
  }
  .player-list-box {
    bottom: 100px;
  }
  .player-list-box {
    width: 320px;
  }
  .player-podcast-bar {
    width: 100px !important;
  }
}
</style>

<template>
  <div>
    <password-icon color="#7684AA" class="input-left-svg" />
    <input
      :type="type"
      class="form-control"
      placeholder="Parolanız"
      v-bind="{ ...$props, ...$attrs }"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <component
      :is="type === 'password' ? 'view-input' : 'hide-password'"
      class="input-right-svg cursor-pointer"
      @click="toggleType"
    />
  </div>
</template>
<script>
import { ref } from "vue";
import ViewInput from "../icon/EyeIcon.vue";

export default {
  name: "BasePasswordInput",
  components: {
    ViewInput,
  },
  props: {
    modelValue: {
      type: [String, null],
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const type = ref("password");

    return {
      type,
      toggleType() {
        const val = type.value;
        type.value = val === "password" ? "text" : "password";
      },
    };
  },
};
</script>

<style scoped></style>

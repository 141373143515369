<template>
  <div>
    <section class="content-main bg-image d-flex flex-column">
      <content-main
        title="AYDINLATMA METNİ"
        nav="AYDINLATMA METNİ"
        class="mt-md-100px mt-160px"
      />
    </section>

    <section class="container-xl my-5">
      <div class="row">
        <div class="card-body">
          <h4>
            <small>
              KİŞİSEL VERİLERİN KORUNMASI KAPSAMINDA SAĞLIK MESLEK MENSUPLARI
              (“SMM”), MÜŞTERİLER, TEDARİKÇİ VE ÇÖZÜM ORTAKLARI’NA YÖNELİK
              AYDINLATMA METNİ
            </small>
          </h4>

          <p>
            Kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak
            üzere, kişilerin temel hak ve özgürlüklerini korumak ve kişisel
            verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri belirlemek
            amacıyla 7 Nisan 2016 tarihli ve 29677 Sayılı Resmî Gazete’de
            yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)
            ışığında Santa Farma İlaç San. A.Ş. (“Santa Farma veya Şirket”)
            olarak Veri Sorumlusu sıfatıyla Kanun’un “Veri Sorumlusunun
            Aydınlatma Yükümlülüğü” başlıklı 10. maddesi uyarınca sizleri
            bilgilendirmek isteriz.
          </p>
          <p>
            <strong>Santa Farma</strong>, Sağlık Meslek Mensuplarından (“SMM”),
            müşterilerinden, tedarikçilerinden ve çözüm ortaklarından aldığı
            kişisel bilgilerin gizliliğini korumak için sistem altyapısını ve
            internet sunumlarını en güvenilir seviyede tutmaktadır.
          </p>

          <h4>İŞLENEN KİŞİSEL VERİLER</h4>
          <p>(i) Kimlik Bilgileriniz</p>
          <p>(ii) İletişim Bilgileriniz</p>
          <p>(iii) Eğitim, İş ve Profesyonel Yaşama İlişkin Verileriniz</p>
          <p>(iv) Finans Verilerini</p>
          <p>(vii) Hukuki İşlem Verileriniz</p>
          <p>(viii) Müşteri İşlem Verileriniz</p>
          <p>(ix) Siber Güvenliğe İlişkin Verileriniz</p>
          <p>(x) Özel Nitelikli Kişisel Verileriniz: Dernek ve vakıf üyeliği</p>

          <h4>KİŞİSEL VERİLERİN İŞLENME AMACI</h4>
          <p>
            <strong>Santa Farma,</strong> SMM’lerden, müşterilerilerinden,
            tedarikçilerinden ve çözüm ortaklarından zorunlu olarak alması
            gereken bilgilerin yanında yalnızca, vermeyi hedeflediği en iyi
            hizmet ve ürünü sağlamak amacı ile gerekli olduğunu düşündüğü
            kişisel verileri, yasal düzenlemelerin imkân verdiği ölçüde
            işlemekte, saklamakta ve aktarmaktadır.
          </p>

          <p>Bu bağlamda kişisel veriler;</p>
          <p>
            <strong>Müşteri, tedarikçi ve çözüm ortakları için;</strong>
            Şirketimiz ürün ve hizmetlerinin sunulabilmesi ve bu kapsamda
            gerekli işlerin yürütülmesi, açık rızanızın varlığı halinde ürün ve
            hizmetlerimiz ile ilgili olarak tarafınızca iletişime geçilmesi,
            pazarlama faaliyetlerinde bulunulması, müşteri edinimi
            çalışmalarının gerçekleştirilmesi ve bununla ilgili olarak ürün ve
            hizmetlerimize ilişkin tekliflerin paylaşılması, Şirket faaliyetleri
            kapsamında yapılması gerekli görülen raporlamaların, incelemelerin
            yapılması ile ilgili amaçlar kapsamında işlenmektedir.
          </p>

          <p>
            SMM için; Şirketimiz ürün ve hizmetlerinin sunulabilmesi ve bu
            kapsamda gerekli işlerin yürütülmesi, açık rızanızın varlığı halinde
            ürün ve hizmetlerimiz ile ilgili olarak tarafınızca iletişime
            geçilmesi, eğitimci ve katılımcı listelerinin oluşturulması, kongre
            katılımlarında konaklama ve seyahat planlamasının yapılması,
            sesli/görüntülü iletişim sağlayan çevrimiçi veya çevrimdışı
            platformlarda veya fiziki ortamlarda gerçekleşecek olan kongre/
            toplantı/eğitim/ organizasyon/sempozyum/webcastlerin planlanması ve
            icrası, değer aktarım formlarının doldurulması ve Bakanlık bildirimi
            yapılması ve ilgili mevzuatta öngörülen diğer hukuki yükümlülüklerin
            yerine getirilmesi, Şirket faaliyetleri kapsamında yapılması gerekli
            görünen raporlamaların, incelemelerin yapılması ile ilgili amaçlar
            kapsamında işlenmektedir.
          </p>

          <p>
            Ürün tanıtım toplantısına katılım sağlayacak SMM’lerimiz için,
            sesli/görüntülü iletişim sağlayan çevrimiçi veya çevrimdışı
            platformlarda gerçekleşecek olan ürün tanıtım toplantısının icrası,
            toplantıya olan katılımınızın sağlanması ve Beşeri Tıbbi Ürünlerin
            Tanıtım Faaliyetleri Hakkında Yönetmelik ve ilgili mevzuat/
            düzenleme kapsamında hukuki yükümlülüklerimizin yerine getirilmesi
            amacıyla işlenmektedir.
          </p>

          <h4>KİŞİSEL VERİLERİN TOPLANMASI VE HUKUKİ SEBEP</h4>
          <p>
            <strong>Santa Farma</strong>, SMM, müşterileri, tedarikçi ve çözüm
            ortaklarından kişisel verilerini yazılı, sözlü ve elektronik
            mecralardan, üçüncü kişilerden ve yasal mercilerden yukarıda yer
            verilen amaç ve hizmetlerin Kanun’un 5/1: “açık rıza”, 5/2/a:
            “kanunlarda açıkça öngörülmesi”, 5/2/c: “bir sözleşmenin kurulması
            veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
            taraflarına ait kişisel verilerin işlenmesinin gerekli olması”,
            5/2/ç: “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
            için zorunlu olması”, 5/2/e: bir hakkın tesisi, kullanılması veya
            korunması için veri işlemenin zorunlu olması, 5/2/f: “ilgili kişinin
            temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri
            sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması” , 6/2: “özel nitelikli kişisel verilerin işlenmesinde açık
            rıza” ve 8. Madde “kişisel verilerin aktarılması” hükümlerinde
            öngörülen çerçevede verilebilmesi amacı ile temin edebilmektedir.
          </p>

          <p>
            <strong>Santa Farma</strong>, ürün tanıtım toplantısına katılacak
            olan SMM’lerin kişisel verilerini, elektronik mecralar aracılığıyla
            ilgili kişinin kendisinden ve Kanun’un 5/2/a “kanunlarda açıkça
            öngörülmesi”, 5/2/ç “veri sorumlusunun hukuki yükümlülüğünü yerine
            getirebilmesi için zorunlu olması”, 5/2/e “Bir hakkın tesisi,
            kullanılması veya korunması için veri işlemenin zorunlu olması”
            hukuki sebeplerine dayanarak temin etmektedir.
          </p>

          <h4>İŞLENEN KİŞİSEL VERİLERİN AKTARIMI</h4>
          <p>
            <strong>Santa Farma</strong>’nın hizmet ve ürün sağlaması veya
            sözleşmenin ifası amacıyla hizmet aldığı yurtiçi ve yurtdışındaki
            üçüncü kişilerle, yurtdışındaki birimleriyle, gizlilik sözleşmesi
            yapmak seçimi Santa Farma’ya ait olmak üzere hizmet ve/veya
            danışmanlık aldığı kişi ve kurumlar, sözleşme imzaladığı iş
            ortaklarıyla, ortak çalışmalar yaptığı üçüncü kişilerle
            paylaşabilmektedir.
          </p>

          <p>
            <strong>Santa Farma</strong>’nın SMM, müşterileri, tedarikçi ve
            çözüm ortakları ile gerçekleştirdiği işlemlere ilişkin kayıt ve
            belgeleri yasal düzenlemeler ve Santa Farma’nın iç politikaları
            kapsamında belirli bir süre ile saklaması söz konusu olup; kişisel
            verilerinizin silinmesini veya yok edilmesini ya da
            anonimleştirilmesini istemeniz halinde bu talebiniz
            <strong>Şirketimiz</strong> tarafından yasal düzenlemeler ile
            belirlenen süre içinde yerine getirilebilecek; ancak bu süre
            zarfında kişisel verileriniz <strong>Santa Farma</strong> tarafından
            işlenmeyecek ve ulusal ve uluslararası yasa, düzenleme ve
            sözleşmelerden kaynaklı zorunluluklar haricinde 3. kişiler ile
            paylaşılmayacaktır.
          </p>

          <h4>KİŞİSEL VERİSİ İŞLENEN İLGİLİ KİŞİNİN HAKLARI</h4>

          <p>
            İşbu Sözleşme’nin tarafının gerçek kişi olması halinde SMM,
            müşterileri, tedarikçi ve çözüm ortakları kişisel verilerine ilişkin
            olarak, KVKK hükümleri uyarınca, 7 Ekim 2016 tarihi itibariyle,
            Santa Farma’ya başvurarak aşağıdaki haklarını kullanabilir:
          </p>
          <p>• Kişisel verilerinizin işlenip işlenmediğini öğrenme,</p>
          <p>
            • Kişisel verilerinizin işlenmişse buna ilişkin bilgi talep etme,
          </p>
          <p>
            • Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme,
          </p>
          <p>
            • Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
            üçüncü kişileri bilme,
          </p>
          <p>
            • Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde
            bunların düzeltilmesini isteme,
          </p>
          <p>• Kişisel verilerinizin silinmesini veya yok edilmesini isteme,</p>
          <p>
            • Kişisel verilerinizin düzeltilmesi, silinmesi ya da yok edilmesi
            halinde bu işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere
            bildirilmesini isteme,
          </p>
          <p>
            • İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla
            analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
            ortaya çıkmasına itiraz etme,
          </p>
          <p>
            • Kişisel verileriniz kanuna aykırı olarak işlenmesi sebebiyle
            zarara uğraması hâlinde zararın giderilmesini talep etme.
          </p>

          <p>
            Bu kapsamda iletilen talepler
            <strong>Santa Farma</strong> tarafından en geç otuz gün içinde
            ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrı bir maliyet
            gerektirmesi halinde on sayfanın üzerindeki cevapların her sayfası
            için ve/veya CD, flash bellek gibi bir kayıt ortamında verilmesini
            gerektirmesi halinde Veri Sorumlusuna Başvuru Usul ve Esasları
            Hakkında Tebliğ kapsamında belirlenen tarifedeki ücrete tabi
            olacaktır. Kişisel Verileri Koruma Kurulunca başkaca bir ücret
            öngörülmesi halinde, Şirketimiz tarafından belirlenen tarifedeki
            ücret alınacaktır.
          </p>

          <h4>TALEPLERİNİZ İÇİN BİZİMLE İLETİŞİME GEÇMEK İSTERSENİZ</h4>
          <p>
            6698 Sayılı Kanun kapsamında bizimle iletişime geçmek, geri
            bildirimde bulunmak ya da sorularınızı yöneltmek isterseniz,
            kimliğinizi teşvik edici belgeler ve talebinizi içeren dilekçeniz
            ile Santa Farma İlaç San. A.Ş Okmeydanı, Boru Çiçeği Sok. No:20
            Şişli, İstanbul adresine bizzat elden iletebilir, noter kanalıyla
            ulaştırabilir veya
            <a href="mailto:santafarmaas@hs01.kep.tr"
              >santafarmaas@hs01.kep.tr</a
            >
            adresine güvenli elektronik imzalı olarak iletebilirsiniz.
          </p>
          <p>
            Bu kapsamda konuyla ilgili yapılacak olan yazılı başvurular
            tarafımızdan yapılacak olan kimlik doğrulamasını takiben kabul
            edilecek olup, ilgililere yasal süreler içerisinde geri dönüşte
            bulunulacaktır.
          </p>

          <p>
            Versiyon:19042021 <br />
            Tarih: 19.04.2021
          </p>
          <p>
            <strong>Nihat Püge</strong><br />
            <strong>Santa Farma İlaç San. A.Ş.</strong>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import { useStore } from "vuex";

export default {
  components: {
    ContentMain,
  },
  setup() {
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";

    return {
      main_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}
</style>

import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAqPHNsxLgWelWu5_AvfMn9GGQu769bZ3o",
  authDomain: "zone-v3.firebaseapp.com",
  databaseURL: "https://zone-v3-default-rtdb.firebaseio.com",
  projectId: "zone-v3",
  storageBucket: "zone-v3.appspot.com",
  messagingSenderId: "12053762159",
  appId: "1:12053762159:web:dcf351f7f3ec6f3decbd9a",
  measurementId: "G-TRNWMSZCTV",
};

export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const messaging = async () => (await isSupported()) && getMessaging(app);

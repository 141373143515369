<template>
  <div class="container-xl">
    <h3 class="font-w-600 mb-4">{{ title }}</h3>
    <loading-anim-icon v-if="contentResponse.loading" class="p-3 pb-5 pt-0" />
    <template v-else-if="contentResponse.data.items">
      <div class="row">
        <div
          v-for="(content, index) in contentResponse.data.items"
          :key="index"
          class="col-12 col-sm-6 col-md-4 mb-3 mb-md-5"
        >
          <component
            :is="component"
            v-bind="{ [type]: content }"
            :color="color"
            classs="mx-sm-3 mx-xl-0 w-100 d-xl-none"
          />
        </div>
      </div>
    </template>
    <div v-else>Sonuç Bulunamadı</div>
    <pagination
      v-if="contentResponse.data.items"
      class="row"
      :current-page="contentResponse.data.current_page"
      :total-pages="contentResponse.data.last_page"
      :disabled="contentResponse.loading"
      @go-page="$emit('go-page', $event)"
    />
  </div>
</template>

<script>
import VideosCard from "../VideosCard.vue";
import ArticleSm from "../ArticleSm.vue";
import Pagination from "../Pagination.vue";
import ColorHelper from "../../../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    VideosCard,
    ArticleSm,
    Pagination,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    contentResponse: {
      type: Object,
      required: true,
    },
  },
  emits: ["go-page"],
  setup(props) {
    const store = useStore();
    const component = computed(() => {
      switch (props.type) {
        case "video":
          return "videos-card";
        case "post":
          return "article-sm";
        default:
          return "";
      }
    });

    const title = computed(() => {
      switch (props.type) {
        case "video":
          return "Videolar";
        case "post":
          return "Makaleler";
        default:
          return "";
      }
    });

    return {
      color: ColorHelper[store.state.theme].blueDark,
      component,
      title,
    };
  },
};
</script>

<style lang=""></style>

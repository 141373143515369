import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  sendSms(
    type,
    phoneNumber,
    firstname,
    lastname,
    email,
    isSMSChecked,
    isCallChecked
  ) {
    return apiClient.post("/api/send-sms", {
      type,
      phoneNumber,
      firstname,
      lastname,
      email,
      isSMSChecked,
      isCallChecked,
    });
  },
  validateSms(sms_id, sms_code) {
    return apiClient.post("/api/validate-sms", {
      sms_id,
      sms_code,
    });
  },
};

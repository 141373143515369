<template>
  <div
    class="
      box-shadow-4-20-2
      border-radius
      bg-white
      py-4
      position-relative
      change-settings-box
      d-block
    "
  >
    <div
      v-for="(navItem, navIndex) in settingsTab"
      :key="navIndex"
      class="justify-content-between px-3 py-2 nav-item d-flex"
      @click="$emit('setTab', navItem.name)"
    >
      <div>
        <component :is="navItem.icon" :color="color" class="mr-2" />
        <span class="text-info font-s-12px">{{ navItem.text }}</span>
      </div>
      <div>
        <right-arrow-icon :color="color" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "SettingsLeftNavigation",
  emits: ["setTab"],
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const color = ColorHelper[store.state.theme].blueText;

    const settingsTab = computed(() => {
      const tabs = [
        {
          name: "settings-general-information",
          icon: "person-icon",
          text: "Genel Bilgiler",
        },

        {
          name: "settings-password",
          icon: "password-icon",
          text: "Parola Değişikliği",
        },
        {
          name: "settings-account-settings",
          icon: "person-icon",
          text: "Hesap Ayarları",
        },
        // {
        //   name: "settings-notification",
        //   icon: "notification-icon",
        //   text: "Bilgilendirme Ayarları",
        // },
      ];

      if (theme === "eczazone") {
        tabs.splice(2, 0, {
          name: "settings-pharmacy-information",
          icon: "edit-icon",
          text: "Eczane Bilgileri",
        });
      } else {
        tabs.splice(2, 0, {
          name: "settings-professional-information",
          icon: "job-icon",
          text: "Mesleki Bilgiler",
        });
      }

      return tabs;
    });

    return {
      color,
      settingsTab,
    };
  },
};
</script>

<style scoped></style>

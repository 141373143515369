<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M11.1654 11.3333L8.4987 14L5.83203 11.3333"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 14V8"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0943 12.2599C14.7445 11.9055 15.2582 11.3445 15.5542 10.6657C15.8502 9.98686 15.9118 9.22877 15.7291 8.51107C15.5464 7.79338 15.1299 7.15696 14.5454 6.70225C13.9609 6.24754 13.2415 6.00044 12.501 5.99995H11.661C11.4592 5.21944 11.0831 4.49484 10.5609 3.88061C10.0388 3.26638 9.38418 2.77852 8.64635 2.45369C7.90852 2.12886 7.10665 1.97552 6.30103 2.0052C5.49541 2.03489 4.70699 2.24682 3.99507 2.62506C3.28314 3.0033 2.66622 3.53802 2.1907 4.189C1.71517 4.83999 1.39341 5.59031 1.2496 6.38354C1.1058 7.17678 1.14369 7.9923 1.36043 8.76879C1.57716 9.54527 1.96711 10.2625 2.50095 10.8666"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "UploadIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

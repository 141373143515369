<template>
  <div class="mt-6 mb-3">
    <div v-if="posts.length" class="container-xl">
      <section-title
        title-dark="Öne Çıkan"
        :title-blue="theme == 'eczazone' ? 'Haberler' : 'Makaleler'"
        :more-title="{
          title: theme == 'eczazone' ? 'Tüm Haberler' : 'Tüm Makaleler',
          routeName: 'articles',
        }"
      />

      <div
        class="
          d-flex
          flex-column-reverse
          align-items-center
          justify-content-lg-between
          flex-lg-row
        "
      >
        <div class="mr-4 featured-articles">
          <div
            v-for="(post, index) in posts"
            :key="index"
            class="
              d-flex
              flex-column flex-sm-row
              mb-4
              cursor-pointer
              align-items-center
            "
          >
            <div class="mr-3 featured-th">
              <router-link
                :to="{ name: 'articledetail', params: { id: post.id } }"
              >
                <img class="border-radius" :src="post.image_url" alt="" />
              </router-link>
            </div>
            <div class="d-flex flex-column w-100">
              <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center text-info mb-2">
                  <article-icon :color="colorMoreIcon" />
                  <span class="font-s-12px">
                    {{ theme == "eczazone" ? "Haber" : "Makale" }}
                  </span>
                </div>
                <span class="text-secondary font-s-10px">{{
                  post.publish_on
                }}</span>
              </div>
              <div>
                <router-link
                  :to="{ name: 'articledetail', params: { id: post.id } }"
                >
                  <h4
                    class="font-s-18px font-weight-bold black-700 line-h-25px"
                  >
                    {{ post.title }}
                  </h4>
                </router-link>
                <p class="text-secondary text-secondary line-h-22px">
                  {{ post.subtitle ?? post.summary }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="bigPost"
          class="
            featured-xl-th
            bg-image
            d-flex
            flex-column
            py-4
            px-3
            border-radius
          "
          :style="{ background: bigVideoBackground }"
        >
          <div class="d-flex justify-content-end">
            <save-icon
              favoritable-type="post"
              :favoritable-id="bigPost.id"
              :is-button="true"
              :is-favorited="bigPost.is_favorited"
              color="white"
              class="mr-1"
              button-classes="btn btn-info border-radius d-flex align-items-center"
              text="Kaydet"
              favorited-text="Kaydedildi"
              text-classes="font-s-12px"
            />
          </div>
          <div class="d-flex flex-column justify-content-center mt-auto">
            <div
              class="
                d-flex
                justify-content-center
                text-white
                mb-3
                featured-xl-info
              "
            >
              <div class="d-flex mr-4">
                <article-icon color="white" class="mr-1" />
                <span class="font-s-12px">
                  {{ theme == "eczazone" ? "Haber" : "Makale" }}
                </span>
              </div>
              <div class="font-s-12px">
                {{ bigPost.publish_on }}
              </div>
            </div>
            <router-link
              :to="{ name: 'articledetail', params: { id: bigPost.id } }"
            >
              <h3
                class="
                  featured-xl-title
                  mb-3
                  text-white
                  font-weight-bold font-s-22px
                  text-center
                  line-h-33px
                "
              >
                {{ bigPost.title }}
              </h3>
            </router-link>
            <div class="d-flex justify-content-center">
              <a
                href=""
                class="btn btn-light d-flex align-items-center border-radius"
              >
                <router-link
                  :to="{ name: 'articledetail', params: { id: bigPost.id } }"
                >
                  <span
                    class="
                      featured-btn-text
                      text-info
                      font-s-12px font-w-500
                      mr-1
                    "
                    >Devamını Oku</span
                  >
                  <right-arrow-icon color="#1686D6" />
                </router-link>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container-xl">Makale Bulunamadı.</div>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle.vue";
import { computed, ref } from "vue";
import dayjs from "dayjs";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "FeaturedArticles",
  components: {
    SectionTitle,
  },
  props: {
    recentPosts: { type: Object, required: true },
  },
  setup(props) {
    const store = useStore();
    const theme = ref(store.state.theme);
    const colorMoreIcon = ColorHelper[store.state.theme].blueText;
    const posts = ref(null);
    const bigPost = ref(null);
    const homeArticle = ColorHelper[store.state.theme].homeArticle;
    const bigVideoBackground = ref();

    const mappedPosts = computed(() => {
      if (props.recentPosts.items && props.recentPosts.items.length) {
        return props.recentPosts.items.map((post) => {
          return {
            ...post,
            publish_on: dayjs(post.publish_on)
              .locale("tr")
              .format("DD MMMM YYYY"),
          };
        });
      }
      return [];
    });

    posts.value = mappedPosts.value.filter((item, index) => index < 3);
    if (mappedPosts.value.length > 3) {
      bigPost.value = mappedPosts.value[3];
      bigVideoBackground.value = props.recentPosts.items[3]
        ? "linear-gradient(360deg," +
          homeArticle +
          " -1.17%, rgba(22, 134, 214, 0) 64.62%), linear-gradient(0.82deg, rgba(0, 0, 0, 0.8) -19.07%, rgba(22, 134, 214, 0) 84.74%), url(" +
          props.recentPosts.items[3].image_url +
          ")"
        : "";
    }

    return {
      posts,
      bigPost,
      bigVideoBackground,
      colorMoreIcon,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.featured-th {
  img {
    width: 275px;
    height: 150px;
    object-fit: cover;
  }
}

.featured-xl-th {
  max-width: 350px;
  height: 515px;
}

@media (max-width: 992px) {
  .featured-xl-th {
    height: 311px;
    max-width: 374px;
    margin-bottom: 30px;
  }
  .featured-xl-info {
    margin-bottom: 5px !important;
  }
  .featured-xl-title {
    font-size: 18px;
    line-height: 27px;
  }
  .featured-btn-text {
    font-size: 10px !important;
  }
  .featured-articles {
    margin: 0 !important;
  }
  .featured-th {
    max-width: 374px !important;
    width: 100%;

    img {
      width: 100%;
      max-height: 153px !important;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 576px) {
  .featured-th {
    margin: 0 0 14px !important;
  }
}
</style>

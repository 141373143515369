<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3542 9.25943C12.804 9.25943 13.1815 9.63066 13.1128 10.0749C12.7092 12.6882 10.4721 14.6286 7.77381 14.6286C4.78855 14.6286 2.3689 12.2089 2.3689 9.22434C2.3689 6.76539 4.23697 4.47487 6.34012 3.95697C6.79205 3.84539 7.25521 4.16329 7.25521 4.62855C7.25521 7.78083 7.36118 8.59627 7.95977 9.03978C8.55837 9.48329 9.26223 9.25943 12.3542 9.25943Z"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2972 6.63439C15.333 4.60913 12.8453 1.34457 9.81374 1.40071C9.57795 1.40492 9.38917 1.60141 9.37865 1.8365C9.30216 3.50176 9.40531 5.65966 9.46286 6.6379C9.4804 6.94246 9.7197 7.18176 10.0236 7.19931C11.0292 7.25685 13.2657 7.33545 14.9071 7.08702C15.1302 7.05334 15.2937 6.85966 15.2972 6.63439Z"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "FactIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "#7684AA",
    },
    size: {
      type: Number,
      required: false,
      default: 16,
    },
  },
};
</script>

<style></style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  favorite(user_id, favoritable_type, favoritable_id) {
    return apiClient.post("/api/favorites/update", {
      user_id,
      favoritable_type,
      favoritable_id,
    });
  },
  getFavoritedItems(page = 1, per_page = 15) {
    return apiClient.get("/api/me/favorites", {
      params: {
        page,
        per_page,
      },
    });
  },
  getFavoritedItemsByType(favoritable_type, page = 1, per_page = 15) {
    if (["video", "post", "events"].includes(favoritable_type)) {
      return apiClient.get(`/api/${favoritable_type}`, {
        params: {
          qb: true,
          "filter[favorited]": true,
          page,
          per_page,
        },
      });
    }

    throw "parameter favoritable_type is not valid";
  },
};

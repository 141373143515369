<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.47064 10.8012L1.99923 10.3053V8.92031L12.315 6L22.6309 8.92031V10.3053L12.315 13.6398L5.00324 11.2761"
      :stroke="color"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0332 13.521V15.8398C16.7374 17.0586 14.6939 17.8421 12.4456 17.8421C10.1999 17.8421 8.15376 17.0612 6.85796 15.8398V12.0146"
      :stroke="color"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.91241 16.3201V10.2552L11.8145 8.40857"
      :stroke="color"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.79566 17.4808C3.79566 18.0929 4.29589 18.5862 4.91053 18.5862C5.52783 18.5862 6.02539 18.0902 6.02539 17.4808C6.02539 16.8714 5.52517 16.3755 4.91053 16.3755C4.29323 16.3755 3.79566 16.8714 3.79566 17.4808Z"
      :stroke="color"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.41395 18.5072L6.03125 21.0002H3.79088L4.40818 18.5072"
      :stroke="color"
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "JobIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <section class="mb-75px">
    <div class="container-xl">
      <div class="bg-white border-radius box-shadow-4-20 p-5">
        <h2 class="font-s-24px text-primary mb-5">CLINICAL KEY/ELSEVIER</h2>
        <p>
          Açılan sayfadaki kullanıcı adı ve şifre bölümlerine OXFET yazarak
          giriş yapabilirsiniz.
        </p>
        <ul class="mb-4">
          <li>Kullanıcı Adı: OXFET</li>
          <li>Şifre: OXFET</li>
        </ul>

        <div>
          <a
            href="https://www.onlineerisim.com/santafarma/index.php"
            target="_blank"
            class="btn btn-primary border-radius px-4 py-2"
          >
            Arayüze Git
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>

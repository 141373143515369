<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.23525 5.96695C2.66958 4.11534 4.11534 2.66958 5.96696 2.23525C7.30417 1.92158 8.69583 1.92158 10.033 2.23525C11.8847 2.66958 13.3304 4.11534 13.7647 5.96696C14.0784 7.30417 14.0784 8.69583 13.7647 10.033C13.3304 11.8847 11.8847 13.3304 10.033 13.7648C8.69583 14.0784 7.30417 14.0784 5.96696 13.7647C4.11534 13.3304 2.66958 11.8847 2.23525 10.033C1.92158 8.69583 1.92158 7.30417 2.23525 5.96695Z"
      :stroke="color"
    />
    <path
      d="M9.17856 6.82153L6.82153 9.17856M9.17856 9.17856L6.82153 6.82153"
      :stroke="color"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseCircleIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
    size: {
      type: Number,
      required: false,
      default: 16,
    },
  },
};
</script>

<style></style>

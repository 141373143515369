<template>
  <div>
    <div>
      <textarea
        v-model="comment"
        name="comment"
        class="w-100 p-2 border-radius"
        placeholder="Yorumunuzu buraya yazabilirsiniz..."
      />
    </div>
    <div class="d-flex justify-content-end mt-3 mb-5">
      <button
        :disabled="isButtonDisabled"
        class="btn btn-info border-radius"
        @click="createComment"
      >
        Yorumu Gönder
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import CommentService from "../../../../services/comment/CommentService";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SwalError from "../../../../helpers/SwalError";

export default {
  name: "VideoCommentForm",
  props: {
    videoId: { type: String, required: true },
  },
  emits: ["commentAdded"],
  setup(props, ctx) {
    const comment = ref("");
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const isButtonDisabled = ref(false);

    const createComment = () => {
      isButtonDisabled.value = true;
      CommentService.createVideoComment(
        props.videoId,
        user.value.id,
        comment.value
      )
        .then(() => {
          comment.value = "";
          ctx.emit("commentAdded");
          Swal.fire({
            icon: "success",
            title: "Yorumunuz başarıyla gönderildi.",
            text: "Yorumunuz yönetici onayının ardından yayınlanacaktır.",
            timer: 2000,
            showConfirmButton: false,
          });
        })
        .catch((error) => {
          SwalError.fire(error);
        })
        .finally(() => {
          isButtonDisabled.value = false;
        });
    };

    return {
      comment,
      createComment,
      isButtonDisabled,
    };
  },
};
</script>

<style lang="scss" scoped>
textarea {
  border: 1px solid #7684aa73;
  height: 150px;
  resize: none;

  &::placeholder {
    color: #7684aa81;
    font-style: italic;
    font-size: 10px;
  }
}
</style>

<template>
  <div class="form-vue-select" :class="{ 'form-error-border': errorMessage }">
    <vue-select
      v-model="value"
      :multiple="multiple"
      v-bind="binds"
      :options="options ?? []"
      :loading="loading"
      :disabled="loading || disabled"
      :class="{ 'is-invalid': errorMessage }"
    />
    <div v-if="showErrorMsg && errorMessage" class="input-error-msg px-2">
      {{ errorMessage }}
    </div>
    <p v-if="infoMessage" class="m-0 mt-2 px-2 font-s-12px gray-500">
      {{ infoMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { watchEffect, computed } from "vue";
import VueSelect from "vue-select";

export default {
  name: "BaseSelect",
  components: {
    VueSelect,
  },
  inheritAttrs: false,
  props: {
    infoMessage: {
      type: String,
      required: false,
      default: undefined,
    },
    showErrorMsg: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: false,
      type: [String, Boolean, Object, Array],
      default: undefined,
    },
    validations: {
      type: Object,
      required: false,
      default: undefined,
    },
    optionTextKey: {
      type: String,
      required: false,
      default: undefined,
    },
    optionValueKey: {
      type: String,
      required: false,
      default: undefined,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { attrs, emit }) {
    const { errorMessage, value } = props.validations
      ? useField(attrs.name, props.validations)
      : useField(attrs.name);

    watchEffect(() => {
      emit("update:modelValue", value.value);
    });

    const binds = computed(() => {
      const obj = {
        ...attrs,
        options: props.options,
      };

      if (props.optionTextKey) {
        obj.label = props.optionTextKey;
      }
      if (props.optionValueKey) {
        obj.reduce = (opt) => opt[props.optionValueKey];
      }
      return obj;
    });

    return {
      errorMessage,
      value,
      binds,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="login d-flex align-items-center justify-content-center p-4">
    <div class="max-w-555px">
      <div class="d-none d-lg-block login-logo mb-4">
        <router-link :to="{ name: 'home' }">
          <img :src="logo_url" alt="" class="img-fluid" />
        </router-link>
      </div>

      <div class="mb-5">
        <p class="font-s-16px gray-500">
          Türkiye'nin {{ zone.deparment }}
          {{ zone.theme == "ahezone" ? "hekimlerine" : "uzmanlarına" }} özel
          geniş içeriğe sahip iletişim platformu.
        </p>
      </div>

      <div>
        <form>
          <div class="form-input">
            <div class="mb-4 position-relative">
              <base-input
                type="email"
                name="email"
                placeholder="E-Posta Adresiniz"
                :left-icon="{
                  name: 'MailIcon',
                  color: '#7684aa',
                  class: 'input-left-svg',
                  size: 24,
                }"
                :show-error-msg="false"
              />
            </div>
          </div>
          <div class="mb-4">
            <button
              type="button"
              class="btn btn-primary w-100 border-radius py-3"
              :disabled="isSubmitting"
              @click="forgotPassword()"
            >
              Parola Unuttum Bildirimi Gönder
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useForm } from "vee-validate";
import * as yup from "yup";
import BaseInput from "./RegisterForm/BaseInput";
const store = useStore();
import UserServiceNew from "../../services/UserServiceNew";
import Swal from "sweetalert2/dist/sweetalert2.js";

defineProps({
  zone: {
    type: Object,
    required: true,
  },
});

const userService = new UserServiceNew();
const isSubmitting = ref(false);
const logo_url = "/new-img/" + store.state.theme + "-logo.png";
const router = useRouter();

const form = useForm({
  validationSchema: {
    email: yup.string().required(),
  },
});

async function forgotPassword() {
  const { valid } = await form.validate();
  if (!valid) {
    return;
  }

  isSubmitting.value = true;
  try {
    await userService.forgotPassword(form.values);
    isSubmitting.value = false;
    Swal.fire({
      icon: "success",
      title: "Şifre sıfırlama linki e-posta adresinize gönderildi.",
      confirmButtonText: "Tamam",
      timer: 3000,
    });
    router.push("/");
  } catch (e) {
    isSubmitting.value = false;
    Swal.fire({
      icon: "error",
      title: "Bir hata oluştu. Email adresinizi kontrol edin.",
      confirmButtonText: "Tamam",
    });
  }
}
</script>

<style></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.94414 8.78418C3.31747 7.12497 4.60448 5.81882 6.26653 5.41238C8.53218 4.85834 10.9252 4.86467 13.1909 5.41871C14.832 5.82005 16.1361 7.09343 16.549 8.72135L16.574 8.81991C17.1868 11.2356 17.1868 13.7644 16.574 16.1801L16.549 16.2787C16.1361 17.9066 14.832 19.18 13.1909 19.5813C10.9252 20.1353 8.53218 20.1417 6.26653 19.5876C4.60449 19.1812 3.31747 17.875 2.94414 16.2158L2.8825 15.9419C2.3725 13.6752 2.3725 11.3248 2.8825 9.05815L2.94414 8.78418Z"
      :stroke="color"
      stroke-width="1.5"
    />
    <path
      d="M16.6633 15.8084L16.8603 15.8736C16.9115 15.8905 16.9614 15.9111 17.0097 15.9351L18.6936 16.7713C19.9829 17.4117 21.5 16.4804 21.5 15.0485L21.5 10.3101C21.5 8.79729 19.8246 7.87498 18.533 8.67679L17.0474 9.59908C16.9742 9.64452 16.9064 9.69808 16.8453 9.75871L16.7904 9.81325C17.1526 11.7977 17.1102 13.8366 16.6633 15.8084Z"
      :stroke="color"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "EventCastIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

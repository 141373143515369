<template>
  <div
    class="
      channel-card
      d-flex
      flex-column
      align-items-center
      justify-content-between
      box-shadow-4-20-3
      p-4
    "
    :class="mobile"
  >
    <router-link
      :to="{ name: 'channeldetail', params: { id: channelValue.id } }"
      class="d-flex flex-column align-items-center"
    >
      <img
        :src="channelValue.thumbnail"
        alt=""
        class="channel-card-person border-radius-50 mb-3 box-shadow-4-20"
      />
      <h4
        class="
          height-50px
          font-s-20px
          text-primary
          font-w-600
          text-center
          channel-card-name
        "
      >
        {{ channelValue.title }}
      </h4>
      <span class="text-primary font-s-12px mb-2"
        >{{ channelValue.video_count }} Video</span
      >
      <span class="gray-700 font-s-12px text-center mb-3 channel-card-desc"
        ><span class="font-w-500">{{ channelValue.description }}</span></span
      >
    </router-link>
    <div>
      <button
        v-if="!channelValue.is_subscribed"
        :disabled="isSubscribing"
        class="btn btn-info d-flex align-items-center btn-subs border-radius"
        @click="subscribe"
      >
        <notification-icon class="mr-1" color="white" :size="16" />
        <span class="font-s-12px font-w-500">Kanala Abone Ol</span>
      </button>
      <button
        v-else
        :disabled="isSubscribing"
        class="btn btn-light d-flex align-items-center btn-subs border-radius"
        :style="{ backgroundColor: '#AFBBDB', color: 'white' }"
        @click="unsubscribe"
        @mouseover="handleUnsubcribeHover(true)"
        @mouseleave="handleUnsubcribeHover(false)"
      >
        <confirm-icon class="mr-1" />
        <span class="font-s-12px font-w-500">{{ unsubscribeText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import SubscriptionService from "../../services/subscription/SubscriptionService";
import { ref } from "@vue/reactivity";

export default {
  name: "ChannelCard",
  props: {
    channel: { type: Object, required: true },
    mobile: { type: String, required: false, default: null },
  },
  setup(props) {
    const channelValue = ref(props.channel);
    const isSubscribing = ref(false);
    const unsubscribeText = ref("Kanala Abonesin");

    const subscribe = () => {
      isSubscribing.value = true;
      channelValue.value.is_subscribed = true;
      SubscriptionService.subscribe("video_category", channelValue.value.id)
        .catch(() => {
          channelValue.value.is_subscribed = false;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    const unsubscribe = () => {
      isSubscribing.value = true;
      channelValue.value.is_subscribed = false;
      SubscriptionService.unsubscribe("video_category", channelValue.value.id)
        .catch(() => {
          channelValue.value.is_subscribed = true;
        })
        .finally(() => {
          isSubscribing.value = false;
        });
    };

    const handleUnsubcribeHover = (isHover) => {
      if (isHover) {
        unsubscribeText.value = "Abonelikten Çık";
      } else {
        unsubscribeText.value = "Kanala Abonesin";
      }
    };

    return {
      isSubscribing,
      channelValue,
      subscribe,
      unsubscribe,
      unsubscribeText,
      handleUnsubcribeHover,
    };
  },
};
</script>

<style lang="scss" scoped>
.channel-card {
  max-width: 245px;
  width: 100%;
  height: 354px;
}

.channel-card-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.channel-card-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.channel-card-person {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: top;
}

.btn-subs {
  height: 35px;
}

@media (max-width: 768px) {
  .channel-card-name {
    font-size: 16px;
  }

  .channel-card-desc {
    font-size: 10px;
  }

  .btn-subs {
    width: 148px;
  }
}
</style>

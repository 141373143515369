<template>
  <div class="library">
    <section class="library-main bg-image d-flex flex-column mb-5">
      <content-main
        title="EPI Library"
        nav="EPL Library"
        class="mt-md-100px mt-160px"
      />
    </section>

    <template v-if="!isVideosLoading">
      <section>
        <div class="container-xl">
          <div
            class="
              d-flex
              justify-content-center justify-content-xl-between
              mb-5
            "
          >
            <div>
              <videos-cardmain :video="mainVideo" />
            </div>

            <div class="d-none max-w-260px d-xl-flex flex-column">
              <videos-card class="mx-sm-3 mx-xl-0" :video="leftSideVideos[0]" />
              <videos-card class="mx-sm-3 mx-xl-0" :video="leftSideVideos[1]" />
            </div>

            <div class="d-none max-w-260px d-xl-flex flex-column">
              <videos-card
                class="mx-sm-3 mx-xl-0"
                :video="rightSideVideos[0]"
              />
              <videos-card
                class="mx-sm-3 mx-xl-0"
                :video="rightSideVideos[1]"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-xl">
          <div
            class="
              d-flex
              flex-wrap
              justify-content-center justify-content-xl-between
            "
          >
            <videos-card
              class="mx-sm-3 mx-xl-0 width-355px d-xl-none"
              :video="leftSideVideos[0]"
            />
            <videos-card
              class="mx-sm-3 mx-xl-0 width-355px d-xl-none"
              :video="leftSideVideos[1]"
            />
            <videos-card
              class="mx-sm-3 mx-xl-0 width-355px d-xl-none"
              :video="rightSideVideos[0]"
            />
            <videos-card
              class="mx-sm-3 mx-xl-0 width-355px d-xl-none"
              :video="rightSideVideos[1]"
            />
            <videos-card
              v-for="(video, index) in otherVideos"
              :key="index"
              class="mx-sm-3 mx-xl-0 width-355px"
              :video="video"
            />
          </div>

          <div class="d-flex justify-content-center mb-90px">
            <router-link
              :to="{ name: 'epilibrary' }"
              class="d-flex align-items-center"
            >
              <span class="text-info font-w-500">Son Eklenen Tüm Videolar</span>
              <right-arrow-icon :color="color" />
            </router-link>
          </div>
        </div>
      </section>
    </template>
    <loading-anim-icon v-else class="p-3" />

    <section>
      <div class="container-xl">
        <div class="row">
          <div class="d-flex justify-content-center align-items-center w-100">
            <pagination
              :disabled="isVideosLoading"
              :total-pages="videosMeta.last_page"
              :current-page="videosMeta.current_page"
              @go-page="fetchRecentVideos($event)"
            />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <channel-list v-if="!isChannelsLoading" :channels="videoCategories" />
        <loading-anim-icon v-else class="p-3" />
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ChannelList from "../components/ui/ChannelList.vue";
import VideosCard from "../components/ui/VideosCard.vue";
import VideosCardmain from "../components/ui/VideosCardmain.vue";
import { ref } from "vue";
import VideoService from "../services/video/VideoService";
import VideoCategoryService from "../services/video/VideoCategoryService";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import Pagination from "../components/ui/Pagination";

export default {
  name: "EpiLibrary",
  components: {
    ContentMain,
    VideosCard,
    VideosCardmain,
    ChannelList,
    Pagination,
  },
  setup() {
    const isVideosLoading = ref(false);
    const isChannelsLoading = ref(false);
    const mainVideo = ref([]);
    const leftSideVideos = ref([]);
    const rightSideVideos = ref([]);
    const otherVideos = ref([]);
    const videoCategories = ref([]);
    const videosMeta = ref({});
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueText;
    const mainColor1 = ColorHelper[store.state.theme].guidesMain1;
    const mainColor2 = ColorHelper[store.state.theme].guidesMain2;
    const img_url = "url(/new-img/library-main.png)";

    const fetchRecentVideos = (page = 1, per_page = 14) => {
      isVideosLoading.value = true;
      VideoService.getRecentEpiLibraryVideos(page, per_page)
        .then((response) => {
          const videos = response.data.data.items;

          videosMeta.value = {
            last_page: response.data.data.last_page,
            current_page: response.data.data.current_page,
          };
          mainVideo.value = videos[0];
          leftSideVideos.value = videos.slice(1, 3);
          rightSideVideos.value = videos.slice(3, 5);
          otherVideos.value = videos.slice(5, 14);
        })
        .finally(() => {
          isVideosLoading.value = false;
        });
    };

    const fetchChannels = () => {
      isChannelsLoading.value = true;
      VideoCategoryService.getEpiLibraryCategories()
        .then((response) => {
          videoCategories.value = response.data.data;
        })
        .finally(() => {
          isChannelsLoading.value = false;
        });
    };

    fetchRecentVideos();
    fetchChannels();

    return {
      videosMeta,
      fetchRecentVideos,
      mainVideo,
      leftSideVideos,
      rightSideVideos,
      otherVideos,
      isVideosLoading,
      videoCategories,
      isChannelsLoading,
      color,
      mainColor1,
      mainColor2,
      img_url,
    };
  },
};
</script>

<style lang="scss" scoped>
.library-main {
  background: linear-gradient(
      180deg,
      rgba(0, 62, 167, 0) 24.02%,
      v-bind("mainColor1") 96.22%
    ),
    linear-gradient(0deg, v-bind("mainColor2"), v-bind("mainColor2")),
    v-bind("img_url");
  width: 100vw;
  height: 300px;
}

@media (max-width: 768px) {
  .library-main {
    height: 180px;
  }
}
</style>

import ApiService from "../ApiService";

export default class ChatService extends ApiService {
  collection = "chat";
  sendMessage(threadId, body) {
    return this.client.post(`/api/chat/threads/${threadId}/messages`, { body });
  }

  seenMessage(threadId) {
    return this.client.post(`/api/chat/threads/${threadId}/seen`);
  }
  start(users, subject = "") {
    return this.client.post("/api/chat/start", { users, subject });
  }
}

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="Vücut Yüzey Alanı Hesaplama Aracı"
        nav="Vücut Yüzey Alanı Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-30px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="form_vya"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-5 mb-4">
                <h5 class="font-s-20px font-w-600 black-700">Formül Seçiniz</h5>
                <div class="d-flex align-items-baseline">
                  <input
                    id="formula-1"
                    type="radio"
                    class="mr-1 h1_formula"
                    name="formula"
                    value="1"
                  />
                  <label class="text-secondary" for="formula-1"
                    >Mosteller Formülü</label
                  >
                </div>
                <div class="d-flex align-items-baseline">
                  <input
                    id="formula-2"
                    type="radio"
                    class="mr-1 h1_formula"
                    name="formula"
                    value="2"
                  />
                  <label class="text-secondary" for="formula-2"
                    >DuBois Formülü</label
                  >
                </div>
                <div class="d-flex align-items-baseline">
                  <input
                    id="formula-3"
                    class="mr-1 h1_formula"
                    type="radio"
                    name="formula"
                    value="3"
                  />
                  <label class="text-secondary" for="formula-3"
                    >Haycock Formülü</label
                  >
                </div>
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Boy (cm)</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="boy"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Kilo (kg)</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="kilo"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "BodySurface",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const calculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      $(".return-text").slideDown();
      let form = $("#form_vya");
      let boy = form.find('input[name="boy"]').val();
      let kilo = form.find('input[name="kilo"]').val();
      let ftype = form.find(".h1_formula:checked").val();
      let error = 0;
      form.find(".is-invalid").removeClass("is-invalid");
      if (!ftype) {
        form.find(".h1_formula:checked").addClass("is-invalid");
        error++;
      }
      if (!boy) {
        form.find('input[name="boy"]').addClass("is-invalid");
        error++;
      }
      if (!kilo) {
        form.find('input[name="kilo"]').addClass("is-invalid");
        error++;
      }
      if (error) {
        $(".return-text").html("Eksik alanlar mevcut");
        return false;
      }
      let call;
      if (ftype == 1) {
        call = Math.sqrt((boy * kilo) / 3600);
      } else if (ftype == 2) {
        call = 0.20247 * Math.pow(boy / 100, 0.725) * Math.pow(kilo, 0.425);
      } else if (ftype == 3) {
        call = 0.024265 * Math.pow(boy, 0.3964) * Math.pow(kilo, 0.5378);
      }
      returnData(parseFloat(call.toFixed(2)), null, "m2");
    };

    const clearAll = () => {
      $("form")[0].reset();
      $(".return-text").slideUp();
    };

    const returnData = (data = null, string = null, type = "") => {
      if (!data || data < 0) {
        data = -1;
      } else {
        data = parseFloat(data);
      }
      if (!string) {
        string = -1;
      }
      if (data == -1 && string == -1) {
        string = "-";
      }
      if (type) {
        if (data > 0) {
          $(".return-number").html(data);
          $(".return-text").html(type);
        } else {
          $(".return-text").html(type);
        }
      } else {
        if (data > 0) {
          $(".return-number").html(data);
        }
      }
    };

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      calculate,
      clearAll,
      apgar: [
        {
          title: "Vücut Yüzey Alanı Nedir, Nasıl Hesaplanır?",
          content: `  <p>Doğumdan itibaren sürekli gelişen ve değişen vücut yapısı, kişiden kişiye göre farklılık gösterir. Özellikle boy ve kilo; beslenme, genetik yapı, çevresel faktörler, fiziksel aktiviteler, ilaç kullanımı gibi faktörlere bağlı olarak değişir. Ayrıca kişinin günlük yaşam aktiviteleri de boy ve kilo oranında etkili bir rol oynar. Voleybol, basketbol gibi sporlarla uğraşan kişiler genellikle uzun boylu olduğu için, vücut yüzey alanları ideal bir seviyededir. Gün içinde fazla hareket etmeyen ve sık sık yemek yiyen kişilerde bu oran yükseklik gösterir. Vücut Yüzey Alanı Nedir? Vücut yüzey alanı, kişinin boy ve ağırlığının birbirine oranına göre vücudun kapladığı toplam yüzey alanıdır. Vücut yüzey alanı hesaplanarak ideal kilo ve yağ oranı belirlenir. Özellikle diyet programlarında yer verilen VYA hesaplama, bireyler için uygun diyet programları hazırlanmasını sağlar. Hesaplama sonucu düşük veya yüksek çıkarsa, sonuç mutlaka doktor ve diyetisler ile paylaşılmalıdır.</p>


        `,
        },
        {
          title: "Vücut Yüzey Alanı Nasıl (BSA) Hesaplama ve Formülleri",
          content: `    <p>BSA hesaplamada birbirinden farklı formüller kullanılır. Hesaplama yapılırken boy, kilo ve istenilen formül seçilmelidir. Böylece doğru sonuçlar elde edilir. Vücut yüzey alanı hesaplama formülü olarak en fazla; Mosteller, DuBois ve Haycock formülleri kullanılır. İnternetten otomatik bir şekilde vücut metrekare hesaplaması yapılabilir. Vücut yüzey alanı yüksek çıkan kişilerin ideal kilolarını ve yağ oranlarını hesaplaması da önerilir. Diyet programlarında vücuttaki yağ oranı baz alınır. Böylece kişinin ideal kilosu, günlük alması ve yakması gereken kalori değeri hesaplanır. Ayrıca gün içerisinde yapılması gereken yürüyüş ve egzersizler de belirlenir.</p>


        `,
        },
        {
          title: "Vücut Yüzey Alanı Hesaplama Sonuçları",
          content: `
            <p>Vücut yüzeyi hesaplama sonuçları yüksek çıktığında, bireyin kilo ve boy oranındaki normal değerler belirlenir. Hesaplamanın yüksek çıkmasındaki genel nedenler;</p>

            <ul>
                <li>Dengeli ve düzenli beslenmemek</small></li>
                <li>Uyku düzensizliği</small></li>
                <li>Genetik olarak kilo almaya yatkınlık</small></li>
                <li>Metabolizmanın yavaş çalışması</small></li>
                <li>Aşırı yağ tüketimi</small></li>
                <li>Spor yapmamak</small></li>
                <li>Ana öğünleri arttırmak</small></li>
                <li>Bazı ilaçların yan etkisi</small></li>
                <li>Hamilelik</small></li>
                <li>Hareketsiz bir yaşamdır.</small></li>
            </ul>

            <p>Kadınların vücudundaki yağ oranı, erkeklere göre daha fazladır. Bu da vücut yüzey alanı değerini arttırır. 20-40 yaş aralığındaki kadınların ideal yağ oranı %21-32 arasındayken, bu oran 41-59 yaş arasında %23-33’e yükselir. 20 ile 40 yaş aralığında bulunan erkeklerde ideal oran %8-19, 41-59 yaş aralığında ise %11-21 arasında olmalıdır. Vücut yüzey alanı hesaplanırken boy uzunluğunun önemi de oldukça büyüktür. Kısa boylu kişiler kilo almaya yatkın olduğu için, her yıl BSA hesaplaması yapmalıdır. BSA hesaplarının yüksek çıkması obezite gibi hastalıkların habercisidir. Bu nedenle doktor ve diyetisyen desteği alınmalıdır.</p>


`,
        },
      ],
      references: [
        "http://www.perfuzyon.org.tr/TR,553/vucut-yuzey-alani-hesaplama-formulleri.html",
        "https://acikders.ankara.edu.tr/pluginfile.php/47655/mod_resource/content/0/PDF%202.pdf",
        "https://www.hesaplamaci.com/vucut-yuzey-alani-hesaplama",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <div
    class="point border-radius-50 mr-2 mt-2"
    :class="color"
    :style="'min-width:' + size + 'px;min-height:' + size + 'px'"
  />
</template>

<script>
export default {
  name: "PointTextIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "bg-primary",
    },
  },
};
</script>

<style lang="scss" scoped>
.point {
  min-width: 9px;
  min-height: 9px;
}
</style>

<template>
  <div v-if="show" class="notification-container">
    <div class="notification-image">
      <notification-icon color="black" :size="48" />
    </div>
    <div class="notification-body">
      <h1>Sizleri en son güncellemeler hakkında bilgilendirmek isteriz</h1>
      <div class="button-container">
        <button
          type="button"
          class="btn btn-success"
          @click="
            () => {
              show = false;
              saveMessagingDeviceToken();
            }
          "
        >
          İzin Ver
        </button>
        <button type="button" class="btn btn-secondary" @click="show = false">
          Kapat
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import NotificationIcon from "../icon/NotificationIcon.vue";
import { saveMessagingDeviceToken } from "../../shared/firebase-messaging";

const show = ref(true);
</script>

<style scoped>
.notification-container {
  z-index: 9999;
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  max-width: 384px;
  padding: 16px;
  background-color: white;
}

.notification-image {
  padding: 16px;
  border-radius: 100%;
  background-color: lightgray;
}

.notification-body {
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 8px;
}

.notification-body h1 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #111827;
}

.notification-body .button-container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.notification-body .button-container button {
  width: 96px;
}
</style>

<template>
  <div
    class="
      bg-image
      background-fixed
      position-relative
      d-flex
      flex-column
      mb-85px
    "
    :style="{ background: mainBackground }"
  >
    <!-- mobile swiper -->
    <div class="invisible-md height-385px">
      <div class="swiper-container mySwiperMain">
        <div class="swiper-wrapper">
          <div
            v-for="(slide, index) in mappedSlides"
            :key="index"
            class="
              swiper-slide
              bg-image
              height-320px
              d-flex
              flex-column
              bg-transparent
              text-white
              px-4
              py-3
            "
            :style="{ backgroundImage: `url(${slide.url})` }"
          >
            <div class="d-flex mb-4">
              <div class="d-flex mr-4">
                <article-icon color="white" />
                <span class="font-s-13px">{{ slide.type }}</span>
              </div>
              <div class="font-s-12px">
                {{ slide.created_at }}
              </div>
            </div>
            <h1 class="font-weight-bold font-s-28px mb-3">
              {{ slide.title }}
            </h1>
            <div>
              <a
                :href="slide.target_url"
                class="
                  d-flex
                  align-items-center
                  btn btn-main
                  border-radius
                  nav-button
                  max-w-160px
                "
              >
                <router-link
                  :to="{
                    name: slide.routeName,
                    params: { id: slide.target_id },
                  }"
                  class="font-s-12px text-white"
                  >Detaylı Bilgi</router-link
                >
                <right-arrow-icon color="white" />
              </a>
            </div>
          </div>
        </div>
        <div
          class="swiper-pagination-main d-flex justify-content-center mt-3 mb-2"
        />
      </div>
    </div>

    <!-- web slider -->
    <div
      class="
        main
        d-none
        container-xl
        d-md-flex
        flex-row-reverse
        justify-content-between
        align-items-center
        mt-5
      "
    >
      <nav>
        <div class="d-flex flex-column">
          <button
            v-for="(slide, index) in slides"
            id="home-slider-nav"
            :key="index"
            class="bg-transparent border-none my-2"
            @click="changeMainSlider(index + 1)"
          >
            <span
              :class="{ active: mainSliderShow == index + 1 }"
              class="mr-2 font-w-300 text-white font-s-12px"
              >{{ index + 1 }}</span
            >
            <small-line-icon
              class="opacity-0"
              :class="{ active: mainSliderShow == index + 1 }"
            />
          </button>
        </div>
      </nav>
      <div class="text-white main-slider-content">
        <transition
          v-for="(slide, index) in mappedSlides"
          :key="index"
          name="component-fade"
          mode="out-in"
        >
          <div v-if="mainSliderShow == index + 1" class="">
            <div class="d-flex mb-4">
              <div class="d-flex mr-4">
                <article-icon color="white" />
                <span>{{ slide.type }}</span>
              </div>
              <div>{{ slide.created_at }}</div>
            </div>
            <h1 class="font-weight-bold font-s-40px mb-3">
              {{ slide.title }}
            </h1>
            <p class="mb-4">
              {{ slide.subtitle }}
            </p>
            <div>
              <a
                :href="slide.target_url"
                class="
                  d-flex
                  align-items-center
                  btn-main
                  border-radius
                  nav-button
                  max-w-160px
                "
              >
                <router-link
                  :to="{
                    name: slide.routeName,
                    params: { id: slide.target_id },
                  }"
                  class="text-white"
                  >Detaylı Bilgi</router-link
                >
                <right-arrow-icon color="white" />
              </a>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="min-h-100px" />

    <main-cards v-if="theme != 'hemaworld'" />
  </div>
</template>

<script>
import MainCards from "./MainCards.vue";
import Swiper from "swiper/bundle";
import { computed, ref, onMounted } from "vue";
import dayjs from "dayjs";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";
import {
  getRouteNameByNamespace,
  getSlideTypeByNamespace,
} from "../../shared/utils";

export default {
  name: "Main",
  components: {
    MainCards,
  },
  props: {
    slides: { type: Object, required: true },
  },
  setup(props) {
    const mainSliderShow = ref(1);
    const store = useStore();
    const theme = store.state.theme;
    const mainSlider1 = ColorHelper[theme].mainSlider1;
    const mainSlider2 = ColorHelper[theme].mainSlider2;
    const swiperInstance = ref(null);

    const mappedSlides = computed(() => {
      return props.slides.map((slide) => {
        return {
          ...slide,
          type: getSlideTypeByNamespace(slide.target_type),
          routeName: getRouteNameByNamespace(slide.target_type),
          created_at: dayjs(slide.created_at)
            .locale("tr")
            .format("DD MMMM YYYY"),
        };
      });
    });

    const mainBackground = computed(() => {
      const slideBgImageUrl =
        props.slides[mainSliderShow.value - 1]?.url ||
        "/img/thumbnails/default.jpg";
      return `linear-gradient(0deg, ${mainSlider1}, ${mainSlider2}), url(${slideBgImageUrl})`;
    });

    const changeMainSlider = (index) => {
      mainSliderShow.value = index;
      if (swiperInstance.value) {
        swiperInstance.value.slideTo(index - 1);
      }
    };

    onMounted(() => {
      if (props.slides.length > 1) {
        swiperInstance.value = new Swiper(".mySwiperMain", {
          observer: true,
          observeParents: true,
          slidesPerView: 1,
          spaceBetween: 0,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination-main",
            clickable: true,
          },
        });

        swiperInstance.value.on("slideChange", function () {
          changeMainSlider(this.activeIndex + 1);
        });
      }
    });

    return {
      mainBackground,
      mainSliderShow,
      changeMainSlider,
      mappedSlides,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .invisible-md {
    visibility: hidden;
    height: 0px;
  }
}
.main {
  width: 100vw;
  height: 540px;
}

.main-slider-content {
  max-width: 830px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  margin-top: 60px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-main {
  position: relative;
  bottom: 35px;
  z-index: 2;
}

.btn-main {
  border: 1px solid #fff;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

#home-slider-nav {
  span {
    &.active {
      color: #fff;
      font-weight: 500;
      font-size: 16px;
    }
  }

  svg {
    &.active {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div class="home-podcast mb-5">
    <div v-if="podcasts.length" class="container-xl">
      <section-title
        :title-dark="zone.podcast"
        :title-dark-class="theme == 'ortopedizone' ? 'mr-0' : 'mr-2'"
        title-blue="Podcast"
        :more-title="{ title: 'Tüm Podcastler', routeName: 'podcast' }"
      />

      <section
        v-if="podcasts.length"
        class="d-flex justify-content-center mb-4"
      >
        <div class="d-flex flex-column align-items-center podcast">
          <voice-icon :size="89" :color="color" class="mb-4" />
          <p class="font-s-22px font-weight-bold text-center mb-4">
            {{ podcasts[0].title }}
          </p>
          <div
            class="mb-5 d-flex justify-content-center align-items-center w-100"
          >
            <podcast-play-icon
              :color="color"
              :size="29"
              class="mr-2 cursor-pointer"
              @click="playPodcast(podcasts[0])"
            />

            <div class="podcast-add-icon bg-blue-300 border-radius-50">
              <plus-icon
                :size="20"
                color="white"
                class="cursor-pointer"
                @click="addPodcastList(podcasts[0], 0)"
              />
            </div>
            <!-- <input
              type="range"
              min="0"
              max="100"
              class="podcast-bar mr-2 w-100"
            />
            <span class="gray-500">38:11</span> -->
          </div>
        </div>
      </section>

      <section class="row">
        <div
          v-for="(podcast, index) in podcasts.slice(0, 6)"
          :key="index"
          class="col-lg-6 mb-4 mb-md-3"
        >
          <div class="d-flex flex-column flex-md-row align-items-center">
            <div class="mb-2 mb-md-0 mr-3">
              <div
                class="border-radius px-5 py-4"
                :class="
                  activePodcast.id == podcast.id ? 'bg-primary' : 'bg-blue-300'
                "
              >
                <voice-icon :size="50" color="white" />
              </div>
            </div>
            <div>
              <div class="d-flex align-items-center">
                <div class="position-relative">
                  <podcast-play-icon
                    :color="color"
                    :size="29"
                    class="mr-2 cursor-pointer"
                    @click="playPodcast(podcast)"
                  />
                  <transition name="slide-fade">
                    <div
                      v-if="playingText"
                      class="position-absolute bg-white border-radius p-2"
                    >
                      <span class="font-s-12px">Oynatılıyor</span>
                    </div>
                  </transition>
                </div>
                <div
                  class="
                    podcast-add-icon
                    bg-blue-300
                    border-radius-50
                    position-relative
                  "
                >
                  <plus-icon
                    :size="20"
                    color="white"
                    class="cursor-pointer"
                    @click="addPodcastList(podcast, index)"
                  />
                  <transition name="slide-fade">
                    <div
                      v-if="warningText && index == currentIndex"
                      class="
                        warning-text
                        p-2
                        border-radius
                        bg-white
                        position-absolute
                      "
                    >
                      <span class="font-s-12px">Playliste eklendi.</span>
                    </div>
                  </transition>
                </div>
              </div>
              <div v-if="podcast.channel">
                <router-link
                  :to="{
                    name: 'podcastchanneldetail',
                    params: { id: podcast.channel.id },
                  }"
                  class="font-s-12px blue-300"
                  >Konuk: {{ podcast.channel.title }}
                </router-link>
              </div>
              <div>
                <p
                  class="
                    podcast-card-text
                    font-s-16px font-w-600
                    text-secondary
                    m-0
                  "
                >
                  {{ podcast.title }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="container-xl">Podcast Bulunamadı.</div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import SectionTitle from "./SectionTitle.vue";
import ColorHelper from "../../helpers/ColorHelper";
import { ref, computed } from "vue";
import ZoneHelper from "../../shared/zone-helper";

export default {
  name: "HomePodcast",
  components: {
    SectionTitle,
  },
  props: {
    podcasts: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueText;
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const warningText = ref(false);
    const currentIndex = ref();
    const playingText = ref(false);

    const theme = store.state.theme;
    const zoneHelper = new ZoneHelper();
    const zone = computed(() => zoneHelper.getZoneInfo(theme));

    const activePodcast = computed(() => {
      return store.getters.getActivePodcast;
    });

    const addPodcastList = (podcast, index) => {
      currentIndex.value = index;
      warningText.value = true;
      if (!activePodcast.value.id) {
        store.commit("setActivePodcast", podcast);
      }
      store.commit("setPodcastList", podcast);
      store.dispatch("setStoragePodcastList");
      setTimeout(() => {
        warningText.value = false;
      }, 2000);
    };

    const playPodcast = (podcast) => {
      playingText.value = true;
      if (activePodcast.value.id != podcast.id) {
        store.commit("setPodcastList", podcast);
        store.dispatch("setStoragePodcastList");
        store.commit("setActivePodcast", podcast);
      }
      if (!activePodcast.value.id) {
        store.commit("setActivePodcast", podcast);
      }
      setTimeout(() => {
        playingText.value = false;
      }, 2000);
    };

    return {
      color,
      colorDark,
      addPodcastList,
      activePodcast,
      warningText,
      currentIndex,
      playPodcast,
      zone,
      theme,
      playingText,
    };
  },
};
</script>

<style lang="scss" scoped>
.podcast {
  max-width: 780px;
}

.warning-text {
  left: 35px;
  top: -10px;
  line-height: 13px;
}

.podcast-add-icon {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.podcast-card-text {
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  subscribe(subscribable_type, subscribable_id) {
    return apiClient.put("/api/me/subscribe", {
      subscribable_type,
      subscribable_id,
    });
  },
  unsubscribe(subscribable_type, subscribable_id) {
    return apiClient.put("/api/me/unsubscribe", {
      subscribable_type,
      subscribable_id,
    });
  },
};

<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.8086 29.5871C34.8569 29.5991 34.9051 29.6112 34.9534 29.6233C40.2393 30.7456 45.6277 31.3731 51.0523 31.4878C52.2651 31.5119 53.3271 30.6853 53.5806 29.4965L53.8219 28.3561C54.2021 26.5761 55.952 25.4356 57.732 25.8158L60.206 26.3407C62.342 26.7933 64.4419 25.3934 64.8341 23.2272C65.2142 21.1333 63.7359 19.1481 61.6602 18.7016L59.3431 18.2128C57.5631 17.8327 56.4226 16.0828 56.8028 14.3028L56.9476 13.627C57.2734 12.0943 56.133 10.6522 54.5641 10.6039C49.4472 10.441 44.3666 9.82549 39.3764 8.7635C39.3281 8.75143 39.2798 8.73936 39.2316 8.72729"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M32.1094 58.7738C32.1576 58.7738 34.0282 58.7497 34.0765 58.7497C39.477 58.8704 44.8895 58.4842 50.2417 57.5911C51.4365 57.392 52.3295 56.3783 52.3537 55.1654L52.3778 54.0009C52.42 52.1786 53.9286 50.7364 55.7508 50.7787L58.2791 50.833C60.4634 50.8813 62.2676 49.1193 62.2495 46.9169C62.2314 44.7929 60.4152 43.1094 58.2912 43.0611L55.9258 43.0068C54.1035 42.9646 52.6614 41.4561 52.7036 39.6338L52.7217 38.9398C52.7579 37.377 51.3641 36.1702 49.8194 36.4116C44.7628 37.202 37.9262 37.902 32.8274 37.7934"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M39.7489 87.2727C39.7972 87.2666 39.8455 87.2546 39.8998 87.2485C45.2158 86.2831 50.4413 84.8168 55.5039 82.8618C56.6323 82.4273 57.302 81.2567 57.0848 80.062L56.8736 78.9155C56.5478 77.1234 57.7365 75.4097 59.5286 75.0839L62.0207 74.6313C64.1688 74.2391 65.5808 72.1513 65.1222 69.9971C64.6757 67.9154 62.5577 66.6362 60.4699 67.0163L58.1408 67.4387C56.3487 67.7645 54.635 66.5758 54.3091 64.7837L54.1824 64.1019C53.9049 62.5632 52.2998 61.6581 50.8335 62.2072C46.0364 63.9993 33.0934 67.1611 33.0391 67.1732"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M39.23 8.73328C39.23 8.73328 22.4372 56.3964 39.749 87.2727"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M53.1219 62.5632C53.8278 61.6521 54.0933 60.4211 53.8278 59.2988C53.5684 58.1764 52.7779 57.1929 51.7461 56.686"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M52.168 36.9788C53.3325 36.4237 54.1713 35.2471 54.3221 33.9678C54.473 32.6886 53.9299 31.5301 52.9283 30.7275"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "KasIskelet",
};
</script>

<style></style>

import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  fire(error) {
    if (error.response?.status === 412) {
      Swal.fire({
        title: "Uyarı!",
        text: error.response.data.data[
          Object.keys(error.response.data.data)[0]
        ][0],
        icon: "error",
        confirmButtonText: "Kapat",
      });
    } else if (error.response?.status === 422) {
      Swal.fire({
        title: "Uyarı!",
        html: error.response.data?.errors
          ? error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ][0]
          : error.response.data.message,
        icon: "error",
        confirmButtonText: "Kapat",
      });
    } else if (error.response?.status === 429) {
      Swal.fire({
        title: "Uyarı!",
        text: "Art arda çok sayıda deneme yaptınız. Lütfen daha sonra tekrar deneyiniz.",
        icon: "error",
        confirmButtonText: "Kapat",
      });
    } else {
      Swal.fire({
        title: "Hata!",
        text: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        icon: "error",
        confirmButtonText: "Kapat",
      });
    }
  },
};

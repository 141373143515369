<template>
  <section class="mb-5">
    <div class="container-xl">
      <h3 class="font-s-24px text-secondary mb-4">
        <b class="text-info">Yeni Klinik Tanıtımı</b>
        Oluştur
      </h3>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <div class="mb-5">
            <p class="font-s-12px font-w-600 gray-500">Klinik Adı</p>
            <input
              type="text"
              class="font-s-12px gray-500 border-radius title-input w-100 px-3"
              placeholder="Lütfen klinik adını yazınız..."
              :value="title"
              @input="$emit('update:title', $event.target.value)"
            />
          </div>
          <template v-if="questions.length > 0">
            <div
              v-for="(question, index) in questions"
              :key="index"
              class="mb-5 px-4"
            >
              <add-collapse
                v-model:content="answers[question.id]"
                :text="question.title"
                :color="colorText"
              />
            </div>
          </template>
          <loading-anim-icon v-else class="m-3 mb-5" />
          <file-upload-area v-model:files="filesValue" :is-type="isType" />
        </div>

        <div class="col-12 col-md-4 col-lg-3">
          <add-sidebar
            v-model:files="filesValue"
            :color="color"
            :disabled="isSending"
            @send="$emit('send')"
            @save="$emit('save')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AddCollapse from "./AddCollapse.vue";
import AddSidebar from "./AddSidebar.vue";
import UserContentService from "../../services/user-content/UserContentService";
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import FileUploadArea from "./FileUploadArea.vue";

export default {
  name: "AddClinic",
  components: {
    AddSidebar,
    AddCollapse,
    FileUploadArea,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    colorDark: {
      type: String,
      required: true,
    },
    colorText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:title", "update:content", "update:files", "send", "save"],
  setup(props, ctx) {
    const userContentService = new UserContentService();
    const questions = ref([]);
    const answers = ref([]);

    const filesValue = computed({
      get() {
        return props.files;
      },
      set(value) {
        ctx.emit("update:files", value);
      },
    });

    const fetchClinicQuestions = () => {
      userContentService.getClinicQuestions().then((response) => {
        questions.value = response.data.data;
      });
    };

    fetchClinicQuestions();

    watch(
      [answers, questions],
      () => {
        ctx.emit(
          "update:content",
          JSON.stringify({
            questions: questions.value,
            answers: answers.value,
          })
        );
      },
      { deep: true }
    );

    return {
      questions,
      answers,
      filesValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.title-input,
.select-year {
  border: 1px solid #afbbdb;
  height: 45px;
}

.upload-clinic-box {
  border: 1px dashed #7684aa;
}
.upload-img {
  max-height: 316px;
  max-width: 316px;
}
.upload-btn {
  width: 208px;
  height: 36px;
}
</style>

<template>
  <div
    class="
      d-flex
      flex-column
      align-items-center
      font-s-12px
      text-white
      mb-100px
      opacity-0-5
    "
    @click="$emit('changeShow', false)"
  >
    <router-link :to="{ name: 'yasal-uyari' }" class="text-white my-2">
      Yasal Uyarı
    </router-link>
    <router-link :to="{ name: 'aydinlatma-metni' }" class="text-white my-2">
      Aydınlatma Metni
    </router-link>
    <router-link :to="{ name: 'kvkk' }" class="text-white my-2">
      Açık Rıza Metni
    </router-link>
    <router-link :to="{ name: 'kvkk-yd' }" class="text-white my-2">
      Açık Rıza Metni (Yurt Dışı Veri Transferi)
    </router-link>
    <a href="mailto:npuge@santafarma.com.tr" class="text-white my-2"
      >İletişim</a
    >
  </div>
</template>

<script>
export default {
  name: "LegalContentMobile",
  emits: ["changeShow"],
};
</script>

<style></style>

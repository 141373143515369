<template>
  <div>
    <ul class="settings-account-tab pl-0">
      <li
        :class="
          activeTab == 'suspend'
            ? 'border-primary text-primary font-weight-bold'
            : ''
        "
        @click="activeTab = 'suspend'"
      >
        Hesap Dondurma
      </li>
      <li
        :class="
          activeTab == 'remove'
            ? 'border-primary text-primary font-weight-bold'
            : ''
        "
        @click="activeTab = 'remove'"
      >
        Hesap Silme
      </li>
    </ul>
    <div>
      <h4 class="font-weight-normal font-s-24px mb-4 d-none d-md-block">
        {{
          activeTab == "suspend"
            ? "Hesabını Geçici Olarak Dondur"
            : activeTab == "remove"
            ? "Hesabını Kalıcı Olarak Sil"
            : ""
        }}
      </h4>
      <p v-if="activeTab == 'suspend'" class="text-secondary font-s-14px my-4">
        Hesabınızı dondurmak istemenize üzüldük. Sizi {{ zoneName }} ailesinin
        bir parçası olarak görmeye devam etmek istediğimiz için görüşleriniz
        bizim için çok değerli. Hesabınız ile ilgili yaşadığınız bir sorun veya
        sormak istedikleriniz varsa bize
        <a href="mailto:" class="font-weight-bold">npuge@santafarma.com.tr</a>
        adresi üzerinden ulaşabilirsiniz.
      </p>
      <p
        v-else-if="activeTab == 'remove'"
        class="text-secondary font-s-14px my-4"
      >
        Hesabınızı iptal etmek istemenize üzüldük. Sizi {{ zoneName }} ailesinin
        bir parçası olarak görmeye devam etmek istediğimiz için görüşleriniz
        bizim için çok değerli. Hesabınız ile ilgili yaşadığınız bir sorun veya
        sormak istedikleriniz varsa bize
        <a href="mailto:" class="font-weight-bold">npuge@santafarma.com.tr</a>
        adresi üzerinden ulaşabilirsiniz.
      </p>
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex flex-column mb-4">
            <span class="font-s-12px font-w-600 gray-500 mb-3">
              {{
                activeTab == "suspend"
                  ? "Hesabınızı neden donduruyorsunuz?"
                  : activeTab == "remove"
                  ? "Hesabını neden siliyorsunuz?"
                  : ""
              }}
            </span>
            <div class="position-relative w-100">
              <base-select
                class="w-100"
                name="answer"
                :options="answerOptions"
                placeholder="Bir neden seçiniz"
                option-value-key="key"
                option-text-key="value"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex flex-column mb-4">
            <span class="font-s-12px font-w-600 gray-500 mb-3"
              >Lütfen şifrenizi giriniz.</span
            >
            <div class="position-relative w-100">
              <base-input
                name="password"
                type="password"
                :show-error-msg="false"
                style="height: 50px !important"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <settings-action-buttons
          :submit-button-text="
            activeTab == 'suspend'
              ? 'Hesabımı Geçici Olarak Dondur'
              : activeTab == 'remove'
              ? 'Hesabımı Kalıcı Olarak Sil'
              : ''
          "
          :is-submitting="isSubmitting"
          @submit="
            activeTab == 'suspend'
              ? suspendAccount()
              : activeTab == 'remove'
              ? removeAccount()
              : null
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import SettingsActionButtons from "./SettingsActionButtons";
import BaseInput from "../ui/RegisterForm/BaseInput";
import ZoneHelper from "../../shared/zone-helper";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserServiceNew from "../../services/UserServiceNew";
import BaseSelect from "../ui/RegisterForm/BaseSelect";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { showError } from "../../shared/utils";

const userService = new UserServiceNew();

export default {
  name: "SettingsAccountSettings",
  components: {
    SettingsActionButtons,
    BaseInput,
    BaseSelect,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const zoneHelper = new ZoneHelper();
    const zoneName = computed(() => zoneHelper.getZoneName(store.state.theme));
    const activeTab = ref("suspend");
    const isSubmitting = ref(false);

    const answerOptions = [
      { key: "Çok zamanımı alıyor", value: "Çok zamanımı alıyor" },
      {
        key: "Takip edecek kişiler bulamıyorum",
        value: "Takip edecek kişiler bulamıyorum",
      },
      {
        key: "Gizlilikle ilgili endişelerim var",
        value: "Gizlilikle ilgili endişelerim var",
      },
      { key: "Kullanmakta zorlanıyorum", value: "Kullanmakta zorlanıyorum" },
      { key: "Çok fazla reklam var", value: "Çok fazla reklam var" },
      { key: "İkinci bir hesabım var", value: "İkinci bir hesabım var" },
      { key: "Başka bir şey", value: "Başka bir şey" },
    ];

    const form = useForm({
      validationSchema: {
        answer: yup.string().required(),
        password: yup.string().required(),
      },
    });

    const fieldLabels = {
      answer: "Bir neden seçiniz",
      password: "Şifre",
    };

    async function suspendAccount() {
      const { valid, errors } = await form.validate();
      if (!valid) {
        return showError(valid, errors, fieldLabels);
      }

      isSubmitting.value = true;
      try {
        await userService.suspendAccount(form.values);
        store.dispatch("logout").then(() => router.go());
        isSubmitting.value = false;
        Swal.fire({
          icon: "success",
          title: "Hesabınız başarıyla donduruldu.",
          confirmButtonText: "Tamam",
          timer: 3000,
        });
      } catch (e) {
        isSubmitting.value = false;
        Swal.fire({
          icon: "error",
          title: "Bir hata oluştu. Şifrenizi kontrol edin.",
          confirmButtonText: "Tamam",
        });
      }
    }

    async function removeAccount() {
      const { valid, errors } = await form.validate();
      if (!valid) {
        const errorFields = Object.keys(errors).map(
          (fieldName) => fieldLabels[fieldName] || fieldName
        );

        const errorMessage =
          errorFields.length > 1
            ? `Lütfen ${errorFields.join(", ")} alanlarını kontrol ediniz.`
            : `Lütfen ${errorFields[0]} alanını kontrol ediniz.`;

        const errorTitle =
          errorFields.length > 1 ? "Formda hatalar var" : "Formda hata var";

        return Swal.fire({
          title: errorTitle,
          html: errorMessage,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      }
      isSubmitting.value = true;
      try {
        await userService.removeAccount(form.values);
        store.dispatch("logout").then(() => router.go());
        isSubmitting.value = false;
        Swal.fire({
          icon: "success",
          title: "Hesabınız başarıyla silindi.",
          timer: 2000,
          confirmButtonText: "Tamam",
        });
      } catch (e) {
        isSubmitting.value = false;
        Swal.fire({
          icon: "error",
          title: "Bir hata oluştu. Şifrenizi kontrol edin.",
          confirmButtonText: "Tamam",
        });
      }
    }

    return {
      zoneName,
      activeTab,
      suspendAccount,
      removeAccount,
      isSubmitting,
      answerOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.settings-account-tab {
  display: flex;
  margin-bottom: 50px;
  li {
    list-style: none;
    font-weight: 500;
    margin-right: 1rem;
    text-align: center;
    padding: 10px 0;
    border-bottom: 3px solid #e0e0e0;
    cursor: pointer;
    &:hover {
      border-bottom: 3px solid #bababa;
    }
  }
}
</style>

<template>
  <div class="videos">
    <div class="videos-main bg-image d-flex flex-column">
      <content-main
        title="Videolar"
        nav="Videolar"
        class="mt-m-100px mt-160px"
      />
    </div>

    <div>
      <div class="container-xl">
        <div>
          <div class="position-relative select-input-videos mb-4 mt-4 mt-md-0">
            <div class="row">
              <div class="col-12 col-md-2 mb-2">
                <select
                  v-model="filters.year"
                  name="year"
                  class="
                    custom-select
                    select-year
                    height-50px
                    border-radius
                    box-shadow-4-20-2
                    font-s-12px
                  "
                >
                  <option selected>Hepsi</option>
                  <option v-for="year in years" :key="year">
                    {{ year }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-4 mb-2">
                <input
                  v-model="filters.keyword"
                  type="text"
                  class="
                    w-100
                    input-videos
                    border-radius
                    height-50px
                    box-shadow-4-20-2
                  "
                  placeholder="Hepsi"
                  @keyup.enter="filterVideos"
                />
              </div>
              <div v-if="!isChannelsLoading" class="col-12 col-md-4 mb-2">
                <select
                  v-model="filters.channel"
                  name="channel"
                  class="
                    custom-select
                    height-50px
                    box-shadow-4-20-2
                    select-channel
                    border-radius
                    font-s-12px
                  "
                >
                  <option selected>Hepsi</option>
                  <option
                    v-for="(videoCategory, index) in videoCategories"
                    :key="index"
                    :value="videoCategory.id"
                  >
                    {{ videoCategory.title }}
                  </option>
                </select>
              </div>
              <div v-else class="col-12 col-md-4 mb-2">
                <select
                  name="channel"
                  class="
                    custom-select
                    box-shadow-4-20-2
                    height-50px
                    select-channel
                    border-radius
                    font-s-12px
                  "
                >
                  <option selected>Yükleniyor...</option>
                </select>
              </div>
              <div class="col-12 col-md-2 mb-2">
                <button
                  class="
                    w-100
                    btn btn-primary btn-videos-search
                    height-50px
                    d-flex
                    justify-content-center
                    align-items-center
                    border-radius
                  "
                  @click="filterVideos"
                >
                  <search-icon :show="true" color="white" class="mr-1" />
                  <span class="font-s-12px font-w-500">Videoları Filtrele</span>
                </button>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center my-4 my-md-0 mb-md-5">
            <button
              :disabled="isRecentVideosLoading"
              :class="{ active: activeBtn == 0 }"
              class="
                btn
                gray-500
                btn-videos-category
                mr-2 mr-md-4
                font-s-12px font-w-500
                border-radius
              "
              @click="
                changeTab(0);
                changeBtn(0);
              "
            >
              Son Eklenen Videolar
            </button>
            <button
              :disabled="isPopularVideosLoading"
              :class="{ active: activeBtn == 1 }"
              class="
                btn
                gray-500
                btn-videos-category
                mr-2 mr-md-3
                font-s-12px font-w-500
                border-radius
              "
              @click="
                changeTab(1);
                changeBtn(1);
              "
            >
              En Çok İzlenen Videolar
            </button>
            <router-link
              :to="{ name: 'channels' }"
              class="
                btn
                gray-500
                btn-videos-category
                font-s-12px font-w-500
                border-radius
              "
            >
              Kanallar
            </router-link>
          </div>
        </div>

        <template v-if="tab === 0">
          <wide-video-list
            v-if="!isRecentVideosLoading"
            :videos="recentVideos.items"
          />
          <loading-anim-icon v-else class="p-3 pb-5" />

          <pagination
            :disabled="videoCategoriesLoading"
            :total-pages="recentVideos.last_page"
            :current-page="recentVideos.current_page"
            @go-page="fetchRecentVideos($event)"
          />
        </template>
        <template v-else>
          <wide-video-list
            v-if="!isPopularVideosLoading"
            :videos="popularVideos.items"
          />
          <loading-anim-icon v-else class="p-3 pb-5" />

          <pagination
            :disabled="isPopularVideosLoading"
            :total-pages="popularVideos.last_page"
            :current-page="popularVideos.current_page"
            @go-page="fetchPopularVideos($event)"
          />
        </template>

        <!-- <div class="d-flex justify-content-center mb-90px">
          <router-link
            :to="{ name: 'videos' }"
            class="d-flex align-items-center"
          >
            <span class="text-info font-w-500"
              >{{ tab === 0 ? "Son Eklenen" : "En Çok İzlenen" }} Tüm
              Videolar</span
            >
            <right-arrow-icon :color="colorArrow" />
          </router-link>
        </div> -->

        <channel-list v-if="!isChannelsLoading" :channels="videoCategories" />
        <loading-anim-icon v-else class="p-3 pb-5 pt-0" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ChannelList from "../components/ui/ChannelList.vue";
import SearchIcon from "../components/icon/SearchIcon.vue";
import WideVideoList from "../components/ui/videos/WideVideoList.vue";
import VideoService from "../services/video/VideoService";
import VideoCategoryService from "../services/video/VideoCategoryService";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import ColorHelper from "../helpers/ColorHelper";
import Pagination from "../components/ui/Pagination.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

dayjs.extend(dayOfYear);

export default {
  name: "Videos",
  components: {
    ContentMain,
    SearchIcon,
    ChannelList,
    WideVideoList,
    Pagination,
  },
  setup() {
    const isRecentVideosLoading = ref(true);
    const isPopularVideosLoading = ref(true);
    const isChannelsLoading = ref(true);
    const popularVideos = ref([]);
    const recentVideos = ref([]);
    const videoCategories = ref([]);
    const tab = ref(0);
    const isFilterActivated = ref(false);
    const filters = ref({
      year: ref("Hepsi"),
      keyword: ref(""),
      channel: ref("Hepsi"),
    });
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueDark;
    const colorArrow = ColorHelper[store.state.theme].blueText;
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const years = computed(() => {
      let y = [];

      for (let i = new Date().getFullYear(); i > 2010; i -= 1) {
        y.push(i);
      }

      return y;
    });

    const fetchPopularVideos = (page = 1, per_page = 14, filters = null) => {
      isPopularVideosLoading.value = true;
      VideoService.getPopularVideos(page, per_page, filters)
        .then((response) => {
          popularVideos.value = response.data.data;
        })
        .finally(() => {
          isPopularVideosLoading.value = false;
        });
    };

    const fetchRecentVideos = (page = 1, per_page = 14, filters = null) => {
      isRecentVideosLoading.value = true;
      VideoService.getRecentVideos(page, per_page, filters)
        .then((response) => {
          recentVideos.value = response.data.data;
        })
        .finally(() => {
          isRecentVideosLoading.value = false;
        });
    };

    const fetchChannels = () => {
      isChannelsLoading.value = true;
      VideoCategoryService.getAllVideoCategories(1, 100)
        .then((response) => {
          videoCategories.value = response.data.data.items;
        })
        .finally(() => {
          isChannelsLoading.value = false;
        });
    };

    const changeTab = (tabNumber) => {
      tab.value = tabNumber;
      if (isFilterActivated.value === true) {
        filterVideos();
      }
    };

    const filterVideos = () => {
      isFilterActivated.value = true;
      const filterParams = ref({});
      if (filters.value.keyword !== "") {
        filterParams.value = {
          ...filterParams.value,
          "filter[title]": filters.value.keyword,
        };
      }
      if (filters.value.year !== "Hepsi") {
        const startDate = dayjs(dayjs(filters.value.year).dayOfYear(1)).format(
          "YYYY-MM-DD"
        );
        const endDate = dayjs(dayjs(filters.value.year).dayOfYear(365)).format(
          "YYYY-MM-DD"
        );

        filterParams.value = {
          ...filterParams.value,
          "filter[publish_between]": `${startDate},${endDate}`,
        };
      }
      if (filters.value.channel !== "Hepsi") {
        filterParams.value = {
          ...filterParams.value,
          "filter[category_id]": filters.value.channel,
        };
      }

      if (tab.value === 0) {
        fetchRecentVideos(1, recentVideos.value.last_page, filterParams.value);
      } else {
        fetchPopularVideos(1, recentVideos.value.last_page, filterParams.value);
      }
    };

    fetchPopularVideos();
    fetchRecentVideos();
    fetchChannels();

    return {
      years,
      fetchPopularVideos,
      fetchRecentVideos,
      popularVideos,
      recentVideos,
      isRecentVideosLoading,
      isPopularVideosLoading,
      videoCategories,
      isChannelsLoading,
      changeTab,
      tab,
      filterVideos,
      filters,
      color,
      colorArrow,
      main_img,
    };
  },
  data() {
    return {
      activeBtn: 0,
    };
  },
  methods: {
    changeBtn(id) {
      this.activeBtn = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-videos-category {
  &.active {
    background-color: v-bind("color");
    color: white;
  }
}

.videos-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 330px;
}

.select-input-videos {
  top: -25px;
}

.input-videos {
  border: none;
  padding: 0 30px;

  &::placeholder {
    text-align: left;
    font-size: 12px;
    color: #7684aa;
  }
}

.select-year,
.select-channel {
  border: none;
  padding: 0 30px;
}

.btn-videos-category {
  padding: 13px 50px;
  background-color: #f3f4f5;
}

@media (max-width: 756px) {
  .btn-videos-category {
    padding: 13px 15px;
    font-size: 10px;
  }

  .videos-main {
    height: 200px;
  }

  .select-input-videos {
    position: static !important;
  }
}
</style>

<template>
  <div>
    <section class="podcast-main bg-image d-flex flex-column mb-5">
      <content-main
        :title="theme == 'ortopedizone' ? 'OrtoPodcast' : 'Podcast'"
        nav="Podcast"
        class="mt-m-100px mt-160px"
      />
    </section>

    <section v-if="settings.podcast_top_banner" class="mb-5">
      <div class="container">
        <img :src="settings.podcast_top_banner" class="podcast-banner-img" />
      </div>
    </section>

    <section class="mb-5">
      <div class="container-xl">
        <div
          class="
            d-flex
            flex-column flex-sm-row
            align-items-center
            justify-content-sm-between
            mt-5
            mb-4
          "
        >
          <div
            class="d-flex justify-content-between justify-content-sm-start mb-3"
          >
            <button
              :class="{ active: activeBtn == 0 }"
              class="
                btn
                border-radius
                font-s-12px font-w-500
                mr-3
                podcast-nav-btn
              "
              @click="changeBtn(0)"
            >
              Son Eklenen
            </button>
            <button
              :class="{ active: activeBtn == 1 }"
              class="btn border-radius font-s-12px font-w-500 podcast-nav-btn"
              @click="changeBtn(1)"
            >
              En Çok Dinlenen
            </button>
          </div>
          <div class="mb-3 mx-3">
            <div
              class="
                position-relative
                podcast-search
                px-3
                d-flex
                align-items-center
                justify-content-center
                btn
                bg-blue-light
                border-none border-radius
              "
            >
              <search-icon :show="true" :color="color" class="mr-1" />
              <input
                type="text"
                class="text-info font-s-12px font-w-500 bg-blue-light"
                placeholder="Podcast Ara"
                @focus="showInputBox = true"
                @blur="showInputBox = false"
                @input="
                  debounce(() => {
                    searchString = $event.target.value;
                  })
                "
              />
            </div>
          </div>
        </div>

        <template v-if="!isPodcastsLoading">
          <div class="mb-5">
            <podcast-card
              v-for="(podcast, index) in podcasts"
              :key="index"
              :podcast="podcast"
              :index="index"
            />
          </div>

          <div
            v-if="currentPage < podcastTotalPage && !isLoadingMore"
            class="d-flex justify-content-center align-items-center"
            @click="loadMoreHandler"
          >
            <recyling-icon :color="color" class="mr-1" />
            <span class="font-s-12px font-w-500 text-info"
              >Daha Fazla Podcast Yükle</span
            >
          </div>
          <div
            v-if="isLoadingMore"
            class="d-flex justify-content-center align-items-center"
          >
            <span class="font-s-12px font-w-500 text-info">YÜKLENİYOR...</span>
          </div>
        </template>
        <loading-anim-icon v-else class="m-5" />
      </div>
    </section>

    <section v-if="settings.podcast_middle_banner" class="mb-5">
      <div class="container">
        <img :src="settings.podcast_middle_banner" class="podcast-banner-img" />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <channel-list
          v-if="!isChannelsLoading"
          channel-type="podcast"
          :channels="podcastChannels"
          card="podcast-channel-card"
        />
        <loading-anim-icon v-else class="m-5" />
      </div>
    </section>

    <section v-if="settings.podcast_bottom_banner" class="mb-5">
      <div class="container">
        <img :src="settings.podcast_bottom_banner" class="podcast-banner-img" />
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import ChannelList from "../components/ui/ChannelList.vue";
import PodcastChannelService from "../services/podcast/PodcastChannelService";
import PodcastService from "../services/podcast/PodcastService";
import PodcastCard from "../components/ui/PodcastCard.vue";

export default {
  name: "Podcast",
  components: {
    ContentMain,
    ChannelList,
    PodcastCard,
  },
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const podcastChannelService = new PodcastChannelService();
    const podcastService = new PodcastService();
    const isChannelsLoading = ref(false);
    const isPodcastsLoading = ref(false);
    const isLoadingMore = ref(false);
    const podcastChannels = ref([]);
    const podcasts = ref([]);
    const podcastSorts = ref(["-publish_on"]);
    const currentPage = ref(1);
    const podcastTotalPage = ref(1);
    const searchString = ref("");
    const showInputBox = ref(false);
    const activeBtn = ref(0);
    const color = ColorHelper[store.state.theme].blueText;
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const settings = computed(() => {
      return store.state.settings;
    });

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 750);
      };
    }

    const changeBtn = (id) => {
      currentPage.value = 1;
      activeBtn.value = id;
      switch (id) {
        case 0:
          podcastSorts.value = ["-publish_on"];
          break;
        case 1:
          podcastSorts.value = ["-listen_count"];
          break;
        default:
          break;
      }
      fetchPodcasts(true);
    };

    const fetchChannels = () => {
      isChannelsLoading.value = true;
      podcastChannelService
        .all()
        .then((response) => {
          podcastChannels.value = response;
        })
        .finally(() => {
          isChannelsLoading.value = false;
        });
    };

    const fetchPodcasts = (fresh = false) => {
      isPodcastsLoading.value = fresh;
      isLoadingMore.value = !fresh;

      if (fresh) {
        podcasts.value = [];
      }
      const data = {
        filters: { is_active: [true] },
        includes: ["channel"],
        sorts: podcastSorts.value,
        page: currentPage.value,
        per_page: 5,
      };
      if (searchString.value) {
        data.filters["title"] = [searchString.value];
      }
      podcastService
        .all(data)
        .then((response) => {
          podcasts.value = [...podcasts.value, ...response.items];
          podcastTotalPage.value = response.last_page;
        })
        .finally(() => {
          isPodcastsLoading.value = false;
          isLoadingMore.value = false;
        });
    };

    const searchStringChanged = () => {
      currentPage.value = 1;
      fetchPodcasts(true);
    };

    const loadMoreHandler = () => {
      if (currentPage.value < podcastTotalPage.value) {
        currentPage.value++;
        fetchPodcasts();
      }
    };
    watch(searchString, searchStringChanged);
    fetchChannels();
    fetchPodcasts();

    return {
      settings,
      color,
      main_img,
      changeBtn,
      showInputBox,
      activeBtn,
      isChannelsLoading,
      podcastChannels,
      isPodcastsLoading,
      podcasts,
      searchString,
      searchStringChanged,
      debounce: createDebounce(),
      currentPage,
      podcastTotalPage,
      loadMoreHandler,
      isLoadingMore,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.podcast-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}

.podcast-nav-btn {
  width: 145px;
  height: 45px;
  background-color: #f3f4f5;
  color: #7684aa;

  &.active {
    color: white;
    background-color: v-bind("color");
  }
}

.podcast-search {
  height: 45px;

  input {
    border: none;
    width: 80px;
    transition: 0.3s;

    &::placeholder {
      color: v-bind("colorIcon");
    }

    &:focus {
      width: 300px;
    }
  }
}

.input-open-box {
  max-width: 360px;
  width: 100%;
  top: 55px;
  padding: 23px 0;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  span {
    padding: 10px 50px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: v-bind("colorIcon");
      background: rgba(218, 237, 247, 0.2);
    }
  }
}

.podcast-banner-img {
  width: 100%;
}

@media (max-width: 576px) {
  .podcast-main {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .btn-podcast {
    right: 0px;
  }
}
</style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 4.0625C28.0246 4.0625 34.9375 10.9739 34.9375 19.5C34.9375 28.0261 28.0246 34.9375 19.5 34.9375C10.9739 34.9375 4.0625 28.0261 4.0625 19.5C4.0625 10.9739 10.9739 4.0625 19.5 4.0625Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.375 19.4921C24.375 18.174 17.6191 13.9573 16.8527 14.7155C16.0864 15.4737 16.0127 23.4391 16.8527 24.2687C17.6928 25.1013 24.375 20.8102 24.375 19.4921Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PodcastPlayIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <loading-anim-icon v-if="loading" />
      <component
        :is="card"
        v-for="(channel, index) in channels.items"
        v-else
        :key="index"
        :channel="channel"
        class="mb-4 mx-2 mx-sm-2 mx-xl-3"
        mobile="channel-card-mobile"
      />
    </div>
    <pagination
      v-if="channels"
      :disabled="loading"
      :total-pages="channels.last_page"
      :current-page="channels.current_page"
      @go-page="$emit('go-page', $event)"
    />
  </div>
</template>

<script>
import VideoChannelCard from "../ui/VideoChannelCard";
import PodcastChannelCard from "../ui/PodcastChannelCard";
import Pagination from "../ui/Pagination";
export default {
  name: "ChannelsList",
  components: { Pagination, VideoChannelCard, PodcastChannelCard },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    channels: {
      type: Object,
      required: true,
    },
    card: {
      type: String,
      required: false,
      default: "video-channel-card",
    },
  },
  emits: ["go-page"],
  setup() {},
};
</script>

<style scoped></style>

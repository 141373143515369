export default function auth({ next, to, store }) {
  if (
    store.state.theme === "eczazone" &&
    to.name !== "settings" &&
    store.state.user.user &&
    !store.state.user.user.is_admin &&
    !store.state.user.user.pharmacy?.gln_no
  ) {
    return next({
      name: "settings",
      query: {
        page: "settings-pharmacy-information",
      },
    });
  }

  if (store.state.bearerToken === null || store.state.user.user === null) {
    return next({
      name: "login",
      query: {
        redirect: to.fullPath,
      },
    });
  }

  return next();
}

<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0001 37.3333C8.87486 37.3333 0.666748 29.1252 0.666748 19C0.666748 8.87474 8.87486 0.666626 19.0001 0.666626C29.1253 0.666626 37.3334 8.87474 37.3334 19C37.3223 29.1206 29.1207 37.3222 19.0001 37.3333ZM17.1667 28.1666V31.8333H20.8334V28.1666H17.1667ZM19.0001 9.83329C21.0251 9.83329 22.6667 11.4749 22.6667 13.5C22.6732 14.473 22.2826 15.4065 21.5851 16.085L19.3117 18.395C17.9394 19.7731 17.1682 21.6384 17.1667 23.5833V24.5H20.8334C20.6909 22.5291 21.4858 20.6065 22.9784 19.3116L24.6284 17.625C25.7249 16.5329 26.3388 15.0475 26.3335 13.5C26.3335 9.44987 23.0502 6.16663 19.0001 6.16663C14.95 6.16663 11.6667 9.44987 11.6667 13.5H15.3334C15.3334 11.4749 16.975 9.83329 19.0001 9.83329Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="37.3334"
        y1="35.7991"
        x2="5.84805"
        y2="-0.88145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6188" />
        <stop offset="1" stop-color="#FFB199" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "DummyIcon",
};
</script>

<style></style>

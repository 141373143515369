<template>
  <div>
    <adverse-modal v-if="isPopupOpened" />
    <div
      v-if="
        route.name != 'login' &&
        route.name != 'register' &&
        route.name != 'forgot-password' &&
        route.name != 'reset-password'
      "
    >
      <div
        v-if="user && banner && route.name != 'get-app'"
        class="footer-banner"
      >
        <div class="container-xl">
          <div class="row">
            <a :href="banner.redirect_url" class="col-12">
              <img :src="banner.image_url" alt="" class="img-fluid vw-100" />
            </a>
          </div>
        </div>
      </div>

      <div
        class="footer-top bg-image d-flex"
        :class="route.name == 'get-app' ? 'get-app-bg' : ''"
      >
        <div
          class="
            container-xl
            d-flex
            justify-content-center justify-content-lg-between
          "
        >
          <div class="d-flex flex-column justify-content-center">
            <div
              class="
                text-white
                d-flex
                flex-column
                align-items-center align-items-lg-start
                mb-5
              "
            >
              <span
                class="
                  footer-text-first
                  font-s-25px font-w-600
                  letter-spacing-4-5
                "
                >{{ findThemeDetail.word }} Vizyonu
              </span>
              <span
                class="
                  footer-text-second
                  font-w-900 font-s-55px
                  letter-spacing-7-5
                  line-h-1-1
                "
                >{{ findThemeDetail.name }}'da</span
              >
            </div>
            <div
              v-if="theme != 'hemaworld'"
              class="
                mb-5
                d-flex
                justify-content-center justify-content-lg-start
              "
            >
              <a
                href="https://apps.apple.com/tr/app/zone/id1573401266"
                target="_blank"
              >
                <button
                  class="
                    btn
                    footer-download
                    mr-3
                    text-primary
                    border-radius-90px
                    d-flex
                    justify-content-center
                    align-items-center
                    bg-white
                  "
                >
                  <ios-icon :color="color" class="mr-1" />
                  <div
                    class="d-flex flex-column line-h-12px font-w-500 btn-text"
                  >
                    <span class="font-s-15px btn-text-store"
                      >App Store'dan</span
                    >
                    <span class="font-s-10px btn-text-download">İndir</span>
                  </div>
                </button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.epigra.zone"
                target="_blank"
              >
                <button
                  class="
                    btn
                    footer-download
                    text-primary
                    border-radius-90px
                    d-flex
                    justify-content-center
                    align-items-center
                    bg-white
                  "
                >
                  <android-icon :color="color" class="mr-1" />
                  <div
                    class="d-flex flex-column line-h-12px font-w-500 btn-text"
                  >
                    <span class="font-s-13px btn-text-store"
                      >Google Play'den</span
                    >
                    <span class="font-s-10px btn-text-download">İndir</span>
                  </div>
                </button>
              </a>
            </div>
          </div>

          <div class="d-none d-lg-block">
            <img
              :src="footer_tel"
              alt=""
              class="img-fluid footer-tel position-relative"
              :class="route.name == 'get-app' ? 'get-app-img' : ''"
            />
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container-xl">
          <div class="height-0 d-flex justify-content-center d-lg-block">
            <div
              class="
                footer-logo
                position-relative
                bg-blue-footer-logo
                border-radius
                p-4
              "
            >
              <img src="/new-img/footer-logo.png" alt="" class="img-fluid" />
            </div>
          </div>

          <div class="text-white pt-5 d-flex flex-column align-content-between">
            <div
              class="
                footer-nav
                d-flex
                flex-column flex-lg-row
                justify-content-end
                font-s-12px
              "
            >
              <router-link
                :to="{ name: 'yasal-uyari' }"
                class="white-0-5 footer-nav-item"
                >Yasal Uyarı
              </router-link>
              <router-link
                :to="{ name: 'aydinlatma-metni' }"
                class="white-0-5 ml-3 footer-nav-item"
                >Aydınlatma Metni
              </router-link>
              <router-link
                :to="{ name: 'kvkk' }"
                class="white-0-5 ml-3 footer-nav-item"
                >Açık Rıza Metni
              </router-link>
              <router-link
                :to="{ name: 'kvkk-yd' }"
                class="white-0-5 ml-3 footer-nav-item"
                >Açık Rıza Metni (Yurt Dışı Veri Transferi)
              </router-link>
              <a
                href="mailto:npuge@santafarma.com.tr"
                class="white-0-5 ml-3 footer-nav-item"
                >İletişim</a
              >
            </div>
            <div
              class="
                d-flex
                flex-column-reverse flex-lg-row
                justify-content-between
                font-s-10px
              "
            >
              <p class="max-w-315px line-h-20px white-0-8">
                © 2010 - {{ currentYear }} Tüm hakları saklıdır. Santa Farma
                İlaç Sanayi A.Ş. Okmeydanı, Boruçiçeği Sok. No:16 34382
                Şişli-İstanbul <br />Tel: +90 212 220 64 00 Faks:+90 212 222 57
                06
              </p>
              <p
                class="
                  max-w-500px
                  text-left
                  line-h-16px
                  mt-auto
                  text-lg-right
                  white-0-8
                "
              >
                Bu sitedeki bilgiler, hekim veya eczacıya danışmanın yerine
                geçemez.<br />
                Santa Farma Ürünleri ile ilgili Yan Etki / Advers bildirimleri
                için
                <a
                  class="font-weight-bold white-0-8 cursor-pointer"
                  @click="isPopupOpened = !isPopupOpened"
                >
                  tıklayınız</a
                >. Site sorumlusu:
                <a href="mailto:npuge@santafarma.com.tr" class="text-white"
                  >Nihat Püge</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdverseModal from "../../components/ui/AdverseModal.vue";
import ColorHelper from "../../helpers/ColorHelper";
import BannerService from "../../services/banner/BannerService";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { ref } from "vue";
import { shuffleArray } from "../../shared/utils";
import { onMounted } from "@vue/runtime-core";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "Footer",
  components: {
    AdverseModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const theme = store.state.theme;
    const theme_detail = [
      { theme: "ahezone", name: "Ahezone", word: "Aile Hekimlerinin" },
      { theme: "norozone", name: "Nörozone", word: "Nörolojinin" },
      { theme: "dermazone", name: "Dermazone", word: "Dermatolojinin" },
      { theme: "eczazone", name: "Eczazone", word: "Eczane Eczacısının" },
      { theme: "kvczone", name: "Kvczone", word: "Kvcnin" },
      { theme: "ftrzone", name: "Ftrzone", word: "Ftrnin" },
      { theme: "kbbzone", name: "Kbbzone", word: "Kbbnin" },
      { theme: "ortopedizone", name: "Ortopedizone", word: "Ortopedinin" },
      { theme: "psikezone", name: "Psikezone", word: "Psikiyatrinin" },
      { theme: "hemaworld", name: "Hemaworld", word: "Hematolojinin" },
    ];
    const user = computed(() => store.state.user.user);
    const footer_tel = "/new-img/" + store.state.theme + "-footer-tel.png";
    const color = ColorHelper[store.state.theme].blueDark;
    const isPopupOpened = ref(false);
    const footer_bg =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const banners = ref();

    const banner = computed(() => {
      if (!banners.value || banners.value.length === 0) {
        return null;
      }

      return banners.value[0];
    });

    const fetchBanners = () => {
      BannerService.getBannersByCollectionName().then((response) => {
        banners.value = response.data.data;
      });
    };

    const findThemeDetail = computed(() => {
      var result = theme_detail.find((i) => i.theme == theme);

      return result;
    });

    if (user.value) {
      fetchBanners();
    }

    onMounted(() => {
      if (user.value) {
        window.setInterval(() => {
          if (banners.value) {
            banners.value = shuffleArray(banners.value);
          }
        }, 10000);
      }
    });

    return {
      banner,
      isPopupOpened,
      user: computed(() => store.state.user.user),
      footer_bg,
      footer_tel,
      color,
      findThemeDetail,
      theme,
      theme_detail,
      router,
      route,
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-banner {
  background-color: #f3f4f5;
  padding: 80px 0 100px;
}

.footer-top {
  background: v-bind("footer_bg");
  width: 100vw;
  height: 390px;
}

.footer-bottom {
  min-height: 140px;
}

.footer-text-first {
  color: transparent;
  letter-spacing: 4.5px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.footer-download {
  width: 159px;
  height: 41px;
}

.footer-tel {
  max-height: 456px;
  top: -65px;
}

.footer-logo {
  width: 228px;
  height: 80px;
  top: -45px;
}

.get-app-bg {
  height: 500px !important;
}

.get-app-img {
  top: 45px !important;
}

@media (max-width: 992px) {
  .footer-nav-item {
    margin: 0 0 15px !important;
  }
  .footer-nav {
    margin-bottom: 15px;
  }
  .footer-banner {
    padding: 50px;
  }
}

@media (max-width: 768px) {
  .footer-text-first {
    font-size: 12px;
  }
  .footer-text-second {
    font-size: 26px;
  }
  .footer-download {
    height: 32px;
    width: 126px;
  }
  .footer-logo {
    width: 176px;
    height: 63px;
    top: -35px;
  }
  .btn-text {
    line-height: 11px !important;

    &-download {
      font-size: 8px !important;
    }

    &-store {
      font-size: 10px !important;
    }
  }
}
</style>

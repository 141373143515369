<template>
  <div
    class="register d-flex align-items-center justify-content-center py-5 px-4"
  >
    <div class="max-w-555px">
      <div class="d-none d-lg-block login-logo mb-4">
        <router-link :to="{ name: 'home' }">
          <img :src="logo_url" alt="" class="img-fluid" />
        </router-link>
      </div>

      <div class="mb-4">
        <p class="font-s-16px gray-500">
          Türkiye'nin {{ zone.deparment }}
          {{ zone.theme == "ahezone" ? "Hekimlerine" : "uzmanlarına" }} özel
          geniş içeriğe sahip iletişim platformu.
        </p>
      </div>
      <div>
        <form @submit.prevent="registerHandler">
          <div>
            <div class="mb-4 position-relative form-item">
              <base-input
                name="firstname"
                placeholder="İsim"
                :left-icon="{
                  name: 'name-icon',
                  class: 'input-left-svg',
                }"
                :show-error-msg="false"
                @change="checkUserAllowances"
              />
            </div>
            <div class="mb-4 position-relative form-item">
              <base-input
                name="lastname"
                placeholder="Soyisim"
                :left-icon="{
                  name: 'name-icon',
                  class: 'input-left-svg',
                }"
                :show-error-msg="false"
                @change="checkUserAllowances"
              />
            </div>
            <div class="mb-4 position-relative form-item">
              <base-input
                type="email"
                name="email"
                placeholder="E-Posta Adresiniz"
                :left-icon="{
                  name: 'MailIcon',
                  color: '#7684aa',
                  class: 'input-left-svg',
                  size: 24,
                }"
                :show-error-msg="false"
                @change="checkUserAllowances"
              />
            </div>
            <div class="mb-4 position-relative form-item">
              <base-input
                v-mask="'(5##) ### ####'"
                name="phone"
                :left-icon="{
                  name: 'tel-icon',
                  class: 'input-left-svg',
                }"
                type="tel"
                placeholder="Tel"
                @change="checkUserAllowances"
              />
            </div>
            <div class="mb-2 position-relative form-item">
              <base-input
                name="password"
                type="password"
                placeholder="Parola"
                info-message="Parolanız en az 8 karakterden oluşmalı ve en az bir küçük harf,
                bir büyük harf, bir rakam ve bir de özel karakter içermelidir."
              />
            </div>
            <div class="mb-4 position-relative form-item">
              <base-input
                name="passwordConfirmation"
                type="password"
                placeholder="Parola Tekrar Giriniz"
              />
            </div>
            <div class="mb-4">
              <base-select
                name="city"
                :options="cityPromise.results.value"
                option-text-key="name"
                option-value-key="id"
                placeholder="Yaşadığınız Şehir"
                :loading="cityPromise.loading.value"
                @update:modelValue="fetchHospitals"
              />
            </div>
            <div v-if="form.values.city" class="mb-4">
              <base-select
                name="hospital"
                :options="hospitalPromise.results.value"
                option-text-key="name"
                option-value-key="id"
                placeholder="Çalıştığınız Kurum"
                :loading="hospitalPromise.loading.value"
                @update:modelValue="checkUserAllowances"
              />
            </div>
            <div class="mb-4">
              <base-select
                name="title"
                :options="titlePromise.results.value"
                option-text-key="name"
                option-value-key="id"
                placeholder="Ünvanınız"
                :loading="titlePromise.loading.value"
              />
            </div>
            <div class="mb-3">
              <base-input
                id="cbBeyan"
                name="checkedCompliances"
                value="0"
                type="checkbox"
                :label="
                  zone.name +
                  ' sadece ' +
                  zone.deparment +
                  (zone.theme == 'ahezone'
                    ? ' hekimlerinin'
                    : ' uzmanlarının') +
                  ' üye olabildiği bir platformdur. ' +
                  zone.deparment +
                  (zone.theme == 'ahezone' ? ' hekimi' : ' uzmanı') +
                  ' olduğumu beyan ediyorum.'
                "
              />
            </div>
            <div class="mb-3">
              <base-input
                id="cbKvkk"
                name="checkedCompliances"
                value="1"
                type="checkbox"
              >
                <template #label>
                  <label class="gray-500 font-s-12px m-0" for="cbKvkk">
                    <router-link
                      :to="{ name: 'kvkk' }"
                      target="_blank"
                      class="blue-300"
                      >KVKK Açık RIza Metni</router-link
                    >’ni okudum onaylıyorum
                  </label>
                </template>
              </base-input>
            </div>
            <div class="mb-3">
              <base-input
                id="cbKvkkYurtDisi"
                name="checkedCompliances"
                value="2"
                type="checkbox"
              >
                <template #label>
                  <label class="gray-500 font-s-12px m-0" for="cbKvkkYurtDisi">
                    <router-link
                      :to="{ name: 'kvkk-yd' }"
                      target="_blank"
                      class="blue-300"
                      >KVKK Yurtdışı Açık RIza Metni</router-link
                    >’ni okudum onaylıyorum
                  </label>
                </template>
              </base-input>
            </div>
            <div class="mb-3">
              <label class="gray-500 font-s-12px m-0">
                <router-link
                  :to="{ name: 'etk' }"
                  target="_blank"
                  class="blue-300"
                  >ETK - Elektronik Ticari Onay Metni</router-link
                >’ni okudum ve aşağıda belirttiğim kanallardan tarafıma
                iletişime geçilmesini onaylıyorum.
              </label>
            </div>
            <div class="mb-3">
              <base-input
                id="cbArama"
                name="checkedCompliances"
                value="5"
                type="checkbox"
                label="Arama"
              />
            </div>
            <div class="mb-3">
              <base-input
                id="cbMesaj"
                name="checkedCompliances"
                value="8"
                type="checkbox"
                label="Mesaj"
              />
            </div>
            <div class="mb-3">
              <base-input
                id="cbEposta"
                name="checkedCompliances"
                value="6"
                type="checkbox"
                :disabled="uncheckableCompliances.includes('6')"
                label="E-Posta (Zorunlu)"
              />
            </div>
          </div>
          <div class="mb-4">
            <button
              :disabled="isRegisterProcessing"
              type="submit"
              class="
                btn btn-primary
                text-white
                height-50px
                d-flex
                w-100
                border-radius
                d-flex
                justify-content-center
                align-items-center
              "
            >
              Üye Ol
            </button>
          </div>
        </form>
      </div>

      <div class="d-flex justify-content-center">
        <router-link :to="{ name: 'login' }" class="font-s-16px blue-300">
          Giriş Yap
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import * as yup from "yup";
import { ref } from "vue";
import SmsService from "../../services/sms/SmsService";
import HospitalService from "../../services/hospital/HospitalService";
import RegisterService from "../../services/register/RegisterService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SwalError from "../../helpers/SwalError";
import { useStore } from "vuex";
import { mask } from "vue-the-mask";
import router from "../../router/router";
import BaseInput from "./RegisterForm/BaseInput";
import { useForm } from "vee-validate";
import BaseSelect from "./RegisterForm/BaseSelect";
import usePromise from "../../shared/composables/use-promise";
import { checkEmail } from "../../shared/utils";

const hospitalService = new HospitalService();

export default {
  name: "RegisterForm",
  components: {
    BaseSelect,
    BaseInput,
  },
  directives: { mask },
  props: {
    zone: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const logo_url = "/new-img/" + store.state.theme + "-logo.png";
    const uncheckableCompliances = ref(["6"]);
    const form = useForm({
      validationSchema: yup.object({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        phone: yup
          .string()
          .min(14)
          .required()
          .transform((_, val) =>
            typeof val === "string" ? (val.length ? val : null) : val
          ),
        email: yup.string().required().email(),
        password: yup
          .string()
          .matches(
            /* eslint-disable-next-line no-useless-escape */
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\]:;\"'|\\<>,.\/~`±§+-]).{8,30}$/
          )
          .required()
          .min(8),
        passwordConfirmation: yup
          .string()
          .required()
          .min(8)
          .oneOf([yup.ref("password")], "Passwords must match"),
        city: yup.number().required(),
        hospital: yup.number().required(),
        title: yup.string().required(),
      }),
      initialValues: {
        checkedCompliances: ["5", "6", "8"],
      },
    });

    const smsCodes = ref([]);
    const isRegisterProcessing = ref(false);
    const neededCompliances = ["0", "1", "2", "6"];

    const cityPromise = usePromise(() => RegisterService.getCities());
    const titlePromise = usePromise(
      () => RegisterService.getTitles(),
      (x) => {
        const titles = ref([]);

        Object.keys(x.data.data).map((key) => {
          titles.value.push({ name: x.data.data[key], id: key });
        });

        return titles.value;
      }
    );
    const hospitalPromise = usePromise((cityId) =>
      hospitalService.getHospitalsByCity(cityId)
    );

    const sendSms = async () => {
      const isSmsChecked = form.values.checkedCompliances.includes("8");
      const isCallChecked = form.values.checkedCompliances.includes("5");
      let smsId = null;

      await SmsService.sendSms(
        "full",
        form.values.phone,
        form.values.firstname,
        form.values.lastname,
        form.values.email,
        isSmsChecked,
        isCallChecked
      )
        .then((response) => {
          smsId = response.data.data.sms_id;
        })
        .catch((error) => {
          SwalError.fire(error);
        });

      return smsId;
    };

    const openPopup = async (title, html, step) => {
      let smsId = "";
      let timerInterval;
      let forceResend = false;

      const result = await Swal.fire({
        title: title,
        html: html,
        timer: 180000,
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: "Onayla",
        cancelButtonText: "Tekrar Gönder",
        allowOutsideClick: false,
        allowEscapeKey: false,
        input: "text",
        inputValue: "",
        didOpen: async () => {
          Swal.getCancelButton().disabled = true;

          Swal.stopTimer();
          smsId = await sendSms();
          Swal.resumeTimer();
          timerInterval = setInterval(() => {
            Swal.getHtmlContainer().querySelector("b").textContent = (
              Swal.getTimerLeft() / 1000
            ).toFixed(0);
          }, 100);
        },
        inputValidator: async (value) => {
          if (forceResend === true) {
            return "Lütfen yeni SMS kodu alınız.";
          }

          if (value.length !== 6) {
            return "Lütfen gelen SMS kodunu altı haneli olarak giriliniz.";
          }

          try {
            Swal.getCancelButton().disabled = false;

            await SmsService.validateSms(smsId, value).then((response) => {
              if (response.data.data.sms_id !== smsId) {
                Swal.stopTimer();
                forceResend = true;

                return "Hatalı SMS kodu girdiniz, lütfen yeni SMS kodu alınız.";
              }
            });
          } catch (error) {
            if (error.response.status === 400) {
              Swal.stopTimer();
              forceResend = true;

              return "Hatalı SMS kodu girdiniz, lütfen yeni SMS kodu alınız.";
            } else if (error.response.status === 429) {
              Swal.stopTimer();
              isRegisterProcessing.value = false;

              return "Art arda çok sayıda deneme yaptınız. Lütfen daha sonra tekrar deneyiniz.";
            }
            Swal.stopTimer();
            isRegisterProcessing.value = false;

            return "Bir hata meydana geldi. Lütfen yeni SMS kodu alınız.";
          }
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });

      if (result.dismiss === Swal.DismissReason.timer) {
        await Swal.fire({
          title: "Dikkat",
          html: "Size verilen süre içerisinde giriş yapmadınız, lütfen yeni SMS kodu alınız.",
          icon: "warning",
          confirmButtonText: "Tekrar Gönder",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }

      if (result.isDismissed) {
        return openPopup(title, html, step);
      }

      smsCodes.value[step] = {
        smsCode: result.value,
        smsId,
      };

      return {
        smsCode: result.value,
        smsId,
      };
    };

    const registerHandler = async () => {
      isRegisterProcessing.value = true;
      try {
        await form.validate();

        if (!form.meta.value.valid) {
          isRegisterProcessing.value = false;
          return;
        }

        const emailBackendValidation = await checkEmail(form.values.email);

        if (emailBackendValidation !== "valid") {
          form.setErrors({
            email: emailBackendValidation,
          });

          isRegisterProcessing.value = false;
          Swal.fire({
            icon: "info",
            title: "Uyarı",
            html: emailBackendValidation,
            confirmButtonText: "Kapat",
          });

          return;
        }

        let missingCompliances = neededCompliances.filter(
          (item) => !form.values.checkedCompliances.includes(item)
        );

        if (missingCompliances.length > 0) {
          isRegisterProcessing.value = false;
          return missingCompliances.forEach((item) => {
            const strItem = item.toString();
            const text =
              strItem === "0"
                ? "Zone platformlarına sadece ilgili alanların uzmanları kayıt olabilir!"
                : strItem === "1" || strItem === "2"
                ? "KVKK Açık Rıza Metnini ve KVKK Yurt Dışı Açık Rıza Metnini onaylamanız gerekmektedir."
                : strItem === "6"
                ? "E-Posta izni zorunludur."
                : null;

            if (text) {
              Swal.fire({
                title: "Hata",
                text,
                icon: "warning",
                confirmButtonText: "Tamam",
              });
            }
          });
        }

        await openPopup(
          "KVKK, KVKK Yurtdışı Açık Rıza Metnini ve Elektronik Ticari Onay Metnini Onaylayın",
          '<p class="mb-2 mb-0">Lütfen KVKK, KVKK Yurtdışı Açık Rıza Metnini ve ETK - Elektronik Ticari Onay Metnini kabul ettiğinizi onaylamak için cep telefonunuza gelen kodu giriniz.\
              </p><p class="text-center">Kalan Geçerlilik Süresi: <b>180</b></p>',
          0
        );

        RegisterService.register({ ...form.values, smsCodes })
          .then((response) => {
            Swal.fire({
              title: "Başarılı",
              text: response.data.data,
              icon: "success",
              confirmButtonText: "Tamam",
            });
            router.push({ name: "login" });
          })
          .catch((error) => {
            SwalError.fire(error);
          })
          .finally(() => {
            isRegisterProcessing.value = false;
          });
      } catch (error) {
        isRegisterProcessing.value = false;
        SwalError.fire(error);
      }
    };

    const removeItemFromArray = (array, item) => {
      const index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
      }
    };

    const checkUserAllowances = () => {
      if (
        !form.values.email?.length ||
        !form.values.phone?.length ||
        !form.values.hospital ||
        !form.values.firstname?.length ||
        !form.values.lastname?.length
      ) {
        return;
      }

      RegisterService.checkAllowance(
        form.values.email,
        form.values.phone,
        form.values.hospital,
        form.values.firstname,
        form.values.lastname
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            if (response.data.data.includes("8")) {
              form.values.checkedCompliances.push("8");
              uncheckableCompliances.value.push("8");
            } else {
              removeItemFromArray(form.values.checkedCompliances, "8");
              removeItemFromArray(uncheckableCompliances.value, "8");
            }

            if (response.data.data.includes("5")) {
              form.values.checkedCompliances.push("5");
              uncheckableCompliances.value.push("5");
            } else {
              removeItemFromArray(form.values.checkedCompliances, "5");
              removeItemFromArray(uncheckableCompliances.value, "5");
            }
          }
        })
        .catch(() => {
          //
        });
    };

    function fetchHospitals() {
      hospitalPromise.createPromise(form.values.city);
    }

    cityPromise.createPromise();
    titlePromise.createPromise();

    return {
      logo_url,
      yup,
      smsCodes,
      titlePromise,
      hospitalPromise,
      cityPromise,
      checkUserAllowances,
      fetchHospitals,
      uncheckableCompliances,
      isRegisterProcessing,
      registerHandler,
      form,
    };
  },
};
</script>

<style lang="scss" scoped></style>

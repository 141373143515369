<template>
  <router-link
    :to="{
      name: 'productcategory',
      params: { id: productCategory.id },
    }"
    class="
      category-card
      d-flex
      flex-column
      align-items-center
      justify-content-between
      border-radius
      box-shadow-4-20
      p-3
      h-100
    "
  >
    <div>
      <div class="mb-3 d-flex justify-content-center category-card-img">
        <component :is="productCategory.slug" />
      </div>
      <h5
        class="font-s-22px font-w-600 gray-500 text-center mb-3 font-s-14px-m"
      >
        {{ productCategory.title }}
      </h5>
    </div>
    <button class="btn bg-gray-500 w-100 border-radius mb-4 products-btn">
      <span class="font-s-12px font-w-500 text-white">Ürünleri Göster</span>
      <right-arrow-icon color="white" />
    </button>
  </router-link>
</template>

<script>
import { useStore } from "vuex";
import ColorHelper from "../../../helpers/ColorHelper";
import Dermatoloji from "../../icon/product/Dermatoloji.vue";
import EndokrinVeMetabolizma from "../../icon/product/EndokrinVeMetabolizma.vue";
import UrogenitalSistem from "../../icon/product/UrogenitalSistem.vue";
import GastroenterolojiVeEnfeksiyon from "../../icon/product/GastroenterolojiVeEnfeksiyon.vue";
import Hematoloji from "../../icon/product/Hematoloji.vue";
import KardiyovaskulerSistem from "../../icon/product/KardiyovaskulerSistem.vue";
import KasIskeletSistemi from "../../icon/product/KasIskeletSistemi.vue";
import Radyoloji from "../../icon/product/Radyoloji.vue";
import SindirimSistemiMetabolizma from "../../icon/product/SindirimSistemiMetabolizma.vue";
import SinirSistemi from "../../icon/product/SinirSistemi.vue";
import SolunumSistemi from "../../icon/product/SolunumSistemi.vue";

export default {
  name: "ProductCard",
  components: {
    Dermatoloji,
    EndokrinVeMetabolizma,
    UrogenitalSistem,
    GastroenterolojiVeEnfeksiyon,
    Hematoloji,
    KardiyovaskulerSistem,
    KasIskeletSistemi,
    Radyoloji,
    SindirimSistemiMetabolizma,
    SinirSistemi,
    SolunumSistemi,
  },
  props: {
    productCategory: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const color = ColorHelper[store.state.theme].blueText;

    return {
      color,
    };
  },
};
</script>

<style lang="scss" scoped>
.products-btn {
  height: 36px;
}

.category-card:hover h5 {
  color: v-bind("color");
}

.category-card:hover button {
  background-color: v-bind("color");
}
</style>

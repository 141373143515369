<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.6637 18.3273H10.1016V76.8H86.6637V18.3273Z"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M16.7383 69.0028H28.8046"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M30.1933 37.0942C30.1933 37.0942 28.4156 45.2331 39.3253 43.1953C40.0193 43.0611 41.2856 42.695 42.7102 42.329"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.4534 26.8688C34.4534 26.8688 31.6773 34.1413 42.1729 33.3237C42.3677 33.3115 42.5626 33.2871 42.7452 33.2627"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.1933 48.0762C30.1933 48.0762 28.4156 56.215 39.3253 54.1772C40.0071 54.043 41.2491 53.6892 42.6371 53.3231"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.1856 59.4119C36.1856 59.4119 32.0701 64.7564 41.3603 64.7564H42.7727"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M66.5861 37.0942C66.5861 37.0942 68.3638 45.2331 57.4542 43.1953C55.8591 42.9025 51.3175 41.365 48.6875 41.365"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.3255 26.8688C62.3255 26.8688 65.1016 34.1413 54.6059 33.3237C52.6334 33.1773 51.3062 32.4086 48.6641 32.4086"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M66.5861 48.0762C66.5861 48.0762 68.3638 56.215 57.4542 54.1772C55.8591 53.8844 51.3175 52.3469 48.6875 52.3469"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.5962 59.4119C60.5962 59.4119 64.7117 64.7564 55.4214 64.7564H48.5664"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.3828 29.1871V68.2828"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Radyoloji",
};
</script>

<style></style>

<template>
  <div class="d-flex justify-content-between mb-4">
    <div>
      <h3 class="gray-700 font-s-24px comment-title">
        Yapılan <b class="text-info font-weight-bold">Yorumlar</b>
      </h3>
    </div>
    <button
      class="btn d-none d-sm-flex align-items-center"
      @click="toggleAction"
    >
      <comment-icon color="#AFBBDB" :size="24" />
      <span class="text-info font-w-600">{{
        isExpanded ? "Yorumları Gizle" : "Yorumları Göster"
      }}</span>
    </button>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  emits: ["toggle"],
  setup(props, { emit }) {
    const isExpanded = ref(true);

    const toggleAction = () => {
      isExpanded.value = !isExpanded.value;
      emit("toggle", isExpanded.value);
    };

    return {
      isExpanded,
      toggleAction,
    };
  },
};
</script>

<style lang="scss" scoped></style>

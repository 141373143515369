<template>
  <div class="d-flex flex-column flex-lg-row mt-m-60px">
    <login-form :zone="zone" />
    <login-info :zone="zone" btn-text="Ücretsiz Üye Olun" btn-class="login" />
  </div>
</template>

<script>
import LoginForm from "../components/ui/LoginForm.vue";
import LoginInfo from "../components/ui/LoginInfo.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import ZoneHelper from "../shared/zone-helper";
import { useRoute } from "vue-router";
import { Toast } from "../utils/toast";

export default {
  name: "Login",
  components: {
    LoginForm,
    LoginInfo,
  },
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const zoneHelper = new ZoneHelper();
    const zone = computed(() => zoneHelper.getZoneInfo(theme));
    const route = useRoute();

    if (route.query.alert_type == "auth") {
      Toast.fire({
        title: "Giriş Yapın",
        text: "Erişmek istediğiniz sayfayı görüntüleyebilmek için üye girişi yapmış olmanız gerekmektedir.",
        icon: "warning",
      });
    }

    return {
      zone,
    };
  },
};
</script>

<style lang="scss" scoped></style>

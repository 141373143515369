<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.72 7.28074C4.5802 7.15605 4.39698 7.0909 4.20958 7.09923C4.02218 7.10756 3.84551 7.18872 3.7174 7.32532C3.65469 7.39262 3.60606 7.4717 3.57435 7.55794C3.54264 7.64418 3.52848 7.73585 3.53271 7.82761C3.53694 7.91936 3.55947 8.00936 3.59899 8.09234C3.6385 8.17531 3.6942 8.24961 3.76285 8.31089L7.10284 11.3103C7.29856 11.4848 7.55509 11.5761 7.81746 11.5644C8.07983 11.5527 8.32718 11.4391 8.50652 11.2478C8.52207 11.2309 8.52207 11.2309 8.53689 11.2135L13.304 5.57876C13.3636 5.50857 13.4085 5.42723 13.4362 5.33951C13.4639 5.25179 13.4737 5.15945 13.4651 5.06789C13.4566 4.97633 13.4297 4.8874 13.3863 4.8063C13.3428 4.7252 13.2835 4.65356 13.2119 4.59558C13.0665 4.47741 12.8805 4.4207 12.6937 4.43759C12.5069 4.45448 12.3341 4.54362 12.2124 4.68593L7.7309 9.98426L4.72 7.28074Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ConfirmIcon",
};
</script>

<style></style>

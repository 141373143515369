<template>
  <!-- action buttons -->
  <div class="col-12">
    <div class="d-flex justify-content-end mb-4 mb-md-0">
      <button
        class="
          btn btn-info
          border-radius
          btn-save
          font-s-12px font-w-500
          w-m-100
        "
        :disabled="isSubmitting"
        @click="$emit('submit')"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsActionButtons",
  props: {
    submitButtonText: {
      type: String,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["submit"],
};
</script>

<style scoped></style>

<template>
  <!-- home-card-right-sss -->
  <div
    v-if="showSSS == 2"
    class="d-none position-relative z-index-15 d-xl-flex justify-content-center"
  >
    <sss-card
      class="sss-card"
      :show-s-s-s="showSSS"
      :arrow-show="2"
      arrow="mt-5"
      close="justify-content-end"
      :text="
        theme != 'hemaworld'
          ? 'Yeni makale, video, olgu veya klinik tanıtımı yapabileceğiniz içerikler oluşturarak daha fazla kişiye erişebilirsiniz.'
          : 'Clinical Key ile en güncel bilimsel veriler tek tıkla yakınınızda.'
      "
      @showSSS="$emit('update:showSSS', $event)"
    />
    <main-cards
      class="main-cards"
      class-card-blue="invisible"
      class-card-red="z-index-15"
    />
  </div>

  <!-- home-tool-sss -->
  <div v-if="showSSS == 4" class="position-absolute tools-sss z-index-15">
    <div
      class="
        tools-card-sss
        d-flex
        flex-column
        align-items-center
        bg-white
        border-radius
        p-5
        position-relative
      "
    >
      <div class="mb-3">
        <calculate-img />
      </div>
      <div>
        <h4 class="font-s-22px mb-2 text-info font-weight-bold">
          Hesaplama Cetvelleri
        </h4>
      </div>
      <div>
        <p class="font-s-12px mb-3 gray-500 text-center">
          Hesaplama Cetvelleri ile birçok değeri hesaplayabilirsiniz.
        </p>
      </div>
      <div>
        <button class="btn bg-info text-white border-radius">
          <span class="font-s-12px white border-radius">Tümünü Gör</span>
          <right-arrow-icon color="white" />
        </button>
      </div>
      <sss-card
        class="sss-card-tools"
        :show-s-s-s="showSSS"
        :arrow-show="3"
        arrow="mt-160px mr-4"
        close="justify-content-start"
        card="flex-row-reverse"
        text="Birçok değeri hesaplayabileceğiniz 'Hesaplama Cetvelleri' artık tek tık uzağınızda."
        @showSSS="$emit('update:showSSS', $event)"
      />
    </div>
  </div>

  <!-- header-sss -->
  <div
    v-if="showSSS != 2 && showSSS != 0"
    class="
      d-none
      header
      position-absolute
      z-index-15
      d-xl-flex
      align-items-center
      w-100
      mt-4
    "
  >
    <div
      class="
        container-header
        p-3
        px-4
        border-radius
        transition-0-3
        bg-white
        d-flex
      "
    >
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center">
          <img :src="sss_logo" alt="" class="header-logo" />
        </div>

        <div class="d-flex align-items-center">
          <div
            v-for="(item, index) in headerMenu"
            :key="index"
            class="position-relative nav-link"
          >
            <span :class="showSSS - 5 == index ? 'text-primary' : 'gray-300'">{{
              item.name
            }}</span>
            <point-text-icon
              v-if="showSSS - 5 == index"
              :size="8"
              color="bg-blue-300"
              class="position-absolute point-nav"
            />
            <div v-for="(itemNav, indexNav) in headerMenu" :key="indexNav">
              <sss-card
                v-if="
                  item.name == headerMenu[indexNav].name &&
                  showSSS == indexNav + 5
                "
                class="sss-nav"
                :item-size="4 + headerMenu.length"
                :show-s-s-s="showSSS"
                content="mt-4"
                close="justify-content-start"
                :text="
                  navText[navText.map((x) => x.nav).indexOf(item.name)].text
                "
                @showSSS="$emit('update:showSSS', $event)"
              />
              <div
                v-if="
                  item.name == headerMenu[indexNav].name &&
                  showSSS == indexNav + 5
                "
                class="position-absolute focus-icon-nav"
              >
                <focus-icon
                  :color="colorFocus"
                  :show="item.name == 'Literatür Tarama' ? 3 : 2"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="position-relative">
            <div class="position-relative">
              <search-icon
                class="mx-3"
                :show="true"
                :color="showSSS == 3 ? color : '#AFBBDB'"
              />
              <sss-card
                v-if="showSSS == 3"
                :show-s-s-s="showSSS"
                content="mt-4"
                close="justify-content-end"
                :text="
                  'Altyapısını tamamen yenilediğimiz arama modülümüzle artık ' +
                  zone.name +
                  ' bünyesinde yer alan' +
                  (theme != 'hemaworld' ? ' videolar, kanallar,' : '') +
                  ' makaleler, hesaplama cetvelleri gibi modüllere daha kolay erişebilirsiniz.'
                "
                class="sss-search"
                @showSSS="$emit('update:showSSS', $event)"
              />
            </div>
            <div
              v-if="showSSS == 3"
              class="position-absolute focus-icon-search"
            >
              <focus-icon :color="colorFocus" :show="1" />
            </div>
          </div>

          <div class="position-relative">
            <sss-card
              v-if="showSSS == 1"
              :show-s-s-s="showSSS"
              :arrow-show="1"
              content="mt-4"
              close="justify-content-end"
              text="Profil ayarlarınıza, mesajlarınıza ve kaydettiğiniz içeriklere profil sekmenizden erişebilirsiniz."
              class="sss-profile"
              @showSSS="$emit('update:showSSS', $event)"
            />
            <div v-if="showSSS == 1" class="position-relative btn-open-sss">
              <span
                class="
                  user-box-sss
                  d-none d-lg-flex
                  align-items-lg-center
                  btn
                  bg-primary
                  text-white
                  border-radius
                  font-s-12px
                  nav-button
                "
              >
                <div class="mr-2">
                  <img
                    src="/new-img/homevideo-sm-person.png"
                    alt=""
                    class="person-img border-radius-50"
                  />
                </div>
                <div class="d-flex flex-column align-items-center">
                  <span>Merhaba</span>
                  <bottom-arrow-icon color="white" :size="8" />
                </div>
              </span>
              <div
                class="
                  position-absolute
                  bg-primary
                  border-radius
                  flex-column
                  btn-open-box-sss
                  w-100
                  font-s-12px
                  d-flex
                  flex-column
                "
              >
                <span class="py-2 px-3 text-white bg-primary"
                  >Profil Ayarları</span
                >
                <span class="py-2 px-3 text-white bg-primary">Mesajlar</span>
                <span class="py-2 px-3 text-white bg-primary"
                  >Kaydettiklerim</span
                >
                <span
                  class="
                    py-2
                    px-3
                    text-white
                    bg-primary
                    bottom-left-radius bottom-right-radius
                  "
                  >Çıkış</span
                >
              </div>
            </div>
            <div v-if="showSSS != 0 && showSSS != 1">
              <span
                class="
                  d-none d-lg-flex
                  align-items-lg-center
                  btn
                  bg-gray-500
                  text-white
                  border-radius
                  font-s-12px
                  nav-button
                "
              >
                <div class="mr-2">
                  <img
                    src="/new-img/homevideo-sm-person-black.png"
                    alt=""
                    class="person-img border-radius-50"
                  />
                </div>
                <div class="d-flex flex-column align-items-center">
                  <span>Merhaba</span>
                  <bottom-arrow-icon color="white" :size="8" />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainCards from "./MainCards.vue";
import SssCard from "./SssCard.vue";
import CalculateImg from "../icon/img/CalculateImg.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { navText } from "../../helpers/WalkThrough";
import ColorHelper from "../../helpers/ColorHelper";
import ZoneHelper from "../../shared/zone-helper";
export default {
  name: "WalkThrough",
  components: {
    MainCards,
    SssCard,
    CalculateImg,
  },
  props: {
    showSSS: {
      type: Number,
      required: true,
    },
    headerMenu: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:showSSS"],
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const sss_logo = "/new-img/" + theme + "-logo-sss.png";
    const colorFocus = ColorHelper[theme].blue300;
    const color = ColorHelper[theme].blueDark;

    const zoneHelper = new ZoneHelper();

    const zone = computed(() => zoneHelper.getZoneInfo(theme));
    return {
      sss_logo,
      navText,
      colorFocus,
      color,
      zone,
      theme,
    };
  },
};
</script>

<style style="scss" scoped>
.header {
  height: 85px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.container-header {
  max-width: 1194px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.header-logo {
  width: 213px;
}

.point-nav {
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.main-cards {
  top: 590px;
}

.sss-card {
  max-width: 282px;
  top: 460px;
  margin-right: 350px !important;
}

.sss-card-tools {
  right: -400px;
  top: -250px;
  max-width: 350px;
}

.sss-profile {
  right: 150px;
  top: 100px;
}

.sss-search {
  top: 60px;
  right: -25px;
}

.sss-nav {
  top: 60px;
}

.tools-sss {
  top: 2078px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.tools-card-sss {
  width: 370px;
  height: 350px;
}

.focus-icon-search {
  top: -7px;
  left: -11px;
  z-index: 15;
}

.focus-icon-nav {
  top: -10px;
  left: -10px;
}

.user-box-sss {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-open-box-sss {
  top: 45px;
}

.person-img {
  width: 24px;
  height: 24px;
}
</style>

<template>
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.428 4.09326C15.7423 4.09326 15.228 4.59905 15.228 5.27344C15.228 5.94783 15.7423 6.45362 16.428 6.45362C17.1137 6.45362 17.628 5.94783 17.628 5.27344C17.628 4.59905 17.0566 4.09326 16.428 4.09326Z"
      :fill="color"
    />
    <path
      d="M11.114 5.55444C8.37116 5.55444 6.08545 7.7462 6.08545 10.4999C6.08545 13.1975 8.31402 15.4455 11.114 15.4455C13.8569 15.4455 16.1426 13.2537 16.1426 10.4999C16.1426 7.8024 13.8569 5.55444 11.114 5.55444ZM11.114 13.6471C9.34259 13.6471 7.91402 12.2421 7.91402 10.4999C7.91402 8.75778 9.34259 7.35281 11.114 7.35281C12.8854 7.35281 14.314 8.75778 14.314 10.4999C14.314 12.2421 12.8854 13.6471 11.114 13.6471Z"
      :fill="color"
    />
    <path
      d="M15.1138 20.5034H6.94241C3.57098 20.5034 0.828125 17.8058 0.828125 14.4901V6.50987C0.828125 3.19413 3.57098 0.496582 6.94241 0.496582H15.0567C18.4281 0.496582 21.171 3.19413 21.171 6.50987V14.4901C21.2281 17.8058 18.4853 20.5034 15.1138 20.5034ZM6.94241 2.35115C4.59955 2.35115 2.77098 4.20571 2.77098 6.45367V14.4901C2.77098 16.7943 4.6567 18.5926 6.94241 18.5926H15.0567C17.3996 18.5926 19.2281 16.7381 19.2281 14.4901V6.50987C19.2281 4.20571 17.3424 2.40735 15.0567 2.40735H6.94241V2.35115Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "InstagramIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <!-- modal (modalın aktif olması için alt satırdaki false silinecek)-->
    <div
      v-if="modal.open && modal.type === 'image'"
      class="
        position-fixed
        d-flex
        justify-content-center
        align-items-center
        home-modal
      "
    >
      <div
        class="home-modal-bg position-absolute"
        @click="modal.open = false"
      ></div>
      <div class="home-modal-box position-relative">
        <div
          class="
            border-radius-50
            position-absolute
            modal-img-delete
            bg-gray-300
            cursor-pointer
            d-flex
            justify-content-center
            align-items-center
          "
          @click="modal.open = false"
        >
          <close-icon color="white" :size="24" />
        </div>
        <a :href="modal.target_url" target="_blank">
          <img
            :src="modal.image_url"
            alt=""
            class="border-radius m-5 home-modal-img"
          />
        </a>
      </div>
    </div>
    <Main v-if="!isSlidesLoading" :slides="slides" />
    <featured-articles
      v-if="!isRecentPostsLoading && theme != 'hemaworld'"
      :recent-posts="recentPosts"
    />
    <home-videos
      v-if="!isPopularVideosLoading && theme != 'hemaworld'"
      :popular-videos="popularVideos"
    />
    <home-tools v-if="!isPopularVideosLoading" />
    <home-podcast
      v-if="user && !isPodcastsLoading && theme != 'hemaworld'"
      :podcasts="podcasts"
    />
    <upcoming-events
      v-if="!isUpcomingEventsLoading"
      :upcoming-events="upcomingEvents"
    />
    <social-media v-if="theme != 'hemaworld'" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import Main from "../components/ui/Main.vue";
import FeaturedArticles from "../components/ui/FeaturedArticles.vue";
import SocialMedia from "../components/ui/SocialMedia.vue";
import HomeVideos from "../components/ui/HomeVideos.vue";
import HomeTools from "../components/ui/HomeTools.vue";
import UpcomingEvents from "../components/ui/UpcomingEvents.vue";
import VideoService from "../services/video/VideoService";
import SlideService from "../services/slide/SlideService";
import PostService from "../services/post/PostService";
import EventService from "../services/event/EventService";
import PopupService from "../services/popup/PopupService";
import HomePodcast from "../components/ui/HomePodcast.vue";
import PodcastService from "../services/podcast/PodcastService";
import { setCookie, getCookie } from "../helpers/Cookie";

export default {
  components: {
    Main,
    FeaturedArticles,
    HomeVideos,
    HomeTools,
    UpcomingEvents,
    SocialMedia,
    HomePodcast,
  },
  setup() {
    const store = useStore();
    const theme = store.state.theme;
    const modal = ref({
      type: "",
      open: false,
      target_url: "",
      image_url: "",
    });
    const user = computed(() => store.state.user.user);
    const isPopularVideosLoading = ref(false);
    const isSlidesLoading = ref(false);
    const isRecentPostsLoading = ref(false);
    const isUpcomingEventsLoading = ref(false);
    const isPodcastsLoading = ref(false);
    const podcastService = new PodcastService();
    const podcasts = ref([]);
    const popularVideos = ref({});
    const slides = ref([]);
    const recentPosts = ref([]);
    const upcomingEvents = ref({});
    const loading = computed(
      () =>
        isPopularVideosLoading.value ||
        isSlidesLoading.value ||
        isRecentPostsLoading.value
    );

    const fetchPopularVideos = () => {
      isPopularVideosLoading.value = true;

      VideoService.getPopularVideos(1, 17)
        .then((response) => {
          popularVideos.value = response.data.data;
        })
        .finally(() => (isPopularVideosLoading.value = false));
    };

    const fetchSlides = () => {
      isSlidesLoading.value = true;
      SlideService.getSlidesByGroup()
        .then((response) => {
          slides.value = response.data.data;
        })
        .finally(() => {
          isSlidesLoading.value = false;
        });
    };

    const fetchRecentPosts = () => {
      isRecentPostsLoading.value = true;
      PostService.getRecentPostsByCollection("haberler", 1, 4)
        .then((response) => {
          recentPosts.value = response.data.data;
        })
        .finally(() => {
          isRecentPostsLoading.value = false;
        });
    };

    const fetchPodcasts = (sorts = ["-publish_on"]) => {
      isPodcastsLoading.value = true;
      podcastService
        .all({
          filters: { is_active: [true] },
          includes: ["channel"],
          sorts,
        })
        .then((response) => {
          podcasts.value = response;
        })
        .finally(() => {
          isPodcastsLoading.value = false;
        });
    };

    const fetchUpcomingEvents = () => {
      isUpcomingEventsLoading.value = true;
      EventService.getUpcomingEvents(1, 4)
        .then((response) => {
          upcomingEvents.value = response.data.data;
        })
        .finally(() => {
          isUpcomingEventsLoading.value = false;
        });
    };

    const fetchPopups = () => {
      if (user.value) {
        PopupService.getPopups().then((response) => {
          const popups = response.data.data.filter((popup) => {
            return getCookie(`home_popup_${popup.id}`) === null;
          });
          if (popups.length > 0) {
            const popup = popups[0];
            modal.value.type = popup.type;
            modal.value.target_url = popup.target_url;
            modal.value.image_url = popup.image_url;
            modal.value.open = true;
            setCookie(`home_popup_${popup.id}`, true, 1);
          }
        });
      }
    };

    watch(loading, (newVal) => {
      store.commit("setLoading", newVal);
    });

    fetchSlides();
    fetchRecentPosts();
    fetchPopularVideos();
    fetchUpcomingEvents();
    if (user.value) {
      fetchPodcasts();
    }
    fetchPopups();

    return {
      user,
      isPopularVideosLoading,
      popularVideos,
      isSlidesLoading,
      slides,
      isRecentPostsLoading,
      recentPosts,
      isUpcomingEventsLoading,
      upcomingEvents,
      modal,
      podcasts,
      isPodcastsLoading,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.home-modal {
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#00000060, #00000060);
}

.home-modal-bg {
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.home-modal-box {
  z-index: 10002;
}

.home-modal-img {
  max-width: 80vw;
  max-height: 80vh;
}

.modal-img-delete {
  right: 30px;
  top: 30px;
  width: 40px;
  height: 40px;
}
</style>

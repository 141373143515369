<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="CDAI Hesaplama Aracı"
        nav="CDAI Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-30px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <form
            id="bet"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Ağrılı Eklem Sayısı
                </h5>
                <input
                  id=""
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="aes"
                />
              </div>
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Şiş Eklem Sayısı
                </h5>
                <input
                  id=""
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="ses"
                />
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">PGA</h5>
                <input
                  id=""
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="pga"
                />
              </div>
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">EGA</h5>
                <input
                  id=""
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                  "
                  name="ega"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll">
              <close-icon :size="16" :color="color" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius btn-calculate"
              @click="showResult = true"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "CdaiScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    $(document).ready(function () {
      $(".btn-calculate").on("click", function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
        $(".return-text").slideDown();
        var form = $("#bet");
        var error = 0;
        var aes = form.find('input[name="aes"]').val();
        var ses = form.find('input[name="ses"]').val();
        var pga = form.find('input[name="pga"]').val();
        var ega = form.find('input[name="ega"]').val();
        if (!aes | (aes > 28) | (aes < 0)) {
          form.find('input[name="aes"]').addClass("errorCol");
          if ((aes > 28) | (aes < 0)) {
            form
              .find('input[name="aes"]')
              .closest(".inputFs")
              .addClass("errorCol");
          }
          error++;
        }
        if (!ses | (ses > 28) | (ses < 0)) {
          form.find('input[name="ses"]').addClass("errorCol");
          if ((ses > 28) | (ses < 0)) {
            form
              .find('input[name="ses"]')
              .closest(".inputFs")
              .addClass("errorCol");
          }
          error++;
        }
        if (!pga | (pga > 10) | (pga < 0)) {
          form.find('input[name="pga"]').addClass("errorCol");
          if ((pga > 28) | (pga < 0)) {
            form
              .find('input[name="pga"]')
              .closest(".inputFs")
              .addClass("errorCol");
          }
          error++;
        }
        if (!ega | (ega > 28) | (ega < 0)) {
          form.find('input[name="ega"]').addClass("errorCol");
          if ((ega > 28) | (ega < 0)) {
            form
              .find('input[name="ega"]')
              .closest(".inputFs")
              .addClass("errorCol");
          }
          error++;
        }
        if (error) {
          return false;
        }
        var f = parseInt(aes) + parseInt(ses) + parseInt(pga) + parseInt(ega);
        var status = "";
        if (f <= 2.8) {
          status = "Remisyon";
        } else if ((f > 2.8) & (f <= 10)) {
          status = "D&uuml;ş&uuml;k Hastalık Aktivitesi";
        } else if ((f > 10) & (f <= 22)) {
          status = "Orta Hastalık Aktivitesi";
        } else if (f > 22) {
          status = "Şiddetli Hastalık Aktivitesi";
        }
        showReturnHtml(status);
      });
    });

    const showReturnHtml = (text) => {
      $(".return-text").html(text);
    };

    const clearAll = () => {
      $("form")[0].reset();
      $(".return-text").slideUp();
    };

    $(document).ready(function () {
      $("form").submit(function (event) {
        event.preventDefault();
        $(".btn-calculate").click();
      });
      $(".calc_add").click(function () {
        var c = $(this).prev("input").val();
        $(this)
          .prev("input")
          .val(parseInt(c) + 1);
      });
      $(".calc_int").click(function () {
        var c = $(this).closest("div").find("input");
        if (c.val()) {
          c.val(parseInt(c.val()) - 1);
        }
      });
    });

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      clearAll,
      apgar: [
        {
          title: "Romatoid Artrit Hakkında",
          content: `            <p>Nedeni tam olarak bilinmeyen Romatoid Artrit, eklem iltihabının en çok görülen biçimidir ve vücudun sağlıklı doku ve hücrelere saldırdığı bir otoimmün hastalıktır. Eklem zarlarında iltihaplanmaya sebep olan Romatoid Artrit ilerleyici ve kronik bir hastalıktır.</p>
            <p>Kesin bir tedavisi olmayan Romatoid Artrit’in sebep olduğu ağrı, sertlik ve şişmeleri hafifletmeye yardımcı olan ilaçlar mevcuttur.</p>
            <p>Romatoid Artrit’in etkilediği tek yer ise organlar değildir, deri, gözler, akciğer, kalp ve kan damarı gibi pek çok noktaya da zarar verebilir.</p>
            <p>Romatoid Artrit’in belirtileri arasında hassas ve şişmiş eklemler, sabahları ve hareketsizlik sonrası artan eklem sertliği, yorgunluk, ateş ve iştah kaybı olabilir.</p>`,
        },
        {
          title: "CDAI (Clinical Disease Activity Index) Nedir?",
          content: `<p>Romatoid Artrit tanısı genellikle hastaların simetrik olarak eklemlerinin deforme olup olmadığının kontrol edilmesiyle konulur. Romatoid Artritte vücut yapısının bozulmasını en aza indirgemek için erken teşhis oldukça önemlidir. Uzun yıllardır Romatoid Artrit’in gidişatı ve durumu DAS-28 testiyle tespit edilmiştir. Hastalığın aktivitesini ölçmek için yeni oluşturulan bir yöntem ise Clinical Disease Activity Index (CDAI) yani Klinik Hastalık Aktivite Endeksidir. Bu akut faz reaktan içermeyen tek hastalık aktivitesi endeksidir.</p>`,
        },
        {
          title: "CDAI (Clinical Disease Activity Index) Nasıl Hesaplanır?",
          content: `
              <p>Clinical Disease Activity Index (CDAI) skoru, DAS-28’e göre daha pratiktir. CDAI skoru, 28 eklemin şişlik ve hassasiyetine göre değerlendirilmesi, hastanın kendisini hastalık aktivitesini 0-10 arasından değerlendirmesi, doktorun hastanın hastalık aktivitesi 0-10 arasından değerlendirmesi sonuçlarının toplamından oluşur.</p>

            <p>Clinical Disease Activity Index (CDAI) skorunun 2.8 ve altında olması gerileme, 2.8’den yüksek, 10 ve altında olması düşük hastalık aktivitesi, 10’dan yüksek, 22 ve altında olması orta hastalık aktivitesi, 22’den yüksek olması ise şiddetli hastalık aktivitesi anlamına gelmektedir.</p>`,
        },
      ],
      references: [
        "https://www.rheumtutor.com/assessment-tools/clinical-disease-activity-index/",
        "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3254008/",
        "https://www.mayoclinic.org/diseases-conditions/rheumatoid-arthritis/symptoms-causes/syc-20353648",
        "https://www.medicalpark.com.tr/romatoid-artrit-nedir-teshisi-ve-tedavisi-nelerdir/hg-132#5",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <div v-if="!productPromise.loading.value" class="product-detail mb-100px">
    <!-- eslint-disable vue/no-v-html -->
    <section class="product-detail-main bg-image d-flex flex-column mb-5">
      <content-main
        :title="productPromise.results.value.title"
        :nav="productPromise.results.value.title"
        class="mt-m-100px mt-160px"
      />
    </section>

    <section>
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-5">
            <div class="d-flex flex-column position-relative product-img">
              <div class="border-radius bg-white p-3 mb-4 box-shadow-4-20-2">
                <img
                  :src="productPromise.results.value.thumbnail.permalink"
                  :alt="productPromise.results.value.title"
                  class="
                    img-fluid
                    border-radius
                    object-fit-cover
                    product-img
                    w-100
                  "
                />
              </div>
              <div class="d-flex justify-content-center">
                <a
                  :href="productPromise.results.value.kt_file.permalink"
                  target="_blank"
                  class="
                    btn
                    bg-blue-light
                    btn-product
                    text-info
                    border-radius
                    mr-3
                    d-flex
                    align-items-center
                    justify-content-center
                    w-50
                    height-50px
                  "
                >
                  <right-btn-icon :color="color" class="mr-2" />
                  <span class="font-s-12px font-w-500 text-info"
                    >Kullanma Talimatı</span
                  >
                </a>
                <a
                  :href="productPromise.results.value.kub_file.permalink"
                  target="_blank"
                  class="
                    btn
                    bg-blue-light
                    btn-product
                    text-info
                    border-radius
                    d-flex
                    align-items-center
                    justify-content-center
                    w-50
                    height-50px
                  "
                >
                  <right-btn-icon :color="color" class="mr-2" />
                  <span class="font-s-12px font-w-500 text-info"
                    >Kısa Ürün Bilgisi</span
                  >
                </a>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-7">
            <div>
              <div class="row py-4">
                <div class="col-6 col-md-4">
                  <h5 class="font-s-16px font-w-500 black-700 m-0">
                    Endikasyon
                  </h5>
                </div>
                <div class="col-6 col-md-8">
                  <p
                    class="text-secondary m-0"
                    v-html="productPromise.results.value.indication"
                  ></p>
                </div>
              </div>
              <div class="row bg-gray-200-70 py-3">
                <div class="col-6 col-md-4">
                  <h5 class="font-s-16px font-w-500 black-700 m-0">Form</h5>
                </div>
                <div class="col-6 col-md-8">
                  <p
                    class="text-secondary m-0"
                    v-html="productPromise.results.value.form"
                  ></p>
                </div>
              </div>
              <div class="row py-4">
                <div class="col-6 col-md-4">
                  <h5 class="font-s-16px font-w-500 black-700 m-0">
                    Etken Madde
                  </h5>
                </div>
                <div class="col-6 col-md-8">
                  <p
                    class="text-secondary m-0"
                    v-html="productPromise.results.value.active_ingredient"
                  ></p>
                </div>
              </div>
              <div class="row bg-gray-200-70 py-3">
                <div class="col-6 col-md-4">
                  <h5 class="font-s-16px font-w-500 black-700 m-0">
                    Etken Madde Miktarı
                  </h5>
                </div>
                <div class="col-6 col-md-8">
                  <p
                    class="text-secondary m-0"
                    v-html="
                      productPromise.results.value.active_ingredient_amount
                    "
                  ></p>
                </div>
              </div>
              <div class="row py-4">
                <div class="col-6 col-md-4">
                  <h5 class="font-s-16px font-w-500 black-700 m-0">
                    Ticari Şekli
                  </h5>
                </div>
                <div class="col-6 col-md-8">
                  <p
                    class="text-secondary m-0"
                    v-html="productPromise.results.value.commercial_figure"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import { useStore } from "vuex";
import ColorHelper from "../helpers/ColorHelper";
import { useRoute } from "vue-router";
import ProductService from "../services/product/ProductService";
import usePromise from "../shared/composables/use-promise";

export default {
  name: "ProductDetail",
  components: {
    ContentMain,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const color = ColorHelper[store.state.theme].blueText;

    const productPromise = usePromise(() => {
      return ProductService.getProductById(route.params.id);
    });

    store.dispatch("setLoading", true);
    productPromise.createPromise().finally(() => {
      store.dispatch("setLoading", false);
    });

    return {
      productPromise,
      main_img,
      color,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-detail-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 356px;
}

.product-img {
  top: -100px;
}

@media (max-width: 768px) {
  .product-detail-main {
    height: 240px;
  }

  .btn-product {
    height: 38px !important;
  }

  .product-img {
    height: 250px;
  }
  .product-img {
    height: 215px;
  }
}
</style>

<template>
  <div class="guides mb-100px">
    <section
      class="
        guides-detail-main
        bg-image
        d-flex
        flex-column
        align-items-center
        mb-5
      "
    >
      <content-main
        title="Kılavuzlar"
        nav="Kılavuzlar"
        class="mt-md-100px mt-160px"
      />
    </section>

    <section class="mb-5">
      <div class="container-xl">
        <p class="gray-700 text-center mb-5">
          Sistemimizde bulunan Kılavuzlara rahatlıkla ulaşabilir ve bu
          kılavuzları cihazlarınıza indirebilirsiniz.
        </p>

        <div class="row">
          <div class="col-12 col-md-2">
            <select
              v-model="filteredYear"
              name="year"
              class="
                mb-3
                custom-select
                select-year
                height-50px
                border-radius
                box-shadow-4-20-2
                font-s-12px
              "
            >
              <option selected value="all">Tüm Yıllar</option>
              <option v-for="year in years" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <select
              v-model="filterDisease"
              name="disease"
              class="
                mb-3
                custom-select
                select-disease
                height-50px
                border-radius
                box-shadow-4-20-2
                font-s-12px
              "
            >
              <option selected value="all">Tüm Hastalıklar</option>
              <option
                v-for="disease in diseases"
                :key="disease"
                :value="disease"
              >
                {{ disease }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <select
              v-model="filterJournal"
              name="channel"
              class="
                mb-3
                custom-select
                height-50px
                box-shadow-4-20-2
                select-magazine
                border-radius
                font-s-12px
              "
            >
              <option selected value="all">Tüm E-Dergiler</option>
              <option
                v-for="journal in journals"
                :key="journal"
                :value="journal"
              >
                {{ journal }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-2">
            <button
              class="
                w-100
                mb-3
                btn btn-primary btn-guides-search
                height-50px
                d-flex
                justify-content-center
                align-items-center
                border-radius
              "
              @click="filter"
            >
              <search-icon :show="true" color="white" class="mr-1" />
              <span class="font-s-12px font-w-500">Sonuçları Göster</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <h3 class="font-s-24px font-weight-normal gray-700 mb-4">
          Arama <b class="text-info">Sonuçları</b>
        </h3>

        <div v-for="guide in filteredGuides" :key="guide.id">
          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-between
              align-items--md-center
            "
          >
            <div>
              <div class="d-flex mb-3 font-weight-normal font-s-10px">
                <span
                  class="bg-primary text-white border-radius-5px mr-2 py-1 px-2"
                  >{{ guide.disease }}</span
                >
                <span
                  class="bg-blue-light text-info border-radius-5px py-1 px-2"
                  >{{ guide.journal }}</span
                >
              </div>
              <p class="gray-700 max-w-810px pr-4">
                {{ guide.description }}
              </p>
            </div>
            <div class="d-flex w-m-100">
              <a
                :href="guide.pdf"
                class="
                  w-m-50
                  btn btn-info btn-guides
                  border-radius
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <download-icon color="white" class="mr-1" />
                <span class="font-s-12px font-w-500">Kılavuzu İndir</span>
              </a>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import GuideService from "../services/guide/GuideService";

export default {
  name: "Guides",
  components: {
    ContentMain,
  },
  setup() {
    const store = useStore();
    const showInputBox = ref(false);
    const color = ColorHelper[store.state.theme].blueDark;
    const mainColor1 = ColorHelper[store.state.theme].guidesMain1;
    const mainColor2 = ColorHelper[store.state.theme].guidesMain2;
    const img_url = "url(/new-img/guides-main.png)";
    const guides = ref([]);
    const filteredGuides = ref([]);
    const guideService = new GuideService();
    const filterJournal = ref("all");
    const filterDisease = ref("all");
    const filteredYear = ref("all");

    const diseases = computed(
      () => new Set(guides.value.map((guide) => guide.disease))
    );
    const journals = computed(
      () => new Set(guides.value.map((guide) => guide.journal))
    );
    const years = computed(
      () =>
        new Set(
          guides.value.map((guide) => guide.created_at.substring(0, 4)).sort()
        )
    );

    async function fetchGuides() {
      guides.value = await guideService.all();
      filteredGuides.value = guides.value;
    }

    function filter() {
      filteredGuides.value = guides.value.filter(
        (guide) =>
          (filterJournal.value === "all"
            ? true
            : guide.journal === filterJournal.value) &&
          (filterDisease.value === "all"
            ? true
            : guide.disease === filterDisease.value) &&
          (filteredYear.value === "all"
            ? true
            : guide.created_at.substring(0, 4) === filteredYear.value)
      );
    }

    fetchGuides();

    return {
      color,
      journals,
      filteredGuides,
      filterDisease,
      filteredYear,
      filterJournal,
      mainColor1,
      mainColor2,
      diseases,
      img_url,
      changeShowInputBox() {
        showInputBox.value = !showInputBox.value;
      },
      guides,
      filter,
      years,
    };
  },
};
</script>

<style lang="scss" scoped>
.guides-detail-main {
  background: linear-gradient(
      180deg,
      rgba(0, 62, 167, 0) 24.02%,
      v-bind("mainColor1") 96.22%
    ),
    linear-gradient(0deg, v-bind("mainColor2"), v-bind("mainColor2")),
    v-bind("img_url");
  width: 100vw;
  height: 300px;
}

.select-year,
.select-disease,
.select-magazine {
  border: none;
  padding: 0 30px;
}

.btn-guides {
  width: 128px;
  height: 36px;
}

@media (max-width: 768px) {
  .guides-detail-main {
    height: 190px;
  }

  h3 {
    font-size: 18px !important;
  }
}
</style>

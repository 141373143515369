<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00065 1.33331C3.87598 1.33331 1.33398 3.87531 1.33398 6.99998C1.33398 10.1246 3.87598 12.6666 7.00065 12.6666C10.1253 12.6666 12.6673 10.1246 12.6673 6.99998C12.6673 3.87531 10.1253 1.33331 7.00065 1.33331M7.00065 13.6666C3.32465 13.6666 0.333984 10.676 0.333984 6.99998C0.333984 3.32398 3.32465 0.333313 7.00065 0.333313C10.6767 0.333313 13.6673 3.32398 13.6673 6.99998C13.6673 10.676 10.6767 13.6666 7.00065 13.6666"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.99609 7.9153C6.72009 7.9153 6.49609 7.6913 6.49609 7.4153V4.4693C6.49609 4.1933 6.72009 3.9693 6.99609 3.9693C7.27209 3.9693 7.49609 4.1933 7.49609 4.4693V7.4153C7.49609 7.6913 7.27209 7.9153 6.99609 7.9153"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00398 10.1972C6.63532 10.1972 6.33398 9.89922 6.33398 9.53056C6.33398 9.16189 6.62932 8.86389 6.99732 8.86389H7.00398C7.37265 8.86389 7.67065 9.16189 7.67065 9.53056C7.67065 9.89922 7.37265 10.1972 7.00398 10.1972"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "WarningError",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

import ApiService from "../ApiService";

export default class UserContentService extends ApiService {
  collection = "question-banks";
  getQuestionsWithAnswers(question_bank_id, page = 1, per_page = 15) {
    return this.client.get(
      `/api/question-banks/${question_bank_id}/questions`,
      {
        params: {
          page,
          per_page,
        },
      }
    );
  }
}

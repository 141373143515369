<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8.10169C14 11.3592 11.3137 14 8 14C4.68629 14 2 11.3592 2 8.10169C2 4.84415 4.66667 2.20339 8 2.20339C12 2.20339 14 5.48022 14 5.48022M14 5.48022L14 2M14 5.48022H10.8966"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "RecylingIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

import { createApiClient } from "../utils";

const apiClient = createApiClient();
apiClient.defaults.baseURL = process.env.MIX_SANTAFARMA_API_URL;
export default {
  getNewProducts() {
    return apiClient.get(
      "/collections/products/entries?filter[site]=tr&sort=-date&limit=6"
    );
  },
  searchByName(keyword) {
    return apiClient.get(
      `/collections/products/entries?filter[site]=tr&filter[title:contains]=${keyword}`
    );
  },
  searchByActiveIngredient(keyword) {
    return apiClient.get(
      `/collections/products/entries?filter[site]=tr&filter[active_ingredient:contains]=${keyword}`
    );
  },
  getProductById(id) {
    return apiClient.get(`/collections/products/entries/${id}`);
  },
};

import { createStore } from "vuex";
import SettingService from "../services/settings/SettingService.js";

import * as user from "./modules/user.js";

const store = createStore({
  modules: {
    user,
  },
  state: {
    bearerToken: null,
    authLoading: false,
    loading: false,
    theme: process.env.MIX_THEME,
    routerReady: false,
    podcastList: [],
    activePodcast: {},
    settings: {},
  },
  mutations: {
    setBearerToken(state, payload) {
      state.bearerToken = payload;
    },
    setAuthLoading(state, payload) {
      state.authLoading = payload;
    },
    setRouterReady(state, payload) {
      state.routerReady = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setPodcastList(state, payload) {
      state.podcastList.push(payload);
    },
    setPodcastListStorage(state, payload) {
      state.podcastList = payload;
    },
    setEmptyPodcastListStorage(state) {
      state.podcastList = [];
    },
    deletePodcastList(state, payload) {
      state.podcastList.splice(
        state.podcastList.findIndex((podcast) => podcast.id == payload),
        1
      );
    },
    setActivePodcast(state, payload) {
      state.activePodcast = payload;
    },
    setSettings(state, payload) {
      state.settings = payload;
    },
  },
  actions: {
    setBearerToken(ctx, token) {
      localStorage.setItem("api_token", token);
      ctx.commit("setBearerToken", token);
    },
    logout(ctx) {
      localStorage.removeItem("api_token");
      ctx.commit("setBearerToken", null);
      ctx.commit("user/setUser", null);
    },
    setLoading(ctx, payload) {
      ctx.commit("setLoading", payload);
    },
    setLS(ctx, { key, payload }) {
      localStorage.removeItem(key);
      if (!payload) {
        return;
      }

      localStorage.setItem(key, payload);
    },
    setStoragePodcastList(ctx) {
      localStorage.setItem(
        "podcast_list",
        JSON.stringify(ctx.state.podcastList)
      );
    },
    getStoragePodcastList(ctx) {
      if (localStorage.getItem("podcast_list") === null) {
        ctx.commit("setEmptyPodcastListStorage");
      } else {
        const podcast = JSON.parse(localStorage.getItem("podcast_list"));
        ctx.commit("setPodcastListStorage", podcast);
      }
    },
    deleteStoragePodcastList(ctx, id) {
      ctx.commit("deletePodcastList", id);
      ctx.dispatch("setStoragePodcastList");
    },
    fetchSettings(ctx) {
      return SettingService.get().then((res) => {
        let settings = {};
        res.data.data.forEach(function (item) {
          settings[item.key] = item.value;
        });
        ctx.commit("setSettings", settings);
      });
    },
  },
  getters: {
    getPodcastList(state) {
      return state.podcastList;
    },
    getActivePodcast(state) {
      return state.activePodcast;
    },
    getSettings(state) {
      return state.settings;
    },
    getTheme(state) {
      return state.theme;
    },
  },
});

export default store;

<template>
  <div>
    <h4 class="font-weight-normal font-s-24px mb-4 d-none d-md-block">
      Eczane <b class="text-info">Bilgileri</b>
    </h4>
    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">GLN No</span>
          <base-input
            name="gln_no"
            placeholder="GLN No"
            type="text"
            :disabled="user.pharmacy?.gln_no"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Şehir</span>
          <base-select
            name="city"
            :options="cities"
            option-text-key="name"
            option-value-key="name"
            placeholder="Şehir seçiniz"
            :loading="isOptionsLoading"
            :disabled="user.pharmacy?.gln_no"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column mb-4">
          <span class="font-s-12px font-w-600 gray-500 mb-3">Eczane Adı</span>
          <base-input
            name="pharmacy_name"
            placeholder="Eczane Adı"
            type="text"
            :disabled="user.pharmacy?.gln_no"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <settings-action-buttons
        submit-button-text="Eczane Bilgilerini Güncelle"
        :is-submitting="isSubmitting"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import * as yup from "yup";
import BaseInput from "../ui/RegisterForm/BaseInput";
import BaseSelect from "../ui/RegisterForm/BaseSelect";
import { useForm } from "vee-validate";
import { ref } from "vue";
import SettingsActionButtons from "./SettingsActionButtons";
import UserServiceNew from "../../services/UserServiceNew";
import RegisterService from "../../services/register/RegisterService";
import SwalError from "../../helpers/SwalError";
import Swal from "sweetalert2/dist/sweetalert2.js";

const userService = new UserServiceNew();

export default {
  name: "SettingsPharmacyInformation",
  components: { SettingsActionButtons, BaseInput, BaseSelect },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["update-success", "update-fail"],
  setup(props, { emit }) {
    const isSubmitting = ref(false);
    const isOptionsLoading = ref(false);
    const cities = ref([]);

    const form = useForm({
      validationSchema: {
        gln_no: yup.string().required(),
        pharmacy_name: yup.string().required(),
        city: yup.string().required(),
      },
      initialValues: {
        gln_no: props.user.pharmacy?.gln_no,
        pharmacy_name: props.user.pharmacy?.pharmacy_name,
        city: props.user.pharmacy?.city,
      },
    });

    function fetchOptions() {
      isOptionsLoading.value = true;
      RegisterService.getCities()
        .then((res) => {
          cities.value = res.data.data;
        })
        .catch((e) => {
          SwalError.fire(e);
        })
        .finally(() => {
          isOptionsLoading.value = false;
        });
    }

    async function handleSubmit() {
      isSubmitting.value = true;
      const { valid } = await form.validate();
      if (!valid) {
        isSubmitting.value = false;
        return;
      }

      try {
        await userService.updatePharmacyInformations(form.values);
        isSubmitting.value = false;
        emit("update-success");
      } catch (e) {
        isSubmitting.value = false;
        emit("update-fail");
        SwalError.fire(e);
      }
    }

    fetchOptions();
    if (!props.user.is_admin && !props.user.pharmacy?.gln_no)
      Swal.fire({
        text: "Devam edebilmek için lütfen Eczane Bilgileri bölümünden GLN No alanını doldurun.",
        icon: "info",
      });

    return {
      handleSubmit,
      form,
      isSubmitting,
      cities,
      isOptionsLoading,
    };
  },
};
</script>

<style scoped></style>

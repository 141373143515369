<template>
  <Footer />
</template>

<script>
import { computed } from "@vue/reactivity";
import Footer from "../layouts/DefaultLayout/Footer.vue";

export default {
  name: "GetAppPage",
  components: {
    Footer,
  },
  setup() {
    const isIphone = computed(() =>
      /iPhone|iPad|iPod/i.test(navigator.userAgent)
    );
    const isAndroid = computed(() =>
      /Android|webOS|Opera Mini/i.test(navigator.userAgent)
    );

    if (isIphone.value) {
      window.location.href = "https://apps.apple.com/tr/app/zone/id1573401266";
    } else if (isAndroid.value) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.epigra.zone";
    }

    return {
      isIphone,
      isAndroid,
    };
  },
};
</script>

<style></style>

<template>
  <div class="add-content mb-5">
    <page-intro :page-title="main_title" :page-nav="main_title" />

    <add-video-article
      v-if="path == 'addarticle' || path == 'addvideo'"
      v-model:title="title"
      v-model:content="content"
      v-model:files="files"
      :is-type="type"
      :color="color"
      :color-dark="colorDark"
      :is-sending="isSending"
      @send="send"
      @save="save"
    />
    <add-clinic
      v-else-if="path == 'addclinic'"
      v-model:title="title"
      v-model:content="content"
      v-model:files="files"
      :color="color"
      :color-dark="colorDark"
      :color-text="colorText"
      :is-sending="isSending"
      @send="send"
      @save="save"
    />
    <translation-request
      v-else-if="path == 'translation-request'"
      v-model:title="title"
      v-model:content="content"
      v-model:files="files"
      :is-sending="isSending"
      @send="send"
    />
    <clinical-key v-else-if="path == 'clinical-key'" />
    <statistics-support
      v-else-if="path == 'statistics-support'"
      v-model:title="title"
      v-model:content="content"
      v-model:files="files"
      :is-sending="isSending"
      @send="send"
    />
    <academic-book
      v-else-if="path == 'academic-book'"
      v-model:title="title"
      v-model:content="content"
      :is-sending="isSending"
      @send="send"
    />
    <redaction-request
      v-else-if="path == 'redaction-request'"
      v-model:title="title"
      v-model:content="content"
      v-model:files="files"
      :is-sending="isSending"
      @send="send"
    />
  </div>
</template>

<script>
import PageIntro from "../components/ui/PageIntro.vue";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ref, computed } from "vue";
import UserContentService from "../services/user-content/UserContentService";
import Swal from "sweetalert2/dist/sweetalert2";
import SwalError from "../helpers/SwalError";

import AddVideoArticle from "../components/ui/AddVideoArticle.vue";
import AddClinic from "../components/ui/AddClinic.vue";
import TranslationRequest from "../components/ui/TranslationRequest.vue";
import ClinicalKey from "../components/ui/ClinicalKey.vue";
import StatisticsSupport from "../components/ui/StatisticsSupport.vue";
import AcademicBook from "../components/ui/AcademicBook.vue";
import RedactionRequest from "../components/ui/RedactionRequest.vue";

export default {
  name: "AddContent",
  components: {
    PageIntro,
    AddVideoArticle,
    AddClinic,
    TranslationRequest,
    ClinicalKey,
    StatisticsSupport,
    AcademicBook,
    RedactionRequest,
  },
  setup() {
    const userContentService = new UserContentService();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const path = ref(route.name);
    const title = ref("");
    const content = ref("");
    const files = ref([]);
    const isSending = ref(false);

    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const color = ColorHelper[store.state.theme].blue300;
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;

    const main_title = computed(() => {
      if (path.value == "addarticle") {
        return "Yeni Makale";
      } else if (path.value == "addvideo") {
        return "Yeni Video";
      } else if (path.value == "addclinic") {
        return "Yeni Klinik";
      } else if (path.value == "translation-request") {
        return "Çeviri Talebi";
      } else if (path.value == "clinical-key") {
        return "CLINICAL KEY/ELSEVIER";
      } else if (path.value == "statistics-support") {
        return "İSTATİSTİK DESTEK TALEBİ";
      } else if (path.value == "academic-book") {
        return "Akademik Kitap/Dergi/Makale";
      } else if (path.value == "redaction-request") {
        return "BİLİMSEL YAYIN REDAKSİYON TALEBİ";
      }

      return "";
    });

    const type = computed(() => {
      if (path.value == "addarticle") {
        return "makale";
      } else if (path.value == "addvideo") {
        return "video";
      } else if (path.value == "addclinic") {
        return "klinik-tanitimi";
      } else if (path.value == "translation-request") {
        return "ceviri-talebi";
      } else if (path.value == "clinical-key") {
        return "clinical-key";
      } else if (path.value == "statistics-support") {
        return "istatistik-destek";
      } else if (path.value == "academic-book") {
        return "akademik-kitap-makale";
      } else if (path.value == "redaction-request") {
        return "redaksiyon";
      }

      return path.value;
    });

    const sendClinicContent = () => {
      const { questions, answers } = JSON.parse(content.value);
      const questionAnswers = ref("");

      isSending.value = true;

      questions.map((question) => {
        questionAnswers.value +=
          `<p>${question.title}<p><br>` + (answers[question.id] ?? "") + "<br>";
      });

      sendUserContent(
        type.value,
        title.value,
        questionAnswers.value,
        files.value
      ).finally(() => {
        isSending.value = false;
      });
    };

    const sendUserContent = (type, title, content, files) => {
      const fd = new FormData();
      fd.append("type", type);
      fd.append("title", title);
      fd.append("content", content);
      for (const file of files) {
        fd.append("files[]", file, file.name);
      }

      isSending.value = true;
      userContentService
        .create(fd)
        .then((response) => {
          Swal.fire({
            title: "Başarılı",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Tamam",
          }).then(() => {
            router.push({ name: "home" });
          });
        })
        .catch((error) => {
          SwalError.fire(error);
        })
        .finally(() => {
          isSending.value = false;
        });
    };

    const send = () => {
      if (type.value === "klinik-tanitimi") {
        sendClinicContent();
      } else {
        sendUserContent(type.value, title.value, content.value, files.value);
      }
      localStorage.removeItem(`${path.value}-save`);
    };

    const save = () => {
      localStorage.setItem(
        `${path.value}-save`,
        JSON.stringify({
          title: title.value,
          content: content.value,
        })
      );
      Swal.fire({
        title: "Kayıt",
        text: "Kayıt başarıyla yapıldı.",
        icon: "success",
        confirmButtonText: "Tamam",
      });
    };

    const fetchSavedData = () => {
      const savedData = localStorage.getItem(`${path.value}-save`);
      if (savedData) {
        const data = JSON.parse(savedData);
        title.value = data.title;
        content.value = data.content;
      }
    };

    fetchSavedData();

    return {
      title,
      content,
      files,
      send,
      save,
      isSending,
      main_img,
      color,
      colorDark,
      colorText,
      path,
      main_title,
      type,
    };
  },
};
</script>

<style lang="scss" scoped></style>

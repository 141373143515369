<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="APGAR Skoru Hesaplama Aracı"
        nav="APGAR Skoru Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            position-relative
            bg-white
            d-flex
            flex-column
            align-items-center
          "
        >
          <div
            v-if="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="
                      red-200
                      font-s-30px font-weight-bold
                      return-result-number
                    "
                  ></h4>
                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-result-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>

          <div
            class="d-flex flex-wrap justify-content-center mb-4 calculate-forms"
          >
            <div>
              <div class="mr-5 mb-4">
                <form class="apgar">
                  <h5 class="font-s-20px font-w-600 black-700">
                    Kalp atış hızı ve düzeni
                  </h5>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-1-1"
                      type="radio"
                      class="mr-1"
                      name="q1"
                      value="2"
                    />
                    <label class="text-secondary" for="apgar-1-1"
                      >100 atım / dakika veya daha fazla</label
                    >
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-1-2"
                      type="radio"
                      class="mr-1"
                      name="q1"
                      value="1"
                    />
                    <label class="text-secondary" for="apgar-1-2"
                      >100’den az</label
                    >
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-1-3"
                      type="radio"
                      class="mr-1"
                      name="q1"
                      value="0"
                    />
                    <label class="text-secondary" for="apgar-1-3">Yok</label>
                  </div>
                </form>
              </div>

              <div class="mr-5 mb-4">
                <form class="apgar">
                  <h5 class="font-s-20px font-w-600 black-700">
                    Akciğer Olgunluğu
                  </h5>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-2-1"
                      type="radio"
                      class="mr-1"
                      name="q2"
                      value="2"
                    />
                    <label class="text-secondary" for="apgar-2-1"
                      >Düzenli Nefes Alma</label
                    >
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-2-2"
                      type="radio"
                      class="mr-1"
                      name="q2"
                      value="1"
                    />
                    <label class="text-secondary" for="apgar-2-2"
                      >Düzensiz</label
                    >
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-2-3"
                      type="radio"
                      class="mr-1"
                      name="q2"
                      value="0"
                    />
                    <label class="text-secondary" for="apgar-2-3">Yok</label>
                  </div>
                </form>
              </div>
            </div>

            <div>
              <div class="mr-5 mb-4">
                <form class="apgar">
                  <h5 class="font-s-20px font-w-600 black-700">
                    Kas Tonusu ve Hareketi
                  </h5>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-3-1"
                      type="radio"
                      class="mr-1"
                      name="q3"
                      value="2"
                    />
                    <label class="text-secondary" for="apgar-3-1">Aktif</label>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-3-2"
                      type="radio"
                      class="mr-1"
                      name="q3"
                      value="1"
                    />
                    <label class="text-secondary" for="apgar-3-2">Orta</label>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-3-3"
                      type="radio"
                      class="mr-1"
                      name="q3"
                      value="0"
                    />
                    <label class="text-secondary" for="apgar-3-3">Gevşek</label>
                  </div>
                </form>
              </div>

              <div class="mr-5 mb-4">
                <form class="apgar">
                  <h5 class="font-s-20px font-w-600 black-700">
                    Cilt Rengi / Oksijenlenmesi
                  </h5>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-4-1"
                      type="radio"
                      class="mr-1"
                      name="q4"
                      value="2"
                    />
                    <label class="text-secondary" for="apgar-4-1">Pembe</label>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-4-2"
                      type="radio"
                      class="mr-1"
                      name="q4"
                      value="1"
                    />
                    <label class="text-secondary" for="apgar-4-2"
                      >Mavimsi ekstremiteler</label
                    >
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-4-3"
                      type="radio"
                      class="mr-1"
                      name="q4"
                      value="0"
                    />
                    <label class="text-secondary" for="apgar-4-3"
                      >Tamamen Mavi</label
                    >
                  </div>
                </form>
              </div>
            </div>

            <div>
              <div class="mr-5 mb-4">
                <form class="apgar">
                  <h5 class="font-s-20px font-w-600 black-700">
                    Uyaranlara Refleks Tepki
                  </h5>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-5-1"
                      type="radio"
                      class="mr-1"
                      name="q5"
                      value="2"
                    />
                    <label class="text-secondary" for="apgar-5-1">Ağlama</label>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-5-2"
                      type="radio"
                      class="mr-1"
                      name="q5"
                      value="1"
                    />
                    <label class="text-secondary" for="apgar-5-2"
                      >Mırıldanma</label
                    >
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      id="apgar-5-3"
                      type="radio"
                      class="mr-1"
                      name="q5"
                      value="0"
                    />
                    <label class="text-secondary" for="apgar-5-3"
                      >Sessizlik</label
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius btn-calculate"
              @click="showResult = true"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import { ref } from "vue";
import $ from "jquery";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "CalculateScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    $(document).ready(function () {
      $(".btn-calculate").on("click", function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
        $(".return-result-text").slideDown();
        var total = 0;
        var qcount = 0;
        var q1 = $('.apgar input[name="q1"]:checked').val();
        if (q1) {
          total += parseInt(q1);
          qcount++;
        }
        var q2 = $('.apgar input[name="q2"]:checked').val();
        if (q2) {
          total += parseInt(q2);
          qcount++;
        }
        var q3 = $('.apgar input[name="q3"]:checked').val();
        if (q3) {
          total += parseInt(q3);
          qcount++;
        }
        var q4 = $('.apgar input[name="q4"]:checked').val();
        if (q4) {
          total += parseInt(q4);
          qcount++;
        }
        var q5 = $('.apgar input[name="q5"]:checked').val();
        if (q5) {
          total += parseInt(q5);
          qcount++;
        }
        if (qcount == 5) {
          let returnText;
          if (total > 6) {
            returnText = "Normal";
          } else {
            returnText = "Sıkıntılı";
          }
          returnData(total, null, returnText);
        } else {
          showReturnHtml("T&uuml;m se&ccedil;imleri yapmalısınız");
        }
      });
    });

    const showReturnHtml = (text) => {
      $(".return-result-text").html(text + " ");
    };

    const clearAll = () => {
      $(".apgar").each(function (i, form) {
        form.reset();
      });
      $(".return-result-text").slideUp();
    };

    $(document).ready(function () {
      $("form").submit(function (event) {
        event.preventDefault();
        $(".btn-calculate").click();
      });
      $(".calc_add").click(function () {
        var c = $(this).prev("input").val();
        $(this)
          .prev("input")
          .val(parseInt(c) + 1);
      });
      $(".calc_int").click(function () {
        var c = $(this).closest("div").find("input");
        if (c.val()) {
          c.val(parseInt(c.val()) - 1);
        }
      });
    });

    const returnData = (data = null, string = null, type = "") => {
      if (!data || data < 0) {
        data = -1;
      } else {
        data = parseFloat(data);
      }
      if (!string) {
        string = -1;
      }
      if (data == -1 && string == -1) {
        string = "-";
      }
      if (type) {
        if (data > 0) {
          $(".return-result-number").html(data);
          $(".return-result-text").html(type);
        } else {
          $(".return-result-text").html(type);
        }
      } else {
        if (data > 0) {
          $(".return-result-text").html(type);
        }
      }
    };

    return {
      clearAll,
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      apgar: [
        {
          title: "Apgar Skoru Nedir, Nasıl Yapılır?",
          content: ` <p>Apgar skoru; yeni doğmuş bebeklerin 1. ve 5. dakikasında cilt tonu, kalp hızı, kas tonusu ve solunum ölçüldüğü testtir. Doğumun ardından uygulanan bu test, olası hastalıkların ve sorunların gözden kaçmaması için uygulanır. Bebeğin gelişimi hakkında bilgi vermesinin yanı sıra acil müdahale gerektiren durumları da gösterir.</p>
            <p>Apgar değerlendirmesinde solunum tipi, cilt rengi, kalp hızı, refleks yanıtı ve hareketliliği ölçülür. Yapılan ölçümler 0 - 2 puan olacak şekilde değerlendirilir. Değerlendirme sonuçlarına göre bebeğe müdahale gerekip gerekmediğine bakılır. Özel durumlarda test 10. dakika da tekrarlanabilir.</p>
        `,
        },
        {
          title: "Apgar Skorlaması Neden Önemlidir?",
          content: `<p>Apgar skoru ile bebeğin doğumu uygun bir şekilde atlatıp atlatmadığı gözlemlenir. Aynı zamanda yeni doğanların anne karnı dışındaki hayata verdiği tepkiler ve adaptasyonu ölçülür. Çıkan sonuçlara göre doğumdan sonra oluşabilecek komplikasyonlar değerlendirilir. Özellikle kalp, sinir hücreleri ve kas sisteminde görülebilecek hastalıkların erkenden teşhis edilmesi ve uygun tedavinin belirlenmesi sağlanır.</p>
        `,
        },
        {
          title: "Apgar Skorunu Etkileyen Faktörler Nelerdir?",
          content: `
        <p>Apgar skorunda anneye bağlı olarak; hamilelik sürecinde sigara, alkol ve uyuşturucu kullanımı, damar tıkanıklığı, hipertansiyon, rahim hastalıkları ve doğumun uzaması gibi faktörler puanı olumsuz etkiler. Bebeğe bağlı olarak; doğumsal anomaliler, kas ve sinir hastalıkları, akciğer enfeksiyonları ve plasentanın önceden ayrılması skoru değiştiren ölçütlerdir. Doğum esnasında rahmin yırtılması, göbek kordonunun bebeğe dolanması ve bebeğin vakumla çıkartılması skoru düşürebilir.</p>
        `,
        },
        {
          title:
            "Apgar Skorlaması ve Hesaplama Ölçeği Hangi Etmenlere Göre Belirlenir?",
          content: `<ul>
                <li>Kas tonusunu ölçerken bebeğin bükülü bir pozisyonda olması 1, hareketli olması ise 2 puan olarak değerlendirilir.</li>
                <li>Reflekslerini ölçmek için burun ucuna kateter sokulur. Bebek yüzünü buruşturursa 1, ağlama veya öksürme olursa 2 puan verilir.</li>
                <li>Bebeğin kalp hızı dakikada 100 atımın altındaysa 1, üstünde ise 2 puan daha yazılır.</li>
                <li>Bebeğin vücudu pembe, kol ve bacak bölgeleri morarmalar oluştuysa 1, tüm vücudu pembe ise 2 puan olarak hesaplanır.</li>
                <li>Bebeğin solunumu düzensizse ve cılız bir şekilde ağlıyorsa 1, düzenli soluk alıp kuvvetli ağlıyorsa 2 puan ölçüme eklenir. Test sonunda hesaplamalar yapılarak sonuç değerlendirilir.</li>
            </ul>
        `,
        },
        {
          title: "Apgar Testi Nasıl Değerlendirilir?",
          content: `
            <ul>
                <li>Testin sonunda 0-3 puan aralığında olan bebeklere acil müdahale uygulanır. Özellikle solunum ve kalp hızını normale döndürmek için uygun tedavi yöntemlerine başvurulur. Sorunu tedavi ettikten sonra test tekrar uygulanabilir.</li>
                <li>3-6 puan aralığında olan bebekler sorunun tespit edilmesi için, yakından takibe alınır. Soruna göre basit müdahalelerle bebek normal seviyeye getirilir.</li>
                <li>Puan aralığı 7-10 arasında ise, değerlendirme bebeğin sağlıklı olduğunu gösterir. Böylece normal kontrollere devam edilir.</li>
            </ul>
            <p>Anestezi doktoru Virginia Apgar tarafından geliştirilen apgar testi, 1952 yılında yapılan çalışmalarla standart hale getirilmiştir. Basit uygulamalarla yapılan bu test, bebeğin sağlığı hakkında genel bilgiler verir.</p>
        `,
        },
      ],
      references: [
        "http://tmftp.org/webkontrol/uploads/files/Apgar%20Skoru.pdf",
        "https://tiplopedi.com/Apgar_skoru",
        "http://www.cocukgelisimi.com/apgar-testi-nedir",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <div class="favorites mb-5">
    <section class="favorites-main bg-image d-flex flex-column mb-5">
      <content-main
        title="Kayıtlı İçerikler"
        nav="Kaydettiklerim"
        class="mt-m-100px mt-160px"
      />
    </section>

    <section lass="mb-4 mb-md-0">
      <div class="container-xl">
        <h4 class="font-s-24px font-weight-bold text-info mb-4 section-title">
          {{ theme == "eczazone" ? "Haber" : "Makale" }}
        </h4>
        <template v-if="!isFavoritedPostsLoading && posts.items.length">
          <div class="row">
            <div
              v-for="(post, i) in posts.items"
              :key="i"
              class="col-12 col-sm-6 col-md-4"
            >
              <article-sm :post="post" :color="color" class="mb-3 mb-md-5" />
            </div>
          </div>
          <pagination
            :total-pages="posts.last_page"
            :current-page="posts.current_page"
            @go-page="fetchFavoritedPosts($event)"
          />
        </template>
        <loading-anim-icon v-else-if="isFavoritedPostsLoading" />
        <p v-else>Sonuç bulunamadı</p>
      </div>
    </section>

    <section class="mb-4 mb-md-0">
      <div class="container-xl">
        <h4 class="font-s-24px font-weight-bold text-info mb-4 section-title">
          Videolar
        </h4>
        <template v-if="!isFavoritedVideosLoading && videos.items.length">
          <div class="row">
            <div
              v-for="(video, i) in videos.items"
              :key="i"
              class="col-12 col-sm-6 col-md-4"
            >
              <videos-card :video="video" class="mb-3 mb-md-5" />
            </div>
          </div>
          <pagination
            :total-pages="videos.last_page"
            :current-page="videos.current_page"
            @go-page="fetchFavoritedVideos($event)"
          />
        </template>
        <loading-anim-icon v-else-if="isFavoritedVideosLoading" />
        <p v-else>Sonuç bulunamadı</p>
      </div>
    </section>

    <section class="mb-4 mb-md-0">
      <div class="container-xl">
        <h4 class="font-s-24px font-weight-bold text-info mb-4 section-title">
          Etkinlikler
        </h4>
        <template v-if="!isFavoritedEventsLoading && events.items.length">
          <div>
            <event-card
              v-for="(event, i) in events.items"
              :key="i"
              :event="event"
            />
          </div>
          <pagination
            :total-pages="events.last_page"
            :current-page="events.current_page"
            @go-page="fetchFavoritedEvents($event)"
          />
        </template>
        <loading-anim-icon v-else-if="isFavoritedEventsLoading" />
        <p v-else>Sonuç bulunamadı</p>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../components/ui/ContentMain.vue";
import ArticleSm from "../components/ui/ArticleSm.vue";
import VideosCard from "../components/ui/VideosCard.vue";
import Pagination from "../components/ui/Pagination.vue";
import EventCard from "../components/ui/EventCard.vue";
import ColorHelper from "../helpers/ColorHelper";
import FavoriteService from "../services/favorite/FavoriteService";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "Favorites",
  components: {
    ContentMain,
    ArticleSm,
    VideosCard,
    Pagination,
    EventCard,
  },
  setup() {
    const store = useStore();
    const theme = ref(store.state.theme);
    const color = ColorHelper[store.state.theme].blueText;
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const posts = ref([]);
    const videos = ref([]);
    const events = ref([]);
    const isFavoritedPostsLoading = ref(false);
    const isFavoritedVideosLoading = ref(false);
    const isFavoritedEventsLoading = ref(false);

    const fetchFavoritedPosts = (page = 1) => {
      isFavoritedPostsLoading.value = true;
      FavoriteService.getFavoritedItemsByType("post", page, 6)
        .then((response) => {
          posts.value = response.data.data;
        })
        .finally(() => {
          isFavoritedPostsLoading.value = false;
        });
    };

    const fetchFavoritedVideos = (page = 1) => {
      isFavoritedVideosLoading.value = true;
      FavoriteService.getFavoritedItemsByType("video", page, 6)
        .then((response) => {
          videos.value = response.data.data;
        })
        .finally(() => {
          isFavoritedVideosLoading.value = false;
        });
    };

    const fetchFavoritedEvents = (page = 1) => {
      isFavoritedEventsLoading.value = true;
      FavoriteService.getFavoritedItemsByType("events", page, 6)
        .then((response) => {
          events.value = response.data.data;
        })
        .finally(() => {
          isFavoritedEventsLoading.value = false;
        });
    };

    fetchFavoritedPosts();
    fetchFavoritedVideos();
    fetchFavoritedEvents();

    return {
      isFavoritedPostsLoading,
      isFavoritedVideosLoading,
      isFavoritedEventsLoading,
      fetchFavoritedPosts,
      fetchFavoritedVideos,
      fetchFavoritedEvents,
      posts,
      videos,
      events,
      color,
      main_img,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.favorites-main {
  background: v-bind("main_img");
  background-attachment: fixed;
  width: 100vw;
  height: 300px;
}
</style>

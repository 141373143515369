<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="7"
      y="11"
      width="76"
      height="69"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 11.25H82.5514V79.4456H7.5V11.25Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.047 16.875C43.262 16.875 41.672 17.7975 40.7795 19.3462L13.787 66.465C12.9095 68.0025 12.917 69.8362 13.8057 71.37C14.6945 72.9037 16.2845 73.8225 18.0582 73.8225H71.9945C73.7645 73.8225 75.3545 72.9037 76.2432 71.37C77.1357 69.8362 77.1432 68.0025 76.2582 66.465L49.3145 19.3462C48.4257 17.7975 46.8357 16.875 45.047 16.875ZM71.9943 79.4475H18.0581C14.2556 79.4475 10.8468 77.4825 8.9381 74.19C7.02935 70.9013 7.0181 66.9675 8.90435 63.6712L35.9043 16.5487C37.8018 13.23 41.2181 11.25 45.0468 11.25H45.0506C48.8756 11.25 52.2994 13.2337 54.1968 16.5562L81.1443 63.6712C83.0306 66.9675 83.0193 70.9013 81.1106 74.19C79.2019 77.4825 75.7931 79.4475 71.9943 79.4475Z"
        :fill="color"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.0137 53.1149C43.4612 53.1149 42.2012 51.8549 42.2012 50.3024V38.6774C42.2012 37.1249 43.4612 35.8649 45.0137 35.8649C46.5662 35.8649 47.8262 37.1249 47.8262 38.6774V50.3024C47.8262 51.8549 46.5662 53.1149 45.0137 53.1149Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.0227 65.6213C42.9489 65.6213 41.2539 63.9451 41.2539 61.8713C41.2539 59.7976 42.9152 58.1213 44.9852 58.1213H45.0227C47.0964 58.1213 48.7727 59.7976 48.7727 61.8713C48.7727 63.9451 47.0964 65.6213 45.0227 65.6213Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "ReportImgIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

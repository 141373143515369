import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  like(user_id, likeable_type, likeable_id) {
    return apiClient.post("/api/likes/update", {
      user_id,
      likeable_type,
      likeable_id,
    });
  },
};

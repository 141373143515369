<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1671 60.0302C22.0066 65.5823 28.7806 67.8262 33.595 64.1376C34.9282 63.1156 36.1578 60.8409 34.4095 59.5999C33.8485 59.2041 33.1646 59.0158 32.5191 58.7853C31.1666 58.305 30.1061 57.3291 29.9486 55.8344C29.6528 53.0487 32.277 51.1353 34.8744 51.7001C36.5343 52.0613 37.7523 53.3561 38.5784 54.7701C41.2334 59.3232 40.05 65.736 35.9503 69.0481C31.8698 72.3448 25.7221 72.3025 21.1344 69.7512C16.5505 67.1961 13.4229 62.5354 11.7361 57.5673C11.617 57.2215 11.5056 56.8757 11.4019 56.526C11.4019 56.5222 11.398 56.5145 11.398 56.5068C10.5258 53.5905 10.1762 50.4782 10.107 47.462C10.1032 47.2738 10.0993 47.0817 10.1032 46.8934C10.0993 46.7051 10.1032 46.513 10.107 46.3247C10.1723 43.3085 10.5258 40.1963 11.398 37.2723C11.398 37.2646 11.4019 37.2569 11.4019 37.2531C11.5056 36.9034 11.617 36.5576 11.7361 36.2118C13.4191 31.2437 16.5467 26.583 21.1344 24.0279C25.7182 21.4766 31.8659 21.4343 35.9503 24.731C40.05 28.0431 41.2334 34.4559 38.5784 39.009C37.7523 40.4268 36.5305 41.7178 34.8744 42.079C32.277 42.6438 29.6528 40.7303 29.9486 37.9447C30.1061 36.45 31.1705 35.4779 32.5191 34.9938C33.1685 34.7633 33.8485 34.575 34.4095 34.1792C36.1578 32.9382 34.9282 30.6635 33.595 29.6415C28.7844 25.9567 22.0066 28.1968 19.1671 33.7489"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5664 42.0444C17.7163 43.3085 18.2964 44.2807 19.1341 45.0222C20.9323 46.6168 23.9561 46.7167 26.6957 46.8435L35.168 46.7666"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.505 35.5894C26.0324 36.2003 24.9258 37.8102 24.9028 40.0618C24.8759 42.444 26.0785 44.2422 27.1236 45.3526C27.7038 45.9712 28.5607 46.5092 29.3483 46.8204"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.9845 58.6777C25.7693 58.1244 25.485 57.1984 25.4158 56.0419C25.4158 56.0381 25.4158 56.0381 25.4158 56.0381C25.3812 55.5232 25.3966 54.9661 25.4773 54.3743C25.5887 53.5713 25.7885 52.8336 26.0652 52.165C26.069 52.1612 26.069 52.1573 26.069 52.1573C27.5598 48.5879 31.2599 46.8742 35.1676 46.7743H40.7043C44.9808 46.7743 48.4504 50.2439 48.4504 54.5204V54.5396"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.1914 42.175H39.8549H44.1353C45.0651 42.175 45.8143 41.4219 45.8143 40.4959V33.3877"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.8125 51.6079H40.8247C43.0148 51.6079 44.7899 53.3831 44.7899 55.5732V73.3092"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M77.7278 33.3877C74.8883 27.8356 68.1144 25.5917 63.3 29.2803C61.9667 30.3023 60.7372 32.577 62.4854 33.818C63.0464 34.2138 63.7303 34.4021 64.3758 34.6326C65.7283 35.1129 66.7888 36.0888 66.9463 37.5835C67.2422 40.3691 64.6179 42.2826 62.0205 41.7178C60.3606 41.3566 59.1426 40.0618 58.3165 38.6478C55.6615 34.0947 56.8449 27.6819 60.9447 24.3698C65.0252 21.0731 71.1728 21.1154 75.7605 23.6667C80.3444 26.2218 83.472 30.8825 85.1588 35.8506C85.2779 36.1964 85.3893 36.5422 85.4931 36.8919C85.4931 36.8957 85.4969 36.9034 85.4969 36.9111C86.3691 39.8351 86.7226 42.9473 86.7879 45.9635C86.7918 46.1518 86.7956 46.3439 86.7918 46.5322C86.7956 46.7205 86.7918 46.9126 86.7879 47.1008C86.7226 50.117 86.3691 53.2293 85.4969 56.1533C85.4969 56.161 85.4931 56.1687 85.4931 56.1725C85.3893 56.5222 85.2779 56.868 85.1588 57.2138C83.4759 62.1819 80.3482 66.8426 75.7605 69.3977C71.1767 71.949 65.029 71.9912 60.9447 68.6946C56.8449 65.3825 55.6615 58.9697 58.3165 54.4166C59.1426 52.9988 60.3645 51.7078 62.0205 51.3466C64.6179 50.7818 67.2422 52.6952 66.9463 55.4809C66.7888 56.9755 65.7245 57.9476 64.3758 58.4318C63.7265 58.6623 63.0464 58.8506 62.4854 59.2463C60.7372 60.4874 61.9667 62.762 63.3 63.7841C68.1105 67.4689 74.8883 65.2288 77.7278 59.6767"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M61.7266 46.4054L70.1988 46.4823C72.9384 46.3555 75.9623 46.2556 77.7605 44.661C78.6019 43.9156 79.1783 42.9473 79.3281 41.6832"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.3941 35.2244C70.8667 35.8353 71.9733 37.4452 71.9963 39.6968C72.0232 42.079 70.8206 43.8772 69.7755 44.9876C69.1953 45.6063 68.3385 46.1442 67.5508 46.4554"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.4492 73.3092V54.5434C48.4492 50.29 51.7152 46.6437 55.9647 46.4208C56.1146 46.4131 56.2644 46.4093 56.4143 46.4093H61.7282C65.632 46.5054 69.3321 48.2229 70.8267 51.7924C70.8267 51.7924 70.8267 51.7962 70.8306 51.8C71.1072 52.4686 71.307 53.2063 71.4185 54.0094C71.4991 54.6011 71.5145 55.1621 71.4799 55.6731C71.4799 55.6731 71.4799 55.6731 71.4799 55.6769C71.4108 56.8334 71.1264 57.7556 70.9113 58.3127"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.7053 41.8062H57.0418H52.3119C51.3821 41.8062 50.6328 41.0531 50.6328 40.1271V33.3877"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.079 51.2428H56.0668C53.8767 51.2428 52.1016 53.0179 52.1016 55.2081V73.3091"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.8203 40.4921V43.2124"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.6328 40.1271V43.2124"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UrogenitalSistem",
};
</script>

<style></style>

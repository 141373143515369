<template>
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4394 36.3167C28.386 36.3167 34.828 29.8749 34.828 21.9286C34.828 13.9823 28.386 7.54053 20.4394 7.54053C12.4928 7.54053 6.05078 13.9823 6.05078 21.9286C6.05078 29.8749 12.4928 36.3167 20.4394 36.3167Z"
      fill="#18BEFF"
    />
    <path
      d="M22.8071 21.5079L19.7902 18.4912L22.8071 15.4745C22.9722 15.3016 23.0632 15.0709 23.0605 14.8318C23.0577 14.5927 22.9615 14.3642 22.7924 14.1951C22.6233 14.026 22.3948 13.9298 22.1557 13.9271C21.9166 13.9243 21.6859 14.0153 21.513 14.1805L18.4961 17.1945L15.4793 14.1778C15.3064 14.0126 15.0757 13.9216 14.8366 13.9244C14.5975 13.9271 14.3689 14.0233 14.1999 14.1924C14.0308 14.3615 13.9346 14.59 13.9318 14.8291C13.9291 15.0682 14.0201 15.2989 14.1852 15.4718L17.2021 18.4885L14.1839 21.5079C14.0951 21.5907 14.0242 21.6908 13.9758 21.8021C13.9273 21.9135 13.9023 22.0336 13.9023 22.155C13.9023 22.2763 13.9273 22.3964 13.9758 22.5078C14.0242 22.6191 14.0951 22.7192 14.1839 22.802C14.2684 22.8877 14.3691 22.9558 14.4802 23.0023C14.5913 23.0488 14.7105 23.0727 14.8309 23.0727C14.9513 23.0727 15.0705 23.0488 15.1816 23.0023C15.2927 22.9558 15.3934 22.8877 15.478 22.802L18.4948 19.7853L21.5116 22.802C21.6838 22.9725 21.9163 23.0682 22.1587 23.0682C22.401 23.0682 22.6336 22.9725 22.8057 22.802C22.8916 22.7175 22.9598 22.6169 23.0064 22.5059C23.0529 22.3948 23.077 22.2756 23.0771 22.1552C23.0773 22.0348 23.0534 21.9156 23.0071 21.8045C22.9607 21.6934 22.8927 21.5926 22.8071 21.5079V21.5079Z"
      fill="white"
    />
    <path
      d="M18.5014 2.48967C22.2035 2.49106 25.7907 3.77593 28.6518 6.12538C31.5128 8.47484 33.4708 11.7435 34.1921 15.3746C34.9135 19.0057 34.3535 22.7746 32.6077 26.0391C30.8618 29.3037 28.0381 31.862 24.6175 33.2782C21.197 34.6944 17.3912 34.881 13.8485 33.806C10.3058 32.7311 7.24545 30.4612 5.18871 27.383C3.13197 24.3048 2.20611 20.6088 2.56886 16.9246C2.93161 13.2403 4.56054 9.79583 7.17813 7.17785C8.66184 5.68674 10.4266 4.50473 12.3702 3.70023C14.3139 2.89573 16.3978 2.48473 18.5014 2.49102V2.48967ZM18.5014 0.00134791C14.8423 0.0010813 11.2654 1.08582 8.22294 3.11839C5.18045 5.15096 2.80905 8.04007 1.40862 11.4204C0.00819606 14.8006 -0.358358 18.5203 0.355316 22.1089C1.06899 25.6975 2.83084 28.9939 5.41806 31.5812C8.00527 34.1685 11.3017 35.9305 14.8903 36.6444C18.479 37.3583 22.1988 36.9921 25.5793 35.5919C28.9598 34.1918 31.8492 31.8207 33.8821 28.7785C35.915 25.7363 37 22.1595 37 18.5007C37.0005 16.0712 36.5224 13.6654 35.593 11.4208C34.6636 9.1761 33.301 7.13651 31.5832 5.41849C29.8654 3.70047 27.8259 2.33769 25.5813 1.40797C23.3367 0.478256 20.9309 -0.000177058 18.5014 4.91533e-08V0.00134791Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseModalIcon",
};
</script>

<style></style>

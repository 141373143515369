<template>
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9414 21.8335L30.1261 32.252L33.5481 26.6767L36.165 40.1199L39.587 34.5446L41.5763 44.465"
      stroke="#7684AA"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M52.4974 10.4728V11.481C52.4974 15.718 53.3914 18.3407 54.9129 21.5755C55.8543 23.5679 58.708 23.3159 59.3237 21.1914C60.1289 18.3887 62.1004 15.478 66.7717 15.2379C69.3234 15.1059 71.8692 15.8021 74.0302 17.1824C78.4291 19.9791 85.7764 28.4831 82.6563 44.393C82.6563 44.393 82.307 46.4815 81.3005 49.6142C81.3005 49.6142 77.9436 62.4453 67.1151 71.0154C66.0553 71.8556 64.8949 72.6057 63.6753 73.3079C51.9468 80.0535 38.383 77.4909 29.0464 67.6306C28.1465 66.6763 27.2406 65.6381 26.3526 64.5158C25.0619 62.8774 22.451 63.6696 22.2497 65.7581C21.9832 68.5728 22.0306 83.6904 22.0306 86.211"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M77.1211 30.6976C75.5877 24.8702 71.923 22.3376 71.325 21.9535C70.1587 21.2093 68.8029 20.8132 67.4648 20.8132"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M27.1484 41.8483V50.3584C27.1484 51.8107 28.0543 52.975 29.2384 53.7852C32.1039 55.7357 35.0523 56.5639 35.0523 56.5639C43.8027 58.8444 51.2093 53.5452 52.044 45.2152C52.6657 38.9977 49.4982 33.4403 49.4982 33.4403C44.01 23.0458 43.8264 13.0054 43.8264 13.0054V10.4728"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M13.5898 86.2111V63.9097C13.5898 58.7845 16.6981 55.6637 18.6992 54.1874C19.7175 53.4372 20.3333 52.2489 20.3333 50.9766V41.8484"
      stroke="#7684AA"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "GastroenterolojiEnfeksiyon",
};
</script>

<style></style>

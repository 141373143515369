<template>
  <svg
    width="116"
    height="111"
    viewBox="0 0 116 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="path-fill"
      d="M97.3655 13.1791V102.73H97.3425V103.128H91.2227V13.1791H97.3655Z"
      fill="#F3F4F5"
    />
    <path
      class="path-fill"
      d="M97.365 98.1309H26.0156V102.73H97.365V98.1309Z"
      fill="#F3F4F5"
    />
    <path
      class="path-fill"
      d="M106.472 34.7102C107.311 34.7102 107.992 34.0307 107.992 33.1925C107.992 32.3543 107.311 31.6749 106.472 31.6749C105.632 31.6749 104.951 32.3543 104.951 33.1925C104.951 34.0307 105.632 34.7102 106.472 34.7102Z"
      fill="#7684AA"
    />
    <path
      class="path-fill"
      d="M10.5204 54.4784C11.36 54.4784 12.0407 53.7989 12.0407 52.9607C12.0407 52.1225 11.36 51.443 10.5204 51.443C9.68069 51.443 9 52.1225 9 52.9607C9 53.7989 9.68069 54.4784 10.5204 54.4784Z"
      fill="#7684AA"
    />
    <path
      d="M97.3423 103.128H91.2455H91.2225H25.7549V98.5678H26.016H91.2225H91.2455"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M97.3419 103.128V102.73V98.5678V98.1309V13.432H91.2451V98.1309V98.5678V102.73V103.128"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.167 27.1142H80.5795"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.167 36.2356H80.5795"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.167 49.9177H82.1076"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.2457 13.432V8.87132H19.6582V98.5754H25.755"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.749 39.2786H34.1318C32.0279 39.2786 30.3232 37.5769 30.3232 35.4767V27.873C30.3232 25.7728 32.0279 24.0711 34.1318 24.0711H41.749C43.8529 24.0711 45.5576 25.7728 45.5576 27.873V35.4767C45.5499 37.5769 43.8452 39.2786 41.749 39.2786Z"
      stroke="#AFBBDB"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.167 59.0391H71.442"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.167 72.7289H65.9826"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.167 80.3249H63.8326"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.749 62.0821H34.1318C32.0279 62.0821 30.3232 60.3805 30.3232 58.2802V50.6765C30.3232 48.5763 32.0279 46.8746 34.1318 46.8746H41.749C43.8529 46.8746 45.5576 48.5763 45.5576 50.6765V58.2802C45.5499 60.3805 43.8452 62.0821 41.749 62.0821Z"
      stroke="#AFBBDB"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.749 84.8857H34.1318C32.0279 84.8857 30.3232 83.184 30.3232 81.0838V73.48C30.3232 71.3798 32.0279 69.6782 34.1318 69.6782H41.749C43.8529 69.6782 45.5576 71.3798 45.5576 73.48V81.0838C45.5499 83.184 43.8452 84.8857 41.749 84.8857Z"
      stroke="#AFBBDB"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5205 57.5214V98.5678C10.5205 102.937 13.6073 106.593 17.7153 107.482"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M106.472 28.6318V13.432C106.472 9.06295 103.385 5.40672 99.2773 4.51758"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "QuestionnaireImg",
};
</script>

<style></style>

<template>
  <div class="position-absolute d-flex mr-2" :class="card">
    <div class="d-flex flex-column mr-3" :class="content">
      <a
        class="d-flex mb-3 cursor-pointer"
        :class="close"
        @click="sendShowSSS(0)"
      >
        <close-circle-icon class="mr-1 d-flex align-items-center" />
        <span class="text-white font-s-12px font-w-500">Kapat</span>
      </a>
      <div class="mb-2">
        <p class="text-center text-white font-s-18px font-w-500">
          {{ text }}
        </p>
      </div>
      <div>
        <button
          v-if="showSSS != itemSize"
          class="
            btn
            home-info-card-bgRed
            mb-3
            text-white
            border-radius
            d-flex
            justify-content-center
            align-items-center
          "
          :class="showSSS == 1 || showSSS == 2 ? 'btn-next' : 'btn-next-big'"
          @click="sendShowSSS(showSSS + 1)"
        >
          <span class="font-s-12px font-w-600 mr-1">Sonraki</span>
          <right-arrow-icon color="white" />
        </button>
        <button
          v-if="showSSS != 1"
          :class="showSSS == 1 || showSSS == 2 ? 'btn-prev' : 'btn-prev-big'"
          class="btn border-radius"
          @click="sendShowSSS(showSSS - 1)"
        >
          <left-arrow-icon color="white" class="mr-1" />
          <span class="font-s-12px font-w-600 text-white">Önceki</span>
        </button>
      </div>
    </div>
    <div :class="arrow">
      <line-arrow-icon :show="arrowShow" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SssCard",
  components: {},
  props: {
    showSSS: {
      type: Number,
      required: true,
    },
    showNavS: {
      type: Number,
      required: false,
      default: 3,
    },
    arrowShow: {
      type: Number,
      required: false,
      default: 0,
    },
    text: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
      default: "",
    },
    arrow: {
      type: String,
      required: false,
      default: "",
    },
    close: {
      type: String,
      required: false,
      default: "",
    },
    card: {
      type: String,
      required: false,
      default: "",
    },
    itemSize: {
      type: Number,
      required: false,
      default: 100,
    },
  },
  emits: ["showSSS"],
  methods: {
    sendShowSSS(index) {
      this.$emit("showSSS", index);
      if (this.showSSS == 3) {
        window.scrollTo(0, 1600);
      }
      if (this.showSSS == 2 || this.showSSS == 4) {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-next,
.btn-prev {
  width: 282px;
  height: 42px;
}

.btn-next-big,
.btn-prev-big {
  width: 355px;
  height: 42px;
}

.btn-prev,
.btn-prev-big {
  border: 1px solid #fff;
  background-color: transparent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>

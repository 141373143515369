import ApiService from "../ApiService";

export default class UserContentService extends ApiService {
  collection = "user-contents";
  getClinicQuestions() {
    return this.client.get("/api/user-contents/klinik-tanitimi/questions");
  }
  getRemainingAndLimits(type) {
    return this.client.get(`/api/user-contents/remaining-and-limits/${type}`);
  }
}

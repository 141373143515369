<template>
  <div>
    <!-- Modal -->
    <div
      id="adverse_modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="adverse_modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="mb-0">DOKTORLAR İÇİN AÇIK RIZA METNİ</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body fs-13">
            <p>
              Santa Farma İlaç Sanayi A.Ş. (“Şirket veya Santa Farma”)
              bünyesinde bulunan şahsıma ait her türlü kişisel verinin 6698
              sayılı Kişisel Verilerin Korunması Kanunu ve sair mevzuat
              kapsamında öngörülen düzenlemelere tabi olduğunu ve bu hususta
              Şirket’in tarafıma ilgili kanun ve sair mevzuat kapsamındaki
              haklarımla ilgili olarak tam ve açık bir bilgilendirme yaptığını
              kabul ve beyan ederim. İlgili bilgilendirme kapsamında, Şirket
              mevzuat kapsamında veri güvenliğine ilişkin yükümlülüklerini tam
              ve eksiksiz olarak yerine getireceğini taahhüt eder, buna
              istinaden kişisel verilerimin kanuna aykırı olarak işlenmesi ve
              ele geçirilmesi sebebiyle zarara uğramam hâlinde zararın
              giderilmesini talep etme hakkımı saklı tutarım.
            </p>

            <p>
              Şahsıma ait kişisel veriler Şirket tarafından görevlendirilmiş
              tanıtım mümessilleri dahil tüm Şirket çalışanları ile yüz yüze
              iletişimde, internet üzerinden yapılan aramalarda veya üçüncü
              kişiler vasıtası ile;
            </p>
            <ul>
              <li>
                Tanıtım ve tanıtım amaçlı olmayan bilimsel yönde bilgilendirme
                metodlarının hepsi,
              </li>
              <li>Sağlık meslek mensubu listelerinin oluşturulması,</li>
              <li>
                Ürün, hizmet, tanıtım süreçleri ile ilgili olarak, pazar
                araştırma uygulamalarının dahil olduğu, tanıtım faaliyetlerinin
                planlanması ve icrası,
              </li>
              <li>Ziyaret verilerinin tutulması,</li>
              <li>Yan etki bildirimlerinin alınması,</li>
              <li>
                Yurt içi ve yurt dışı kongrelerin planlanması ve yapılması,
              </li>
              <li>
                Dijital platformlarımıza üyelik oluşturulması amacıyla
                toplanmaktadır.
              </li>
            </ul>
            <p>
              Şirket tarafından web sayfasında yayınlanan Kişisel Verilerin
              Korunması ve İşlenmesi Politikalarına uygun olarak ve yukarıdaki
              Aydınlatma Metni kapsamında, mevzuata uygun şekilde e-posta/mail,
              SMS/kısa mesaj, otomatik arama, telefonla arama ve diğer
              elektronik iletişim kanalları arasından tercihime uygun yol ile
              Santa Farma tarafından tarafıma ticari elektronik iletiler ve
              diğer iletiler gönderilmesine, özel nitelikli kişisel verilerim
              dâhil tüm kişisel verilerimin işlenmesine, saklanmasına ve iş
              süreçlerinin gerektirmesi halinde yurt içi veya yurt dışında
              ilgili kamu kurum ve kuruluşlarına, sözleşmenin mahiyeti gereği
              üçüncü kişi ve kuruluşlara, hizmet sağlayıcı firmalara ve Santa
              Farma bünyesindeki yurt dışı ofislerine aktarılmasını onaylıyorum.
            </p>
            <div class="checkbox">
              <label class="sign-lbl">
                <input
                  id="adverse-agreement-checkbox"
                  v-model="isAgreed"
                  name="adverse_agreement"
                  type="checkbox"
                />
                <strong> Açık Rıza Metni'ni okudum ve kabul ediyorum</strong>
              </label>
            </div>
            <div v-if="isAgreed" id="adverse-mail-text">
              <p>
                Santa Farma İlaç San. A.Ş.’ın
                <strong
                  >tüm tıbbi ürünleri için yan etki/advers reaksiyon bildirmek
                  isterseniz
                  <a href="/file/advers-reaksiyon-bildirim-formu.docx"
                    >TÜFAM advers etki bildirim formunu</a
                  >
                  doldurup
                  <a
                    style="white-space: nowrap"
                    href="mailto:sf-pv@santafarma.com.tr"
                    >sf-pv@santafarma.com.tr</a
                  >
                  adresine gönderiniz</strong
                >
              </p>
              <p>
                Yan etki bildirimleriniz için 0850 242 91 74 numaralı telefonu
                arayarak ad soyad, telefon ve e-posta adresinizi de içerecek
                şekilde mesaj bırakabilirsiniz
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a ref="button" data-toggle="modal" data-target="#adverse_modal" hidden></a>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { ref } from "vue";

export default {
  setup() {
    const button = ref();
    const isAgreed = ref(false);

    onMounted(() => {
      button.value.click();
    });

    return {
      button,
      isAgreed,
    };
  },
};
</script>

<style></style>

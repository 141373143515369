<template>
  <div v-if="!isPostsLoading" class="article-detail mb-100px">
    <section class="article-detail-main bg-image d-flex flex-column mb-5">
      <div class="container-xl d-flex flex-column align-items-center">
        <div class="d-flex align-items-center mb-2 mt-md-100px mt-160px">
          <div class="mr-3">
            <article-icon color="white" class="mr-1" />
            <span class="font-s-13px text-white">
              {{ theme == "eczazone" ? "Haber" : "Makale" }}
            </span>
          </div>
          <div>
            <clock-icon color="white" :size="20" class="mr-1" />
            <span class="font-s-13px text-white">{{ post.publish_on }}</span>
          </div>
        </div>
        <div class="mb-2">
          <h1
            class="
              line-h-60px
              font-s-40px
              text-white text-center
              font-weight-bold
            "
          >
            {{ post.title }}
          </h1>
        </div>
        <div
          v-if="post.read_time"
          class="
            d-flex
            justify-content-center
            align-items-center
            bg-blue-light
            border-radius-5px
            px-10px
            height-25px
          "
        >
          <div class="font-s-10px text-primary">
            Okuma Süresi: {{ Math.floor(post.read_time / 60) }} Dakika
            {{ post.read_time % 60 }} Saniye
          </div>
        </div>
      </div>
      <div
        class="article-detail-main-line bg-black-700 w-100 height-5px mt-auto"
      >
        <div class="h-100 w-50 bg-blue-300 border-radius-90px" />
      </div>
    </section>

    <section v-if="post.subtitle ?? post.summary" class="mb-3">
      <div class="container-xl d-flex flex-column align-items-center">
        <p
          class="
            font-s-20px font-w-600
            text-primary
            mb-3
            text-center
            line-h-30px
            max-w-940px
          "
        >
          {{ post.subtitle ?? post.summary }}
        </p>
        <div class="description-line bg-blue-300 border-radius-90px" />
      </div>
    </section>

    <section class="font-s-16px text-secondary mb-5">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="container-xl" v-html="post.content" />
    </section>

    <section v-if="post.references" class="mb-5">
      <div class="container">
        <div class="d-flex flex-column mb-5">
          <span
            class="
              article-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="article-bottom-line w-100" />
        </div>
        <div class="d-flex align-items-start references">
          <div class="references-point border-radius-50 mr-2 mt-2 bg-primary" />
          <p class="font-s-16px">
            {{ post.references }}
          </p>
        </div>
      </div>
    </section>

    <section v-if="post.tags">
      <div class="container">
        <div class="d-flex flex-column mb-5">
          <div class="text-info mt-2 mb-4">
            <span class="font-s-12px mr-2">Etiketler:</span>
            <router-link
              v-for="(tag, index) in post.tags"
              :key="index"
              :to="{ name: 'tags', params: { id: tag.id } }"
              class="font-s-10px bg-blue-light video-detail-tag mr-2"
            >
              {{ tag.name.tr }}
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section v-if="!isSuggestionsLoading">
      <div class="container-xl">
        <div class="mb-5">
          <h4 class="font-s-24px gray-700 mb-3">
            Bunlar da
            <span class="text-info font-weight-bold">İlgini Çekebilir</span>
          </h4>
          <div class="bottom-recommended-line d-flex align-items-center">
            <div class="bottom-recommended-line-left" />
            <div class="bottom-recommended-line-right" />
          </div>
        </div>

        <div class="d-flex article-bottom-recommended">
          <div class="row">
            <div
              v-for="(suggestion, index) in suggestions"
              :key="index"
              class="col-12 col-sm-6 col-md-4"
            >
              <div class="">
                <div class="mb-2">
                  <router-link
                    :to="{
                      name: 'articledetail',
                      params: { id: suggestion.id },
                    }"
                  >
                    <img
                      :src="suggestion.image_url"
                      alt=""
                      class="img-fluid border-radius object-fit-cover"
                    />
                  </router-link>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div class="d-flex">
                    <div class="mr-2">
                      <follow-icon
                        :size="16"
                        likeable-type="post"
                        :likeable-id="suggestion.id"
                        :is-liked="suggestion.is_liked"
                        counter-classes="font-s-12px gray-300"
                        :like-count="suggestion.like_count"
                        :is-countable="true"
                      />
                    </div>
                    <div class="mr-2">
                      <comment-icon color="#AFBBDB" :size="16" class="mr-1" />
                      <span class="font-s-12px gray-300">0</span>
                    </div>
                    <div class="mr-2">
                      <clock-icon :size="16" color="#AFBBDB" class="mr-1" />
                      <span class="font-s-12px gray-300">{{
                        suggestion.publish_on
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <save-icon
                      favoritable-type="post"
                      :favoritable-id="suggestion.id"
                      :is-favorited="false"
                      :color="color"
                      class="mr-1"
                      text="Kaydet"
                      favorited-text="Kaydedildi"
                      text-classes="font-s-12px font-w-500 text-info"
                    />
                  </div>
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'articledetail',
                      params: { id: suggestion.id },
                    }"
                  >
                    <h5 class="font-s-18px font-w-600">
                      {{ suggestion.title }}
                    </h5>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import PostService from "../services/post/PostService";
import dayjs from "dayjs";
import ColorHelper from "../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "ArticleDetail",
  setup() {
    const route = useRoute();
    const post = ref(null);
    const suggestions = ref([]);
    const isPostsLoading = ref(false);
    const isSuggestionsLoading = ref(false);
    const store = useStore();
    const theme = ref(store.state.theme);
    const color = ColorHelper[store.state.theme].blueDark;
    const colorLine = ColorHelper[store.state.theme].blueText;
    const mainColor = ColorHelper[store.state.theme].articleDetailMain;
    const img_url = "url(/new-img/articledetail-bg.png)";

    const fetchPost = (postId) => {
      isPostsLoading.value = true;
      store.dispatch("setLoading", true);
      PostService.getPost(postId)
        .then((res) => {
          post.value = {
            ...res.data.data,
            publish_on: dayjs(res.data.data.publish_on)
              .locale("tr")
              .format("DD.MM.YYYY"),
          };
        })
        .finally(() => {
          isPostsLoading.value = false;
          store.dispatch("setLoading", false);
        });
    };

    const fetchSuggestions = (postId) => {
      isSuggestionsLoading.value = true;
      PostService.getPostSuggestionByPost("haberler", postId, 3)
        .then((res) => {
          suggestions.value = res.data.data.map((suggestion) => {
            return {
              ...suggestion,
              publish_on: dayjs(suggestion.publish_on)
                .locale("tr")
                .format("DD.MM.YYYY"),
            };
          });
        })
        .finally(() => {
          isSuggestionsLoading.value = false;
        });
    };

    onBeforeMount(() => {
      if (route.params.id) {
        fetchPost(route.params.id);
        fetchSuggestions(route.params.id);
      }
    });

    return {
      post,
      suggestions,
      isPostsLoading,
      isSuggestionsLoading,
      color,
      colorLine,
      mainColor,
      img_url,
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.article-detail-main {
  height: 450px;
  background: linear-gradient(0deg, v-bind("mainColor"), v-bind("mainColor")),
    linear-gradient(
      180deg,
      rgba(7, 38, 66, 0.2) 0%,
      rgba(11, 22, 32, 0.2) 48.44%
    ),
    v-bind("img_url"), #081f32;
}

.description-line {
  height: 2px;
  width: 90px;
}

.article-img {
  max-width: 459px;
  max-height: 300px;
}

p {
  margin-bottom: 30px !important;
}

.article-bottom-line {
  height: 1px;
  background-color: v-bind("colorLine");
}
.article-bottom-btn {
  width: 180px;
  height: 36px;
  display: flex;
  align-items: center;
  top: 20px;
  background-color: white;
}

.references-point {
  min-width: 9px;
  min-height: 9px;
}

.article-bottom-recommended {
  img {
    max-height: 147px;
    width: 100%;
  }

  &-3 {
    margin-right: 0 !important;
  }
}

.bottom-recommended-line {
  &-left {
    min-width: 320px;
    height: 3px;
    background-color: v-bind("colorLine");
  }

  &-right {
    width: 100%;
    height: 1px;
    background-color: #afbbdb;
  }
}

@media (max-width: 768px) {
  .article-detail-main {
    height: 372px;

    h1 {
      font-size: 24px !important;
      line-height: 35px;
    }
  }
}
</style>

<template>
  <svg
    width="116"
    height="111"
    viewBox="0 0 116 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="path-fill"
      d="M88.5265 86.4764V61.9528C86.962 61.9528 85.4281 62.1061 83.9476 62.3896V94.6535C83.9476 96.9143 82.1236 98.7382 79.8799 98.7382H67.3794C71.5997 106.065 79.4907 111 88.5265 111C102.012 111 112.948 100.018 112.948 86.4764H88.5265Z"
      fill="#AFBBDB"
    />
    <path
      class="path-fill"
      d="M112.947 83.4109C112.901 71.6013 103.354 62.0371 91.5864 62.0371V83.4109H112.947Z"
      fill="#F3F4F5"
    />
    <path
      d="M40.4932 18.6073L50.0937 8.96644L57.9695 16.883L71.7292 3.06544"
      stroke="#AFBBDB"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.5791 60.4201V98.7382"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.5791 45.0928V61.9528"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="path-fill"
      d="M9.15789 58.8873H0V71.1491H9.15789V58.8873Z"
      fill="#AFBBDB"
    />
    <path
      d="M79.8796 98.7382H30.0149C27.7636 98.7382 25.9473 96.9066 25.9473 94.6535V23.1212C25.9473 20.8604 27.7712 19.0365 30.0149 19.0365H79.872C82.1233 19.0365 83.9396 20.8681 83.9396 23.1212V94.6458C83.9473 96.9066 82.1233 98.7382 79.8796 98.7382Z"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M76.3159 31.2983H33.5791V43.5601H76.3159V31.2983Z"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M76.3159 51.2237H33.5791V89.5418H76.3159V51.2237Z"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.2632 51.2237V81.8782"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M65.6313 51.2237V89.5418"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M54.9473 51.2237V89.5418"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="path-fill"
      d="M74.7893 75.7473H67.1577V88.0091H74.7893V75.7473Z"
      fill="#F3F4F5"
    />
    <path
      class="path-fill"
      d="M42.737 52.7564H35.1055V57.3546H42.737V52.7564Z"
      fill="#F3F4F5"
    />
    <path
      class="path-fill"
      d="M19.8421 29.7655H13.7368V66.551H19.8421V29.7655Z"
      fill="#7684AA"
    />
    <path
      d="M16.7896 12.9056V29.7655"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.7896 65.0182V86.4764"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M65.25 2.17648H72.8816V9.84011"
      stroke="#AFBBDB"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.3157 107.935H30.0149C25.9244 107.935 22.2612 106.065 19.8344 103.129C17.9341 100.83 16.7817 97.8722 16.7817 94.6535M79.8796 9.8401C87.1754 9.8401 93.1052 15.8024 93.1052 23.1212V34.1032"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="path-fill"
      d="M93.1054 40.4946C93.9484 40.4946 94.6317 39.8084 94.6317 38.9619C94.6317 38.1154 93.9484 37.4292 93.1054 37.4292C92.2625 37.4292 91.5791 38.1154 91.5791 38.9619C91.5791 39.8084 92.2625 40.4946 93.1054 40.4946Z"
      fill="#7684AA"
    />
    <path
      class="path-fill"
      d="M114.474 68.8501C115.317 68.8501 116 68.1638 116 67.3173C116 66.4708 115.317 65.7846 114.474 65.7846C113.631 65.7846 112.947 66.4708 112.947 67.3173C112.947 68.1638 113.631 68.8501 114.474 68.8501Z"
      fill="#AFBBDB"
    />
    <path
      class="path-fill"
      d="M48.7812 3.06545C49.6242 3.06545 50.3075 2.37923 50.3075 1.53273C50.3075 0.686225 49.6242 0 48.7812 0C47.9382 0 47.2549 0.686225 47.2549 1.53273C47.2549 2.37923 47.9382 3.06545 48.7812 3.06545Z"
      fill="#AFBBDB"
    />
    <path
      class="path-fill"
      d="M51.895 109.467C52.7379 109.467 53.4213 108.781 53.4213 107.935C53.4213 107.088 52.7379 106.402 51.895 106.402C51.052 106.402 50.3687 107.088 50.3687 107.935C50.3687 108.781 51.052 109.467 51.895 109.467Z"
      fill="#7684AA"
    />
    <path
      d="M111.421 63.3859C105.88 57.7684 98.2107 54.2892 89.7549 54.2892"
      stroke="#AFBBDB"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.082 13.3577L45.7744 4.63649"
      stroke="#AFBBDB"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5791 58.8873H76.3159"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5791 66.551H76.3159"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5791 74.2146H76.3159"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5791 81.8782H76.3159"
      stroke="#7684AA"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CalculateImg",
};
</script>

<style></style>

<template>
  <div class="d-flex justify-content-center my-4 flex-wrap">
    <left-pagination-icon
      v-if="currentPage > 1"
      :disabled="disabled"
      class="mr-2"
      @click="$emit('go-page', currentPage - 1)"
    />
    <button
      v-for="i in pages.slice(
        currentPage < 3 ? 0 : currentPage - 3,
        currentPage + 3
      )"
      :key="i"
      :class="{ active: currentPage === i }"
      class="
        mb-1
        gray-300
        border-radius-50
        mr-2
        page-number
        d-flex
        justify-content-center
        align-items-center
      "
      :disabled="disabled"
      @click="$emit('go-page', i)"
    >
      {{ i }}
    </button>
    <right-pagination-icon
      v-if="currentPage < totalPages"
      :disabled="disabled"
      @click="$emit('go-page', currentPage + 1)"
    />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

export default {
  name: "Pagination",

  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["go-page"],
  setup(props) {
    const pages = computed(() => {
      return Array.from(Array(props.totalPages).keys(), (x) => x + 1);
    });

    return {
      pages,
    };
  },
};
</script>
<style lang="scss" scoped>
svg {
  cursor: pointer;
}
.page-number {
  border: 1px solid #afbbdb;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: transparent;

  &:disabled {
    background: #ddd !important;
  }
}
.active {
  background: #afbbdb;
  color: white;
}
</style>

<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.23525 6.46695C2.66958 4.61534 4.11534 3.16958 5.96696 2.73525C7.30417 2.42158 8.69583 2.42158 10.033 2.73525C11.8847 3.16958 13.3304 4.61534 13.7647 6.46696C14.0784 7.80417 14.0784 9.19583 13.7647 10.533C13.3304 12.3847 11.8847 13.8304 10.033 14.2648C8.69583 14.5784 7.30417 14.5784 5.96696 14.2647C4.11534 13.8304 2.66958 12.3847 2.23525 10.533C1.92158 9.19583 1.92158 7.80417 2.23525 6.46695Z"
      :stroke="color"
    />
    <path
      d="M9.66634 8.50001H6.33301M7.99967 10.1667L7.99967 6.83334"
      :stroke="color"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DrugAddIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>

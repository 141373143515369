import { ref } from "firebase/database";
import { db } from "../shared/firebase";

export const threadsDocRef = ref(
  db,
  process.env.MIX_FIREBASE_CHAT_THREAD_DOCUMENT
);

export const userThreadsDocRef = ref(
  db,
  process.env.MIX_FIREBASE_CHAT_USER_THREAD_DOCUMENT
);

export const messagesDocRef = ref(
  db,
  process.env.MIX_FIREBASE_CHAT_MESSAGE_DOCUMENT
);

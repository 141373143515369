<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6666 15.6622C10.9452 15.6706 10.2615 15.341 9.81873 14.7714C9.37598 14.2018 9.2252 13.4579 9.41123 12.7609L5.2379 10.3756C4.51675 11.0363 3.45969 11.1761 2.59156 10.7256C1.72343 10.2752 1.22915 9.3304 1.35414 8.36038C1.47914 7.39036 2.19678 6.60174 3.15075 6.38608C4.10471 6.17041 5.0918 6.57366 5.6219 7.39558L9.41057 5.22958C9.36155 5.04437 9.33557 4.85382 9.33323 4.66225C9.32368 3.54672 10.0984 2.57767 11.1887 2.34146C12.279 2.10525 13.3853 2.66674 13.8383 3.68619C14.2913 4.70564 13.9666 5.90306 13.0605 6.55392C12.1545 7.20477 10.9161 7.13031 10.0946 6.37558L5.9939 8.71891C5.98984 8.89179 5.96567 9.06362 5.9219 9.23091L10.0946 11.6156C10.8627 10.9106 12.0057 10.8018 12.8932 11.3491C13.7806 11.8965 14.1963 12.9667 13.9111 13.9696C13.6259 14.9725 12.7092 15.6638 11.6666 15.6622ZM11.6666 12.3289C11.1143 12.3289 10.6666 12.7766 10.6666 13.3289C10.6666 13.8812 11.1143 14.3289 11.6666 14.3289C12.2189 14.3289 12.6666 13.8812 12.6666 13.3289C12.6666 12.7766 12.2189 12.3289 11.6666 12.3289ZM3.66656 7.66225C3.11428 7.66225 2.66656 8.10996 2.66656 8.66225C2.66656 9.21453 3.11428 9.66225 3.66656 9.66225C4.21885 9.66225 4.66656 9.21453 4.66656 8.66225C4.66656 8.10996 4.21885 7.66225 3.66656 7.66225ZM11.6666 3.66225C11.1143 3.66225 10.6666 4.10996 10.6666 4.66225C10.6666 5.21453 11.1143 5.66225 11.6666 5.66225C12.2189 5.66225 12.6666 5.21453 12.6666 4.66225C12.6666 4.10996 12.2189 3.66225 11.6666 3.66225Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ShareIcon",
};
</script>

<style></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons/podcast/volumeup">
      <g id="Volume Up">
        <path
          id="Stroke 1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.50185 12.5001C2.49906 13.7298 2.44419 15.407 3.20494 16.0339C3.91453 16.6187 4.41395 16.468 5.70945 16.5631C7.00587 16.6591 9.74195 20.47 11.8512 19.2646C12.9393 18.4089 13.0202 16.6151 13.0202 12.5001C13.0202 8.38515 12.9393 6.59135 11.8512 5.73571C9.74195 4.52938 7.00587 8.34121 5.70945 8.43717C4.41395 8.53225 3.91453 8.38157 3.20494 8.96635C2.44419 9.59328 2.49906 11.2705 2.50185 12.5001Z"
          stroke="#AFBBDB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Stroke 3"
          d="M19.5845 6.40393C22.1345 10.0749 22.1429 14.9173 19.5845 18.5955"
          stroke="#AFBBDB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Stroke 5"
          d="M17.0811 8.81445C18.3924 11.1051 18.3924 13.9026 17.0811 16.1861"
          stroke="#AFBBDB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "VolumeIcon",
  props: {
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="HAQ Score - Sağlık Değerlendirme Anketi"
        nav="HAQ Score - Sağlık Değerlendirme Anketi"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-text"
                  ></h4>

                  <span class="font-s-12px font-w-500 red-200 text-center"
                    >Haq Skoru :</span
                  >
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="haqscore"
            class="
              d-flex
              flex-column
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div>
              <p class="font-s-m-12px font-s-16px text-secondary">
                Aşağıda belirtilenleri yapabiliyor musunuz?
              </p>
            </div>
            <div class="d-flex flex-column">
              <div class="mb-3">
                <div>
                  <h5 class="text-info">Giyinme Genel Bakım</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Ayakkabı bağlamak ve düğme iliklemek dahil olmak üzere
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-1-1"
                          type="radio"
                          class="mr-1"
                          name="q1"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-1-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-1-2"
                          type="radio"
                          class="mr-1"
                          name="q1"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-1-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-1-3"
                          type="radio"
                          class="mr-1"
                          name="q1"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-1-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-1-4"
                          type="radio"
                          class="mr-1"
                          name="q1"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-1-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Saç yıkamak
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-2-1"
                          type="radio"
                          class="mr-1"
                          name="q2"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-2-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-2-2"
                          type="radio"
                          class="mr-1"
                          name="q2"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-2-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-2-3"
                          type="radio"
                          class="mr-1"
                          name="q2"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-2-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-2-4"
                          type="radio"
                          class="mr-1"
                          name="q2"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-2-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div>
                  <h5 class="text-info">Oturup Kalkma</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Kolluğu olmayan dik bir sandalyeden kalkma
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-3-1"
                          type="radio"
                          class="mr-1"
                          name="q3"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-3-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-3-2"
                          type="radio"
                          class="mr-1"
                          name="q3"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-3-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-3-3"
                          type="radio"
                          class="mr-1"
                          name="q3"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-3-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-3-4"
                          type="radio"
                          class="mr-1"
                          name="q3"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-3-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Yatağa yatıp kalkmak
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-4-1"
                          type="radio"
                          class="mr-1"
                          name="q4"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-4-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-4-2"
                          type="radio"
                          class="mr-1"
                          name="q4"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-4-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-4-3"
                          type="radio"
                          class="mr-1"
                          name="q4"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-4-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-4-4"
                          type="radio"
                          class="mr-1"
                          name="q4"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-4-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div>
                  <h5 class="text-info">Yemek Yeme</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Bıçakla et kesmek
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-5-1"
                          type="radio"
                          class="mr-1"
                          name="q5"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-5-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-5-2"
                          type="radio"
                          class="mr-1"
                          name="q5"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-5-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-5-3"
                          type="radio"
                          class="mr-1"
                          name="q5"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-5-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-5-4"
                          type="radio"
                          class="mr-1"
                          name="q5"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-5-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Dolu bir bardağı ağza götürmek
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-6-1"
                          type="radio"
                          class="mr-1"
                          name="q6"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-6-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-6-2"
                          type="radio"
                          class="mr-1"
                          name="q6"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-6-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-6-3"
                          type="radio"
                          class="mr-1"
                          name="q6"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-6-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-6-4"
                          type="radio"
                          class="mr-1"
                          name="q6"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-6-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Açılmamış karton bir süt kutusunu açmak
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-7-1"
                          type="radio"
                          class="mr-1"
                          name="q7"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-7-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-7-2"
                          type="radio"
                          class="mr-1"
                          name="q7"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-7-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-7-3"
                          type="radio"
                          class="mr-1"
                          name="q7"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-7-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-7-4"
                          type="radio"
                          class="mr-1"
                          name="q7"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-7-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div>
                  <h5 class="text-info">Yürüyüş</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Düz yolda yürümek
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-8-1"
                          type="radio"
                          class="mr-1"
                          name="q8"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-8-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-8-2"
                          type="radio"
                          class="mr-1"
                          name="q8"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-8-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-8-3"
                          type="radio"
                          class="mr-1"
                          name="q8"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-8-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-8-4"
                          type="radio"
                          class="mr-1"
                          name="q8"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-8-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Beş basamak çıkıp, inmek
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-9-1"
                          type="radio"
                          class="mr-1"
                          name="q9"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-9-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-9-2"
                          type="radio"
                          class="mr-1"
                          name="q9"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-9-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-9-3"
                          type="radio"
                          class="mr-1"
                          name="q9"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-9-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-9-4"
                          type="radio"
                          class="mr-1"
                          name="q9"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-9-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div>
                  <h5 class="text-info">Hijyen</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Tüm vücudu yıkayıp, kurulayabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-10-1"
                          type="radio"
                          class="mr-1"
                          name="q10"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-10-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-10-2"
                          type="radio"
                          class="mr-1"
                          name="q10"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-10-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-10-3"
                          type="radio"
                          class="mr-1"
                          name="q10"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-10-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-10-4"
                          type="radio"
                          class="mr-1"
                          name="q10"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-10-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Banyo yapabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-11-1"
                          type="radio"
                          class="mr-1"
                          name="q11"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-11-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-11-2"
                          type="radio"
                          class="mr-1"
                          name="q11"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-11-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-11-3"
                          type="radio"
                          class="mr-1"
                          name="q11"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-11-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-11-4"
                          type="radio"
                          class="mr-1"
                          name="q11"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-11-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Tuvalete gidebiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-12-1"
                          type="radio"
                          class="mr-1"
                          name="q12"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-12-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-12-2"
                          type="radio"
                          class="mr-1"
                          name="q12"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-12-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-12-3"
                          type="radio"
                          class="mr-1"
                          name="q12"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-12-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-12-4"
                          type="radio"
                          class="mr-1"
                          name="q12"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-12-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div>
                  <h5 class="text-info">Uzanma</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Başının üstündeki seviyede bulunan bir raftan 2-3 kilo
                        kadar bir ağırlığı alabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-13-1"
                          type="radio"
                          class="mr-1"
                          name="q13"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-13-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-13-2"
                          type="radio"
                          class="mr-1"
                          name="q13"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-13-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-13-3"
                          type="radio"
                          class="mr-1"
                          name="q13"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-13-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-13-4"
                          type="radio"
                          class="mr-1"
                          name="q13"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-13-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Yerde bulunan bir giysiyi eğilip, alabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-14-1"
                          type="radio"
                          class="mr-1"
                          name="q14"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-14-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-14-2"
                          type="radio"
                          class="mr-1"
                          name="q14"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-14-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-14-3"
                          type="radio"
                          class="mr-1"
                          name="q14"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-14-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-14-4"
                          type="radio"
                          class="mr-1"
                          name="q14"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-14-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div>
                  <h5 class="text-info">Kavrama</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Araba kapılarını açabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-15-1"
                          type="radio"
                          class="mr-1"
                          name="q15"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-15-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-15-2"
                          type="radio"
                          class="mr-1"
                          name="q15"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-15-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-15-3"
                          type="radio"
                          class="mr-1"
                          name="q15"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-15-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-15-4"
                          type="radio"
                          class="mr-1"
                          name="q15"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-15-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Daha önce açılmamış bir kavanoz kapağını açabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-16-1"
                          type="radio"
                          class="mr-1"
                          name="q16"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-16-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-16-2"
                          type="radio"
                          class="mr-1"
                          name="q16"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-16-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-16-3"
                          type="radio"
                          class="mr-1"
                          name="q16"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-16-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-16-4"
                          type="radio"
                          class="mr-1"
                          name="q16"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-16-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Muslukları kapatıp, açabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-17-1"
                          type="radio"
                          class="mr-1"
                          name="q17"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-17-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-17-2"
                          type="radio"
                          class="mr-1"
                          name="q17"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-17-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-17-3"
                          type="radio"
                          class="mr-1"
                          name="q17"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-17-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-17-4"
                          type="radio"
                          class="mr-1"
                          name="q17"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-17-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div>
                  <h5 class="text-info">Diğer Aktiviteler</h5>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Evin dışındaki işleri, örneğin alışveriş yapabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-18-1"
                          type="radio"
                          class="mr-1"
                          name="q18"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-18-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-18-2"
                          type="radio"
                          class="mr-1"
                          name="q18"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-18-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-18-3"
                          type="radio"
                          class="mr-1"
                          name="q18"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-18-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-18-4"
                          type="radio"
                          class="mr-1"
                          name="q18"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-18-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Arabaya binip, inebiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-19-1"
                          type="radio"
                          class="mr-1"
                          name="q19"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-19-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-19-2"
                          type="radio"
                          class="mr-1"
                          name="q19"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-19-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-19-3"
                          type="radio"
                          class="mr-1"
                          name="q19"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-19-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-19-4"
                          type="radio"
                          class="mr-1"
                          name="q19"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-19-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column flex-md-row">
                  <div class="mr-5 mb-4 w-m-100 w-50">
                    <div>
                      <h5 class="font-s-20px font-w-600 black-700">
                        Elektrikli süpürge kullanabiliyor mu?
                      </h5>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-20-1"
                          type="radio"
                          class="mr-1"
                          name="q20"
                          value="0"
                        />
                        <label class="text-secondary" for="haq-20-1"
                          >Hiç Zorlanmadan</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-20-2"
                          type="radio"
                          class="mr-1"
                          name="q20"
                          value="1"
                        />
                        <label class="text-secondary" for="haq-20-2"
                          >Biraz Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-20-3"
                          type="radio"
                          class="mr-1"
                          name="q20"
                          value="2"
                        />
                        <label class="text-secondary" for="haq-20-3"
                          >Çok Zor</label
                        >
                      </div>
                      <div class="d-flex align-items-baseline">
                        <input
                          id="haq-20-4"
                          type="radio"
                          class="mr-1"
                          name="q20"
                          value="3"
                        />
                        <label class="text-secondary" for="haq-20-4"
                          >Yapamıyorum</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius btn-calculate"
              @click="showResult = true"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "EssScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    $(document).ready(function () {
      $(".btn-calculate").on("click", function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
        $(".return-text").slideDown();
        let score = calc_row();
        $(".return-text").html(parseFloat(score.toFixed(2)));
      });
    });

    function clearAll() {
      $("form")[0].reset();
      $(".return-text").slideUp();
    }

    $(document).ready(function () {
      $("form").submit(function (event) {
        event.preventDefault();
        $(".btn-calculate").click();
      });
    });

    function calc_row() {
      let score = 0;
      let i = 1;
      for (i; i <= 20; i++) {
        if ($('#haqscore input:checked[name="q' + i + '"]').val()) {
          score =
            score +
            parseInt($('#haqscore input:checked[name="q' + i + '"]').val());
        }
      }
      score = score / 20;
      return score;
    }

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      clearAll,
      apgar: [
        {
          title: "HAQ Skoru Nedir?",
          content: `  <p>HAQ skoru, 1980 yılında romatoid artrit hastaların fiziksel aktivitelerini değerlendirme amacıyla yapılan bir testtir. Anket şeklinde uygulanan test, hastaların günlük yaşamdaki fiziksel aktivitelerini ölçmek için kullanılır. Bu skora göre, bireylerin kas ve sinir sisteminin performansında öğrenilir. Genellikle yaygın olarak görülen romatizma hastalarına uygulanır. Global durum ve ağrıları değerlendiren bu skor, çeşitli çalışmalarda kullanılarak güvenilirliği kabul edilmiştir. Romatizmal hastalıklar, kadınlarda erkeklere oranla daha fazla görülür. Özellikle 40 yaş ve üzerindeki kişilerin her yıl düzenli olarak genel sağlık taraması yaptırması gerekir. Romatoid artrit ve osteoartrit gibi romatizmal hastalıkların başlıca nedenleri; ağır şartlarda çalışma, genetik faktörler, çevresel faktörler, ağır spor antrenmanları ve farklı hastalıkların yan etkileridir. Nedenin belirlenebilmesi için ilk olarak HAQ skoru değerlendirilir. Sonuçları yüksek olan kişilerde, kas ve eklem sisteminin performansını gösteren testler uygulanır</p>
        `,
        },
        {
          title: "Sağlık Değerlendirme Anketi Nedir ve Nasıl Hesaplanır?",
          content: ` <p>Sağlık değerlendirme anketi, bireylerin HAQ skorunu belirmek için kullanılan yöntemdir. Genellikle romatoid artrit ve osteoartrit hastalarına uygulanır. Yaklaşık 10 dakika süren bu anket, hastanın kendisi tarafından doldurulur. İçeriğinde günlük yaşam aktivitelerini kapsayan;</p>

            <ul >
                <li>Genel bakım ve giyinme,</li>
                <li>Yemek yeme,</li>
                <li>Oturup kalkma,</li>
                <li>Hijyen,</li>
                <li>Yürüyüş,</li>
                <li>Kavrama,</li>
                <li>Uzanma,</li>
                <li>Diğer aktiviteler</li>
            </ul>

            <p>gibi sorulara yer verilir. Anket içerisinde hiç zorlanmıyorum, zorlanıyorum, yapamıyorum gibi seçenekler mevcuttur. Hastaların verdiği cevaplara göre her soruya 0-3 arası puan verilir. Toplamda 20 sorudan oluşan sağlık değerlendirme anketinin sonucu, sorulardan alınan puanların toplanmasıyla hesaplanır. Bu anket, doktor kontrolünde veya internet üzerinden yapılabilir. Çıkan sonuçlar hastanın doktoru ile paylaşılmalıdır. Bir soruda verilen 0 puan aktivitelerin hiç zorlanmadan yapıldığını gösterirken, 3 puan güçlük aktivitelerin yapılamadığı anlamına gelir. Toplumda yaygın görülen romatizma hastalığının ilerlemesi halinde, HAQ skoru sonuçları yükselir.</p>
        `,
        },
        {
          title: "HAQ Skoru Hesaplama",
          content: ` <p>20 sorudan oluşan sağlık değerlendirme anketi eksiksiz bir şekilde doldurulmalıdır. Özellikle cihaz veya bir kişinin yardımına ihtiyaç duyan kişilerde soruya verilen puan 2 veya 3'tür. HAQ skoru en az 0, en fazla 3 olacak şekilde hesaplanır. Skorun yüksek çıkması, sağlık durumunun düşük olduğunu gösterir. Haq skoru 2’nin üzerinde ise, hastanın kemik ve kas taraması yapılır. Bu şekilde doğru bir teşhis konularak uygun tedavi yöntemi belirlenir.&nbsp;</p>

            <p>Romatizmal hastalıkların başlangıcında en çok eklemlerde ağrı, hafif şişme ve hassasiyet görülür. Tedavi uygulanmadığı takdirde diz ve dirseklerde tutulmalar meydana gelir. Özellikle hareket oranı düşük olan kişilerde, eklem tutulmaları giderek artar. El ve ayak bileklerine kadar uzanan romatizmal hastalıklar, hastanın hareket kabiliyetini kısıtlar. Bireylerde kemik sızlaması, eklem ve kas ağrısı, eklem şişmesi, el ve ayaklarda titreme, kasları kontrol ederken güçlük yaşama gibi sorunlar görülüyorsa, mutlaka sağlık değerlendirme anketi yapılmalıdır. Teşhis konulan hastaların durumuna göre ilaç, iğne ve fizik tedavi yöntemleri uygulanır.</p>
        `,
        },
        {
          title: "HAQ Skor Sonucu Değerlendirme",
          content: ` <p>HAQ endeksinin değeri üç kategori açısından yorumlanabilir:</p>

            <ul>
                <li>0'dan 1'e: hafif zorluklar ve orta derecede engellilik,</li>
                <li>1'den 2'ye: orta ila şiddetli engellilik,</li>
                <li>2'den 3'e: şiddetli ila çok ciddi engellilik</li>
            </ul>
`,
        },
      ],
      references: [
        "http://geriatri.dergisi.org/uploads/pdf/pdf_TJG_549.pdf",
        "http://www.ftronline.com/haq/",
        "http://cms.galenos.com.tr/Uploads/Article_21328/IMJ-9-9-En.pdf",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="MELD Skoru Hesaplama Aracı"
        nav="MELD Skoru Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-20px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), handleClear()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="bet"
            class="
              d-flex
              flex-column
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-100">
              <div class="mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">
                  Geçtiğimiz hafta içerisinde en az 2 kez diyalize girdi mi?
                </h5>
                <select
                  id="hd"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    gender
                  "
                >
                  <option selected disabled>Lütfen Seçiniz</option>
                  <option value="1">Evet</option>
                  <option value="2">Hayır</option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div class="w-m-100 w-50">
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Kreatinin (mg/dL)
                  </h5>
                  <input
                    id="cr"
                    type="text"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    name="creatine"
                    maxlength="9"
                    max="9"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||(event.charCode >= 188 && event.charCode <= 190)"
                  />
                </div>
                <div class="mr-md-5 mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">INR</h5>
                  <input
                    id="inr"
                    type="text"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    name="inr"
                    maxlength="9"
                    max="9"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||(event.charCode >= 188 && event.charCode <= 190)"
                  />
                </div>
              </div>

              <div class="w-m-100 w-50">
                <div class="mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Bilirubin (mg/dL)
                  </h5>
                  <input
                    id="bili"
                    type="text"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    name="bilirubin"
                    maxlength="9"
                    max="9"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 188 && event.charCode <= 190)"
                  />
                </div>
                <div class="mb-5">
                  <h5 class="font-s-20px mb-3 font-w-600 black-700">
                    Sodyum (mEq/L)
                  </h5>
                  <input
                    id="na"
                    type="text"
                    class="
                      w-100
                      px-3
                      box-shadow-4-20-3
                      border-none
                      height-50px
                      border-radius
                      form-control
                    "
                    name="na"
                    maxlength="9"
                    max="9"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 188 && event.charCode <= 190)"
                  />
                </div>
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius btn-calculate"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "MeldScore",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    let meldnew;
    let value = "";
    let nadiff = "";

    function calculate() {
      var bili = $("#bili").val().replace(",", ".");
      var cr = $("#cr").val().replace(",", ".");
      var inr = $("#inr").val().replace(",", ".");
      var na = $("#na").val().replace(",", ".");
      var hd = $("#hd").val().replace(",", ".");

      bili = bili < 1 ? 1 : bili;
      cr = cr < 1 ? 1 : cr;
      inr = inr < 1 ? 1 : inr;
      na = na < 125 ? 125 : na;
      na = na > 137 ? 137 : na;
      cr = hd == 1 || cr > 4 ? 4 : cr;
      value =
        10 *
        (0.378 * Math.log(bili) +
          1.12 * Math.log(inr) +
          0.957 * Math.log(cr) +
          0.643);
      if (value < 6) {
        value = 6;
      }
      if (value > 11) {
        nadiff = 137 - na;
        meldnew = value + 1.32 * nadiff - 0.033 * value * nadiff;
      } else {
        meldnew = value;
      }
      let returnText =
        "MELD Skoru (2016)* : <b>" + Math.round(meldnew) + "</b> Puan";
      $(".return-text").slideDown();
      $(".return-text").html(returnText);
    }

    function clearAll() {
      $("form")[0].reset();
      $(".return-text").slideUp();
    }

    $(document).ready(function () {
      $("form").submit(function (event) {
        event.preventDefault();
        $(".btn-calculate").click();
      });
    });

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      clearAll,
      calculate,
      apgar: [
        {
          title: "Meld Skoru Nedir, Nasıl Hesaplanır?",
          content: `  <p>Meld skoru, kronik karaciğer hastalığının şiddet seviyesini ölçmek ve transplantasyon için öncelik sırasını belirlemek amacıyla kullanılan puanlama sistemidir. Bu skor, karaciğer hastalarının gelecekteki 3 aylık yaşam beklentisini gösterir. Hastaların aciliyet durumuna göre transplantasyonun 3 ayda gerekli olup olmadığına meld skoru karar verir. Transplantasyon, kronik karaciğer yetmezliğinde kullanılan en etkili yöntemdir. Fakat organ bulma konusunda zorluk yaşandığı için, transplantasyon yapılacak hastalar, child meld skoru sonucuna göre sınıflandırılır. Bu skorun sonuçlarına göre hastaların yaşam süresi belirlendiği için, sınıflandırmalar güvenli bir şekilde yapılır.</p>

            <p>Karaciğer hastalıklarında ilk olarak genel tarama yapılır ve hastalığa dair bulgular toplanır. Karaciğer yetmezliği teşhisi konulduğunda, hastalar bekleme listesine alınır. Ardından hastanın child ve meld skoruna bakılarak listedeki yeri belirlenir. Aciliyet gerektiren durumlarda, uygun organ bulununca transplantasyon başlatılır. Child ve meld skoru arasındaki en büyük fark, meld skoru ile daha net veriler alınmasıdır.</p>
`,
        },
        {
          title: "Meld Skoru Nasıl Hesaplanır?",
          content: `   <p>Meld skoru hesaplama da kullanılan bilgiler şu şekildedir;</p>

            <ul>
                <li>Serum kreatinin değeri,</li>
                <li>Bilirubin,</li>
                <li>Sodyum,</li>
                <li>Haftalık diyalize girme sayısı.</li>
            </ul>

            <p>Bu değerlere göre hesaplanan meld skoru hastaya söylense de, bekleme listesindeki yeri hakkında bilgi verilmez. 1'in altındaki değerler 1, 4'ün üstündeki değerler ise 4 olarak alınır. Meld skoru en az 6, en fazla ise 40 olarak değerlendirilir. Buna ek olarak child pugh skoru da veriler arasında tutulur ve hastalığın şiddetine göre kategori belirlenir. Hastanın kreatinin, bilirubin ve sodyum değerleri biliniyorsa, meld skoru formülü ile sonucu internetten hesaplamak da mümkündür.</p>

            <hr>

            <ul>
                <li>Hepatosellüler kanser,</li>
                <li>Ailesel amiloidoz,</li>
                <li>Primer oksalüri,</li>
            </ul>

            <p>hastalıklarında meld skoru değerleri hesaplanandan daha yüksektir. 16 yaş altı çocuklarda match meld uygulanır ve mümkünse hastalarda 3 ay içinde organ nakli gerçekleştirilir. Hastaların meld skoru aynı ise organ; bekleme listesinde daha uzun süre yer alan hastaya verilir.</p>
`,
        },
        {
          title: "Transplantasyon Yapılabilmesi İçin Meld Skoru Kaç Olmalıdır?",
          content: `
                      <p>Transplantasyon işleminin yapılabilmesi için bazı kriterler mevcuttur. Karaciğer yetmezliği teşhisi konulduktan sonra hastanın child ve meld skoru hesaplanır. Nakil işleminin gerekli görülmesi için child skoru en az 7, meld skoru ise en az 15 olmalıdır. Nakil gerekli görüldüğünde, hastanın yaşı, genel sağlık durumu, diğer hastalıkları ve transplantasyonu kaldırıp kaldıramayacağı göz önünde tutulur. Farklı hastalıklar varsa, organ nakli sırasında oluşabilecek riskler hesaplanır ve hastanın listedeki yeri belirlenir. Meld skoru yüksek olan ve 2. kategoride yer alan hastalar aciliyet gerektirdiği için, listede öncelik verilir.</p>
`,
        },
        {
          title: "Meld Skoru Hangi Aralıklarla Hesaplanır?",
          content: `   <p>Karaciğer hastalarının genel taramalarında meld skoru hesaplanır. Skor sonucu 10'un altında olanlara yıllık, 11-18 arasındakilere 3 aylık, 19-24 arasındakilere aylık, 25 ve üzerindeki hastalara ise haftalık laboratuvar testi uygulanır. Karaciğer hastalıklarında erken teşhis konulabilmesi için yılda en az bir kez genel sağlık taraması uygulanmalıdır.</p>
`,
        },
      ],
      references: [
        "https://www.klimik.org.tr/wp-content/uploads/2017/05/Karaci%C4%9Fer-Transplantasyonu-%C4%B0ndikasyonlar-Emel-YILMAZ.pdf",
        "https://www.kentsaglikgrubu.com/uploads/_clinic/files/1498446951145094990karaciger.pdf",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>

<template>
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.60791 6.03097C9.60791 7.19497 8.66391 8.13831 7.49991 8.13831C6.33591 8.13831 5.39258 7.19497 5.39258 6.03097C5.39258 4.86631 6.33591 3.92297 7.49991 3.92297C8.66391 3.92297 9.60791 4.86631 9.60791 6.03097Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.49883 10.8988C10.0375 10.8988 12.3595 9.07351 13.6668 6.03084C12.3595 2.98818 10.0375 1.16284 7.49883 1.16284H7.5015C4.96283 1.16284 2.64083 2.98818 1.3335 6.03084C2.64083 9.07351 4.96283 10.8988 7.5015 10.8988H7.49883Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ViewIcon",
};
</script>

<style></style>

<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.73525 6.46695C3.16958 4.61534 4.61534 3.16958 6.46696 2.73525C7.80417 2.42158 9.19583 2.42158 10.533 2.73525C12.3847 3.16958 13.8304 4.61534 14.2647 6.46696C14.5784 7.80417 14.5784 9.19583 14.2647 10.533C13.8304 12.3847 12.3847 13.8304 10.533 14.2648C9.19583 14.5784 7.80417 14.5784 6.46696 14.2647C4.61534 13.8304 3.16958 12.3847 2.73525 10.533C2.42158 9.19583 2.42158 7.80417 2.73525 6.46695Z"
      :stroke="color"
    />
    <path
      d="M9.67831 7.3215L7.32129 9.67853M9.67831 9.67853L7.32129 7.3215"
      :stroke="color"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DrugCloseIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.35288 9.45043C4.00437 6.67301 6.17301 4.50437 8.95043 3.85288C10.9563 3.38237 13.0437 3.38237 15.0496 3.85288C17.827 4.50437 19.9956 6.67301 20.6471 9.45044C21.1176 11.4563 21.1176 13.5437 20.6471 15.5496C19.9956 18.327 17.827 20.4956 15.0496 21.1471C13.0437 21.6176 10.9563 21.6176 8.95044 21.1471C6.17301 20.4956 4.00437 18.327 3.35288 15.5496C2.88237 13.5437 2.88237 11.4563 3.35288 9.45043Z"
      :stroke="color"
      stroke-width="1.5"
    />
    <path
      d="M14.4997 15L11.6611 13.1939V9.5"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EventClockIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3835 6.31201C13.3835 6.31201 13.0215 10.802 12.8115 12.6933C12.7115 13.5967 12.1535 14.126 11.2395 14.1427C9.50019 14.174 7.75885 14.176 6.02018 14.1393C5.14085 14.1213 4.59218 13.5853 4.49418 12.698C4.28285 10.79 3.92285 6.31201 3.92285 6.31201"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3053 4.15975H3"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.1264 4.15974C11.6031 4.15974 11.1524 3.78974 11.0497 3.27707L10.8877 2.46641C10.7877 2.09241 10.4491 1.83374 10.0631 1.83374H7.24107C6.85507 1.83374 6.5164 2.09241 6.4164 2.46641L6.2544 3.27707C6.15173 3.78974 5.70107 4.15974 5.17773 4.15974"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DeleteIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>

<template>
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0002 3.97943C9.1895 3.97943 3.66683 9.50076 3.66683 16.3128C3.66683 23.1234 9.1895 28.6461 16.0002 28.6461C22.8108 28.6461 28.3335 23.1234 28.3335 16.3128C28.3335 9.50076 22.8108 3.97943 16.0002 3.97943Z"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.9233 11.6845L13.2753 16.3125L17.9233 20.9405"
      stroke="#AFBBDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "LeftPaginationIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00004 11.8984C11.7595 11.8984 13.4987 11.4162 13.6667 9.48035C13.6667 7.54588 12.4541 7.67027 12.4541 5.29675C12.4541 3.44277 10.6969 1.33334 8.00004 1.33334C5.30322 1.33334 3.54594 3.44277 3.54594 5.29675C3.54594 7.67027 2.33337 7.54588 2.33337 9.48035C2.50201 11.4235 4.24122 11.8984 8.00004 11.8984Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.59254 13.9048C8.68311 14.9147 7.26444 14.9266 6.34631 13.9048"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "NotificationIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>

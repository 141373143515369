import { createApiClient } from "../utils";

const apiClient = createApiClient();

export default {
  getVideoComments(video_id, page = 1, per_page = 15) {
    return apiClient.get("/api/comments", {
      params: {
        page,
        per_page,
        qb: true,
        "filter[commentable_id]": video_id,
        "filter[commentable_type]": "Epigra\\Videos\\Models\\Video",
        "filter[approved]": true,
        sort: "-created_at",
      },
    });
  },
  createVideoComment(video_id, user_id, comment) {
    return apiClient.post("/api/comments", {
      body: comment,
      commentable_id: video_id,
      commentable_type: "Epigra\\Videos\\Models\\Video",
      creator_id: user_id,
      creator_type: "App\\Models\\User",
    });
  },
};

<template>
  <div class="login d-flex align-items-center justify-content-center p-4">
    <div class="max-w-555px">
      <div class="d-none d-lg-block login-logo mb-4">
        <router-link :to="{ name: 'home' }">
          <img :src="logo_url" alt="" class="img-fluid" />
        </router-link>
      </div>

      <div class="mb-5">
        <p class="font-s-16px gray-500">
          Türkiye'nin {{ zone.deparment }}
          {{ zone.theme == "ahezone" ? "hekimlerine" : "uzmanlarına" }} özel
          geniş içeriğe sahip iletişim platformu.
        </p>
      </div>

      <div>
        <form @submit.prevent="loginHandler">
          <div class="form-input">
            <div class="mb-4 position-relative">
              <mail-icon :size="24" color="#7684AA" class="input-left-svg" />
              <input
                v-model="email"
                type="email"
                class="form-control"
                placeholder="E-posta Adresiniz"
              />
            </div>
            <div class="mb-4 position-relative">
              <base-password-input v-model="password" />
            </div>
          </div>
          <div class="mb-4 form-check d-flex justify-content-between">
            <div class="d-flex">
              <input
                id="exampleCheck1"
                v-model="rememberMe"
                type="checkbox"
                class="form-check-input"
              />
              <label class="gray-500 font-s-12px" for="exampleCheck1"
                >Beni Hatırla</label
              >
            </div>
            <div>
              <router-link
                :to="{ name: 'forgot-password' }"
                class="font-s-12px text-info"
                >Parolanızı mı Unuttunuz?
              </router-link>
            </div>
          </div>
          <div class="mb-4">
            <button
              :disabled="waitingLoginProcess"
              type="submit"
              class="
                btn btn-primary
                text-white
                font-s-16px
                d-flex
                w-100
                height-50px
                border-radius
                d-flex
                justify-content-center
                align-items-center
              "
            >
              Giriş Yap
            </button>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-center">
        <router-link :to="{ name: 'register' }" class="font-s-16px blue-300">
          Ücretsiz Üye Ol
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UserService from "../../services/UserService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import BasePasswordInput from "./BasePasswordInput";
import { LS_KEY_EMAIL_PASSWORD } from "../../shared/local-storage-keys";

export default {
  name: "LoginForm",
  components: {
    BasePasswordInput,
  },
  props: {
    zone: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const email = ref(null);
    const password = ref(null);
    const store = useStore();
    const router = useRouter();
    const waitingLoginProcess = ref(false);
    const rememberMe = ref();
    const logo_url = "/new-img/" + store.state.theme + "-logo.png";

    function fillFormFromLocalStorage() {
      const emailPassFromLS = localStorage.getItem(LS_KEY_EMAIL_PASSWORD);

      if (
        emailPassFromLS &&
        emailPassFromLS.length > 0 &&
        emailPassFromLS.includes(" ")
      ) {
        const [emailFromLS, passwordFromLS] = emailPassFromLS
          .toString()
          .split(" ");
        email.value = emailFromLS;
        password.value = passwordFromLS;
        rememberMe.value = true;
      }
    }

    fillFormFromLocalStorage();

    const loginHandler = () => {
      waitingLoginProcess.value = true;
      UserService.login(email.value, password.value)
        .then((resp) => {
          localStorage.setItem("api_refresh_token", resp.data.refresh_token);
          localStorage.setItem("api_token_expires_in", resp.data.expires_in);
          store
            .dispatch("setBearerToken", resp.data.access_token)
            .then(() =>
              store.dispatch("setLS", {
                key: LS_KEY_EMAIL_PASSWORD,
                payload: rememberMe.value
                  ? `${email.value} ${password.value}`
                  : null,
              })
            )
            .then(() => store.dispatch("user/fetchAndSetUserInformation"))
            .then(() => {
              let redirectRoute = router.currentRoute.value.query.redirect;
              if (!redirectRoute) router.replace({ name: "home" });
              else router.replace(redirectRoute);
            });
        })
        .catch(({ response }) => {
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: response.data.message,
            confirmButtonText: "Tamam",
          });
        })
        .finally(() => {
          waitingLoginProcess.value = false;
        });
    };

    return {
      logo_url,
      rememberMe,
      email,
      password,
      loginHandler,
      waitingLoginProcess,
      theme: store.state.theme,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 992px) {
  .login {
    min-height: 0;
  }
}
</style>

<template>
  <div>
    <comment-toggler @toggle="isExpanded = $event" />
    <div v-if="isExpanded">
      <video-comment-form :video-id="videoId" @commentAdded="commentAdded" />
      <div v-if="!isCommentsLoading">
        <div v-if="comments.length">
          <single-video-comment
            v-for="(comment, index) in comments"
            :key="index"
            :comment="comment"
          />
          <div
            v-if="hasMoreComments"
            class="w-100 d-flex justify-content-center mb-4"
          >
            <button
              :disabled="isMoreCommentsLoading"
              class="btn text-muted"
              @click="loadMoreComment"
            >
              Devamını Yükle
            </button>
          </div>
        </div>
        <div v-else class="w-100 d-flex justify-content-center mb-4">
          Henüz Yorum Eklenmemiş!
        </div>
      </div>
      <div v-else class="w-100 d-flex justify-content-center mb-4">
        Yorumlar Yükleniyor...
      </div>
    </div>
  </div>
</template>

<script>
import VideoCommentForm from "./partials/VideoCommentForm.vue";
import CommentToggler from "./CommentToggler.vue";
import SingleVideoComment from "./partials/SingleVideoComment.vue";
import CommentService from "../../../services/comment/CommentService";
import { ref } from "@vue/reactivity";

export default {
  name: "VideoComments",
  components: {
    VideoCommentForm,
    SingleVideoComment,
    CommentToggler,
  },
  props: {
    videoId: { type: String, required: true },
  },
  emits: ["commentAdded"],
  setup(props, { emit }) {
    const comments = ref(null);
    const isCommentsLoading = ref(true);
    const isExpanded = ref(true);
    const hasMoreComments = ref(false);
    const isMoreCommentsLoading = ref(false);

    const fetchComments = () => {
      isCommentsLoading.value = true;
      CommentService.getVideoComments(props.videoId, 1, 5)
        .then((res) => {
          comments.value = res.data.data.items;
          hasMoreComments.value =
            res.data.data.current_page < res.data.data.last_page;
        })
        .finally(() => {
          isCommentsLoading.value = false;
        });
    };

    const loadMoreComment = () => {
      isMoreCommentsLoading.value = true;
      CommentService.getVideoComments(
        props.videoId,
        comments.value.length / 5 + 1,
        5
      )
        .then((res) => {
          hasMoreComments.value =
            res.data.data.current_page < res.data.data.last_page;
          comments.value = comments.value.concat(res.data.data.items);
        })
        .finally(() => {
          isMoreCommentsLoading.value = false;
        });
    };

    const commentAdded = () => {
      fetchComments();
      emit("commentAdded");
    };

    fetchComments();

    return {
      comments,
      fetchComments,
      commentAdded,
      isCommentsLoading,
      isExpanded,
      loadMoreComment,
      hasMoreComments,
      isMoreCommentsLoading,
    };
  },
};
</script>

<style></style>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 7.33337H8.66671V4.66671C8.66671 4.4899 8.59647 4.32033 8.47145 4.1953C8.34642 4.07028 8.17685 4.00004 8.00004 4.00004C7.82323 4.00004 7.65366 4.07028 7.52864 4.1953C7.40361 4.32033 7.33338 4.4899 7.33338 4.66671V8.00004C7.33338 8.17685 7.40361 8.34642 7.52864 8.47145C7.65366 8.59647 7.82323 8.66671 8.00004 8.66671H10C10.1769 8.66671 10.3464 8.59647 10.4714 8.47145C10.5965 8.34642 10.6667 8.17685 10.6667 8.00004C10.6667 7.82323 10.5965 7.65366 10.4714 7.52864C10.3464 7.40361 10.1769 7.33337 10 7.33337ZM8.00004 1.33337C6.6815 1.33337 5.39257 1.72437 4.29624 2.45691C3.19991 3.18945 2.34543 4.23064 1.84085 5.44882C1.33626 6.66699 1.20424 8.00744 1.46148 9.30064C1.71871 10.5938 2.35365 11.7817 3.286 12.7141C4.21835 13.6464 5.40623 14.2814 6.69944 14.5386C7.99265 14.7958 9.33309 14.6638 10.5513 14.1592C11.7694 13.6547 12.8106 12.8002 13.5432 11.7038C14.2757 10.6075 14.6667 9.31858 14.6667 8.00004C14.6667 7.12456 14.4943 6.25765 14.1592 5.44882C13.8242 4.63998 13.3331 3.90505 12.7141 3.286C12.095 2.66694 11.3601 2.17588 10.5513 1.84084C9.74243 1.50581 8.87552 1.33337 8.00004 1.33337V1.33337ZM8.00004 13.3334C6.94521 13.3334 5.91406 13.0206 5.037 12.4345C4.15994 11.8485 3.47635 11.0156 3.07269 10.041C2.66902 9.06648 2.5634 7.99412 2.76919 6.95956C2.97498 5.92499 3.48293 4.97468 4.22881 4.2288C4.97469 3.48292 5.925 2.97497 6.95956 2.76919C7.99413 2.5634 9.06648 2.66902 10.041 3.07268C11.0156 3.47635 11.8485 4.15994 12.4345 5.037C13.0206 5.91406 13.3334 6.94521 13.3334 8.00004C13.3334 9.41453 12.7715 10.7711 11.7713 11.7713C10.7711 12.7715 9.41453 13.3334 8.00004 13.3334V13.3334Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "ClockIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "#AFBBDB",
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },
  },
};
</script>

<style></style>

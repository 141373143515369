<template>
  <div class="position-absolute bg-white open-logo-box border-radius">
    <div v-for="(item, index) in zoneLogos" :key="index">
      <div
        v-if="!item.path.includes(theme)"
        class="
          py-2
          px-3
          open-logo-box-img
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <a :href="item.path" target="_blank">
          <img
            :src="item.logo"
            alt=""
            class="img-fluid logo-color height-25px"
          />
          <img
            :src="item.colorlessLogo"
            alt=""
            class="img-fluid logo-colorless height-25px"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZoneLogos",
  props: {
    zoneLogos: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.open-logo-box {
  top: 54px;
  left: -24px;
}

.open-logo-box-img {
  width: 288px;
  height: 75px;
  border-bottom: 1px solid #afbbdb6e;
  transition: 0.3s;

  .logo-color {
    display: none;
    transition: 0.3s;
  }

  &:hover .logo-color {
    display: block;
  }

  &:hover .logo-colorless {
    display: none;
  }
}
</style>

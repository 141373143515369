<template>
  <section class="mb-5">
    <div class="container-xl">
      <h3 class="font-s-24px text-secondary mb-4">
        <b class="text-info"
          >Yeni {{ isType == "makale" ? "Makale" : "Video" }}</b
        >
        Oluştur
      </h3>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <div class="mb-5">
            <p class="font-s-12px font-w-600 gray-500">
              {{ isType == "makale" ? "Makale" : "Video" }} Başlığı
            </p>
            <input
              type="text"
              class="font-s-12px gray-500 border-radius title-input w-100 px-3"
              placeholder="Lütfen makale başlığını yazınız..."
              :value="title"
              @input="$emit('update:title', $event.target.value)"
            />
          </div>
          <div class="d-flex mb-5">
            <a v-if="!changeUpload" class="d-flex mr-4">
              <editor-icon color="#114DAE" class="mr-1" />
              <span class="font-s-12px font-w-500 text-primary"
                >{{ zoneName }} Editörünü Kullan</span
              >
            </a>
            <a
              v-if="changeUpload"
              class="d-flex mr-4 cursor-pointer"
              @click="changeUpload = false"
            >
              <editor-icon color="#7684AA" class="mr-1" />
              <span class="font-s-12px font-w-500 gray-500"
                >{{ zoneName }} Editörünü Kullan</span
              >
            </a>
            <a
              v-if="!changeUpload"
              class="d-flex cursor-pointer"
              @click="changeUpload = true"
            >
              <upload-icon color="#7684AA" class="mr-1" />
              <span class="font-s-12px font-w-500 gray-500"
                >{{ isType == "makale" ? "Makaleyi" : "Videoyu" }} Yükle</span
              >
            </a>
            <a v-if="changeUpload" class="d-flex">
              <upload-icon color="#114DAE" class="mr-1" />
              <span class="font-s-12px font-w-500 text-primary"
                >{{ isType == "makale" ? "Makaleyi" : "Videoyu" }} Yükle</span
              >
            </a>
          </div>
          <div v-show="changeUpload">
            <file-upload-area v-model:files="filesValue" :is-type="isType" />
          </div>
          <div v-show="!changeUpload">
            <vue-editor v-model="contentValue" class="height-530px mb-100px" />
            <!-- <div class="d-flex">
              <a>
                <div class="mr-3 bg-info border-radius-50 p-2">
                  <close-icon :size="25" color="white" />
                </div>
              </a>
              <a>
                <div class="mr-3 bg-white border-radius-50 p-2">
                  <image-icon :size="25" :color="colorDark" />
                </div>
              </a>
              <a>
                <div class="bg-white border-radius-50 p-2">
                  <camera-icon :size="25" :color="colorDark" />
                </div>
              </a>
            </div> -->
          </div>
        </div>

        <div class="col-12 col-md-4 col-lg-3">
          <add-sidebar
            v-model:files="filesValue"
            :color="color"
            :disabled="isSending"
            @send="$emit('send')"
            @save="$emit('save')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueEditor } from "vue3-editor";
import { computed, ref } from "vue";
import AddSidebar from "./AddSidebar.vue";
import FileUploadArea from "./FileUploadArea.vue";
import ZoneHelper from "../../shared/zone-helper";
import { useStore } from "vuex";

const zoneHelper = new ZoneHelper();

export default {
  name: "AddVideoArticle",
  components: {
    VueEditor,
    AddSidebar,
    FileUploadArea,
  },
  props: {
    isType: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    colorDark: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:title", "update:content", "update:files", "send", "save"],
  setup(props, ctx) {
    const changeUpload = ref(false);
    const store = useStore();
    const zoneName = computed(() => zoneHelper.getZoneName(store.state.theme));
    const contentValue = computed({
      get() {
        return props.content;
      },
      set(value) {
        ctx.emit("update:content", value);
      },
    });
    const filesValue = computed({
      get() {
        return props.files;
      },
      set(value) {
        ctx.emit("update:files", value);
      },
    });

    return {
      changeUpload,
      contentValue,
      filesValue,
      zoneName,
    };
  },
};
</script>

<style lang="scss" scoped>
.title-input {
  border: 1px solid #afbbdb;
  height: 45px;
}
</style>

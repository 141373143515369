<template>
  <div class="calculate-score mb-100px">
    <section class="calculate-score-main d-flex flex-column">
      <content-main
        title="Vücut Kitle Endeksi Hesaplama Aracı"
        nav="Vücut Kitle Endeksi Hesaplama Aracı"
        class="mt-md-90px mt-160px mb-3 mb-md-5"
      />

      <div class="container-xl">
        <div
          class="
            p-5
            mb-5
            border-radius
            box-shadow-4-20-3
            bg-white
            d-flex
            flex-column
            align-items-center
            position-relative
          "
        >
          <div
            v-show="showResult"
            class="position-absolute w-100 h-100 z-index-10 result-box"
          >
            <div class="d-flex justify-content-end mr-4">
              <close-icon
                :size="24"
                :color="colorText"
                class="cursor-pointer"
                @click="(showResult = false), clearAll()"
              />
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b class="font-s-16px text-info mb-4">Hesaplama Sonucu</b>
              <div
                class="
                  mb-4
                  result-circle
                  bg-transparent
                  d-flex
                  justify-content-center
                  align-items-center
                  border-radius-50
                "
              >
                <div
                  class="
                    result-content
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    bg-white
                    border-radius-50
                  "
                >
                  <h4
                    class="red-200 font-s-30px font-weight-bold return-number"
                  ></h4>

                  <span
                    class="
                      font-s-12px font-w-500
                      red-200
                      return-text
                      text-center
                    "
                  ></span>
                </div>
              </div>
              <button
                class="btn btn-info btn-result border-radius"
                @click="(showResult = false), clearAll()"
              >
                <calculate-icon class="mr-1" />
                <span class="font-w-500">Yeniden Hesapla</span>
              </button>
            </div>
          </div>
          <form
            id="form_vya"
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center
              mb-4
              calculate-forms
              w-100
            "
          >
            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Boy (cm)</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="boy"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>

            <div class="w-m-100 w-50">
              <div class="mr-md-5 mb-5">
                <h5 class="font-s-20px mb-3 font-w-600 black-700">Kilo (kg)</h5>
                <input
                  type="text"
                  class="
                    w-100
                    px-3
                    box-shadow-4-20-3
                    border-none
                    height-50px
                    border-radius
                    form-control
                  "
                  name="kilo"
                  maxlength="3"
                  max="3"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
            </div>
          </form>

          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-md-center
              w-m-100
            "
          >
            <button class="btn px-4 py-2 mr-2 w-m-100" @click="clearAll()">
              <close-icon :size="16" :color="colorText" class="mr-1" />
              <span class="font-w-500 text-info">Temizle</span>
            </button>
            <button
              class="btn btn-info px-4 py-2 w-m-100 border-radius"
              @click="calculate(), (showResult = true)"
            >
              <calculate-icon class="mr-1" />
              <span class="font-w-500">Hesapla</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-xl">
        <calculate-collapse
          v-for="(item, index) in apgar"
          :key="index"
          :collapse="item"
          class="mb-4"
        />
      </div>
    </section>

    <section>
      <div class="container-xl">
        <div v-if="references.length" class="d-flex flex-column mb-5">
          <span
            class="
              calculater-bottom-btn
              border border-primary
              text-primary
              border-radius-90px
              font-s-12px font-w-500
              px-4
              position-relative
            "
            >İleri Okuma & Kaynak</span
          >
          <div class="calculater-bottom-line w-100" />
        </div>

        <div
          v-for="(item, index) in references"
          :key="index"
          class="d-flex align-items-start mb-3"
        >
          <point-text-icon :size="9" />
          <a :href="item" target="_blank" rel="nofollow" class="text-break">{{
            item
          }}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContentMain from "../../components/ui/ContentMain.vue";
import CalculateCollapse from "../../components/ui/CalculateCollapse.vue";
import CloseIcon from "../../components/icon/CloseIcon.vue";
import CalculateIcon from "../../components/icon/CalculateIcon.vue";
import $ from "jquery";
import { ref } from "vue";
import ColorHelper from "../../helpers/ColorHelper";
import { useStore } from "vuex";

export default {
  name: "BodyMass",
  components: {
    ContentMain,
    CalculateCollapse,
    CloseIcon,
    CalculateIcon,
  },
  setup() {
    const showResult = ref(false);
    const store = useStore();
    const main_img =
      "url('/new-img/" + store.state.theme + "-content-main-bg.png')";
    const colorDark = ColorHelper[store.state.theme].blueDark;
    const colorText = ColorHelper[store.state.theme].blueText;
    const colorRed = ColorHelper[store.state.theme].red200;

    const calculate = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      let form = $("#form_vya");
      $(".return-text").slideDown();
      let error = 0;
      form.find(".is-invalid").removeClass("is-invalid");
      let boy = form.find('input[name="boy"]').val();
      let kilo = form.find('input[name="kilo"]').val();
      if (!boy) {
        form.find('input[name="boy"]').addClass("is-invalid");
        error++;
      }
      if (!kilo) {
        form.find('input[name="kilo"]').addClass("is-invalid");
        error++;
      }
      if (error) {
        $(".return-text").html("Eksik Alanlar Mevcut");
        return false;
      }
      let call = kilo / ((boy * boy) / 10000);
      returnData(parseFloat(call.toFixed(2)), null, "kg / m2");
    };

    const clearAll = () => {
      $("form")[0].reset();
      $(".return-text").slideUp();
    };

    const returnData = (data = null, string = null, type = "") => {
      if (!data || data < 0) {
        data = -1;
      } else {
        data = parseFloat(data);
      }
      if (!string) {
        string = -1;
      }
      if (data == -1 && string == -1) {
        string = "-";
      }
      if (type) {
        if (data > 0) {
          $(".return-number").html(data);
          $(".return-text").html(type);
        } else {
          $(".return-text").html(type);
        }
      } else {
        if (data > 0) {
          $(".return-number").html(data);
        }
      }
    };

    return {
      showResult,
      main_img,
      colorDark,
      colorText,
      colorRed,
      calculate,
      clearAll,
      apgar: [
        {
          title: "Vücut Kitle Endeksi Nedir?",
          content: ` <p>Vücüt kitle endeksi, yetişkin bir bireyin boy/kilo oranını öğrenebileceği ve ideal kilosu hakkında bilgi sahibi olabileceği bir veridir..</p>

        `,
        },
        {
          title: "Vücut Kitle Endeksi Nasıl Hesaplanır?",
          content: `       <p>Vücut kitle endeksi, bireyin vücut kütlesinin kg cinsinden değerinin, vücut uzunluğunun metre cinsinden karesine bölünmesiyle hesaplanır. Yüksek vücut kitle endeksi çeşitli ve ciddi sağlık sorunlarına sebep olabilir.</p>

            <p>Vücut kitle endeksi değerleri yaşa ve ülkeye göre değişse de, Dünya Sağlık Örgütüne göre ortalama bir vücut kitle endeksi tablosu söz konusudur. Bu tablo şöyledir:</p>

            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Kategori</th>
                    <th scope="col">Alt Sınır</th>
                    <th scope="col">Üst Sınır</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Oldukça Ciddi Seviyede Zayıf</th>
                    <td></td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <th scope="row">Ciddi Seviyede Zayıf</th>
                    <td>15</td>
                    <td>16</td>
                  </tr>
                  <tr>
                    <th scope="row">Zayıf</th>
                    <td>16</td>
                    <td>18.5</td>
                  </tr>
                  <tr>
                    <th scope="row">Normal (sağlıklı kilo)</th>
                    <td>18.5</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <th scope="row">Kilolu</th>
                    <td>25</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <th scope="row">Birinci Derece Obez</th>
                    <td>30</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <th scope="row">İkinci Derece Obez</th>
                    <td>35</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <th scope="row">Üçüncü Derece Obez</th>
                    <td>40</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>




        `,
        },
        {
          title: "Nötrofil Düşüklüğü Nedir?",
          content: `
                   <p>Nötrofil düşüklüğü anlamına gelen nötropeniyse genellikle ilaç kullanımıyla alakalı olsa da, farklı sebepleri de olabilir. Bu sebepler şunlar olabilir; kemoterapide kullanılan ilaçlar, bastırılmış bağışıklık sistemi, kemik iliği yetmezliği, aplastik anemi, febril nötropeni, Kostmann sendromu ve siklik nötropeni gibi konjenital hastalıklar, hepatit A, B ya da C, HIV/AIDS, sepsis, otoimmün hastalıklar, lösemi ve myelodisplastik sendromlar.</p>
            <p>Eğer hemogram testindeki nötrofil oranları anormalse, daha derinlemesine inceleme ve değerlendirme yapılmalıdır. Genellikle bir sonraki adım periferik yaymadır.</p>
            <p>Fakat, nötrofil sayısı, nötrofil yükseliği ya da nötrofil düşüklüğünün sebepleri arasında yaş, cinsiyet, kalıtım, deniz seviyesine göre yükseklik, ve test sırasında kullanılan araçlar da yer almaktadır. Bu değişkenlere bağlı olarak, doktorunuz nötrofil yüzdenizi size ve durumunuza uygun olarak yorumlamalıdır.</p>


`,
        },
        {
          title: "Yaşa göre ideal vücut kitle endeksi ise şu şekildedir",
          content: `    <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Yaş</th>
                    <th scope="col">İdeal Vücut Kitle Endeksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">19-24</th>
                    <td>19-24</td>
                  </tr>
                  <tr>
                    <th scope="row">25-34</th>
                    <td>20-25</td>
                  </tr>
                  <tr>
                    <th scope="row">35-44</th>
                    <td>21-26</td>
                  </tr>
                  <tr>
                    <th scope="row">45-54</th>
                    <td>22-27</td>
                  </tr>
                  <tr>
                    <th scope="row">55-65</th>
                    <td>23-28</td>
                  </tr>
                  <tr>
                    <th scope="row">+65</th>
                    <td>24-29</td>
                  </tr>
                </tbody>
              </table>

            <p>20’nin altındaki ve 25’in üstündeki vücut kitle endeksi mortalite yani ölüm riskini artırmaktadır. Aynı zamanda, yüksek vücut kitle indeksine sahip olmak obezite yanında, tip 2 diyabet, hipertansiyon, kanser ve depresyon gibi pek çok sağlık sorununa sebep olabilir.</p>

            <p>Unutulmamalıdır ki, obezite yukarıda bahsedildiği gibi birçok hastalığa sebep olabilir ve her yıl doğrudan ve dolaylı olarak pek çok kişinin hayatını kaybetmesine sebep olabilir. Aynı zamanda, normalden zayıf olmak da çeşitli sağlık sorunlarına sebep olabilir ve sağlığınıza büyük bir tehdit oluşturabilir. Bunlardan korunmak için vücut kitle endeksini kullanarak ideal kilonuzu hesaplayabilir, çıkan sonuca göre aşırı zayıf veya aşırı kiloluysanız önlem almaya başlayabilirsiniz.</p>

            <p>Fakat dikkat edilmesi konulardan biri şudur ki, ideal vücut kitle endeksinde cinsiyet, yaş, coğrafik koşullar ve etnik grup gibi pek çok değişken bulunmaktadır. Her ne kadar ortalama bir vücut kitle endeksinden bahsetmek söz konusu olsa da, bu değişkenlerin de hesaba katılması unutulmamalıdır.</p>

            <p>Örneğin, Asya kökenli bir yetişkin, 25’in altındaki vücut kitle endeksi seviyelerinde daha yüksek oranda sağlık problemleriyle karşılaşabilir.</small></p>

            <p>Vücut kitle endeksinde dikkat edilmesi gereken noktalardan bir diğeriyse kasın yağdan daha ağır olmasıdır. Bu sebeple, boksör, ağırlıkçı ya da diğer sporcuların vücut kitle endeksi obez olarak değerlendirilse de aslında gayet sağlıklıdırlar. </p>

            <p>Hamilelik esnasında ise vücut kitle endeksi değerleri göz önünde bulundurulmamalıdır. Yine de, hamilelik sürecinde kontrollü bir kilo alımı için doktorunuza başvurabilirsiniz.</p>

            <p>Yukarıda verilen bilgiler çerçevesinde, vücut kitle endeksinizi bilmek hayati bir öneme sahip olabilir. Daha sağlıklı bir hayata sahip olmak ve endeksinizi ideal değerler içerisinde tutmak için vücut kitle endeksinizi mutlaka hesaplayın.</p>


`,
        },
      ],
      references: [
        "https://www.nhs.uk/common-health-questions/lifestyle/what-is-the-body-mass-index-bmi/",
        "https://tr.wikipedia.org/wiki/V%C3%BCcut_kitle_indeksi",
        "https://en.wikipedia.org/wiki/Body_mass_index",
        "https://www.medicalpark.com.tr/vucut-kitle-endeksi/hg-2345",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.result-circle {
  width: 188px;
  height: 188px;
  border: 12px solid v-bind("colorRed");
}

.calculate-score-main {
  background: v-bind("main_img");
  background-repeat: no-repeat;
  background-size: 100vw 532px;
}

.calculater-bottom-line {
  height: 1px;
  background-color: v-bind("colorDark");
}
</style>
